import { withLocale } from "@src/utils";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { DetailedHTMLProps, Dispatch, HTMLAttributes, SetStateAction, useCallback, useEffect } from "react";
import { FilterCheckbox } from "../../FilterCheckbox/FilterCheckbox";
import { IItem } from "../../FilterPanel/FilterPanel";
import css from './FilterCategories.module.scss'

interface IFilterCategories extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    name: 'category';
    label?: string;
    items?: IItem[]
}

export const FilterCategories = ({ name, label, items, ...rest }: IFilterCategories) => {
	const { asPath, pathname, push, replace, query } = useRouter();
	const isChecked = useCallback(
		(value?: string) => {
			const querySlug = query?.slug as string[];
			return querySlug.some((item) => item === value);
		},
		[query]
	);

	// const handleCkecked = (value: string, checked: boolean) => {
	// 	const filters = Object.entries(query).filter(([k, v]) => k !== 'slug');

	// 	if (checked) {
	// 		if (!filters.length) {
	// 			replace(`${asPath}?filter[${name}]=${value}`, undefined, { shallow: true });
	// 		}
	// 		if (filters.length) {
	// 			const isInQuery = filters.some(([k, v]) => k === `filter[${name}]`);
	// 			const newQuery = isInQuery
	// 				? filters.reduce((acc, [key, v], i) => {
	// 						if (key === `filter[${name}]`)
	// 							return acc + `${i !== 0 ? '&' : ''}${key}=` + v + `,${value}`;
	// 						return acc + `${i !== 0 ? '&' : ''}${key}=` + v;
	// 				  }, '?')
	// 				: '?' + asPath.split('?')[1] + `&filter[${name}]=${value}`;
	// 			replace(asPath.split('?')[0] + newQuery, undefined, { shallow: true });
	// 		}
	// 	}
	// 	if (!checked) {
	// 		let newQuery = filters.reduce((acc, [key, v], i) => {
	// 			if (key === `filter[${name}]`) {
	// 				const newValue = `${
	// 					!!v && typeof v === 'string'
	// 						? v
	// 								.split(',')
	// 								.filter((item) => item !== value)
	// 								.join(',')
	// 						: ''
	// 				}`;
	// 				return !!newValue.length ? acc + `${i !== 0 ? '&' : ''}${key}=` + newValue : acc;
	// 			}
	// 			return acc + `${i !== 0 ? '&' : ''}${key}=` + v;
	// 		}, '?');
	// 		if (newQuery === '?') newQuery = '';
	// 		replace(asPath.split('?')[0] + newQuery, undefined, { shallow: true });
	// 	}
	// };
	const handleCkecked = useCallback(
		(to: string) => {
			const filters = Object.entries(query)
				.filter(([k, v]) => k !== 'slug')
				.filter(([k, v]) => {
					k !== `filter[${name}]`;
				});
			if (filters.length) {
				const queryStr = filters.reduce((acc, [k, v], i) => {
					return i === 0 ? acc + k + '=' + v : acc + '&' + k + '=' + v;
				}, ``) + `filter[${name}]=${to}`;
				push('/catalog/section' + `/${to}` + queryStr);
				return
			}
			push('/catalog/section' + `/${to}` + `?filter[${name}]=${to}`);
		},
		[query]
	);
	return (
		<div className={css.items} {...rest}>
			{items?.map((item) => {
				const checked = isChecked(item.value);
				return (
					<label
						className={classNames(css.item, { [css.itemActive]: checked })}
						key={item.label + item.value}>
						<FilterCheckbox
							checked={checked}
							itemValue={item.value}
							handler={() => handleCkecked(item.value)}
						/>
						{item.label}
					</label>
					// <Link
					// 	key={item.label + item.value}
					// 	href={withLocale(`/catalog/section/${item.value}?${getQueryString(item.value)}`)}>
					// 	<a className={classNames(css.item, { [css.itemActive]: checked })}>{item.label}</a>
					// </Link>
				);
			})}
		</div>
	);
};