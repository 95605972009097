import css from './CloseBtn.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Button } from '@src/shared';

interface CloseBtnProps extends HTMLAttributes<HTMLButtonElement> {}

export const CloseBtn: FC<PropsWithChildren<CloseBtnProps>> = React.memo(
	({ className, onClick, children }) => {
		const isText = typeof children === 'string';
		return (
			<Button
				className={classnames(className, css.closeBtn, {
					[css.asText]: isText,
					[css.asIcon]: !isText,
				})}
				onClick={onClick}
				color="gray"
				size="sm">
				{children}
			</Button>
		);
	}
);
