import { useEffect, useState } from 'react';
import scrollLocker from '@utils/utils.scroll/ScrollLocker';

export const useLockBodyByDialogs = (flag: boolean) => {
	const [locked, setLocked] = useState(false);

	useEffect(() => {
		let raf = 0;

		if (flag) {
			setLocked(true);
			scrollLocker.lock();
		} else {
			raf = window.requestAnimationFrame(() => {
				const dialogs = document.querySelectorAll('[role="dialog"]');

				if (dialogs.length === 0 && locked) {
					scrollLocker.unlock().then();
				}
			});
		}

		return () => window.cancelAnimationFrame(raf);
	}, [locked, flag]);
};
