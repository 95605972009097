import css from './Catalog.module.scss';
import classnames from 'classnames';
import type { BlockSectionProductsContent } from '@model/blockSectionProductsContent';
import React, { FC, HTMLAttributes, useState } from 'react';
import { Container, Heading, Section } from '@shared';
import { CatalogFeed, CatalogFilter } from '@src/components';
import { CatalogContextProvider } from '@components/Catalog/context/Catalog.context';
import { IFiltersItem } from '../CatalogFilter/components';

interface CatalogProps extends HTMLAttributes<HTMLElement>, BlockSectionProductsContent {
	typeKey: string;
	['catalog-filter']: IFiltersItem[]

}

export const Catalog: FC<CatalogProps> = React.memo((props) => {
	const [section, setSection] = useState<HTMLElement | null>(null);
	return (
		<CatalogContextProvider container={section} navigation={props?.navigation}>
			<Section ref={setSection} className={classnames(css.component)}>
				<CatalogComponent {...props} />
			</Section>
		</CatalogContextProvider>
	);
});

export const CatalogComponent: FC<CatalogProps> = React.memo(
	(props) => {
		const { typeKey, textSectionName, navigation, products, ...rest } = props
		const filterBlock = props['catalog-filter']
		return (
			<>
				<CatalogFilter filterBlock={filterBlock} />
				<Container className={classnames(css.container)}>
					{textSectionName && <Heading title={textSectionName} />}
					<CatalogFeed navigation={navigation} staticData={products} {...rest} />
				</Container>
			</>
		);
	}
);
