import type { ImageType } from '@model/imageType';
import React, { FC } from 'react';
import Image from 'next/image';

interface CartElementPicProps {
	images?: Array<ImageType>;
}

export const CartElementPic: FC<CartElementPicProps> = React.memo(({ images }) => {
	const image = images && images[0];
	return image?.url ? (
		<Image
			src={image.url}
			alt={image?.alt || ''}
			layout="fill"
			objectFit="cover"
			quality={100}
			priority={true}
			sizes="(max-width: 1023px) 25vw, 13vw"
			itemProp="image"
		/>
	) : null;
});
