import css from '../CartElement.module.scss';
import classnames from 'classnames';
import React, { FC } from 'react';
import { CartElemmentComponentProps, InputSpinner } from '@shared';
import { useBxComponents } from '@context';

interface CartElementAmountProps extends CartElemmentComponentProps {
	min?: number;
	max?: number;
	initialValue?: number | string;
}

export const CartElementAmount: FC<CartElementAmountProps> = React.memo(
	({ min = 1, max = 100, initialValue = 1, className, changeAmountHandler, selectable = true }) => {
		const { cart: cartTexts } = useBxComponents();
		const { textCount } = cartTexts;

		const handleChange = (value: number) => {
			changeAmountHandler && changeAmountHandler(value);
		};

		return (
			<div className={classnames(className, css.amount)}>
				{selectable ? (
					<InputSpinner
						min={min}
						max={max}
						step={1}
						defaultValue={initialValue}
						onChangeValue={handleChange}
						aria-label={textCount}
						className={css.amountSpinner}
					/>
				) : (
					initialValue
				)}
			</div>
		);
	}
);
