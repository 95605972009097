import css from './RelatedProducts.module.scss';
import classnames from 'classnames';
import type { BlockBasketRecommendationsContent } from '@model/models';
import React, { FC, HTMLAttributes, MouseEventHandler, useCallback, useRef, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Box, Container, Heading, Section, SliderCursor, Typography } from '@shared';
import { useViewport } from '@context';
import { ProductCard } from '@shared/ProductCard/ProductCard';

interface RelatedProductsProps
	extends HTMLAttributes<HTMLElement>,
		BlockBasketRecommendationsContent {
	perPage?: [number, number, number];
	textSize?: 'sm' | 'md';
}

export const RelatedProducts: FC<RelatedProductsProps> = React.memo(
	({ textRecommend, perPage = [2, 2, 3], textSize = 'md', textPrev, textNext, products = [] }) => {
		const splide = useRef<Splide | null>(null);

		const { vw, isTablet, isMob } = useViewport();
		const perPageCurrent = isTablet ? (isMob ? perPage[0] : perPage[1]) : perPage[2];

		const [sliderState, setSliderState] = useState<'l' | 'r' | 'lr'>('r');
		const [mouseState, setMouseState] = useState<'prev' | 'next' | null>(null);

		const handleSplideUpdate = useCallback(
			(splide: any) => {
				if (splide) {
					const end = splide.Components.Controller.getEnd() + 1;
					const current = splide.Components.Controller.getIndex() + 1;

					const enought = products.length > perPageCurrent;

					const l = current > 1 && enought ? 'l' : '';
					const r = current + perPageCurrent - 1 < end && enought ? 'r' : '';
					const state = (l + r) as 'l' | 'r' | 'lr';

					setSliderState(state);
				}
			},
			[perPageCurrent, products?.length]
		);

		const handleGoTo = (to: number) => {
			const instance = splide?.current?.splide;
			if (instance) {
				const end = instance.Components.Controller.getEnd();
				const current = instance.Components.Controller.getIndex();
				if (current + perPageCurrent - 1 < end || to < 0) {
					instance.Components.Controller.go(current + to);
				}
			}
		};

		const handleClick: MouseEventHandler = (e) => {
			const to =
				e.clientX > vw * 0.75
					? sliderState.includes('r')
						? 1
						: null
					: e.clientX < vw * 0.25
					? sliderState.includes('l')
						? -1
						: null
					: null;

			if (to !== null && !isTablet) {
				e.preventDefault();
				e.stopPropagation();
				handleGoTo(to);
			}
		};

		const handleMouseMove: MouseEventHandler = ({ clientX }) => {
			const state =
				clientX > vw * 0.75
					? sliderState.includes('r')
						? 'next'
						: null
					: clientX < vw * 0.25
					? sliderState.includes('l')
						? 'prev'
						: null
					: null;
			setMouseState((prev) => (state !== prev ? state : prev));
		};

		const handleMouseLeave: MouseEventHandler = (e) => {
			setMouseState(null);
		};

		if (products.length === 0) return null;

		return (
			<Section className={classnames(css.component)}>
				{textRecommend && (
					<Heading className={css.heading} inContainer={true}>
						<Typography as="h2" type="h2">
							{textRecommend}
						</Typography>
					</Heading>
				)}
				<Box
					className={css.cursorArea}
					onClickCapture={handleClick}
					onMouseMove={handleMouseMove}
					onMouseLeave={handleMouseLeave}>
					<Container className={css.navArea}>
						<Splide
							ref={splide}
							onMove={handleSplideUpdate}
							onUpdated={handleSplideUpdate}
							onMounted={handleSplideUpdate}
							options={{
								type: 'slide',
								autoWidth: true,
								arrows: false,
								pagination: false,
								speed: 800,
								rewindSpeed: 1200,
								drag: products.length > perPageCurrent,
							}}
							className={classnames(css.slider, {
								[css.sliderX4]: perPage[2] > 3,
								[css.cursorPointer]: mouseState !== null,
							})}>
							{products?.map((item, i) => (
								<SplideSlide key={`related-product-slider-${i}`}>
									<ProductCard className={css.card} textSize={textSize} index={i} {...item} />
								</SplideSlide>
							))}
						</Splide>
						<SliderCursor textPrev={textPrev} textNext={textNext} state={mouseState} />
						{/*
						<button
							className={classnames(css.prevBtn, css.navBtn)}
							onClick={() => handleGoTo(-1)}
							type="button"
						/>
						<button
							className={classnames(css.nextBtn, css.navBtn)}
							onClick={() => handleGoTo(1)}
							type="button"
						/>
						*/}
					</Container>
				</Box>
			</Section>
		);
	}
);
