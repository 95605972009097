import React, { FC, useState, createContext, useContext } from 'react';
import { useLockBodyByDialogs } from '@hooks';
import { HTMLElementProps } from '@api/hooks/types';

interface HeaderContextProps {
	sectionCode?: string;
	nav: {
		state: boolean;
		setState?: React.Dispatch<React.SetStateAction<boolean>>;
	};
	search: {
		state: boolean;
		setState?: React.Dispatch<React.SetStateAction<boolean>>;
	};
	cart: {
		count: number;
	};
	color: string | null;
	setColor?: React.Dispatch<React.SetStateAction<string | null>>;
	shown: boolean;
	setShown?: React.Dispatch<React.SetStateAction<boolean>>;
	shownForced: boolean;
	setShownForced?: React.Dispatch<React.SetStateAction<boolean>>;
	colorAnimation?: boolean;
	setColorAnimation?: React.Dispatch<React.SetStateAction<boolean>>;
	allowColorAnimation?: boolean;
	setAllowColorAnimation?: React.Dispatch<React.SetStateAction<boolean>>;
}

interface HeaderContextProviderProps extends HTMLElementProps {
	sectionCode?: string;
}

const initialState = {
	nav: {
		state: false,
	},
	search: {
		state: false,
	},
	cart: {
		count: 0,
	},
	color: null,
	shown: true,
	shownForced: false,
};

export const HeaderContext = createContext<HeaderContextProps>(initialState);

export const HeaderContextProvider: FC<HeaderContextProviderProps> = ({
	sectionCode,
	children,
}) => {
	const [navState, setNavState] = useState(false);
	const [searchState, setSearchState] = useState(false);
	const [color, setColor] = useState<HeaderContextProps['color']>(null);
	const [shown, setShown] = useState<boolean>(true);
	const [shownForced, setShownForced] = useState<boolean>(false);
	const [colorAnimation, setColorAnimation] = useState<boolean>(false);
	const [allowColorAnimation, setAllowColorAnimation] = useState<boolean>(false);

	useLockBodyByDialogs(navState);

	return (
		<HeaderContext.Provider
			value={{
				nav: {
					state: navState,
					setState: setNavState,
				},
				search: {
					state: searchState,
					setState: setSearchState,
				},
				cart: {
					count: 0,
				},
				color,
				setColor,
				shown,
				setShown,
				shownForced,
				setShownForced,
				colorAnimation,
				setColorAnimation,
				allowColorAnimation,
				setAllowColorAnimation,
				sectionCode,
			}}>
			{children}
		</HeaderContext.Provider>
	);
};

export const useHeader = () => {
	return useContext(HeaderContext);
};
