import css from './FileLink.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { Icon } from '@shared';

interface FileLinkProps extends HTMLAttributes<HTMLElement> {
	content?: {
		url?: string;
		name?: string;
	};
}

export const FileLink: FC<FileLinkProps> = React.memo(({ content }) => {
	if (!content?.url || !content?.name) return null;

	return (
		<a
			href={content.url}
			download={content.name}
			target="_blank"
			rel="noreferrer noopener"
			className={classnames(css.component)}>
			<span>{content.name}</span>
			<Icon id="doc" />
		</a>
	);
});
