import css from './ErrorPage.module.scss';
import React, { FC, HTMLAttributes } from 'react';
import { Brackets, TopBlock, Typography } from '@shared';

interface RegularPageProps extends HTMLAttributes<HTMLElement> {
	title?: string;
	headingContent?: JSX.Element;
}

export const ErrorPage: FC<RegularPageProps> = React.memo(({ title, headingContent }) => {
	return (
		<TopBlock
			title={title}
			titleAs="h1"
			titleTag="h2"
			titleAlign="center"
			headingContent={
				<Brackets>
					<Typography as="span" raw={true}>
						{headingContent}
					</Typography>
				</Brackets>
			}
			className={css.component}
		/>
	);
});
