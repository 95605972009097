import React, { FC, HTMLAttributes } from 'react';
import { CurrencyType, useCurrency } from '@hooks';

export type CurrencyMode = 'symbol' | 'string';

export interface CurrencyProps extends HTMLAttributes<HTMLSpanElement> {
	mode?: CurrencyMode;
}

const map: Record<CurrencyMode, Record<CurrencyType, string>> = {
	symbol: {
		rub: '₽',
		usd: '$',
		// eu: '€',
	},
	string: {
		rub: 'руб.',
		usd: 'USD',
		// eu: 'EU',
	},
};

export const Currency: FC<CurrencyProps> = React.memo(({ mode = 'symbol' }) => {
	const currency = useCurrency();
	return (
		<>
			<span>{map[mode][currency]}</span>
			<meta itemProp="priceCurrency" content={currency} />
		</>
	);
});
