import React from 'react';
import dynamic from 'next/dynamic';
import { AccountPersonalProps } from '@components/AccountPersonal/AccountPersonal';

export const AccountPersonalDynamic = dynamic<AccountPersonalProps>(
	() => import('./AccountPersonal').then((module) => module.AccountPersonal),
	{
		ssr: true,
		loading: () => <></>,
	}
);
