import { PageData } from '@api/hooks/types';

export const addresses: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Мои адреса | Личный кабинет',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-account-addresses',
			content: {
				sectionsNav: [
					{
						name: 'Личная информация',
						url: '/mocks/account',
					},
					{
						name: 'Заказы',
						url: '/mocks/account/orders',
					},
					{
						name: 'Понравившиеся',
						url: '/mocks/account/wishlist',
					},
					{
						name: 'Мои адреса',
						url: '/mocks/account/addresses',
					},
				],
				logOut: {
					name: 'Выйти',
				},
				empty: {
					title: 'У вас еще нет адресов',
					text: 'Добавьте ваши адреса для быстрого оформления покупок',
					link: {
						url: '/',
						name: 'Добавить адрес',
					},
				},
				textEntrance: 'подъезд',
				textDoor: 'кв.',
				textEdit: 'Изменить',
				textDelete: 'Удалить',
				textFormTitle: 'Адрес доставки',
				textFormHide: 'Скрыть',
				/*
				 * По аналогии с каталогом:
				 * блок навгиации для пагинации и ревалидации данных
				 */
				navigation: {
					next: '/ru/mocks/account/addresses?pageNumber=1&limit=2',
					self: '/ru/mocks/account/addresses',
					countPerPage: 2,
					total: 2,
				},
				addresses: [
					{
						id: 1,
						/*
						 * Роут для
						 * удаления адреса (DELETE),
						 * редактирования адреса (PATCH),
						 * смены флaга isSelected (PATCH)
						 */
						updateUrl: '/account/addresses/1',
						isSelected: true,
						data: {
							country: 'Россия',
							city: 'Санкт-Петербург',
							address: 'ул. Тверская 41',
							entrance: '7',
							door: '4',
							zip: '192300',
						},
					},
					{
						id: 2,
						updateUrl: '/account/addresses/2',
						isSelected: false,
						data: {
							country: 'Россия',
							city: 'Санкт-Петербург',
							address: 'пр. Литейный 200',
							entrance: '7',
							door: '4',
							zip: '192300',
						},
					},
				],
				/*
				 * Форма для создания/редактирования адреса
				 */
				form: {
					/*
					 * Роут для
					 * создания адреса (POST)
					 */
					action: '/account/addresses/',
					method: 'post',
					textButton: 'Сохранить',
					items: [
						{
							type: 'text',
							name: 'country',
							label: 'Страна',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'text',
							name: 'city',
							label: 'Город',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'text',
							name: 'address',
							label: 'Адрес',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'text',
							name: 'entrance',
							label: 'Подъезд',
							required: false,
						},
						{
							type: 'text',
							name: 'door',
							label: 'Квартира',
							required: false,
						},
						{
							type: 'text',
							name: 'zip',
							label: 'Индекс',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
					],
				},
			},
		},
	],
};
