import css from './CartElement.module.scss';
import table from '@shared/CartTable/CartTable.module.scss';
import classnames from 'classnames';
import type { HTMLElementProps } from '@api/hooks/types';
import type { BasketRequestBody } from '@model/basketRequestBody';
import type { BlockBasketProductsContent } from '@model/blockBasketProductsContent';
import type { BasketOffersCatalogProductsElements } from '@model/basketOffersCatalogProductsElements';
import type { BasketProductsElements } from '@model/basketProductsElements';
import React, { FC, useCallback, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import {
	CartElementSum,
	CartElementAmount,
	CartElementColor,
	CartElementInfo,
	CartElementSize,
	CartElementPic,
} from '@shared/CartElement/components';
import { CloseBtn, ColorDot, Typography } from '@src/shared';
import { useBxComponents, usePageLoadState, useViewport } from '@context';
import { useSwipableTools } from '@hooks';
import { fetcher, useData } from '@api';
import { BASKET_ROUTE, withLocale, withoutTrailingSlash } from '@utils';

export type CartElementOfferType = BasketOffersCatalogProductsElements;
export type CartElementProductType = BasketProductsElements;

export interface CartElementProps extends HTMLElementProps, CartElementProductType {
	view?: 'full' | 'compact';
	selectable?: boolean;
	withBorderTop?: boolean;
}

export interface CartElemmentComponentProps extends CartElementProps {
	amount?: number;
	offer?: CartElementOfferType;
	changeSizeHandler?: (offer?: CartElementOfferType) => void;
	changeAmountHandler?: (amount: number) => void;
	removeHandler?: () => void;
	selectable?: boolean;
}

const hideDuration = 800;

export const CartElement: FC<CartElementProps> = React.memo(
	({ view = 'compact', className,  ...rest }) => {
		const { offers, quantity } = rest;

		const { isTablet } = useViewport();
		const isFull = view === 'full' && !isTablet;

		const [offer, setOffer] = useState(offers && offers.find(({ isSelected }) => isSelected));
		const [amount, setAmount] = useState<number>(Number(quantity) || 1);
		const [visible, setVisible] = useState(true);

		const { id, deleteProductUrl = '/' } = rest;

		const { setLoading } = usePageLoadState();
		const { revalidate } = useData<BlockBasketProductsContent>({ url: withLocale(BASKET_ROUTE) });

		const changeSizeHandler = useCallback(
			(offer?: CartElementOfferType) => {
				if (!offer) return;

				setOffer(offer);
				setLoading(true);

				const data: BasketRequestBody = {
					productId: id,
					offerId: offer.id,
					size: offer.size,
					quantity: amount,
				};

				fetcher({
					url: BASKET_ROUTE,
					method: 'POST',
					body: data,
				}).finally(() => {
					setLoading(false);
					revalidate && revalidate();
				});
			},
			[setLoading, id, amount, revalidate]
		);

		const changeAmountHandler = useCallback(
			(amount: number) => {
				if (!offer) return;

				setAmount(amount);
				setLoading(true);

				const data: BasketRequestBody = {
					productId: id,
					offerId: offer.id,
					size: offer.size,
					quantity: amount,
				};

				fetcher({
					url: BASKET_ROUTE,
					method: 'POST',
					body: data,
				}).finally(() => {
					setLoading(false);
					revalidate && revalidate();
				});
			},
			[setLoading, id, offer, revalidate]
		);

		const removeHandler = useCallback(async () => {
			setVisible(false);
			setLoading(true);

			Promise.all([
				fetcher({
					url: `${withoutTrailingSlash(deleteProductUrl)}`,
					method: 'DELETE',
				}),
				new Promise((resolve) => {
					window.setTimeout(resolve, hideDuration);
				}),
			])
				.then(() => {
					revalidate && revalidate();
				})
				.catch(() => {
					setVisible(true);
				})
				.finally(() => {
					setLoading(false);
				});
		}, [setLoading, deleteProductUrl, revalidate]);

		const props = {
			offer,
			amount,
			removeHandler,
			changeSizeHandler,
			changeAmountHandler,
			...rest,
		};

		return (
			<AnimateHeight
				height={visible ? 'auto' : 0}
				animateOpacity={true}
				duration={visible ? 1 : hideDuration}
				className={className}
				>
				{isFull ? <CartElementFull  {...props} /> : <CartElementCompact {...props} />}
			</AnimateHeight>
		);
	}
);

export const CartElementFull: FC<CartElemmentComponentProps> = ({
	amount,
	removeHandler,
	changeSizeHandler,
	changeAmountHandler,
	...props
}) => {
	const { offer, selectable = true } = props;
	const quantity = Number(props?.quantity) || 1;
	const maxQuantity = Number(offer?.restCount) || 1;

	const { cart: cartTexts } = useBxComponents();
	const { textDelete } = cartTexts;

	return (
		<div className={classnames(css.full, table.row)}>
			<CartElementInfo className={table.cell} {...props}>
				{selectable && (
					<CloseBtn className={css.removeBtn} onClick={() => removeHandler && removeHandler()}>
						{textDelete}
					</CloseBtn>
				)}
			</CartElementInfo>
			<CartElementColor className={table.cell} {...props} />
			<CartElementSize {...props} setSelected={changeSizeHandler} className={table.cell} />
			<CartElementAmount
				{...props}
				max={maxQuantity}
				initialValue={quantity}
				changeAmountHandler={changeAmountHandler}
				className={table.cell}
			/>
			<CartElementSum className={table.cell} amount={amount} {...props} />
		</div>
	);
};

export const CartElementCompact: FC<CartElemmentComponentProps> = ({
	amount,
	removeHandler,
	changeSizeHandler,
	changeAmountHandler,
	...props
}) => {
	const { name, detailPageUrl, offer, images, color, colorText, selectable = true, article, withBorderTop = false } = props;
	const { cart: cartTexts } = useBxComponents();
	const { textArticle, textColor, textCount, textDelete, textSize } = cartTexts;

	const quantity = Number(props?.quantity) || 1;
	const maxQuantity = Number(offer?.restCount) || 1;

	const { isMob } = useViewport();
	const [element, setElementRef] = useState<HTMLElement | null>(null);

	useSwipableTools(element, {
		size: 82,
		skip: !isMob,
	});

	return (
		<div ref={setElementRef} className={classnames(css.compact, withBorderTop && css.compact_withBorder)}>
			<div className={classnames(css.layout)}>
				<a
					href={detailPageUrl}
					target="_blank"
					rel="noreferrer noopener"
					tabIndex={-1}
					className={css.pic}>
					<CartElementPic images={images} />
				</a>
				<div className={css.main}>
					<div className={css.top}>
						<Typography className={css.title} size="md">
							<a href={detailPageUrl} target="_blank" rel="noreferrer noopener">
								{name}
							</a>
						</Typography>

						<CartElementSum className={css.price} amount={amount} {...props} />

						<Typography className={css.article} size="sm" color="gray">
							{textArticle}: {article}
						</Typography>
					</div>
					<div className={css.bottom}>
						<div className={css.bottomItem}>
							<Typography className={css.bottomItemCaption} size="sm">
								{textColor}
							</Typography>
							<ColorDot className={css.colorDot} color={color} />
						</div>

						<div className={css.bottomItem}>
							<Typography className={css.bottomItemCaption} size="sm">
								{textSize}
							</Typography>
							<CartElementSize {...props} setSelected={changeSizeHandler} />
						</div>

						<div className={css.bottomItem}>
							<Typography className={css.bottomItemCaption} size="sm">
								{textCount}
							</Typography>
							<CartElementAmount
								{...props}
								max={maxQuantity}
								initialValue={quantity}
								changeAmountHandler={changeAmountHandler}
							/>
						</div>
					</div>
				</div>
			</div>
			{selectable && (
				<div className={classnames(css.tools)}>
					<CloseBtn className={css.removeBtn} onClick={() => removeHandler && removeHandler()}>
						{textDelete}
					</CloseBtn>
				</div>
			)}
		</div>
	);
};
