import css from './ProductDescription.module.scss';
import classnames from 'classnames';
import type { ProductDetailsProps } from '@components';
import React, { FC, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Spoiler, Typography } from '@shared';
import { useProductDetails } from '@components';

interface ProductDescriptionProps extends ProductDetailsProps {}

export const ProductDescription: FC<ProductDescriptionProps> = ({
	description = [],
	textArticle,
	article
}) => {
	// const { article } = useProductDetails();
	const articleItem = useMemo(
		() =>
			ReactDOMServer.renderToStaticMarkup(
				<p className="color-gray">
					{textArticle} : {article}
				</p>
			),
		[textArticle, article]
	);

	return (
		<div className={classnames(css.description)}>
			{description?.map(({ textTitle, value }, i) => (
				<Spoiler
					className={css.section}
					defaultActive={i === 0}
					head={<Typography size="md">{textTitle}</Typography>}
					key={`description-${textTitle}-${i}`}>
					<Typography className="wysiwyg wysiwyg--sm" size="sm" raw={true}>
						{(i === 0 ? articleItem : '') + value}
					</Typography>
				</Spoiler>
			))}
		</div>
	);
};
