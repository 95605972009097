import { PageData } from '@api/hooks/types';

export const product: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Платье с V-образным вырезом | Agapium',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-catalog-product',
			content: {},
		},
		{
			type: 'block-catalog-sizes-table',
			content: {},
		},
		{
			type: 'block-catalog-recommendations',
			content: {},
		},
	],
};
