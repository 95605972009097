import axios, { AxiosError, AxiosResponse } from 'axios';
import { getHeaders, getUrl, toFormData } from '@utils';

type CRUD = 'options' | 'get' | 'post' | 'put' | 'patch' | 'delete';
export type CRUDType = CRUD | Uppercase<CRUD>;

export interface FetchConfig {
	url: string;
	method?: CRUDType;
	headers?: Headers;
	body?: any;
}

const transformErrorData = (data?: Record<string, string>) => {
	if (!data) return data;

	return Object.entries(data).map((entry) => {
		return `Поле "${entry[0]}": ${entry[1]}.`.replace(/(\.+)$/, '.');
	});
};

const handleError = (error: AxiosError) => {
	const res = error?.response as AxiosResponse;
	const errorText =
		res?.data?.detail ||
		(res?.status === 404
			? 'Эндпоинт не найден, проверьте запрос к API'
			: transformErrorData(res?.data) || 'Неизвестная ошибка');

	if (typeof window !== 'undefined') {
		console.warn(errorText, res);
	} else {
		console.warn(`Ошибка ${res?.status}`, res?.config.url);
	}

	throw error;
};

export const fetcher = <Model>(args: FetchConfig | string) => {
	const {
		url,
		method = 'GET',
		headers = new Headers({}),
		body = undefined,
	} = typeof args === 'string' ? { url: args } : args;

	// const access = getJWT();
	// const refresh = getJWT('refresh');

	axios.interceptors.response.use(
		(response) => response,
		(error) => {
			/*
			 * JWT refresh
			 * При коде статуса 401 пробуем рефрешить токен
			 */
			// if (error?.response?.status === 401) {
			// 	if (error.response.config.url.match('auth/refresh')) {
			// 		resetJWT();
			// 		window.location.href = '/login';
			// 		return error;
			// 	}
			//
			// 	if (!access || !refresh) {
			// 		error.response.status = 401;
			// 		resetJWT();
			// 		window.location.href = '/login';
			// 		return error;
			// 	}
			//
			// 	return refreshToken()
			// 		.then(() => {
			// 			return axios({
			// 				method: error.response.config.method,
			// 				url: error.response.config.url,
			// 				headers: getHeaders(new Headers(error.response.config.headers)),
			// 				data: error.response.config.data,
			// 			})
			// 				.then((response) => {
			// 					return response;
			// 				})
			// 				.catch((error) => {
			// 					return handleError(error);
			// 				});
			// 		})
			// 		.catch((error) => {
			// 			resetJWT();
			// 			window.location.href = '/login';
			// 			return error;
			// 		});
			// } else {
			//  throw error;
			// }
			throw error;
		}
	);

	return axios({
		method: method,
		url: getUrl(url),
		headers: headers === null ? {} : getHeaders(headers),
		data: body && headers?.get('Content-Type') === 'multipart/form-data' ? toFormData(body) : body,
		withCredentials: true,
	})
		.then((response) => {
			try {
				return response.data as Model;
			} catch (e) {
				throw response;
			}
		})
		.catch((error) => {
			if (error?.response?.status !== 401) {
				return handleError(error);
			}
			return error;
		});
};
