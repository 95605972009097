import css from './Table.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren, useMemo } from 'react';
import { Scroller } from '@src/shared';
import { TableRowsColumns } from '@model/tableRowsColumns';

type WrapperType = FC<PropsWithChildren<HTMLAttributes<HTMLElement>>>;
type TableData = Array<TableRowsColumns>; // Record<string, string>[];

interface TableProps extends HTMLAttributes<HTMLTableElement> {
	data?: TableData;
	withScroll?: boolean;
}

export const Table: FC<TableProps> = React.memo(({ data, className, withScroll = true }) => {
	const Wrapper = withScroll ? ScrollWrapper : DefaultWrapper;

	const mapped = useMemo(
		() => data?.map((row) => (Array.isArray(row.columns) ? row.columns : Object.values(row))),
		[data]
	);

	if (!mapped) return null;

	return (
		<Wrapper>
			<table className={classnames(className, css.table)}>
				<thead>
					{mapped.slice(0, 1).map((row, i) => (
						<tr key={`thead-row-${i}-${row}`}>
							{row.map(({ text }, i) => (
								<th key={`thead-cell-${i}-${text}`}>{text}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody>
					{mapped.slice(1).map((row, i) => (
						<tr key={`tbody-row-${i}-${row}`}>
							{row.map(({ text }, i) => (
								<td key={`tbody-cell-${i}-${text}`}>{text}</td>
							))}
						</tr>
					))}
				</tbody>
			</table>
		</Wrapper>
	);
});

const ScrollWrapper: WrapperType = ({ children = [] }) => {
	return (
		<Scroller className={css.scroller} options={{ wheelPropagation: true }} axis="x">
			{children}
		</Scroller>
	);
};

const DefaultWrapper: WrapperType = ({ children }) => {
	return <>{children}</>;
};
