import classNames from 'classnames';
import { useRouter } from 'next/router';
import { DetailedHTMLProps, HTMLAttributes, useCallback } from 'react';
import { FilterCheckbox } from '../../FilterCheckbox/FilterCheckbox';
import { IItem } from '../../FilterPanel/FilterPanel';
import css from './FilterSizes.module.scss';

interface IFilterSizes extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	name: 'sizes';
	label?: string;
	items?: IItem[];
}

export const FilterSizes = ({ name, label, items, ...rest }: IFilterSizes) => {
	const { asPath, pathname, push, replace, query } = useRouter();
	const isChecked = useCallback(
		(value?: string) => {
			const currentQuery = query?.[`filter[${name}]`] as string;
			return currentQuery ? !!currentQuery.split(',').find((item) => item === value) : false;
		},
		[name, query]
	);
	const handleCkecked = (value: string, checked: boolean) => {
		const filters = Object.entries(query).filter(([k, v]) => k !== 'slug');

		if (checked) {
			if (!filters.length) {
				replace(`${asPath}?filter[${name}]=${value}`, undefined, { shallow: true });
			}
			if (filters.length) {
				const isInQuery = filters.some(([k, v]) => k === `filter[${name}]`);
				const newQuery = isInQuery
					? filters.reduce((acc, [key, v], i) => {
							if (key === `filter[${name}]`)
								return acc + `${i !== 0 ? '&' : ''}${key}=` + v + `,${value}`;
							return acc + `${i !== 0 ? '&' : ''}${key}=` + v;
					  }, '?')
					: '?' + asPath.split('?')[1] + `&filter[${name}]=${value}`;
				replace(asPath.split('?')[0] + newQuery, undefined, { shallow: true });
			}
		}
		if (!checked) {
			let newQuery = filters.reduce((a, [key, v], i) => {
				if (key === `filter[${name}]`) {
					const newValue = `${
						!!v && typeof v === 'string'
							? v
									.split(',')
									.filter((item) => item !== value)
									.join(',')
							: ''
					}`;
					return !!newValue.length ? a + `${i !== 0  ? '&' : ''}${key}=` + newValue : a;
				}
				return a + `${i !== 0 ? '&' : ''}${key}=` + v;
			}, '?');
			if (newQuery === '?') newQuery = '';
			if (newQuery.split('&').length === 2 && newQuery.split('&').some(item => item  === '?')) newQuery = newQuery.split('&').join('')
			replace(asPath.split('?')[0] + newQuery, undefined, { shallow: true });
		}
	};
	return (
		<div {...rest} className={css.items}>
			{items?.map((item) => {
				const checked = isChecked(item.value);
				return (
					<label
						className={classNames(css.item, { [css.itemActive]: checked })}
						key={item.label + item.value}>
						<FilterCheckbox key={item.label+'input'} checked={checked} itemValue={item.value} handler={handleCkecked} />
						{item.label}
					</label>
				);
			})}
		</div>
	);
};
