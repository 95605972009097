import css from './ProductPurchase.module.scss';
import classnames from 'classnames';
import type { ProductDetailsProps } from '@components';
import React, {
	FC,
	HTMLAttributes,
	MouseEventHandler,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Link from 'next/link';
import { Button, ColorDot, Icon, useCart } from '@shared';
import { PopUp, PopUpBody, usePopUp } from '@shared/PopUp';
import { useViewport } from '@context';
import { useOffers } from '@hooks/useOffers';
import { useProductDetails } from '@components';
import { updateCart, useCartData } from '@components/Cart/hooks';
import { fetcher } from '@src/api';
import { browser } from 'process';
import { userInfo } from 'os';

export interface OfferChoice {
	value?: string;
	label?: string;
	color?: string;
	disabled?: boolean;
}

export interface OfferSelector {
	name?: string;
	label?: string;
	items?: OfferChoice[];
	selected?: OfferChoice;
	setSelected?: (state: OfferChoice) => void;
	showSelectedText?: boolean;
}

interface ProductPurchaseProps extends ProductDetailsProps {}

export const ProductPurchase: FC<ProductPurchaseProps> = React.memo(
	({ id, color, colorText, differentColors, offers, inWishlist, updateWishlist, ...rest }) => {

		const [addedToWishlist, setAddedToWishlist] = useState(inWishlist);
		const { textColor = 'Цвет' } = rest;
		const { isMob } = useViewport();
		const { toggle: togglePopup } = usePopUp();
		const { setShowNotice: setShowCartNotice } = useCart();
		/*
		 * Обработка ТП товара
		 */
		const { sizes, selectedSize, setSelectedSize } = useOffers(offers);

		/*
		 * Открыть таблицу размеров
		 */
		const openSizeTable: MouseEventHandler = (e) => {
			e.preventDefault();
			togglePopup && togglePopup('sizes-table');
		};

		/*
		 * Добавить в корзину
		 */
		const { offer } = useProductDetails();
		const { data: cart, revalidate } = useCartData();

		const amountInCart = useMemo(() => {
			const items = cart?.products || [];
			const inCart = items.find((item) => {
				return item.offers?.some((item) => item.id === offer?.id);
			});
			return inCart?.quantity ? Number(inCart.quantity) : 0;
		}, [cart?.products, offer?.id]);

		const addToCart = useCallback(() => {
			if (!id || !offer?.id || !offer?.size) return;

			updateCart({
				productId: id,
				offerId: offer?.id,
				quantity: amountInCart + 1,
			})

				.then(() => {
					if (!isMob) {
						togglePopup && togglePopup('cart-widget');
					} else {
						setShowCartNotice && setShowCartNotice(true);
						togglePopup && togglePopup('purchase-mobile');
					}
				})
				.finally(() => {
					revalidate && revalidate();
				});
		}, [
			amountInCart,
			id,
			offer?.id,
			offer?.size,
			isMob,
			setShowCartNotice,
			revalidate,
			togglePopup,
		]);

		const handleAddToCart: MouseEventHandler = useCallback(
			(e) => {
				e.preventDefault();

				if (isMob) {
					togglePopup && togglePopup('purchase-mobile');
					return;
				}

				addToCart();
			},
			[addToCart, isMob, togglePopup]
		);

		/*
		 * Обновить вишлист
		 */
		const handleAddToWishlist: MouseEventHandler = useCallback((e) => {
			e.preventDefault();

			if (!updateWishlist) return;

			fetcher<FormResponse>({
				url: updateWishlist,
				method: 'PUT',
			})
				.then((res) => setAddedToWishlist((prev) => !prev))
				.catch((e) => console.log(e));
		}, [updateWishlist]);

		const isIphone = typeof window !== 'undefined' ? navigator.platform === 'iPhone' && navigator.vendor.split(' ').some(item => item === "Apple") : false;

		return (
			<div className={classnames(css.purchase)}>
				<div className={css.selectors}>
					<ProductColors
						label={textColor}
						color={color}
						colorText={colorText}
						differentColors={differentColors}
					/>
					<ProductPurchaseSelector
						name="size"
						label="Размер"
						selected={selectedSize}
						setSelected={setSelectedSize}
						items={sizes}>
						<Button type="button" onClick={openSizeTable}>
							Таблица размеров
						</Button>
					</ProductPurchaseSelector>
				</div>

				<div className={css.buttons}>
					{offer &&<Button
						className={css.addToCart}
						onClick={handleAddToCart}
						type="button"
						design="stroked"
						wide={true}
						disabled={!offer?.isAvailable && !isMob || !offer.restCount && !isMob || offer.restCount === 0 && !isMob}>
						В корзину
					</Button>}

					<Button
						className={classnames(css.addToWishlist, {[css.addedToWishlist]: addedToWishlist})}
						onClick={handleAddToWishlist}
						type="button"
						design="stroked">
						<Icon id="fav" />
						<Icon id="heart" />
					</Button>
				</div>

				<PopUp name="purchase-mobile">
					<PopUpBody>
						<div className={classnames(css.popupPurchase, {
							[css.popupPurchaseIphone]: isIphone,
						})}>
							<ProductPurchaseSelector
								name="size-purchase"
								selected={selectedSize}
								setSelected={setSelectedSize}
								items={sizes}
								className={css.popupPurchaseSizes}
								classNameFeed={css.popupPurchaseSizesFeed}
								classNameChoice={css.popupPurchaseSizesChoice}
							/>
							<Button
								className={css.popupAddToCart}
								onClick={addToCart}
								type="button"
								design="stroked"
								wide={true}
								disabled={!offer?.isAvailable || offer.restCount === 0}>
								В корзину
							</Button>
							<div className={css.popupTools}>
								<Button type="button" onClick={openSizeTable}>
									Таблица размеров
								</Button>
							</div>
						</div>
					</PopUpBody>
				</PopUp>
			</div>
		);
	}
);

type ProductColorsProps = Pick<ProductDetailsProps, 'color' | 'colorText' | 'differentColors'> & {
	label?: string;
	className?: string;
	classNameFeed?: string;
	classNameChoice?: string;
};

export const ProductColors: FC<ProductColorsProps> = ({
	color,
	colorText,
	differentColors = [],
	label,
	className,
	classNameFeed,
	classNameChoice,
}) => {
	const list = useMemo(
		() => [
			{
				color,
				colorText,
				url: undefined,
			},
			...differentColors ?? [],
		],
		[color, colorText, differentColors]
	);

	return (
		<>
			<div className={classnames(className, css.selector)}>
				<div className={css.selectorLabel}>
					{label}: {colorText?.toLowerCase()}
				</div>
				<div className={classnames(classNameFeed, css.selectorFeed)}>
					{list?.map(({ color, colorText, url }) => (
						<ProductPurchaseSelectorLink
							url={url}
							color={color}
							label={colorText}
							key={`${color}-${colorText}`}
							className={classNameChoice}
						/>
					))}
				</div>
			</div>
		</>
	);
};

type ProductPurchaseSelectorProps = OfferSelector &
	HTMLAttributes<HTMLElement> & {
		className?: string;
		classNameFeed?: string;
		classNameChoice?: string;
	};

export const ProductPurchaseSelector: FC<ProductPurchaseSelectorProps> = ({
	name,
	label,
	items = [],
	children,
	showSelectedText = false,
	selected,
	setSelected,
	className,
	classNameFeed,
	classNameChoice,
}) => {
	const caption = useMemo(
		() => (showSelectedText ? `${label}: ${selected?.label?.toLowerCase() || ``}` : label),
		[label, showSelectedText, selected]
	);

	return (
		<>
			<fieldset className={classnames(className, css.selector)}>
				{caption && <div className={css.selectorLabel}>{caption}</div>}
				<div className={classnames(classNameFeed, css.selectorFeed)}>
					{items?.map((choice, i, self) => {
						return (
							<ProductPurchaseSelectorChoice
								{...choice}
								name={name}
								className={classNameChoice}
								key={`${name}-${choice.label}`}
								checked={choice.value === selected?.value && !choice.disabled}
								onChange={() => {
									setSelected && setSelected(choice);
								}}
							/>
						);
					})}
				</div>
				{children && <div className={css.selectorAdditional}>{children}</div>}
			</fieldset>
		</>
	);
};

type ProductPurchaseSelectorChoiceProps = OfferChoice &
	HTMLAttributes<HTMLElement> & { name?: string; checked?: boolean };

export const ProductPurchaseSelectorChoice: FC<ProductPurchaseSelectorChoiceProps> = ({
	name,
	disabled,
	checked,
	onChange,
	color,
	label,
	className,
}) => {
	return (
		<label className={classnames(className, css.selectorChoice)}>
			<input type="radio" name={name} onChange={onChange} checked={checked} disabled={disabled} />
			<div>{color ? <ColorDot color={color} /> : <span>{label}</span>}</div>
		</label>
	);
};

type ProductPurchaseSelectorLinkProps = OfferChoice &
	HTMLAttributes<HTMLElement> & { url?: string };

export const ProductPurchaseSelectorLink: FC<ProductPurchaseSelectorLinkProps> = ({
	url,
	color,
	label,
	className,
}) => {
	const Tag = url ? 'a' : 'span';
	const item = (
		<Tag
			aria-label={label}
			className={classnames(className, css.selectorLink, { [css.selectorLinkActive]: !url })}>
			<div>
				<ColorDot color={color} />
			</div>
		</Tag>
	);

	return url ? (
		<Link href={url} prefetch={true}>
			{item}
		</Link>
	) : (
		item
	);
};
