import React from 'react';
import dynamic from 'next/dynamic';
import { AccountAddressesProps } from '@components/AccountAddresses/AccountAddresses';

export const AccountAddressesDynamic = dynamic<AccountAddressesProps>(
	() => import('./AccountAddresses').then((module) => module.AccountAddresses),
	{
		ssr: true,
		loading: () => <></>,
	}
);
