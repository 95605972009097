import css from './ContactsForm.module.scss';
import classnames from 'classnames';
import type { BlockContacts } from '@api/mock/types';
import type { FormElementType } from '@api/mock/types';
import React, { FC, HTMLAttributes, useCallback, useState } from 'react';
import Image from 'next/image';
import { Box, Button, Section, Typography } from '@shared';
import { FormComponent, FormSumbitHandlerType } from '@shared/Form/FormComponent/FormComponent';
import { FormChild } from '@shared/Form/FormComponent/components/FormChild/FormChild';
import { handleFormResponse, submitForm } from '@api/utils/submit';

interface ContactsFormProps
	extends HTMLAttributes<HTMLElement>,
		Pick<BlockContacts, 'form' | 'formImage'> {}

export const ContactsForm: FC<ContactsFormProps> = ({ form, formImage }) => {
	const { textTitle, textButton } = form || {};

	const [isLoading, setIsloading] = useState(false);

	const handleSubmit: FormSumbitHandlerType = useCallback(
		(e, ctx) => {
			e.preventDefault();

			if (!form) return;

			const formEl = e.target as HTMLFormElement;
			const formData = new FormData(formEl);

			if (form.providedData) {
				for (const key in form.providedData) {
					if (form.providedData.hasOwnProperty(key)) {
						formData.append(key, form.providedData[key]);
					}
				}
			}

			if (ctx?.setFormState) {
				ctx.setFormState('pending');
			}

			setIsloading(true);

			/*
			 * Проверка каптчи
			 */
			const checkCaptcha =
				ctx?.checkCaptcha ||
				(() => new Promise<string>((resolve) => setTimeout(() => resolve(''), 10)));

			checkCaptcha()
				.then((token) => {
					formData.append('recaptcha', token || '');

					if (!form.action) return;

					/*
					 * Запрос
					 */
					submitForm({
						url: form.action,
						method: (form.method || 'post') as CRUDType,
						body: formData,
					})
						/*
						 * Обработка ответа
						 */
						.then((response) => {
							setIsloading(false);
							return handleFormResponse(response, ctx);
						});
				})
				.catch((error) => {
					console.warn(error);
				});
		},
		[form]
	);

	return (
		<Section className={classnames(css.component)}>
			<Box className={classnames(css.column, css.decor)}>
				<picture>
					{formImage?.url && (
						<Image
							src={formImage.url.replace(/\s/gm, '')}
							alt={formImage.alt || ''}
							layout="fill"
							objectFit="cover"
							quality={100}
							priority={true}
							sizes="(max-width: 1023px) 100vw, 50vw"
						/>
					)}
				</picture>
			</Box>
			<Box className={classnames(css.column, css.form)}>
				{form && (
					<FormComponent grcKey={form?.grcKey} onSubmit={handleSubmit}>
						<Typography className={css.formTitle} type="h2">
							{textTitle}
						</Typography>
						<Box className={css.formBody}>
							{form?.items?.map((item: FormElementType, i) => {
								if (!item.name) return null;

								const key = `form-child-${i}`;

								switch (item.type) {
									default:
										return (
											<FormChild
												key={key}
												className={css.formElement}
												{...(item as FormFieldType)}
											/>
										);
								}
							})}
						</Box>
						<Box className={css.formFooter}>
							<Button type="submit" design="stroked" isLoading={isLoading} wide={true}>
								{textButton}
							</Button>
						</Box>
					</FormComponent>
				)}
			</Box>
		</Section>
	);
};
