import css from './Cart.module.scss';
import classnames from 'classnames';
import type { BlockBasketProductsContent } from '@model/blockBasketProductsContent';
import React, { FC, HTMLAttributes } from 'react';
import AnimateHeight from 'react-animate-height';
import { Section } from '@shared';
import { CartEmpty } from '@components/Cart/components/CartEmpty/CartEmpty';
import { CartContent } from '@components/Cart/components/CartContent/CartContent';
import { useCartData } from '@components/Cart/hooks';

export interface CartProps extends HTMLAttributes<HTMLElement>, BlockBasketProductsContent {}

export const Cart: FC<CartProps> = React.memo(({ products, basketPrice, ...props }) => {
	const { data } = useCartData();

	const items = data?.products || products || [];
	const summary = data?.basketPrice || basketPrice;

	const isEmpty = items.length <= 0;

	return (
		<Section className={classnames(css.component)}>
			<AnimateHeight
				className={css.animatedBox}
				duration={800}
				height={isEmpty ? 'auto' : 0}
				animateOpacity={true}>
				<CartEmpty />
			</AnimateHeight>
			<AnimateHeight
				className={css.animatedBox}
				duration={800}
				height={!isEmpty ? 'auto' : 0}
				animateOpacity={true}>
				<CartContent {...props} items={items} summary={summary} />
			</AnimateHeight>
		</Section>
	);
});
