import { PageData } from '@api/hooks/types';

export const privacy: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Политика конфиденциальности | Политика',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-text-page',
			content: {
				textTitle: 'Политика',
				sectionsNav: [
					{
						name: 'Политика конфиденциальности',
						url: '/client-service/privacy-police',
					},
					{
						name: 'Настройки файлов Cookie',
						url: '/client-service/cookies-police',
					},
				],
				textMain: [
					{
						type: 'html',
						content:
							'<h4>1. Общие положения</h4>' +
							'<p>1.1 Настоящее Соглашение о&nbsp;конфиденциальности персональной информации (далее&nbsp;&mdash; Соглашение) действует в&nbsp;отношении всей информации, которую ИП&nbsp;&laquo;Такое то&raquo; (далее&nbsp;&mdash; компания &laquo;Agapium&raquo;) может получить о&nbsp;пользователе во&nbsp;время использования им&nbsp;сайта, сервисов, служб, программ и&nbsp;продуктов Компании &laquo;Agapium&raquo; (далее&nbsp;&mdash; Сервисы).</p>' +
							'<h4>2. Термины и понятия, используемые в настоящем Соглашении</h4>' +
							'<p>2.1 Настоящее Соглашение о&nbsp;конфиденциальности персональной информации (далее&nbsp;&mdash; Соглашение) действует в&nbsp;отношении всей информации, которую ИП&nbsp;&laquo;Такое то&raquo; (далее&nbsp;&mdash; компания &laquo;Agapium&raquo;) может получить о&nbsp;пользователе во&nbsp;время использования им&nbsp;сайта, сервисов, служб, программ и&nbsp;продуктов Компании &laquo;Agapium&raquo; (далее&nbsp;&mdash; Сервисы).</p>' +
							'<h4>3. Цели сбора и обработки персональной информации пользователей</h4>' +
							'<p>3.1 Настоящее Соглашение о&nbsp;конфиденциальности персональной информации (далее&nbsp;&mdash; Соглашение) действует в&nbsp;отношении всей информации, которую ИП&nbsp;&laquo;Такое то&raquo; (далее&nbsp;&mdash; компания &laquo;Agapium&raquo;) может получить о&nbsp;пользователе во&nbsp;время использования им&nbsp;сайта, сервисов, служб, программ и&nbsp;продуктов Компании &laquo;Agapium&raquo; (далее&nbsp;&mdash; Сервисы).</p>' +
							'<h4>4. Условия обработки персональной информации пользователя и её передачи третьим лицам</h4>' +
							'<p>4.1 Настоящее Соглашение о&nbsp;конфиденциальности персональной информации (далее&nbsp;&mdash; Соглашение) действует в&nbsp;отношении всей информации, которую ИП&nbsp;&laquo;Такое то&raquo; (далее&nbsp;&mdash; компания &laquo;Agapium&raquo;) может получить о&nbsp;пользователе во&nbsp;время использования им&nbsp;сайта, сервисов, служб, программ и&nbsp;продуктов Компании &laquo;Agapium&raquo; (далее&nbsp;&mdash; Сервисы).</p>' +
							'<h4>5. Изменение пользователем персональной информации</h4>' +
							'<p>5.1 Настоящее Соглашение о&nbsp;конфиденциальности персональной информации (далее&nbsp;&mdash; Соглашение) действует в&nbsp;отношении всей информации, которую ИП&nbsp;&laquo;Такое то&raquo; (далее&nbsp;&mdash; компания &laquo;Agapium&raquo;) может получить о&nbsp;пользователе во&nbsp;время использования им&nbsp;сайта, сервисов, служб, программ и&nbsp;продуктов Компании &laquo;Agapium&raquo; (далее&nbsp;&mdash; Сервисы).</p>' +
							'<h4>6. Подтверждение Соглашения</h4>' +
							'<p>6.1 Настоящее Соглашение о&nbsp;конфиденциальности персональной информации (далее&nbsp;&mdash; Соглашение) действует в&nbsp;отношении всей информации, которую ИП&nbsp;&laquo;Такое то&raquo; (далее&nbsp;&mdash; компания &laquo;Agapium&raquo;) может получить о&nbsp;пользователе во&nbsp;время использования им&nbsp;сайта, сервисов, служб, программ и&nbsp;продуктов Компании &laquo;Agapium&raquo; (далее&nbsp;&mdash; Сервисы).</p>' +
							'<h4>7. Изменение Соглашения о конфиденциальности</h4>' +
							'<p>7.1 Настоящее Соглашение о&nbsp;конфиденциальности персональной информации (далее&nbsp;&mdash; Соглашение) действует в&nbsp;отношении всей информации, которую ИП&nbsp;&laquo;Такое то&raquo; (далее&nbsp;&mdash; компания &laquo;Agapium&raquo;) может получить о&nbsp;пользователе во&nbsp;время использования им&nbsp;сайта, сервисов, служб, программ и&nbsp;продуктов Компании &laquo;Agapium&raquo; (далее&nbsp;&mdash; Сервисы).</p>',
					},
				],
			},
		},
		{
			type: 'block-still-have-questions',
			content: {
				textTitle: 'Остались вопросы',
				contactLinks: [
					{
						textTitle: 'Телефона интернет-магазина',
						links: [
							{
								name: '+7 (800) 320-72-65',
								url: '+78003207265',
							},
						],
					},
					{
						textTitle: 'По вопросам клиентского сервиса',
						links: [
							{
								name: 'cs@agapium.com',
								url: 'cs@agapium.com',
							},
						],
					},
					{
						textTitle: 'Связаться с нами в социальных сетях',
						links: [
							{
								name: 'Вконтакте',
								url: 'https://vk.com',
							},
							{
								name: 'Telergram',
								url: 'https://tg.com',
							},
						],
					},
				],
			},
		},
	],
};
