import css from './FormFieldset.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { Typography } from '@shared';

interface FormFieldsetProps extends HTMLAttributes<HTMLFieldSetElement> {
	legend?: string;
	disabled?: boolean;
}

export const FormFieldset: FC<FormFieldsetProps> = ({
	legend,
	style,
	className,
	disabled,
	children,
}) => {
	return (
		<fieldset disabled={disabled} style={style} className={classnames(css.fieldset, className)}>
			{legend && (
				<Typography className={css.legend} as="legend" type="h3">
					{legend}
				</Typography>
			)}
			{children}
		</fieldset>
	);
};
