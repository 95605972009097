import css from './LoginForm.module.scss';
import { BlockLogin } from '@api/mock/types';
import React, {
	ChangeEventHandler,
	FC,
	HTMLAttributes,
	MouseEventHandler,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Box, Button, CloseBtn, Countdown, Typography } from '@shared';
import { FormComponent, FormSumbitHandlerType } from '@shared/Form/FormComponent/FormComponent';
import { FormChild } from '@shared/Form/FormComponent/components/FormChild/FormChild';
import { LoginThirdParty } from '@components/Login/components/LoginThirdParty/LoginThirdParty';
import { submitForm } from '@api/utils/submit';
import { useRouter } from 'next/router';
import classNames from 'classnames';

interface LoginFormProps extends HTMLAttributes<HTMLElement>, Pick<BlockLogin, 'loginForm'> {}

export const LoginForm: FC<LoginFormProps> = ({ loginForm }) => {
	const {
		textTitle,
		textPhone,
		textSubmit,
		textRepeatBtn,
		textRepeatTimer,
		textBackBtn,
		repeatTime = 30,
		sendCode,
		sendCodeMethod
	} = loginForm;
	let { phone: phoneField, smsCode: smsCodeField } = loginForm?.fields || {};
	const timerTextChunks = textRepeatTimer?.split('${time}') || ['', ''];
	const [isLoading, setIsloading] = useState(false);
	const [repeatDisabled, setRepeatDisabled] = useState(false);
	const [userPhone, setUserPhone] = useState<string | null>(null);
	const router = useRouter();
	const [invalidCode, setInvalidCode] = useState(false);

	const handleSubmitPhone: FormSumbitHandlerType = useCallback(
		(e, ctx) => {
			e.preventDefault();

			if (!loginForm || !loginForm.action) return;

			const formEl = e.target as HTMLFormElement;
			const formData = new FormData(formEl);
			const phoneNumber = loginForm.fields.phone.name
				? (formData.get(loginForm.fields.phone.name) as string)
				: null;

			if (ctx?.setFormState) {
				ctx.setFormState('pending');
			}

			setIsloading(true);

			submitForm({
				url: `${sendCode}?phone=${phoneNumber}`,
				method: (sendCodeMethod || 'get') as CRUDType,
			}).then((response) => {
				setIsloading(false);
				setUserPhone(phoneNumber);
				setRepeatDisabled(true);
			});
		},
		[loginForm, sendCode, sendCodeMethod]
	);

	const handleSubmitCode: FormSumbitHandlerType = useCallback(
		(e, ctx) => {
			e.preventDefault();
			setInvalidCode(false)
			if (!loginForm || !loginForm.action) return;

			const formEl = e.target as HTMLFormElement;
			const formData = new FormData(formEl);
			formData.append('phone', userPhone ? userPhone : '');

			if (ctx?.setFormState) {
				ctx.setFormState('pending');
			}

			setIsloading(true);
			setRepeatDisabled(true);

			submitForm({
				url: loginForm.action,
				method: loginForm.method || 'post',
				body: formData,
			})
				.then((res) => {
					router.push(res.location);
				})
				.catch((e) => setInvalidCode(true));
		},
		[loginForm, router, userPhone]
	);

	const handleBackClick: MouseEventHandler<HTMLButtonElement> = useCallback(() => {
		setUserPhone(null);
		setIsloading(false);
		setRepeatDisabled(true);
	}, []);

	const handleInputCode: ChangeEventHandler<HTMLInputElement> = useCallback(({ target }) => {
		target.closest('form')?.requestSubmit();
	}, [])

	const handleTimerComplete = useCallback(() => {
		setRepeatDisabled(false);
	}, []);

	const handleGetNewCode = useCallback(() => {
		/*
		 * ToDo: Логика запроса нового кода
		 */
		setRepeatDisabled(true);
	}, []);

	return (
		<Box className={css.component}>
			<hgroup className={css.heading}>
				<Typography
					className={classNames(css.title, { [css.titleCodePeriod]: !!userPhone })}
					as="h1"
					type="h3">
					{textTitle}
				</Typography>
				{textPhone && userPhone && (
					<Typography as="p" size="sm" raw={true} className={css.subtitle}>
						{textPhone?.replace(/(\${\w+})/gm, `<nobr>${userPhone}</nobr>`)}
					</Typography>
				)}
			</hgroup>

			{!userPhone && (
				<Box className={css.form}>
					<FormComponent onSubmit={handleSubmitPhone}>
						<FormChild className={css.input} initialValue='' placeholder={phoneField.mask?.replace(/0/gm, "_")} {...(phoneField as FormFieldType)} />

						<Box className={css.formFooter}>
							<Button type="submit" design="stroked" isLoading={isLoading} wide={true}>
								{textSubmit}
							</Button>
						</Box>
					</FormComponent>
				</Box>
			)}

			{userPhone && (
				<Box className={css.form}>
					<FormComponent onSubmit={handleSubmitCode}>
						<FormChild
							className={classNames(css.input, { [css.inputInvalid]: invalidCode })}
							onCompleteMask={handleInputCode}
							{...(smsCodeField as FormFieldType)}
						/>

						<Typography
							className={classNames(css.invalidCode, { [css.invalidCodeActive]: invalidCode })}>
							Неверный код
						</Typography>

						<Box className={css.smsCodeTools}>
							{repeatDisabled && textRepeatTimer && (
								<Typography className={css.smsCodeToolsTimer} as="p" size="sm">
									{timerTextChunks[0]}
									<Countdown start={repeatTime} onComplete={handleTimerComplete} />
									{timerTextChunks[1]}
								</Typography>
							)}
							<Button
								className={css.smsCodeToolsBtn}
								type="button"
								design="flat"
								color="dark"
								disabled={repeatDisabled}
								onClick={handleGetNewCode}>
								{textRepeatBtn}
							</Button>
						</Box>
					</FormComponent>
				</Box>
			)}

			{userPhone && (
				<CloseBtn className={css.backBtn} onClick={handleBackClick}>
					{textBackBtn}
				</CloseBtn>
			)}

			{/*<LoginThirdParty />*/}
		</Box>
	);
};
