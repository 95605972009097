import css from './LinksGroup.module.scss';
import classnames from 'classnames';
import { ContactLink } from '@api/mock/types';
import React, { CSSProperties, FC, HTMLAttributes } from 'react';
import { LinkTag, Typography } from '@shared';

interface LinksGroupProps extends HTMLAttributes<HTMLElement>, ContactLink {
	align?: CSSProperties['textAlign'];
}

export const LinksGroup: FC<LinksGroupProps> = ({
	textTitle,
	links,
	align = 'center',
	className,
}) => {
	return (
		<div className={classnames(className, css.component, css[align])}>
			<Typography size="sm" className={css.label}>
				{textTitle}
			</Typography>
			<Typography type="h5" className={css.links}>
				{links?.map(({ name, url }, i) => {
					return url ? (
						<LinkTag key={`links-group-${url}-${name}-${i}`} href={url}>
							{name}
						</LinkTag>
					) : null;
				})}
			</Typography>
		</div>
	);
};
