import css from './FilterControl.module.scss';
import classnames from 'classnames';
import React, {
	ChangeEventHandler,
	FC,
	HTMLAttributes,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { throttle } from '@utils';
import { Currency } from '@shared';
import { FilterCategories } from '../FilterItems/FilterCategories/FilterCategories';
import { FilterSizes } from '../FilterItems/FilterSizes/FilterSizes';
import { FilterColors } from '../FilterItems/FilterColors/FilterColors';
import { FilterRange } from '../FilterItems/FilterRange/FilterRange';
import {IFiltersItem } from '../FilterPanel/FilterPanel';

interface FilterControlProps extends HTMLAttributes<HTMLElement>, IFiltersItem {
}

export const FilterControl: FC<FilterControlProps> = ({ name, ...rest }) => {
	switch (name) {
		case 'category':
			return <FilterCategories name={name} {...rest} />;
		case 'price':
			return <FilterRange min={rest.min || 0} max={rest.max || 0} name={name} {...rest} />;
		case 'sizes':
			return <FilterSizes name={name} {...rest} />;
		case 'color':
			return <FilterColors name={name} {...rest} />;
	}
};


// export const FilterRange: FC<Omit<FilterControlProps, 'name'>> = () => {
// 	const { query, asPath, push } = useRouter();

// 	const min = 1200;
// 	const max = 40000;
// 	const step = 100;
// 	const name = 'price';

// 	const queryValue = query[name] as string;
// 	const defaultValue = useMemo(() => ((max - min) / 2).toString(), [max, min]);

// 	const [value, setValue] = useState<string>(queryValue || defaultValue);

// 	const updateRoute = useCallback(
// 		(value: string) => {
// 			throttle(
// 				() => {
// 					if (defaultValue === value) return;

// 					const search = Object.entries({ ...query, ['price']: value })
// 						.filter((item) => item[0] !== 'slug')
// 						.filter((item) => item[1]);
// 					const searchString = search.map((item) => item[0] + '=' + item[1]).join('&');
// 					const pathname = asPath.split('?')[0];
// 					const url = pathname + (searchString ? '?' + searchString : '');

// 					push(url, undefined, { shallow: true }).then();
// 				},
// 				300,
// 				`filter-${name}`
// 			);
// 		},
// 		[defaultValue, asPath, query, push]
// 	);

// 	const changeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
// 		({ target }) => {
// 			setValue(target.value);
// 			updateRoute(target.value);
// 		},
// 		[updateRoute]
// 	);

// 	return (
// 		<label className={classnames(css.range, css.control)}>
// 			<div className={css.rangeLabels}>
// 				<span>
// 					1200 <Currency />
// 				</span>
// 				<span>
// 					40000 <Currency />
// 				</span>
// 			</div>
// 			<input
// 				type="range"
// 				name="price"
// 				min={min}
// 				max={max}
// 				step={step}
// 				value={value}
// 				onChange={changeHandler}
// 			/>
// 		</label>
// 	);
// };



export const getSearchString = (
	query: ParsedUrlQuery,
	key: string,
	value: string,
	add: boolean
) => {
	const valuesFromQuery = new Set(
		!query[key] || Array.isArray(query[key]) ? query[key] : (query[key] as string).split(',')
	);

	if (!add) {
		value && valuesFromQuery.delete(value);
	} else {
		value && valuesFromQuery.add(value);
	}

	return Object.entries({ ...query, [key]: Array.from(valuesFromQuery).join(',') })
		.filter((item) => item[0] !== 'slug')
		.filter((item) => item[1])
		.map((item) => item[0] + '=' + item[1])
		.join('&');
};
