import css from '../CartElement.module.scss';
import classnames from 'classnames';
import React, { FC } from 'react';
import { CartElemmentComponentProps, Typography } from '@shared';
import { useBxComponents } from '@context';
import { CartElementPic } from '@shared/CartElement/components/CartElementPic';

export const CartElementInfo: FC<CartElemmentComponentProps> = ({
	name,
	article,
	images,
	detailPageUrl,
	className,
	children,
}) => {
	const { cart: cartTexts } = useBxComponents();
	const { textArticle } = cartTexts;

	// const { article } = offer || {};

	return (
		<div className={classnames(className, css.product)}>
			<a
				href={detailPageUrl}
				target="_blank"
				rel="noreferrer noopener"
				tabIndex={-1}
				className={css.productPicture}>
				<CartElementPic images={images} />
			</a>
			<div className={css.productContent}>
				<Typography className={css.title} size="md">
					<a href={detailPageUrl} target="_blank" rel="noreferrer noopener">
						{name}
					</a>
				</Typography>
				<Typography className={css.article} size="sm" color="gray">
					{textArticle}: {article}
				</Typography>
				{children}
			</div>
		</div>
	);
};
