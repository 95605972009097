import { getOffsetRecursively } from '@utils';

export const scrollToPosition = (position: number = 0): void => {
	const popup = document.querySelector('.popup');
	const curtain = document.querySelector('.curtain__scroller');

	if ('scrollBehavior' in document.documentElement.style) {
		(popup || curtain || window).scrollTo({
			top: position,
			behavior: 'smooth',
		});
	} else {
		(popup || curtain || window).scrollTo(0, position);
	}
};

export const scrollToElement = (
	target: HTMLElement,
	offset: number = 0,
	behavior: ScrollBehavior = 'smooth'
): void => {
	const position = Math.max(0, getOffsetRecursively(target).top - offset);
	const popup = document.querySelector('.popup');
	const curtain = target.closest('.curtain__scroller');

	if ('scrollBehavior' in document.documentElement.style) {
		(popup || curtain || window).scrollTo({
			top: position,
			behavior,
		});
	} else {
		if (popup) {
			popup.scrollTop = position;
		} else if (curtain) {
			curtain.scrollTop = position;
		} else {
			window.scrollTo(0, position);
		}
	}
};
