import css from './Select.module.scss';
import classnames from 'classnames';
import React, {
	PropsWithChildren,
	FC,
	HTMLAttributes,
	ChangeEventHandler,
	InvalidEvent,
	useCallback,
	useRef,
} from 'react';
import AsyncSelect from 'react-select/async';
import { Box, Icon } from '@shared';
import { fetcher } from '@api';
import { asyncSelectStyles } from './styles';

interface SelectProps extends HTMLAttributes<HTMLSelectElement> {
	id: string;
	selected: SelectOption | undefined;
	setSelected: (selected: SelectOption) => void;
	defaultOptions?: Array<SelectOption>;
	fetchOptionsUrl?: string;
	transformData?: (res: any) => any;
	name?: string;
	placeholder?: string;
	noOptionsMessage?: string;
	loadingMessage?: string;
	required?: boolean;
	onChange?: ChangeEventHandler<HTMLSelectElement>;
	onInvalid?: (e: InvalidEvent<HTMLSelectElement>) => void;
}

const transform = (res: any) => res;

export const SelectAsync: FC<PropsWithChildren<SelectProps>> = React.memo(
	({
		id,
		name,
		selected,
		setSelected,
		defaultOptions = [],
		fetchOptionsUrl,
		transformData = transform,
		className,
		placeholder = '',
		noOptionsMessage = '',
		loadingMessage = '',
		required,
		onChange,
		onInvalid,
	}) => {
		const nativeSelectRef = useRef<HTMLSelectElement | null>(null);

		/*
		 * Обработка выбора
		 */
		const handleSelect = useCallback(
			(option: SelectOption | null) => {
				option && setSelected(option);

				setTimeout(() => {
					const event = new Event('change', { bubbles: true, cancelable: true });
					nativeSelectRef?.current?.dispatchEvent(event);
				}, 10);
			},
			[setSelected]
		);

		const asyncOptions = useCallback(
			(value: string) =>
				new Promise<SelectOption[]>((resolve, reject) => {
					if (value.length > 1) {
						fetcher(`${fetchOptionsUrl}&q=${value}`)
							.then((response) => resolve(transformData(response)))
							.catch((error) => reject(error));
					} else {
						resolve(defaultOptions);
					}
				}),
			[defaultOptions, fetchOptionsUrl, transformData]
		);

		return (
			<Box className={classnames(className, css.selectAsync)}>
				<AsyncSelect
					value={selected}
					defaultOptions={defaultOptions}
					onChange={handleSelect}
					loadOptions={asyncOptions}
					instanceId={id}
					placeholder={placeholder}
					noOptionsMessage={() => noOptionsMessage}
					loadingMessage={() => loadingMessage}
					styles={asyncSelectStyles}
					components={{
						IndicatorsContainer,
						IndicatorSeparator,
					}}
					menuPortalTarget={typeof document !== 'undefined' ? document.body : null}
				/>
				{name && (
					<select
						ref={nativeSelectRef}
						className="visually-hidden"
						name={name}
						value={selected?.value || ''}
						onChange={onChange}
						onInvalid={onInvalid}
						required={required}>
						<option value="" />
						{selected?.value && <option value={selected.value}>{selected?.label}</option>}
					</select>
				)}
			</Box>
		);
	}
);

const IndicatorsContainer = () => (
	<div className={css.indicator}>
		<Icon id="chevron-down" />
	</div>
);
const IndicatorSeparator = () => null;
