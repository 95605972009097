import css from './ColorDot.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

interface ColorDotProps extends HTMLAttributes<HTMLSpanElement> {
	color?: string;
}

export const ColorDot: FC<ColorDotProps> = React.memo(({ color, className }) => {
	const isWhite = color
		? color.toLowerCase() === '#fff' || color.toLowerCase() === '#ffffff'
		: false;
	return (
		<div
			className={classnames(className, css.color, { [css.isWhite]: isWhite })}
			style={{ background: color }}
		/>
	);
});
