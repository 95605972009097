import { deleteCookie, getCookie, setCookie } from '@src/utils';

export type JWT = {
	access: string | null | undefined;
	refresh: string | null | undefined;
};
export type JWTKey = keyof JWT;

/*
 * Пишет токены в cookies
 */
export const setJWT = ({ access, refresh }: Partial<JWT>): void => {
	const options = {
		expires: 2,
		secure: location ? location.protocol === 'https:' : true,
		samesite: 'strict' as const,
	};

	if (access) {
		setCookie('access', access, options);
	} else {
		deleteCookie('access');
	}

	if (refresh) {
		setCookie('refresh', refresh, options);
	}
};

/*
 * Получает токены
 */
export const getJWT = (tokenName: JWTKey = 'access'): string | null => {
	const isSSR = typeof window === 'undefined';

	if (isSSR) return null;

	const token = getCookie(tokenName);
	return token || null;
};

/*
 * Сбрасывает токены
 */
export const resetJWT = (): void => {
	deleteCookie('access');
	deleteCookie('refresh');
};
