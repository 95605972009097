export const padForNum = (string: number | string, pad = '0000'): string => {
	return (pad + string).substr(pad.length * -1);
};

export const dischargeNumber = (number: number, delimiter = ' '): string => {
	return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + delimiter);
};

export const toFixedNumber = (n: number, digits: number, base: number = 10) => {
	const pow = Math.pow(base, digits);
	return Math.round(n * pow) / pow;
};

export const normalize = (val: number, max: number, min: number, limit: boolean) => {
	const value = (val - min) / (max - min);
	return limit ? Math.max(0, Math.min(value, 1)) : value;
};

export const limit = (num: number) => Math.min(1, Math.max(0, num));

export const getRandom = (min: number, max: number) => {
	return Math.floor(Math.random() * (max - min + 1) + min);
};
