import css from './Footer.module.scss';
import classnames from 'classnames';
import { ComponentFooterContent } from '@model/models';
import React, { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import Link from 'next/link';
import { useBxComponents, useViewport } from '@context';
import { useScrollSeek } from '@hooks';
import { Button, Container, Icon, Spoiler, Typography as Typo } from '@shared';
import { useRouter } from 'next/router';
import { FormComponent, FormSumbitHandlerType } from '@shared/Form/FormComponent/FormComponent';
import { FormChild } from '@shared/Form/FormComponent/components/FormChild/FormChild';
import { handleFormResponse, submitForm } from '@api/utils/submit';

interface FooterProps extends ComponentFooterContent {}

interface FooterComponentProps {
	node?: HTMLElement | null;
	data?: ComponentFooterContent;
}

const year = new Date().getFullYear();

export const Footer: FC = React.memo((props) => {
	const { footer } = useBxComponents();
	const [node, setNode] = useState<HTMLElement | null>(null);

	return (
		<footer ref={setNode} className={classnames(css.footer, 'page__footer')}>
			<FooterComponent node={node} data={footer} {...props} />
		</footer>
	);
});

export const FooterComponent: FC<FooterComponentProps> = React.memo(({ node, data }) => {
	const [logo, setLogo] = useState<HTMLElement | null>(null);

	const { vh } = useViewport();

	useScrollSeek(
		logo,
		useCallback(
			({ bounding }) => {
				const seek = 1 - Math.min(1, Math.max(0, (bounding.bottom - vh * 0.5) / (vh * 0.75)));
				const seekDark = 1 - Math.min(1, Math.max(0, (bounding.bottom - vh * 0.75) / (vh * 0.5)));

				if (logo) {
					logo.style.setProperty('--rotate', seek.toString());
				}

				if (node) {
					node.classList.toggle(css.dark, seekDark >= 1);
					node.classList.toggle('is-dark', seekDark >= 1);
				}
			},
			[node, logo, vh]
		),
		{
			checkInView: false,
		}
	);

	return (
		<Container className={css.wrap}>
			<Link href="/">
				<a ref={setLogo} className={css.logo} tabIndex={-1}>
					<div className={css.sticky}>
						<Icon id="emblem-main" />
						<div className={css.framing}>
							<Icon id="emblem-framing" />
							<Icon id="emblem-framing" />
						</div>
					</div>
				</a>
			</Link>
			<FooterSubscribe {...data} />
			<FooterMenu {...data} />
			<FooterSocial {...data} />
			<FooterInfo {...data} />
		</Container>
	);
});

export const FooterSubscribe: FC<FooterProps> = React.memo(({ subscribeForm }) => {
	const data = subscribeForm;
	const title = data?.textSubscribeOnNewNews;
	const action = data?.action;
	const fields = data?.items || [];
	const providedData: Record<string, any> = useMemo(() => ({}), []);
	const [isLoading, setIsloading] = useState(false);

	const submitHandler: FormSumbitHandlerType = useCallback(
		(e, ctx) => {
			e && e.preventDefault();
			if (!action) {
				console.warn(`Submit form failed: systemAction.url not found`);
				return;
			}

			const formEl = e?.target as HTMLFormElement;
			const formData = new FormData(formEl);

			Object.entries(providedData || {}).forEach(([key, value]) => {
				typeof value === 'string' && formData.append(key, value);
			});

			if (ctx?.setFormState) {
				ctx.setFormState('pending');
			}

			setIsloading(true);

			/*
			 * Проверка каптчи
			 */
			const checkCaptcha =
				ctx?.checkCaptcha ||
				(() => new Promise<string>((resolve) => setTimeout(() => resolve(''), 10)));

			checkCaptcha()
				.then((token) => {
					formData.append('recaptcha', token || '');

					if (!subscribeForm || !subscribeForm?.action) return;

					/*
					 * Запрос
					 */
					return (
						submitForm({
							url: subscribeForm.action,
							method: (subscribeForm.method || 'post') as CRUDType,
							body: formData,
						})
							/*
							 * Обработка ответа
							 */
							.then((response) => {
								setIsloading(false);
								return handleFormResponse(response, ctx);
							})
					);
				})
				.catch((error) => {
					console.warn(error);
				});
		},
		[action, providedData, subscribeForm]
	);

	if (!data) return null;

	return (
		<div className={css.subscribe}>
			<Typo className={css.subscribeCaption} as="div" type="h2">
				{title}
			</Typo>
			<FormComponent
				onSubmit={submitHandler}
				className={css.subscribeForm}
				responseTitleType="h3"
				responseClassName={css.subscribeFormResponse}>
				{fields.map(({ type, name, label, required, pattern, validation }) => {
					const key = `footer-subscribe-${name}`;
					const props = {
						type: (type || 'text') as FormInputType,
						name: name || 'email',
						label,
						required,
						pattern,
						validation: {
							required: validation?.required,
							pattern: validation?.pattern,
						},
					};
					return <FormChild key={key} {...props} />;
				})}
				<Button className={css.subscribeBtn} type="submit" isLoading={isLoading}>
					<Icon id="mail" />
				</Button>
			</FormComponent>
		</div>
	);
});

export const FooterMenu: FC<FooterProps> = React.memo(({ menu }) => {
	return (
		<div className={css.menu}>
			{menu?.groups?.map(({ name, links }, i: number) => (
				<FooterMenuGroup key={`footer-menu-group-${name}-${i}`} title={name}>
					{links && Array.isArray(links) && links.map(({ name, url }: any, j: number) => (
						<FooterMenuItem key={`footer-menu-link-${url}-${j}`} url={url} text={name} />
					))}
				</FooterMenuGroup>
			))}
		</div>
	);
});

const FooterMenuItem: FC<{ url: string; text: string }> = ({ url, text }) => {
	return (
		<li>
			<Link href={url}>
				<a>{text}</a>
			</Link>
		</li>
	);
};

export const FooterMenuGroup: FC<PropsWithChildren<{ title?: string }>> = React.memo(
	({ title, children }) => {
		const { isMob } = useViewport();

		return isMob ? (
			<Spoiler head={title || <></>} className={css.menuSpoiler} classNameHead={css.menuCaption}>
				<ul className={css.menuList}>{children}</ul>
			</Spoiler>
		) : (
			<div className={css.menuColumn}>
				<div className={css.menuCaption}>{title}</div>
				<ul className={css.menuList}>{children}</ul>
			</div>
		);
	}
);

export const FooterSocial: FC<FooterProps> = React.memo(({ vk, telegram, tikTok }) => {
	const links = [vk, telegram, tikTok].filter((item) => !!item);
	return (
		<div className={classnames(css.social, { [css.socialTriple]: true })}>
			{links.map((item) => {
				const link = Array.isArray(item) ? item[0] : item;
				return link ? (
					<a
						key={`footer-social-${link.name}`}
						href={link.url}
						target="_blank"
						rel="noreferrer noopener">
						{link.name}
					</a>
				) : null;
			})}
		</div>
	);
});

export const FooterInfo: FC<FooterProps> = React.memo(({ textMadeBy, textCopyright }) => {
	const { locale } = useRouter();
	const copyright = textCopyright || `Agapium, ${year}`;
	const designedBy =
		textMadeBy || (locale === 'en' ? 'Designed by Red Collar' : 'Cделано в Red Collar');

	return (
		<div className={css.info}>
			<div className={css.infoMain}>
				<div className={css.infoCopyright}>{copyright}</div>
			</div>
			<a
				className={css.infoDev}
				href="https://redcollar.ru/"
				target="_blank"
				rel="noreferrer noopener">
				<Icon id="redcollar" />
				<span>{designedBy}</span>
			</a>
		</div>
	);
});

export const FooterLocaleTools: FC<FooterProps> = React.memo(() => {
	const { locale } = useRouter();
	return (
		<div className={css.locale}>
			<></>
		</div>
	);
});
