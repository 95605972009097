import { BlockBasketOrderPrice } from '@api/mock/types';
import { fetcher, useData } from '@api';
import { useRouter } from 'next/router';
import { BASKET_PRICE_ROUTE, withLocale } from '@utils';
import { useCallback, useState } from 'react';

export interface BasketOrderPriceMutate {
	cityCode: number;
	deliveryService: string;
}

export const useOrderPrices = () => {
	const { locale } = useRouter();

	const { data, isLoading, isError, revalidate } = useData<BlockBasketOrderPrice>({
		url: withLocale(BASKET_PRICE_ROUTE, locale),
	});

	const [mutateIsPending, setIsPending] = useState(false);

	const mutate = useCallback(
		(data: BasketOrderPriceMutate) => {
			setIsPending(true);
			fetcher({
				url: BASKET_PRICE_ROUTE,
				method: 'POST',
				body: data,
			})
				.catch((error) => {
					console.warn(error);
				})
				.finally(() => {
					setIsPending(false);
					revalidate && revalidate();
				});
		},
		[revalidate]
	);

	return {
		data,
		isLoading,
		isError,
		mutate,
		mutateIsPending,
		revalidate,
	};
};
