import {  DetailedHTMLProps, InputHTMLAttributes, useState } from "react"
import css from './FilterCheckbox.module.scss'

interface IFilterCheckbox extends InputHTMLAttributes<HTMLInputElement> {itemValue: string, handler(value: string, checked: boolean): void }

export const FilterCheckbox = ({ checked, itemValue, handler, ...rest }: IFilterCheckbox) => {
	const [isChecked, setIsChecked] = useState(checked ?? false);

	return (
		<input
			className={css.input}
            checked={isChecked}
			onChange={(e) => {
				handler(itemValue, e.target.checked);
                setIsChecked(e.target.checked)
			}}
			type={'checkbox'}
			{...rest}
		/>
	);
};