import css from './TextSpoiler.module.scss';
import React, { FC } from 'react';
import { Spoiler, TypographyFlow } from '@shared';

interface TextSpoilerProps extends TextContentNode {}

export const TextSpoiler: FC<TextSpoilerProps> = React.memo(({ title, defaultActive, content }) => {
	return (
		<>
			<Spoiler
				size="md"
				className={css.component}
				head={title || <></>}
				defaultActive={defaultActive}
				classNameBody={css.body}>
				<TypographyFlow content={content} />
			</Spoiler>
		</>
	);
});
