import type { ScrollData } from '@utils/utils.performance/scroll';
import { useEffect } from 'react';
import { Scroll } from '@utils';

export type ScrollCallback = (data: ScrollData) => void;

export const useScroll = (callback: ScrollCallback): void => {
	useEffect(() => {
		let timer = 0;

		const onScroll = () => {
			const data = Scroll.getData();
			callback(data);
		};

		onScroll();
		timer = window.setTimeout(onScroll, 100);

		Scroll.addListener(onScroll);

		return () => {
			Scroll.removeListener(onScroll);
			window.clearTimeout(timer);
		};
	}, [callback]);
};
