import type { OffersCatalogProductsElements } from '@model/models';
import type { OfferChoice } from '@components/ProductDetails/components/ProductPurchase/ProductPurchase';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useProductDetails } from '@components';
import { mapToArray } from '@utils';

type ChoiceType = OfferChoice | undefined;

export const useOffers = (offers?: OffersCatalogProductsElements[]) => {
	const { setOffer } = useProductDetails();

	/*
	 * Список размеров
	 */
	const items = useMemo(() => {
		const sizesMap = new Map();

		offers?.forEach(({ size, isAvailable, restCount }) => {
			if (size) {
				sizesMap.set(size, {
					value: size,
					label: size.toUpperCase(),
					disabled: !restCount,
				});
			}
		});

		return {
			sizes: mapToArray(sizesMap),
		};
	}, [offers]);

	/*
	 * Стейты: выбранные размеры
	 */
	const [selectedSize, setSelectedSize] = useState<ChoiceType>(
		items.sizes.length > 0 ? items.sizes.filter((item) => !item.disabled)[0] : items.sizes[0]
	);

	useEffect(() => {
		if (offers && selectedSize) {
			const selectedOffer = offers.find(({ size }) => size === selectedSize.value);

			if (selectedOffer) {
				setOffer(selectedOffer);
			}
		}
	}, [offers, selectedSize, setOffer]);

	/*
	 * Выбор размера (из доступных)
	 */
	const setSize = useCallback((offer: OfferChoice) => {
		setSelectedSize(offer);
	}, []);

	return {
		...items,
		sizes: items.sizes,
		selectedSize,
		setSelectedSize: setSize,
	};
};
