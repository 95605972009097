import css from './PopUpBody.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { Box, Scroller } from '@shared';

interface PopUpHeadProps extends HTMLAttributes<HTMLElement> {}

export const PopUpBody: FC<PopUpHeadProps> = ({ className, children }) => {
	return (
		<Scroller className={classnames(css.body, className)} options={{ wheelPropagation: false }}>
			{children}
		</Scroller>
	);
};
