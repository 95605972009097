import css from './FeaturesStackDesktopCard.module.scss';
import { useViewport } from '@src/context';
import { useScrollSeek } from '@src/hooks';
import { Section, Typography } from '@src/shared';
import Image from 'next/image';
import { FC, memo, useCallback, useState } from 'react';
import { FeaturesStackContentCard } from '@components';
import { normalize } from '@utils';

export const FeaturesStackDesktopCard: FC<FeaturesStackContentCard> = memo(
	({ leftImage, heading, text, smallImage }) => {
		const [section, setSection] = useState<HTMLElement | null>(null);
		const { vh, isTablet } = useViewport();
		useScrollSeek(
			section,
			useCallback(
				({ bounding }) => {
					if (isTablet) return;

					const seekOpen = Math.min(1, Math.max(0, (bounding.bottom - vh) / vh));
					const borderFade = normalize(seekOpen, 0.3, 0, true);

					if (section) {
						section.style.setProperty('--seek-open', seekOpen.toString());
						section.style.setProperty('--border-fade', borderFade.toString());
					}
				},
				[section, vh, isTablet]
			),
			{
				checkInView: true,
				resist: 0.125,
			}
		);
		return (
			<Section className={css.component}>
				<div ref={setSection} className={css.sticky}>
					<div className={css.leftImage}>
						{leftImage && <Image quality={100} layout="fill" alt="" src={leftImage} objectFit="cover" />}
					</div>
					<div className={css.content}>
						<div className={css.topContent}>
							{/*<Typography type="h2">{heading}</Typography>*/}
							<Typography raw={true} type="h2">{heading}</Typography>
							<Typography className={css.topContentText} size="sm" raw>
								{text}
							</Typography>
						</div>
						<div className={css.polaroidWrap}>
							<div className={css.polaroidImage}>
								{smallImage && <Image src={smallImage} alt="" layout="fill" objectFit="cover" />}
							</div>
						</div>
						<div className={css.figure}></div>
					</div>
				</div>
			</Section>
		);
	}
);
