import css from './CartWidget.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	HTMLAttributes,
	PropsWithChildren,
	useState,
	useContext,
	useEffect,
	useCallback,
} from 'react';
import AnimateHeight from 'react-animate-height';
import { PopUp, PopUpHead, PopUpBody, PopUpContext } from '@shared/PopUp';
import { Box, Brackets, Button, Icon } from '@src/shared';
import { CartWidgetContent } from './components/CartWidgetContent';
import { useDelayUnmount } from '@hooks';
import { useBxComponents } from '@context';
import { useCartData } from '@components/Cart/hooks';

interface CartContextProps {
	data?: any;
	setData?: React.Dispatch<any>;
	showNotice?: boolean;
	setShowNotice?: React.Dispatch<boolean>;
}

export interface CartWidgetProps extends HTMLAttributes<HTMLElement> {}

export interface CartNoticeProps {
	showNotice: boolean;
	setShowNotice: React.Dispatch<boolean>;
	text?: string;
	timer?: number;
}

export const CartContext = React.createContext<CartContextProps>({});

export const CartProvider: FC<PropsWithChildren> = ({ children }) => {
	const [data, setData] = useState<any>();
	const [showNotice, setShowNotice] = useState(false);

	return (
		<CartContext.Provider value={{ data, setData, showNotice, setShowNotice }}>
			{children}
			<CartWidget />
			<CartNotice showNotice={showNotice} setShowNotice={setShowNotice} />
		</CartContext.Provider>
	);
};

export const CartNotice: FC<CartNoticeProps> = React.memo(
	({ showNotice, setShowNotice, text = 'Модель добавлена в корзину', timer = 2000 }) => {
		const shouldRender = useDelayUnmount(showNotice, 600);

		useEffect(() => {
			if (shouldRender) {
				window.setTimeout(() => {
					setShowNotice(false);
				}, timer);
			}
		}, [shouldRender, setShowNotice, timer]);

		return shouldRender ? (
			<Box className={classnames(css.cartNotice, { [css.isShown]: showNotice })}>{text}</Box>
		) : null;
	}
);

export const CartWidget: FC<CartWidgetProps> = React.memo(() => {
	const { toggle: togglePopup } = useContext(PopUpContext);

	const closePopUp = useCallback(() => {
		togglePopup && togglePopup('cart-widget');
	}, [togglePopup]);

	const { cart: cartTexts } = useBxComponents();
	const { textBasket, textEmptyHere, textHide, toBasket, toMarket } = cartTexts;

	const { data } = useCartData();
	const amount = data?.products?.length || 0;
	const isEmpty = amount <= 0;

	return (
		<PopUp name="cart-widget" closeButtonText={textHide}>
			<PopUpHead
				title={
					isEmpty ? (
						<>
							{textEmptyHere}
							<Icon className={css.smile} id="smile-negative" />
						</>
					) : (
						<>
							{textBasket}
							{amount > 0 && <Brackets className={css.counter}>{amount}</Brackets>}
						</>
					)
				}
			/>

			<PopUpBody className={css.container}>
				<AnimateHeight duration={200} height={isEmpty ? 'auto' : 0} animateOpacity={true}>
					{toMarket && (
						<Button
							className={css.goToCatalog}
							to={toMarket.url}
							onClick={closePopUp}
							design="stroked"
							wide={true}>
							{toMarket.name}
						</Button>
					)}
				</AnimateHeight>

				<AnimateHeight duration={200} height={!isEmpty ? 'auto' : 0} animateOpacity={true}>
					<CartWidgetContent />
				</AnimateHeight>
			</PopUpBody>

			{!isEmpty && toBasket && (
				<Button
					className={css.goToCart}
					onClick={closePopUp}
					to={toBasket.url}
					design="stroked"
					wide={true}>
					{toBasket.name}
				</Button>
			)}
		</PopUp>
	);
});

export const useCart = () => {
	return useContext(CartContext);
};
