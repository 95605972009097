import { withoutLeadingSlash, withoutTrailingSlash } from '@utils/utils.api';

export const HEADER_HEIGHT = 72;
export const HEADER_HEIGHT_SM = 48;

export const DARK_COLOR = '#1e1e1e';
export const DARK_COLOR_HEX = 0x1e1e1e;

export const WHITE_COLOR = '#ffffff';
export const WHITE_COLOR_HEX = 0xffffff;

export const STATIC_PATHS = process.env.NEXT_PUBLIC_STATIC_PATHS || '/static';
export const COMPONENTS_ROUTE = process.env.NEXT_PUBLIC_COMPONENTS || '/components';

export const BASKET_ROUTE = process.env.NEXT_PUBLIC_BASKET || '/basket';
export const BASKET_ORDER_ROUTE = process.env.NEXT_PUBLIC_BASKET_ORDER || '/basket/order';
export const BASKET_PRICE_ROUTE = process.env.NEXT_PUBLIC_BASKET_PRICE || '/basket/order/price';

export const BASKET_ORDER_REGEXP = new RegExp(
	`${withoutTrailingSlash(withoutLeadingSlash(BASKET_ORDER_ROUTE))}[\/]?$`
);
