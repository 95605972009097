import React from 'react';
import dynamic from 'next/dynamic';
import { AccountOrdersProps } from '@components/AccountOrders/AccountOrders';

export const AccountOrdersDynamic = dynamic<AccountOrdersProps>(
	() => import('./AccountOrders').then((module) => module.AccountOrders),
	{
		ssr: true,
		loading: () => <></>,
	}
);
