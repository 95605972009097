import css from './FetchSpinner.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

interface FetchSpinnerProps extends HTMLAttributes<HTMLDivElement> {
	type?: 'ring';
}

export const FetchSpinner: FC<FetchSpinnerProps> = ({ type = 'ring', className }) => {
	return (
		<div className={classnames(className, css.spinner)}>
			<div className={css[type]} />
		</div>
	);
};
