import css from '../../CatalogSectionsStack.module.scss';
import classnames from 'classnames';
import type { CatalogSectionsStackProps } from '@components';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { Section } from '@shared';
import { CatalogSectionsTwin } from '../CatalogSectionsTwin/CatalogSectionsTwin';
import { useViewport } from '@context';
import { useScrollSeek } from '@hooks';
import { chunk } from 'lodash';

export const CatalogSectionsStackRegular: FC<CatalogSectionsStackProps> = ({
	productsSections,
}) => {
	const [section, setSection] = useState<HTMLElement | null>(null);

	const { vh, isTablet } = useViewport();

	useScrollSeek(
		section,
		useCallback(
			({ bounding }) => {
				if (isTablet) return;

				const seekOpen = Math.min(1, Math.max(0, (bounding.bottom - vh) / vh));

				if (section) {
					section.style.setProperty('--seek-open', seekOpen.toString());
					section.classList.toggle(css.outOfView, seekOpen < 1);
				}
			},
			[section, vh, isTablet]
		),
		{
			checkInView: true,
			resist: 0.125,
		}
	);

	const groups = useMemo(() => chunk(productsSections, 2), [productsSections]);

	return (
		<Section ref={setSection} className={classnames(css.component)}>
			{groups.map((group, i) => (
				<CatalogSectionsTwin key={`catalog-stack-group-${i}`} items={group} />
			))}
		</Section>
	);
};
