import css from '../Header.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, MouseEventHandler } from 'react';
import Link from 'next/link';
import { Icon } from '@shared';
import { useBxComponents, useViewport } from '@context';
import { usePopUp } from '@shared/PopUp';
import { useCartData } from '@components/Cart/hooks';

interface CartBtnProps extends HTMLAttributes<HTMLDivElement> {}

export const CartBtn: FC<CartBtnProps> = React.memo(() => {
	const { isMob } = useViewport();
	const { toggle: popupToggle } = usePopUp();

	const { data } = useCartData();

	const { cart } = useBxComponents();
	const { textBasket, toBasket } = cart || {};

	const items = data?.products || [];
	const amount = items.length;

	const clickHandler: MouseEventHandler = (e) => {
		if (!isMob) {
			e.preventDefault();
			popupToggle && popupToggle('cart-widget');
		}
	};

	return (
		<Link href={toBasket?.url || '/basket'}>
			<a
				className={classnames(css.cartBtn, { [css.isEmpty]: amount === 0 }, 'btn')}
				onClickCapture={clickHandler}
				aria-label={textBasket}>
				<div className={css.cartBtnLabel}>Корзина</div>
				{/* <div className={css.cartBtnLabel}>Cart</div> */}
				<div className={classnames(css.cartBtnIcon)}>
					<Icon id="cart" />
					{amount > 0 && <span>{amount}</span>}
				</div>
			</a>
		</Link>
	);
});
