import fastdom from 'fastdom';
import { MutableRefObject, useEffect } from 'react';
import { Scroll, Performance, isTouchDevice } from '@utils';
import { ScrollData } from '@utils/utils.performance/scroll';

type ScrollSeekRef = MutableRefObject<HTMLElement | null>;
type ScrollSeekNode = HTMLElement | null | undefined;

type ScrollSeekCallback = (args: ScrollSeekCallbackArgs) => void;
type ScrollSeekCallbackArgs = {
	boundingAnimated: {
		top: number;
		bottom: number;
	};
	bounding: DOMRect;
	offsetY: number;
	scroll?: ScrollData;
};

interface Options {
	edge?: 'top' | 'bottom';
	resist?: number;
	checkInView?: boolean;
}

const isTouch = isTouchDevice() || false;
const isDOM = typeof window !== 'undefined' && window.document && window.document.documentElement;

const defaultResist = isTouch ? 0.35 : 0.35;

const getElement = (ref: ScrollSeekRef | ScrollSeekNode) =>
	ref instanceof HTMLElement ? ref : ref?.current || null;

export const useScrollSeek = (
	node: ScrollSeekRef | ScrollSeekNode,
	callback: ScrollSeekCallback,
	options: Options = {}
): void => {
	let { edge = 'bottom', resist = defaultResist, checkInView = false } = options;
	const element = isDOM ? getElement(node) : null;

	useEffect(() => {
		if (!element) return;

		let timer = 0;
		let inView = true;

		let vh = window.innerHeight;
		let scroll = Scroll.getData();
		let bounding = element?.getBoundingClientRect();

		if (!bounding) return;

		let originBounding = {
			top: bounding.top,
			bottom: bounding.bottom,
		};
		let animatedBounding = {
			top: originBounding.top,
			bottom: originBounding.bottom,
		};
		let offsetY = bounding.top + scroll.top;

		const onResize = () => {
			if (element) {
				vh = window.innerHeight;
				bounding = element?.getBoundingClientRect();

				callback({
					bounding,
					boundingAnimated: animatedBounding,
					offsetY,
					scroll,
				});
			}
		};

		const onScroll = () => {
			scroll = Scroll.getData();

			fastdom.measure(() => {
				bounding = element?.getBoundingClientRect();

				if (bounding) {
					originBounding = {
						top: bounding.top,
						bottom: bounding.bottom,
					};
					offsetY = bounding.top + scroll.top;

					if (checkInView) {
						inView = bounding.top - vh < 0 && bounding.bottom > 0;
					}
				}
			});
		};

		const onTick = () => {
			if (checkInView && !inView) return;

			if (animatedBounding.top.toFixed(0) === originBounding.top.toFixed(0)) {
				return;
			}

			animatedBounding = {
				top: animateValue(animatedBounding.top, originBounding.top, resist),
				bottom: animateValue(animatedBounding.bottom, originBounding.bottom, resist),
			};

			fastdom.mutate(() => {
				callback({
					bounding,
					boundingAnimated: animatedBounding,
					offsetY,
					scroll,
				});
			});
		};

		onScroll();
		Scroll.addListener(onScroll);
		Performance.addListener(onTick);

		onResize();
		window.addEventListener('resize', onResize);

		return () => {
			Scroll.removeListener(onScroll);
			Performance.removeListener(onTick);

			window.clearTimeout(timer);
			window.removeEventListener('resize', onResize);
		};
	}, [callback, element, edge, resist, checkInView]);
};

export const animateValue = (from: number, to: number, resist: number) => {
	const diff = to - from;
	const delta = Math.abs(diff) < 0.01 ? 0 : diff * resist;

	if (delta) {
		const next = from + delta;
		from = Math.round((next + Number.EPSILON) * 100) / 100;
	} else {
		from = to;
	}

	return from;
};

/*
 * Callback with measuring progress
 */
/*
// should get from hook
const vh = window.innerHeight;

const onScroll = ({ bounding: DOMRect }) => {
	// covered area
	const cover = vh + bounding.height;
	// how far have we gotten through that
	const scrolled = bounding.top - vh;
	// normalize to a 0...1 range
	const progress = -scrolled / cover;
	// you can also convert to -1...1 for some effects
	const viewRelative = progress * 2 - 1;
};

useScrollSeek(onScroll)
*/
