import css from './FormErrorHandle.module.scss';
import classnames from 'classnames';
import { HTMLElementProps } from '@api/hooks/types';
import React, { FC, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { TypographySizeType, Typography, Box } from '@shared';
import { useDelayUnmount } from '@hooks';

export interface FormErrorHandleProps extends HTMLElementProps {
	active: boolean;
	response: FormResponse | null;
	align?: 'left' | 'center' | 'right';
	textSize?: TypographySizeType;
}

export const FormErrorHandle: FC<FormErrorHandleProps> = ({
	active,
	response,
	align = 'center',
	textSize = 'sm',
	className,
}) => {
	const [data, setData] = useState<FormResponse | null>(null);
	const shouldRender = useDelayUnmount(active, 800);

	useEffect(() => {
		if (response && response?.status === 'error' && response?.message) {
			setData(response);
		}
	}, [response]);

	return (
		<>
			{shouldRender && (
				<AnimateHeight height={active ? 'auto' : 0}>
					<Box
						className={classnames(className, css.component, align && css[align], {
							[css.isShown]: active,
						})}>
						<Typography className={css.text} size={textSize} color="accent" raw={true}>
							{data?.message}
						</Typography>
					</Box>
				</AnimateHeight>
			)}
		</>
	);
};
