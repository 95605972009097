import css from '../Header.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { useBxComponents } from '@context';
import { useRouterPath } from '@hooks';
import Link from 'next/link';
import { Spoiler } from '@shared';

interface AdditionalLinksProps extends HTMLAttributes<HTMLDivElement> {}

export const AdditionalLinks: FC<AdditionalLinksProps> = React.memo(() => {
	const path = useRouterPath();

	const { footer } = useBxComponents();
	const data = footer?.menu;

	return (
		<ul className={css.navigationMob}>
			{data?.groups?.map(({ name, links }, i) => {
				return (
					<li key={`header-additional-${name}-${i}`}>
						<Spoiler
							head={name || <></>}
							classNameHead={css.navigationMobLevel}
							classNameBody={css.navigationMobSub}>
							<ul className={css.navigationSubMenu}>
								{links?.map(({ name, url }: any) => (
									<li key={`header-additional-sub-${url}-${i}`}>
										<Link href={url || '/'}>
											<a
												className={classnames({
													[css.isActive]: url?.includes(path),
												})}>
												{name}
											</a>
										</Link>
									</li>
								))}
							</ul>
						</Spoiler>
					</li>
				);
			})}
		</ul>
	);
});
