import css from '../CartWidget.module.scss';
import { BlockBasketProductsContent } from '@model/blockBasketProductsContent';
import { CartWidgetProps } from '@shared';
import React, { FC } from 'react';
import { CartElement } from '@shared';
import { useData } from '@api';
import { BASKET_ROUTE, withLocale } from '@utils';

export const CartWidgetContent: FC<CartWidgetProps> = React.memo(() => {
	const { data } = useData<BlockBasketProductsContent>({ url: withLocale(BASKET_ROUTE) });
	const items = data?.products || [];
	return (
		<>
			<div className={css.content}>
				{items.map((item) => {
					const key = `cart-widget-item-${item.deleteProductUrl}`;
					return <CartElement key={key} {...item} />;
				})}
			</div>
		</>
	);
});
