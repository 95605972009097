import css from './Map2GisMarker.module.scss';
import classnames from 'classnames';
import type { HtmlMarkerOptions } from '@2gis/mapgl/global';
import React, { FC, HTMLAttributes, useEffect, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { Icon, useMap2GisContext } from '@shared';

interface Map2GisHtmlMarkerProps
	extends Omit<HtmlMarkerOptions, 'html'>,
		HTMLAttributes<HTMLElement> {
	html?: string;
	id?: string;
	onClick?: (data?: HtmlMarkerOptions['userData']) => void;
	isActive?: boolean;
}

export const Map2GisHtmlMarker: FC<Map2GisHtmlMarkerProps> = React.memo((props) => {
	const { api, mapInstance } = useMap2GisContext();
	const { id, isActive, onClick } = props;

	const markup = useMemo(
		() =>
			props.html ||
			ReactDOMServer.renderToStaticMarkup(<Map2GisMarkerMarkup id={id} isActive={isActive} />),
		[props.html, isActive]
	);

	const markerOptions = useMemo(
		() => ({
			html: markup,
			coordinates: props.coordinates,
			anchor: props.anchor,
			minZoom: props.minZoom,
			maxZoom: props.maxZoom,
			zIndex: props.zIndex,
			preventMapInteractions: props.preventMapInteractions,
			userData: props.userData,
			interactive: props.interactive,
		}),
		[props, markup]
	);

	useEffect(() => {
		if (!api || !mapInstance) {
			return;
		}

		const clickHandler = () => {
			onClick && onClick();
		};

		const marker = new api.HtmlMarker(mapInstance, markerOptions);
		marker.getContent().addEventListener('click', clickHandler);

		return () => {
			marker && marker.destroy();
			marker.getContent().removeEventListener('click', clickHandler);
		};
	}, [api, mapInstance, markerOptions, onClick]);

	return null;
});

export const Map2GisMarkerMarkup: FC<Pick<Map2GisHtmlMarkerProps, 'id' | 'isActive'>> = ({
	id,
	isActive,
}) => {
	return (
		<div className={classnames(css.marker, { [css.isActive]: isActive })} data-marker-id={id}>
			<Icon id="pin" />
			<svg viewBox="0 0 30 40">
				<g fill="none" fillRule="nonzero">
					<path d="M15 0C6.716 0 0 6.75 0 15.078c0 2.785.542 5.661 2.098 7.735L15 40l12.901-17.188C29.316 20.93 30 17.602 30 15.079 30 6.751 23.284 0 15 0zm0 8.733c3.486 0 6.312 2.841 6.312 6.345 0 3.504-2.826 6.345-6.312 6.345s-6.312-2.841-6.312-6.345c0-3.504 2.826-6.345 6.312-6.345z" />
					<path d="M15 0c8.284 0 15 6.75 15 15.078 0 2.523-.685 5.852-2.099 7.735L15 40 2.098 22.812C.542 20.74 0 17.864 0 15.079 0 6.751 6.716 0 15 0zm0 38.334l12.102-16.122C28.252 20.68 29 17.706 29 15.078 29 7.302 22.73 1 15 1S1 7.302 1 15.078c0 2.936.621 5.433 1.898 7.134L15 38.334zm0-29.601c3.486 0 6.312 2.841 6.312 6.345 0 3.504-2.826 6.345-6.312 6.345s-6.312-2.841-6.312-6.345c0-3.504 2.826-6.345 6.312-6.345zm0 1c-2.932 0-5.312 2.392-5.312 5.345s2.38 5.345 5.312 5.345c2.932 0 5.312-2.392 5.312-5.345S17.932 9.733 15 9.733z" />
				</g>
			</svg>
		</div>
	);
};
