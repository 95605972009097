import css from './FigureTeaser.module.scss';
import classnames from 'classnames';
import type { FigureTeaserContent } from '@src/api/mock/types';
import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import Image from 'next/image';
import { Box, Container, Section, Typography } from '@shared';
import { MoveSmooth } from '@utils';

interface FigureTeaserProps extends HTMLAttributes<HTMLElement>, FigureTeaserContent {}

export const FigureTeaser: FC<FigureTeaserProps> = React.memo(({ textWithImages, subText }) => {
	const [section, setSection] = useState<HTMLElement | null>(null);

	useEffect(() => {
		if (!section) return;

		const onMouseMove = ({ x, y }: { x: number; y: number }) => {
			section.style.setProperty('--x', x.toString());
			section.style.setProperty('--y', y.toString());
		};

		MoveSmooth.addListener(onMouseMove);

		return () => {
			MoveSmooth.removeListener(onMouseMove);
		};
	}, [section]);
	return (
		<Section ref={setSection} className={classnames(css.component)}>
			<Container>
				<Box className={css.content}>
					{textWithImages.map((item, i) => {
						return (
							<div
								key={item.text}
								className={classnames(css.contentLine, {
									[css.justifyRight]: i % 2 !== 0,
									[css.justifyLeft]: i % 2 === 0,
								})}>
								<picture>
									{item.image.url && (
										<Image
											className={css.image}
											src={item.image.url}
											alt={item.text}
											layout="fill"
											objectFit="cover"
											sizes="50vw"
											quality={100}
										/>
									)}
								</picture>
								<Typography className={css.text} raw>{item.text}</Typography>
							</div>
						);
					})}
					<Typography raw className={css.textBlock} size="sm">
						{subText}
					</Typography>
					<div className={css.mobileImages}>
						{textWithImages.map((item) => (
							<picture key={`mobile-${item.text}`}>
								{item.image.url ? (
									<Image
										src={item.image.url}
										alt={item.text}
										layout="fill"
										objectFit="cover"
										sizes="50vw"
										quality={100}
									/>
								) : null}
							</picture>
						))}
					</div>
				</Box>
			</Container>
		</Section>
	);
});
