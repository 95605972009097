import css from './File.module.scss';
import classnames from 'classnames';
import React, { ComponentPropsWithoutRef, useState, useCallback, useRef, ChangeEvent } from 'react';

interface FileInputInterface extends ComponentPropsWithoutRef<'input'> {
	placeholder?: string;
	accept?: string;
	acceptLabel?: string;
	sizeLimit?: number;
	sizeLimitLabel?: string;
	multiple?: boolean;
	required?: boolean;
	hasError?: boolean;
	isLoading?: boolean;
}

export const File = React.forwardRef<HTMLInputElement, FileInputInterface>(
	(
		{
			className,
			placeholder = 'Прикрепить файл',
			accept,
			acceptLabel,
			sizeLimit,
			sizeLimitLabel,
			multiple,
			required,
			hasError,
			isLoading,
			...props
		}: FileInputInterface,
		forwardedRef
	) => {
		const ref = useRef<HTMLInputElement | null>(null);
		const [fileName, setFileName] = useState<string | null>(null);

		const handleChange = useCallback(
			(e: ChangeEvent<HTMLInputElement & { files: FileList }>) => {
				const input = e.target;

				if (input.value) {
					const file = input.files[0];
					setFileName(file.name);
				} else {
					setFileName(null);
				}

				props?.onChange && props.onChange(e);
			},
			[props]
		);

		return (
			<label
				className={classnames(css.fileInput, { [css.hasError]: hasError, 'has-error': hasError })}>
				<input
					{...props}
					ref={forwardedRef ? forwardedRef : ref}
					type="file"
					accept={accept}
					multiple={multiple}
					required={required}
					onChange={handleChange}
					className={classnames('visually-hidden', className)}
				/>
				<span
					className={classnames(css.fileInput__placeholder, css.fileInput__text, {
						[css.isActive]: !!fileName,
					})}>
					{placeholder}
				</span>
				<span className={css.fileInput__field}>
					<span className={css.fileInput__text}>{fileName}</span>
					{acceptLabel && <span className={css.fileInput__ext}>{acceptLabel}</span>}
				</span>
				{sizeLimitLabel && <span className={css.fileInput__restriction}>{sizeLimitLabel}</span>}
			</label>
		);
	}
);
