import css from './TextPageHeader.module.scss';
import classnames from 'classnames';
import { BlockTextPageHeader } from '@api/mock/types';
import React, { FC, HTMLAttributes } from 'react';
import { Section, TopBlock } from '@shared';
import { TextPageNav } from '@components/TextPage/components/TextPageNav/TextPageNav';

interface TextPageProps extends HTMLAttributes<HTMLElement>, BlockTextPageHeader {}

export const TextPageHeader: FC<TextPageProps> = React.memo(({ textTitle, sectionsNav }) => {
	return (
		<Section className={css.component}>
			<TopBlock
				title={textTitle}
				titleAs="h1"
				titleTag="h2"
				titleAlign="center"
				className={css.heading}
			/>

			<TextPageNav sectionsNav={sectionsNav} />
		</Section>
	);
});
