import useSWR from 'swr';
import { PageData, ReturnedSWRData } from '@api/hooks/types';
import { StaticPath, staticPaths, tempMockPaths } from '@api/mock/staticPaths';
import { fetcher } from '@api/utils/fetcher';
import { getMock } from '@api/mock';

export const getPaths = async (url?: string) => {
	if (!url) {
		return [...staticPaths, ...tempMockPaths];
	}

	const remote = await fetcher<StaticPath[]>(url);

	return [...(remote || []), ...tempMockPaths];
};

export const getPageData = async (url: string, needMock: boolean = false, headers: Headers = new Headers()) => {
	const mock = async () => getMock(url);
	if (needMock) return mock();

	return fetcher<any>({ url, headers });
};

export const getPageItem = (data: PageData, key: string) =>
	data.items?.find(({ type }) => type === key)?.content;

export const usePageData = (
	endpoint: string | null,
	needMock: boolean = false
): ReturnedSWRData => {
	const { data, error } = useSWR(endpoint, (url) => getPageData(url, needMock), {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: false,
	});

	return {
		data: data,
		isLoading: !error && !data,
		isError: error,
	};
};
