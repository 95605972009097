import css from './FeaturesStackMobile.module.scss';
import { Section } from '@src/shared';
import { FC, memo, useCallback, useState } from 'react';
import { FeaturesStackProps } from '@components';
import { FeaturesStackMobileCard } from './FeaturesStackMobileCard/FeaturesStackMobileCard';
import { useViewport } from '@src/context';
import { useScrollSeek } from '@src/hooks';

export const FeaturesStackMobile: FC<FeaturesStackProps> = memo(({ cards = [] }) => {
	const [section, setSection] = useState<HTMLElement | null>(null);
	const { vh, isTablet } = useViewport();
	const [seekFirst, setSeekFirst] = useState(1);
	const [seekSecond, setSeekSecond] = useState(1);

	useScrollSeek(
		section,
		useCallback(
			({ bounding }) => {
				if (!isTablet) return;
				const seekFirst = Math.min(1, Math.max(0, (bounding.bottom - vh * 2.2) / vh));
				const seekSecond = Math.min(1, Math.max(0, (bounding.bottom - vh * 1.1) / vh));
				setSeekFirst(seekFirst);
				setSeekSecond(seekSecond);
			},
			[vh, isTablet]
		),
		{
			checkInView: true,
			resist: 0.125,
		}
	);
	return (
		<Section
			ref={setSection}
			className={css.component}
			style={{
				height: `${100 * cards.length + 20}vh`,
			}}>
			<div className={css.sticky}>
				<div className={css.wrapper}>
					{cards.map((item, i) => (
						<FeaturesStackMobileCard
							index={i}
							key={`features-stack-${i}`}
							seek={i === 0 ? seekFirst : seekSecond}
							{...item}
						/>
					))}
				</div>
			</div>
		</Section>
	);
});
