import css from './Input.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	ComponentPropsWithoutRef,
	useState,
	useCallback,
	ChangeEvent,
	FocusEvent,
	useEffect,
	useMemo,
} from 'react';
import { File } from '@shared/Form/File/File';
import { useIMask } from 'react-imask';
import { HTMLMaskElement } from 'imask';

export interface InputProps extends ComponentPropsWithoutRef<'input'> {
	label?: string;
	labelAsPlaceholder?: boolean;
	hasError?: boolean;
	mask?: string;
	maskLazy?: boolean;
	onCompleteMask?: (e: ChangeEvent<HTMLInputElement>) => void;
	IconLeft?: FC;
	IconRight?: FC;
	pattern?: string
}

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
	(
		{
			type = 'text',
			label,
			labelAsPlaceholder = true,
			IconLeft,
			IconRight,
			hasError,
			className,
			mask,
			maskLazy = true,
			onCompleteMask,
			value,
			pattern,
			...props
		},
		forwardedRef
	) => {
		const [inputType] = useState(type);
		const [transitionClass, setTransitionClass] = useState(false);
		const [placeholderFocused, setPlaceholderFocused] = useState(false);

		const handleFocus = useCallback(
			(e: FocusEvent<HTMLInputElement>) => {
				setTransitionClass(true);
				setPlaceholderFocused(true);
				props?.onFocus && props.onFocus(e);
			},
			[props]
		);

		const handleBlur = useCallback(
			(e: FocusEvent<HTMLInputElement>) => {
				setPlaceholderFocused(!!e.target.value.length);
				props?.onBlur && props.onBlur(e);
			},
			[props]
		);

		const handleChange = useCallback(
			(e: ChangeEvent<HTMLInputElement>) => {
				setPlaceholderFocused(!!e.target.value.length || e.target === document.activeElement);
				props?.onChange && props.onChange(e);
			},
			[props]
		);

		const [maskIsComplete, setMaskIsComplete] = useState(false);

		const {
			ref: iMaskRef,
			value: maskedValue,
			setValue: setDefaultMaskedValue,
		} = useIMask(
			{
				mask: mask ? mask : '',
				lazy: maskLazy,
			},
			{
				onAccept: (value, maskRef) => {
					if (mask) {
						const target = (maskRef.el as HTMLMaskElement).input;
						const event = {
							target,
						} as ChangeEvent<HTMLInputElement>;
						setMaskIsComplete(false);
						handleChange(event);
					}
				},
				onComplete: (value, maskRef) => {
					const target = (maskRef.el as HTMLMaskElement).input;
					const event = {
						target,
					} as ChangeEvent<HTMLInputElement>;
					setMaskIsComplete(true);
					onCompleteMask && onCompleteMask(event);
				},
			}
		);

		useEffect(() => {
			if (mask && typeof value === 'string') setDefaultMaskedValue(value);
			// eslint-disable-next-line
		}, []);

		const refCallback = useCallback(
			(node: HTMLInputElement) => {
				if (!node) return;

				if (typeof forwardedRef === 'function') {
					forwardedRef(node);
				} else if (forwardedRef !== null) {
					forwardedRef.current = node;
				}

				if (labelAsPlaceholder) {
					setPlaceholderFocused(!!node.value.length);
				}

				if (mask) {
					iMaskRef.current = node;
				}
			},
			[forwardedRef, labelAsPlaceholder, mask, iMaskRef]
		);

		const Tag = type === 'file' ? 'div' : 'label';

		const inputValue = mask ? maskedValue : value;
		const inputPLaceholder = useMemo(
			() =>  {
				return mask ? (placeholderFocused ? mask.replace(/0/gi, '_') : '') : props.placeholder},
			[mask, props.placeholder, placeholderFocused]
		);

		return (
			<Tag
				className={classnames(css.input, {
					[css.hasError]: hasError,
					'has-error': hasError,
					'mask-is-complete': maskIsComplete,
				})}>
				{label && (
					<div
						className={classnames(css.input__label, {
							[css.isPlaceholder]: labelAsPlaceholder,
							[css.isPlaceholderFocused]: labelAsPlaceholder && placeholderFocused,
							[css.hasTransition]: transitionClass,
						})}>
						{labelAsPlaceholder && props.placeholder ? props.placeholder : label}
					</div>
				)}
				<div className={css.input__field}>
					{IconLeft && <IconLeft />}
					<>
						{type !== 'file' && (
							<input
								{...props}
								ref={refCallback}
								type={inputType}
								className={classnames(className, {})}
								onFocus={handleFocus}
								onBlur={handleBlur}
								onChange={handleChange}
								value={inputValue}
								placeholder={props.placeholder ?? inputPLaceholder}
								pattern={inputType === 'email' ? '^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$' : pattern}
							/>
						)}
						{type === 'file' && <File ref={forwardedRef} className={className} {...props} />}
					</>
					{IconRight && <IconRight />}
				</div>
			</Tag>
		);
	}
);
