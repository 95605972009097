import css from './ProductTableSize.module.scss';
import classnames from 'classnames';
import type { BlockCatalogSizesTableContent } from '@model/blockCatalogSizesTableContent';
import React, { FC, HTMLAttributes } from 'react';
import { PopUp, PopUpHead, PopUpBody } from '@shared/PopUp';
import { Table, Typography } from '@shared';

interface ProductTableSizeProps
	extends HTMLAttributes<HTMLTableElement>,
		BlockCatalogSizesTableContent {}

export const ProductTableSize: FC<ProductTableSizeProps> = React.memo(
	({ table, textDescription, textTableSizes, textHide }) => {
		return (
			<PopUp name="sizes-table" closeButtonText={textHide}>
				<PopUpHead title={textTableSizes} />
				<PopUpBody className={css.container}>
					<Table className={css.sizesTable} data={table?.rows} />
					<Typography className={classnames(css.information, 'wysiwyg')} size="sm" raw={true}>
						{textDescription}
					</Typography>
				</PopUpBody>
			</PopUp>
		);
	}
);
