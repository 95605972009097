import {
	ChangeEventHandler,
	FocusEventHandler,
	InvalidEvent,
	useState,
	useCallback,
	useMemo,
	useEffect,
	useContext,
} from 'react';
import { FormContext } from '@shared/Form/FormComponent/FormComponent';

export type InputElement = HTMLInputElement & HTMLTextAreaElement;

export type ValueType = string | boolean | (FileList | File);
export type ValidationMessagesType = Partial<Record<ErrorKey, string>>;

export type ErrorKey = 'required' | 'pattern' | 'default' | 'custom' | `custom${string}`;
export type ErrorType = {
	type: ErrorKey;
	message?: string | string[];
};

export type UseInputBindType<E = InputElement> = {
	onChange: ChangeEventHandler<E>;
	onFocus: FocusEventHandler<E>;
	onBlur: FocusEventHandler<E>;
	onInvalid: (e: InvalidEvent<E>) => void;
} & ({ value: string } | { checked: boolean } | {});

export interface UseInputInterface<T> {
	name?: string;
	value?: T;
	type?: HTMLInputElement['type'];
	validation?: {
		onBlur?: boolean;
		onChange?: boolean;
		resetOnFocus?: boolean;
		handler?: (value: ValueType) => { status: boolean; key?: string[] };
		messages?: ValidationMessagesType;
	};
}

export interface UseInputReturn<T> {
	bind: UseInputBindType;
	value: T;
	error?: ErrorType[];
	setValue: (value: any) => void;
	setError: (value: any) => void;
}

export const getErrorRecord = (
	keys: ErrorKey | ErrorKey[],
	messages?: ValidationMessagesType | string,
	defaultMessage = 'Некорректные данные'
) => {
	keys = Array.isArray(keys) ? keys : [keys];

	const collection: ErrorType[] = [];

	if (messages) {
		keys.forEach((k) => {
			const msg = typeof messages === 'string' ? messages : messages[k];
			msg &&
				collection.push({
					type: k,
					message: msg,
				});
		});
	}

	if (collection.length === 0) {
		collection.push({
			type: keys[0] as ErrorKey,
			message: defaultMessage,
		});
	}

	return collection;
};

export const useInput = <T>(
	name: string,
	{ value: defaultValue, type = 'text', validation }: UseInputInterface<T>
): UseInputReturn<T> => {
	const isFile = useMemo(() => type === 'file', [type]);
	const isRadio = useMemo(() => type === 'radio', [type]);
	const isCheckbox = useMemo(() => type === 'checkbox', [type]);
	const isChoice = useMemo(() => type === 'checkbox' || type === 'radio', [type]);

	const [valueState, setValueState] = useState<any>(defaultValue);
	const [errorState, setErrorState] = useState<ErrorType[] | undefined>(undefined);

	const setValue = useCallback((value: any) => setValueState(value), []);
	const setError = useCallback((value?: ErrorType) => {
		setErrorState(
			value
				? getErrorRecord(value.type, typeof value?.message === 'string' ? value.message : value)
				: undefined
		);
	}, []);

	/*
	 * Значение по умолчанию
	 */
	useEffect(() => {
		setValueState(defaultValue || (isCheckbox ? false : ''));
	}, [defaultValue, isCheckbox]);

	/*
	 * Обработчик кастомной валидации
	 * вызывает validation.handler, если он передан
	 */
	const customValidation = useCallback(
		(value?: any) => {
			if (!validation?.handler) return;

			const { status, key } = validation.handler(value || valueState);
			const errorKey = key ? key : `custom`;

			setErrorState(
				!status ? getErrorRecord(errorKey as ErrorKey, validation?.messages) : undefined
			);
		},
		[validation, valueState]
	);

	/*
	 * Обработчик смены значения
	 * validation.onChange тригерит валидацию на расфокус
	 */
	const changeHandler: ChangeEventHandler<InputElement> = useCallback(
		({ target }) => {
			const value = isCheckbox ? target.checked : isFile ? target.files : target.value;

			setValueState(value);
			setErrorState(undefined);

			if (validation?.onChange) {
				validation?.handler ? customValidation(value) : target.checkValidity();
			}
		},
		[isCheckbox, isFile, customValidation, validation?.handler, validation?.onChange]
	);

	/*
	 * Обработчик сброса фокуса
	 * validation.onBlur тригерит валидацию на расфокус
	 */
	const blurHandler: FocusEventHandler<InputElement> = useCallback(
		({ target }) => {
			if (validation?.onBlur) {
				validation?.handler ? customValidation() : target.checkValidity();
			}
		},
		[customValidation, validation?.handler, validation?.onBlur]
	);

	/*
	 * Обработчик фокуса
	 * validation.resetOnFocus сбрасывает ошибку при новом фокусе
	 */
	const focusHandler: FocusEventHandler<InputElement> = useCallback(() => {
		if (validation?.resetOnFocus) {
			setErrorState(undefined);
		}
	}, [validation?.resetOnFocus]);

	/*
	 * Обработчик невалидных полей
	 * пишет в error-стейт оишбку с типом и сообщением из validation.messages или нативным
	 */
	const invalidHandler = useCallback(
		(e: InvalidEvent<InputElement>) => {
			e.preventDefault();
			const defaultMessage = e.target?.validationMessage;

			if (e.target.validity.valueMissing) {
				setErrorState(getErrorRecord('required', validation?.messages, defaultMessage));
			} else if (e.target.validity.typeMismatch || e.target.validity.patternMismatch) {
				setErrorState(getErrorRecord('pattern', validation?.messages, defaultMessage));
			} else {
				setErrorState(getErrorRecord('default', validation?.messages, defaultMessage));
			}
		},
		[validation?.messages]
	);

	/*
	 * Пропсы для разных типов полей
	 */
	const value = useMemo(
		() =>
			isChoice
				? isRadio
					? {}
					: { checked: valueState as boolean }
				: { value: valueState as string },
		[isRadio, isChoice, valueState]
	);

	/*
	 * Регистрация в контексте формы
	 */
	const { register } = useContext(FormContext);

	useEffect(() => {
		if (name && register) {
			register(name, { value: valueState, error: errorState, setValue, setError });
		}
	}, [name, register, errorState, setError, valueState, setValue]);

	return {
		bind: {
			...value,
			onChange: changeHandler,
			onFocus: focusHandler,
			onBlur: blurHandler,
			onInvalid: invalidHandler,
		},
		value: valueState,
		error: errorState,
		setValue,
		setError,
	};
};
