import { PageData } from '@api/hooks/types';

export const faq: PageData = {
	meta: [
		{
			type: 'title',
			content: 'FAQ | Клиентский сервис',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		/*
		 * Вариант с выносом заголовка и навигации в отдельный блок
		 */
		{
			type: 'block-text-page-header',
			content: {
				textTitle: 'Клиентский сервис',
				sectionsNav: [
					{
						name: 'Оплата и доставка',
						url: '/client-service/payment-delivery',
					},
					{
						name: 'Возврат',
						url: '/client-service/refund',
					},
					{
						name: 'FAQ',
						url: '/client-service/faq',
					},
				],
			},
		},
		{
			type: 'block-text-page',
			content: {
				textMain: [
					{
						type: 'spoiler',
						title: 'Ткани и уход',
						content:
							'<p>В течение 14 дней после получения заказа зайдите в личный кабинет и выберите товар для возврата. Дождитесь звонка от сотрудника отдела Дружбы с клиентами или свяжитесь с нами  самостоятельно, чтобы договориться о приезде курьера. Курьеры приезжают в будние дни с 10:00 до 18:00</p>',
					},
					{
						type: 'spoiler',
						title: 'Как будет упакован заказ?',
						content:
							'<p>В течение 14 дней после получения заказа зайдите в личный кабинет и выберите товар для возврата. Дождитесь звонка от сотрудника отдела Дружбы с клиентами или свяжитесь с нами  самостоятельно, чтобы договориться о приезде курьера. Курьеры приезжают в будние дни с 10:00 до 18:00</p>',
					},
					{
						type: 'spoiler',
						title: 'Где можно отслеживать заказ?',
						content:
							'<p>В течение 14 дней после получения заказа зайдите в личный кабинет и выберите товар для возврата. Дождитесь звонка от сотрудника отдела Дружбы с клиентами или свяжитесь с нами  самостоятельно, чтобы договориться о приезде курьера. Курьеры приезжают в будние дни с 10:00 до 18:00</p>',
					},
					{
						type: 'spoiler',
						title: 'На вещи обнаружен брак, что делать?',
						content:
							'<p>В течение 14 дней после получения заказа зайдите в личный кабинет и выберите товар для возврата. Дождитесь звонка от сотрудника отдела Дружбы с клиентами или свяжитесь с нами  самостоятельно, чтобы договориться о приезде курьера. Курьеры приезжают в будние дни с 10:00 до 18:00</p>',
					},
					{
						type: 'spoiler',
						title: 'Доставка заказа задерживается, что делать?',
						content:
							'<p>В течение 14 дней после получения заказа зайдите в личный кабинет и выберите товар для возврата. Дождитесь звонка от сотрудника отдела Дружбы с клиентами или свяжитесь с нами  самостоятельно, чтобы договориться о приезде курьера. Курьеры приезжают в будние дни с 10:00 до 18:00</p>',
					},
					{
						type: 'spoiler',
						title: 'Когда вернуться деньги в случае осуществления возврата?',
						content:
							'<p>В течение 14 дней после получения заказа зайдите в личный кабинет и выберите товар для возврата. Дождитесь звонка от сотрудника отдела Дружбы с клиентами или свяжитесь с нами  самостоятельно, чтобы договориться о приезде курьера. Курьеры приезжают в будние дни с 10:00 до 18:00</p>',
					},
					{
						type: 'spoiler',
						title: 'Как внести изменения в заказ, если он уже оформлен?',
						content:
							'<p>В течение 14 дней после получения заказа зайдите в личный кабинет и выберите товар для возврата. Дождитесь звонка от сотрудника отдела Дружбы с клиентами или свяжитесь с нами  самостоятельно, чтобы договориться о приезде курьера. Курьеры приезжают в будние дни с 10:00 до 18:00</p>',
					},
					{
						type: 'spoiler',
						title: 'Почему заказ отменен?',
						content:
							'<p>В течение 14 дней после получения заказа зайдите в личный кабинет и выберите товар для возврата. Дождитесь звонка от сотрудника отдела Дружбы с клиентами или свяжитесь с нами  самостоятельно, чтобы договориться о приезде курьера. Курьеры приезжают в будние дни с 10:00 до 18:00</p>',
					},
				],
			},
		},
		{
			type: 'block-still-have-questions',
			content: {
				textTitle: 'Остались вопросы',
				contactLinks: [
					{
						textTitle: 'Телефона интернет-магазина',
						links: [
							{
								name: '+7 (800) 320-72-65',
								url: '+78003207265',
							},
						],
					},
					{
						textTitle: 'По вопросам клиентского сервиса',
						links: [
							{
								name: 'cs@agapium.com',
								url: 'cs@agapium.com',
							},
						],
					},
					{
						textTitle: 'Связаться с нами в социальных сетях',
						links: [
							{
								name: 'Вконтакте',
								url: 'https://vk.com',
							},
							{
								name: 'Telergram',
								url: 'https://tg.com',
							},
						],
					},
				],
			},
		},
	],
};
