import css from './Login.module.scss';
import type { BlockLogin } from '@api/mock/types';
import React, { FC, HTMLAttributes } from 'react';
import Image from 'next/image';
import { Box } from '@shared';
import { LoginForm } from '@components/Login/components/LoginForm/LoginForm';

interface LoginProps extends HTMLAttributes<HTMLElement>, BlockLogin {}

export const Login: FC<LoginProps> = React.memo(({ imageCover, loginForm }) => {
	return (
		<Box className={css.component}>
			<Box className={css.content}>
				<LoginForm loginForm={loginForm} />
			</Box>
			<Box className={css.decor}>
				<picture>
					{imageCover?.url && (
						<Image
							src={imageCover.url}
							alt={imageCover.alt || ''}
							layout="fill"
							objectFit="cover"
							quality={100}
							priority={true}
							sizes="(max-width: 1023px) 50vw, 50vw"
						/>
					)}
				</picture>
			</Box>
		</Box>
	);
});
