import css from '../Header.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, MouseEventHandler } from 'react';
import { CartBtn } from '@layout/components/Header/components';
import { Button, Icon } from '@shared';
import { useHeader } from '@layout/components/Header/context/Header.context';
import { useBxComponents } from '@context';
import { useRouter } from 'next/router';

interface ToolsProps extends HTMLAttributes<HTMLDivElement> {}

export const Tools: FC<ToolsProps> = React.memo(({ className }) => {
	const { search } = useHeader();

	const searchClickHandler: MouseEventHandler = () => {
		if (search?.setState) search.setState((prev) => !prev);
	};

	const { asPath } = useRouter();

	const { header } = useBxComponents();
	const { login, wishlist, searchComponent } = header?.menu || {};
	/*
	 * ToDo: подключать тулзы по готовности
	 */

	return (
		<div className={classnames(className, css.tools)}>
			<Button
				className={classnames(css.toolsSearch, css.toolsBtn)}
				onClick={searchClickHandler}
				aria-label={searchComponent?.textMain || ''}>
				<Icon id="search" />
			</Button>
			<Button
				className={classnames(css.toolsFav, css.toolsBtn, {[css.toolsBtnActive]: asPath.split('/').some(item => item === 'wishlist')})}
				to={wishlist?.url || '/'}
				aria-label={wishlist?.name || ''}>
				<Icon id="fav" />
			</Button>
			<Button
				className={classnames(css.toolsUser, css.toolsBtn, {[css.toolsBtnActive]: asPath.split('/').some(item => item === 'account') && asPath !== "/account/wishlist"})}
				to={login?.url || '/'}
				aria-label={login?.name || ''}>
				<Icon id="user" />
			</Button>
			<CartBtn />
		</div>
	);
});

export const ToolsMob: FC<ToolsProps> = React.memo(({ className }) => {
	const { header } = useBxComponents();
	const { login } = header?.menu || {};

	return (
		<div className={classnames(className, css.toolsMob, css.navigationMob)}>
			<Button
				to={login?.url || '/'}
				className={classnames(css.toolsMobBtn, css.navigationMobLevel)}>
				<span>{login?.name || ''}</span>
				<Icon id="user" />
			</Button>
		</div>
	);
});
