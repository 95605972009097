import { PageData } from '@api/hooks/types';

export const refund: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Возврат | Клиентский сервис',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-text-page',
			content: {
				/*
				 * Вариант с заголовком и навигацией внутри блока контента
				 */
				textTitle: 'Клиентский сервис',
				sectionsNav: [
					{
						name: 'Оплата и доставка',
						url: '/client-service/payment-delivery',
					},
					{
						name: 'Возврат',
						url: '/client-service/refund',
					},
					{
						name: 'FAQ',
						url: '/client-service/faq',
					},
				],
				textMain: [
					{
						type: 'p',
						content:
							'Мы&nbsp;надеемся, что вы&nbsp;останетесь довольны покупкой, но, если понадобится, заказ можно полностью или частично вернуть в&nbsp;течение 14 дней со&nbsp;дня получения.',
					},
					{
						type: 'p',
						content:
							'Не&nbsp;пользуйтесь товаром, который планируете вернуть&nbsp;&mdash; он&nbsp;должен оставаться новым*, сохраните все вшитые ярлыки и&nbsp;этикетки, а&nbsp;также чеки, подтверждающие покупку в&nbsp;нашем магазине.',
					},
					{
						type: 'p',
						content: 'Нельзя вернуть товары из категорий &laquo;Парфюмерия&raquo;.',
					},
					{
						type: 'h4',
						content: 'Вызвать бесплатного курьера',
					},
					{
						type: 'p',
						content:
							'В&nbsp;течение 14 дней после получения заказа зайдите в&nbsp;личный кабинет и&nbsp;выберите товар для возврата. Дождитесь звонка от&nbsp;сотрудника отдела Дружбы с&nbsp;клиентами или свяжитесь с&nbsp;нами самостоятельно, чтобы договориться о&nbsp;приезде курьера. Курьеры приезжают в&nbsp;будние дни с&nbsp;10:00 до&nbsp;18:00',
					},
					{
						type: 'p',
						content:
							'Упакуйте вещи, которые хотите вернуть, в&nbsp;пакет или фирменную коробку, приложите к&nbsp;товару заполненное заявление на&nbsp;возврат. При курьере разместите на&nbsp;коробке наклейку** с&nbsp;датой, подписью и&nbsp;ее&nbsp;расшифровкой.',
					},
					{
						type: 'p',
						content:
							'Если решение о&nbsp;возврате другого заказа вы&nbsp;приняли в&nbsp;последний момент и&nbsp;уже не&nbsp;успеваете заполнить заявление, попросите курьера вписать номер заказа в&nbsp;транспортную накладную.',
					},
					{
						type: 'h4',
						content: 'Как и когда вернутся деньги?',
					},
					{
						type: 'p',
						content:
							'Мы&nbsp;перечислим деньги в&nbsp;течение 10 дней. Отсчет начинается на&nbsp;следующий день после того, как вы&nbsp;вернули товар в&nbsp;магазин. Если вы&nbsp;вызывали курьера за&nbsp;возвратом, отсчет начинается на&nbsp;следующий день после поступления товара на&nbsp;наш склад.',
					},
					{
						type: 'p',
						content:
							'Если вы&nbsp;оплачивали заказ при получении, мы&nbsp;переведем деньги по&nbsp;реквизитам в&nbsp;заявлении на&nbsp;возврат. Если вы&nbsp;оплачивали заказ банковской картой на&nbsp;сайте, деньги вернутся на&nbsp;ту&nbsp;же карту. Пожалуйста, учитывайте, что на&nbsp;карту деньги могут поступить позже&nbsp;&mdash; срок обработки платежа зависит от&nbsp;вашего банка.',
					},
					{
						type: 'p',
						content:
							'Если вы&nbsp;хотите получить информацию о&nbsp;статусе вашего возврата, свяжитесь с&nbsp;нами любым удобным способом.',
					},
					{
						type: 'textBox',
						content: [
							{
								type: 'html',
								content:
									'<p>* Согласно пункту 1 статьи 25 Закона РФ&nbsp;&laquo;О&nbsp;защите прав потребителей&raquo; от&nbsp;07.02.1992 &#8470;&nbsp;2300&ndash;1</p>' +
									'<p>** В&nbsp;случае отправки возврата без наклейки ответственность за&nbsp;сохранность вещей лежит на&nbsp;отправителе заказа (покупателе). Коробка с&nbsp;обувью должна быть упакована в&nbsp;пакет, который прилагается с&nbsp;отправкой заказа, после чего опечатана индивидуальной наклейкой</p>',
							},
						],
					},
				],
			},
		},
		{
			type: 'block-still-have-questions',
			content: {
				textTitle: 'Остались вопросы',
				contactLinks: [
					{
						textTitle: 'Телефона интернет-магазина',
						links: [
							{
								name: '+7 (800) 320-72-65',
								url: '+78003207265',
							},
						],
					},
					{
						textTitle: 'По вопросам клиентского сервиса',
						links: [
							{
								name: 'cs@agapium.com',
								url: 'cs@agapium.com',
							},
						],
					},
					{
						textTitle: 'Связаться с нами в социальных сетях',
						links: [
							{
								name: 'Вконтакте',
								url: 'https://vk.com',
							},
							{
								name: 'Telergram',
								url: 'https://tg.com',
							},
						],
					},
				],
			},
		},
	],
};
