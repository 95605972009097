import type { PageMetaType } from '@api/hooks/types';
import React, { FC, PropsWithChildren } from 'react';
import Head from 'next/head';

interface HeadBaseProps {
	meta?: PageMetaType[];
}

const isProd = process.env.NODE_ENV === 'production';

export const HeadBase: FC<PropsWithChildren<HeadBaseProps>> = ({ meta = [], children }) => {
	return (
		<Head>
			{meta?.map(({ type, content, name }, i) => {
				if (type === 'sectionCode') return null;
				const key = `${type}-${content}`;
				switch (type) {
					case 'title':
						return <title key={key}>{content}</title>;
					default:
						return <meta key={key} name={name} content={content} />;
				}
			})}
			<meta
				name="viewport"
				content="width=device-width, initial-scale=1, maximum-scale=1.0, user-scalable=no"
			/>

			<link rel="icon" href="/favicons/32_32.png" type="image/x-icon" />
			<link rel="shortcut icon" href="/favicons/32_32.png" type="image/x-icon" />
			<link rel="apple-touch-icon" sizes="76x76" href="/favicons/76_76.png" />
			<link rel="apple-touch-icon" sizes="120x120" href="/favicons/120_120.png" />
			<link rel="apple-touch-icon" sizes="152x152" href="/favicons/152_152.png" />
			<link rel="apple-touch-icon" sizes="180x180" href="/favicons/180_180.png" />

			{isProd && <style>{`html:not(.is-started) { background: #1e1e1e; }`}</style>}

			{children}
		</Head>
	);
};
