import css from '../CartElement.module.scss';
import classnames from 'classnames';
import React, { FC } from 'react';
import { CartElemmentComponentProps, ColorDot, Typography } from '@shared';

export const CartElementColor: FC<CartElemmentComponentProps> = ({
	color,
	colorText,
	className,
}) => {
	return (
		<div className={classnames(className, css.color)}>
			<ColorDot className={css.colorDot} color={color} />
			<Typography size="xs" color="gray">
				{colorText}
			</Typography>
		</div>
	);
};
