import { PageData } from '@api/hooks/types';

export const checkout: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Оформление заказа | Agapium',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-order-main',
			content: {
				textCheckoutOrder: 'Оформление заказа',
				textPay: 'Оплата',
				textCompletion: 'Завершение заказа',
				textPersonalData: 'Личные данные',
				textOrderDelivery: 'Способ доставки',
				textPayMethod: 'Способ оплаты',
				textPromoCode: 'Промокод',
				textToPay: 'Перейти к оплате',
				textDescription: 'Описание с поль. соглашениями',
				textOrderPrice: 'Сумма заказа',
				textDiscount: 'Скидка',
				textDelivery: 'Доставка',
				textTotalPrice: 'Итого к оплате',
				textSelectDeliveryPoint: 'Выбрать пункт выдачи',
				orderForm: {
					action: '/order',
					method: 'post',
					items: [
						{
							type: 'text',
							name: 'lastName',
							label: 'Фамилия',
							required: true,
							validation: {
								required: 'Обязательное поле для заполнения',
							},
						},
						{
							type: 'text',
							name: 'firstName',
							label: 'Имя',
							required: true,
							validation: {
								required: 'Обязательное поле для заполнения',
							},
						},
						{
							type: 'text',
							name: 'middleName',
							label: 'Отчество',
							required: true,
							validation: {
								required: 'Обязательное поле для заполнения',
							},
						},
						{
							type: 'tel',
							name: 'phone',
							label: 'Телефон',
							required: true,
							mask: '+0 000 0000000',
							pattern: '^(\\+\\d)\\s\\d{3}\\s\\d{7}$',
							validation: {
								required: 'Обязательное поле для заполнения',
								pattern: 'Некорректный номер телефона',
							},
						},
						{
							type: 'email',
							name: 'email',
							label: 'Электронная почта',
							required: true,
							pattern: '[0-9a-z_.-]+@[0-9a-z_]+\\.[a-z]{2,5}',
							validation: {
								required: 'Обязательное поле для заполнения',
								pattern: 'Некорректный адрес email',
							},
						},
						{
							type: 'radio',
							name: 'paymentMethod',
							required: true,
							items: [
								{
									selected: true,
									value: 'card',
									label: 'Оплата картой',
								},
								{
									selected: false,
									value: 'byPerson',
									label: 'Наличными при получении',
								},
								{
									selected: false,
									value: 'byPersonCard',
									label: 'Оплата картой при получении',
								},
							],
						},
						{
							type: 'text',
							name: 'promoCode',
							label: 'Ввести промокод',
							required: false,
						},
					],
				},
			},
		},
	],
};
