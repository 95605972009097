import css from './Map2GisMarker.module.scss';
import ReactDOMServer from 'react-dom/server';
import React, { FC, useEffect, useMemo } from 'react';
import { MapObject, useMap2GisContext } from '@shared';
import { Clusterer } from '@2gis/mapgl-clusterer';
import { Map2GisMarkerMarkup } from '@shared/Map2Gis/components/Map2GisMarker/Map2GisHtmlMarker';
import { getBounds } from '@shared/Map2Gis/utils';
import classnames from 'classnames';

interface Map2GisClustererProps {
	objects: MapObject[];
	onSelectObject?: (o: MapObject) => void;
}

export const Map2GisClusterer: FC<Map2GisClustererProps> = React.memo(
	({ objects = [], onSelectObject }) => {
		const { api, mapInstance, setClustererInstance, activeObject } = useMap2GisContext();

		const markers = useMemo(() => {
			return objects.map((object) => ({
				type: 'html' as const,
				html: ReactDOMServer.renderToStaticMarkup(
					<Map2GisMarkerMarkup id={object?.id} isActive={activeObject?.id === object?.id} />
				),
				coordinates: object.coordinates,
				userData: object,
				preventMapInteractions: false,
			}));
		}, [activeObject?.id, objects]);

		useEffect(() => {
			if (!api || !mapInstance || markers.length === 0) return;

			const clusterer = new Clusterer(mapInstance, {
				radius: 60,
				clusterStyle: (count, target) => {
					const hasActive = !!target.data.find(({ userData }) => userData.id === activeObject?.id);
					return {
						type: 'html',
						html: ReactDOMServer.renderToStaticMarkup(
							<Map2GisClusterMarkup count={count} isActive={hasActive} />
						),
					};
				},
			});

			setClustererInstance(clusterer);

			clusterer.load(markers);

			clusterer.on('click', ({ target }) => {
				switch (target.type) {
					case 'cluster':
						// Set zoom
						// const zoom = clusterer.getClusterExpansionZoom(target.id);
						// mapInstance.setZoom(zoom);

						// Set bounds
						const bounds = getBounds(target.data.map(({ coordinates }) => coordinates));
						mapInstance.fitBounds(bounds);

						break;
					case 'marker':
						onSelectObject && onSelectObject(target.data.userData);
						break;
				}
			});

			return () => {
				clusterer && clusterer.destroy();
				setClustererInstance(undefined);
			};
		}, [onSelectObject, markers, api, mapInstance, setClustererInstance, activeObject]);

		return <></>;
	}
);

export const Map2GisClusterMarkup: FC<{ count?: number; isActive?: boolean }> = ({
	count,
	isActive,
}) => {
	return (
		<div className={classnames(css.cluster)}>
			<div className={classnames(css.clusterCircle, { [css.isActive]: isActive })}>{count}</div>
		</div>
	);
};
