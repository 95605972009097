import css from './ProductDetails.module.scss';
import classnames from 'classnames';
import type { OffersCatalogProductsElements } from '@model/models';
import type { BlockCatalogProductContent } from '@model/blockCatalogProductContent';
import React, { createContext, FC, HTMLAttributes, useCallback, useContext, useState } from 'react';
import { Section } from '@shared';
import { ProductGallery } from '@components/ProductDetails/components/ProductGallery/ProductGallery';
import { ProductMain } from '@components/ProductDetails/components/ProductMain/ProductMain';
import { useScrollSeek } from '@hooks';
import { useViewport } from '@context';

type OfferType = OffersCatalogProductsElements | undefined;

export interface ProductDetailsProps
	extends Omit<HTMLAttributes<HTMLElement>, 'id'>,
		BlockCatalogProductContent {}

export const ProductDetails: FC<ProductDetailsProps> = React.memo((props) => {
	const [section, setSection] = useState<HTMLElement | null>(null);

	const { vh } = useViewport();

	useScrollSeek(
		section,
		useCallback(
			({ bounding }) => {
				const end = bounding.bottom - vh / 2 < 0;
				section && section.classList.toggle('is-ended', end);
			},
			[vh, section]
		),
		{
			checkInView: false,
		}
	);

	const { offers } = props;
	const [offer, setOffer] = useState<OfferType>(offers && offers[0]);

	return (
		<ProductContext.Provider value={{ offer, setOffer }}>
			<Section
				ref={setSection}
				className={classnames(css.component)}
				itemType="https://schema.org/Product"
				itemProp="itemListElement"
				itemScope>
				<ProductGallery className={css.gallery} {...props} />
				<ProductMain className={css.main} {...props} />
			</Section>
		</ProductContext.Provider>
	);
});

export interface ProductContextProps {
	offer: OfferType;
	setOffer: React.Dispatch<React.SetStateAction<OfferType>>;
}

export const ProductContext = createContext<ProductContextProps>({
	offer: undefined,
	setOffer: () => undefined,
});

export const useProductDetails = () => {
	return useContext(ProductContext);
};
