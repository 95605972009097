import css from './Select.module.scss';
import classnames from 'classnames';
import React, {
	PropsWithChildren,
	FC,
	HTMLAttributes,
	ChangeEventHandler,
	InvalidEvent,
	useCallback,
	useState,
	useEffect,
	Fragment,
} from 'react';
import { Box, Icon, Portal } from '@shared';
import { useOutsideClick, useDelayUnmount, useResize, useScrollSeek } from '@hooks';
import { useViewport } from '@context';
import { HEADER_HEIGHT, HEADER_HEIGHT_SM } from '@utils';

interface SelectProps extends SelectType, HTMLAttributes<HTMLSelectElement> {
	selected: SelectOption | undefined;
	setSelected: (selected: SelectOption | undefined) => void;
	onChange?: ChangeEventHandler<HTMLSelectElement>;
	onInvalid?: (e: InvalidEvent<HTMLSelectElement>) => void;
	portalize?: boolean;
}

export const Select: FC<PropsWithChildren<SelectProps>> = React.memo(
	({
		name,
		selected,
		setSelected,
		options,
		className,
		required,
		onInvalid,
		placeholder = '',
		portalize = true,
	}) => {
		const { vh, isMob } = useViewport();

		const [ref, setRef] = useState<HTMLElement | null>(null);
		const [drop, setDropRef] = useState<HTMLElement | null>(null);

		/*
		 * Обработка переключения
		 */
		const [active, setActive] = useState(false);
		const shouldRender = useDelayUnmount(active, 600);

		const handleToggle = useCallback(() => {
			setActive((prev) => !prev);
		}, []);

		const handleClose = useCallback(() => {
			setActive(false);
		}, []);

		/*
		 * Обработка выбора
		 */
		const [selectedLocal, setSelectedLocal] = useState<SelectOption | null>(selected || null);

		const handleSelect = useCallback(
			(option: SelectOption) => {
				setSelected(option);
				setSelectedLocal(option);
				setActive(false);
			},
			[setSelected]
		);

		/*
		 * Скрытие по условиям
		 */
		useOutsideClick([ref, drop], handleClose);

		useScrollSeek(
			drop,
			useCallback(
				({ bounding }) => {
					const threshold = isMob ? HEADER_HEIGHT_SM : HEADER_HEIGHT;

					if (active && bounding.top < threshold) {
						setActive(false);
					}
				},
				[active, isMob]
			),
			{ checkInView: true }
		);

		/*
		 * Позиционирование дроп-бокса
		 */
		const [above, setAbove] = useState(false);

		const positioning = useCallback(() => {
			const select = ref;
			if (portalize && select && drop) {
				const { left, top, width, height } = select.getBoundingClientRect();

				drop.style.top = `${(top + window.scrollY + height).toFixed(0)}px`;
				drop.style.left = `${left.toFixed(0)}px`;
				drop.style.minWidth = `${width}px`;
			}
		}, [portalize, ref, drop]);

		useResize(positioning);

		useEffect(() => {
			const select = ref;

			if (shouldRender) {
				positioning();

				setTimeout(() => {
					if (select && drop) {
						const { top: selectTop, height: selectHeight } = select.getBoundingClientRect();
						const { bottom, height } = drop.getBoundingClientRect();
						const margins = 8;
						const scroller = select?.closest('.scrollbar-container')?.getBoundingClientRect();
						const vpb = scroller ? scroller.top + scroller.height : window.innerHeight;

						if (portalize) {
							const baseYPos = selectTop + window.scrollY + selectHeight;
							const yPos = bottom > vpb ? baseYPos - height - margins - selectHeight : baseYPos;

							drop.style.top = `${yPos.toFixed(0)}px`;
						}

						setAbove(bottom - margins > vpb);
					}
				}, 10);
			} else {
				setAbove(false);
			}
		}, [portalize, shouldRender, ref, drop, positioning]);

		/*
		 * Установка фокуса при открытии
		 */
		useEffect(() => {
			if (active && drop) {
				drop.focus();
			}
		}, [active, drop]);

		const DropWrap = portalize ? Portal : Fragment;

		return (
			<Box ref={setRef} className={classnames(className, css.select)}>
				<button
					type="button"
					className={classnames(
						css.header,
						{ [css.isActive]: !!selected || active, [css.isPlaceholder]: !selected },
						'select'
					)}
					onClickCapture={handleToggle}>
					<span>{selected?.label || placeholder}</span>
					<Icon id="chevron-down" />
				</button>
				{name && (
					<select
						className="visually-hidden"
						name={name}
						value={selected?.value || ''}
						onChange={() => null}
						onInvalid={onInvalid}
						required={required}>
						<option value="" />
						{selected?.value && <option value={selected.value}>{selected?.label}</option>}
					</select>
				)}
				<DropWrap>
					<Box
						role="menu"
						ref={setDropRef}
						className={classnames(css.drop, {
							[css.isActive]: active,
							[css.isAbove]: above && !portalize,
						})}
						tabIndex={0}>
						{shouldRender && (
							<Box className={css.list}>
								{options?.map((option) => {
									const isHidden = option.value === null && !selected?.value;
									return !isHidden ? (
										<button
											type="button"
											key={option.value + option.label}
											className={classnames(css.option, {
												[css.selected]: selected?.value === option.value,
											})}
											onClick={() => handleSelect(option)}>
											{option.label}
										</button>
									) : null;
								})}
							</Box>
						)}
					</Box>
				</DropWrap>
			</Box>
		);
	}
);
