import css from './InputSpinner.module.scss';
import classnames from 'classnames';
import React, {
	useState,
	useRef,
	HTMLAttributes,
	ChangeEventHandler,
	useEffect,
	useCallback,
} from 'react';
import { Button } from '@shared';

interface InputSpinnerProps extends HTMLAttributes<HTMLInputElement> {
	min?: number;
	max?: number;
	step?: number;
	plus?: JSX.Element | string;
	minus?: JSX.Element | string;
	onChangeValue?: (n: number) => void;
}

export const InputSpinner = React.memo(
	React.forwardRef<HTMLInputElement, InputSpinnerProps>(
		(
			{
				min = 0,
				max = 100000,
				step = 1,
				defaultValue,
				onChangeValue,
				className,
				plus = <div className={css.icon} />,
				minus = <div className={css.icon} />,
				...props
			}: InputSpinnerProps,
			fwdRef
		) => {
			const ref = useRef<HTMLInputElement | null>(null);

			const minRef = useRef(min);
			const maxRef = useRef(max);
			const defValue = useRef(!Number.isNaN(Number(defaultValue)) ? Number(defaultValue) : 1);

			const [valueLocal, setValueLocal] = useState<number>(defValue.current);

			const getLimitedValue = useCallback(
				(val: ((num: number) => number) | number) => {
					const value = typeof val === 'function' ? val(valueLocal) : val;
					return Math.max(min, Math.min(max, value));
				},
				[max, min, valueLocal]
			);

			const setValue = useCallback(
				(value: number) => {
					setValueLocal(value);
					onChangeValue && onChangeValue(value);
				},
				[onChangeValue]
			);

			const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
				({ target }) => {
					const targetValue =
						target.value && !Number.isNaN(Number(target.value)) ? Number(target.value) : 1;
					const value = getLimitedValue(targetValue);
					setValue(value);
				},
				[getLimitedValue, setValue]
			);

			const increase = useCallback(() => {
				const value = getLimitedValue((prev) => prev + step);
				setValue(value);
			}, [step, getLimitedValue, setValue]);

			const decrease = useCallback(() => {
				const value = getLimitedValue((prev) => prev - step);
				setValue(value);
			}, [step, getLimitedValue, setValue]);

			useEffect(() => {
				if (minRef.current !== min || maxRef.current !== max) {
					const value = getLimitedValue((prev) => prev);
					// setValue(value);
				}
			}, [min, max, getLimitedValue, setValue]);

			return (
				<div className={classnames(className, css.spinner)}>
					<Button
						type="button"
						className={classnames(css.minus, css.button)}
						onClickCapture={(e) => {
							e.preventDefault();
							decrease();
						}}
						disabled={valueLocal <= min}>
						{minus}
					</Button>
					<label>
						<input
							{...props}
							ref={(node) => {
								if (node) {
									ref.current = node;
									typeof fwdRef === 'function' && fwdRef(node);
								}
							}}
							type="tel"
							value={valueLocal}
							onChange={handleChange}
							readOnly={true}
						/>
					</label>
					<Button
						type="button"
						className={classnames(css.plus, css.button)}
						onClickCapture={(e) => {
							e.preventDefault();
							increase();
						}}
						disabled={valueLocal >= max}>
						{plus}
					</Button>
				</div>
			);
		}
	)
);
