import type { OffersCatalogProductsElementsPrice } from '@model/offersCatalogProductsElementsPrice';
import type { ListBasketBasketPriceSum } from '@model/listBasketBasketPriceSum';
import { useMemo } from 'react';
import { dischargeNumber } from '@utils';

export type PriceType = OffersCatalogProductsElementsPrice | ListBasketBasketPriceSum;
export type CurrencyType = keyof OffersCatalogProductsElementsPrice;

// ToDo: получать код валюты глобально
//  он должен выбираться рядом с подвалом или мобильной шапке

export const useCurrency = () => {
	const currency: CurrencyType = 'rub';
	// const currency: CurrencyType = 'usd';
	return useMemo(() => currency, [currency]);
};

export const usePrice = (price?: PriceType | [], prettify: string | boolean = ' ') => {
	const currency: CurrencyType = 'rub';

	return useMemo(() => {
		const result = !Array.isArray(price) && price && price[currency]?.value;
		if (result) {
			return typeof prettify === 'string'
				? dischargeNumber(Number(result), prettify)
				: Number(result);
		}
		return result || null;
	}, [price, prettify]);
};
