import css from './CollectionGallery.module.scss';
import classnames from 'classnames';
import { BlockCollectionGalleryContent } from '@api/mock/types';
import React, { FC, HTMLAttributes, useCallback, useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import { nanoid } from 'nanoid';
import { Container, Section } from '@shared';
import { useScrollSeek } from '@hooks';
import { ImageType } from '@model/imageType';
import { normalize } from '@utils';
import { useViewport } from '@context';

interface CollectionGalleryProps
	extends HTMLAttributes<HTMLElement>,
		BlockCollectionGalleryContent {}

export const CollectionGallery: FC<CollectionGalleryProps> = React.memo(({ items = [] }) => {
	const uid = useMemo(nanoid, []);
	const grid = useMemo(() => (items.length <= 3 ? 'grid-sm' : 'grid-lg'), [items]);

	return (
		<Section className={css.component}>
			<Container className={classnames(css.feed, css[grid])}>
				{items?.map((props, i) => (
					<CollectionGalleryItem key={`gallery-${uid}-${i}`} {...props} />
				))}
			</Container>
		</Section>
	);
});

export const CollectionGalleryItem: FC<ImageType> = React.memo(({ url, alt }) => {
	const [ref, setRef] = useState<HTMLElement | null>(null);
	const { vh } = useViewport();

	useScrollSeek(
		ref,
		useCallback(
			({ bounding }) => {
				const seek = Math.min(vh, Math.max(bounding.top, 0)) / vh;
				const norm = normalize(seek, 0.95, 0.25, true);

				ref?.style.setProperty('--shift', norm.toString());
			},
			[ref, vh]
		),
		{
			checkInView: true,
			resist: 0.075,
		}
	);

	return (
		<picture ref={setRef} className={css.pic}>
			{url && (
				<Image
					src={url.replace(/\s/gm, '')}
					alt={alt}
					layout="fill"
					objectFit="cover"
					sizes="(max-width: 1023px) 100vw, 38vw"
					quality={80}
				/>
			)}
		</picture>
	);
});
