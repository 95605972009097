import css from './TopBlock.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { TypographyTagnameType, TypographyTagType, Section, Heading, Typography } from '@shared';

interface RegularPageProps extends HTMLAttributes<HTMLElement> {
	title?: string;
	titleAs?: TypographyTagnameType;
	titleTag?: TypographyTagType;
	titleAlign?: 'left' | 'right' | 'center';
	headingContent?: string | JSX.Element;
	classNameContent?: string;
	inContainer?: boolean;
}

export const TopBlock: FC<RegularPageProps> = React.memo(
	({
		title,
		titleAs = 'h1',
		titleTag = 'h2',
		titleAlign = 'center',
		inContainer = true,
		headingContent,
		className,
		classNameContent,
		children,
	}) => {
		return (
			<Section className={classnames(className, css.component)}>
				{title && (
					<Heading
						as={titleAs}
						tag={titleTag}
						title={title}
						inContainer={inContainer}
						className={classnames(css.heading, css[titleAlign])}
						classNameTitle={css.title}>
						{headingContent && (
							<Typography
								className={classnames(
									classNameContent,
									css.content,
									{ [css.offset]: titleTag !== 'h1' },
									'wysiwyg'
								)}>
								{headingContent}
							</Typography>
						)}
					</Heading>
				)}
				{children}
			</Section>
		);
	}
);
