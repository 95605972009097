import React, { FC, HTMLAttributes, useEffect, useState } from 'react';

interface CountdownProps extends HTMLAttributes<HTMLElement> {
	start?: number;
	onComplete?: () => void;
}

export const Countdown: FC<CountdownProps> = ({ start = 0, onComplete }) => {
	const [count, setCount] = useState<number>(start);

	useEffect(() => {
		if (start > 0) setCount(start);
	}, [start]);

	useEffect(() => {
		const decrease = (count: number) => {
			setTimeout(() => {
				const next = Math.max(0, count - 1);
				setCount(next);
				if (count > 1) {
					decrease(next);
				} else {
					onComplete && onComplete();
				}
			}, 1000);
		};

		if (count > 0) {
			decrease(count);
		}
	}, [count, onComplete]);

	return <span>{count}</span>;
};
