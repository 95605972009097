import { load } from '@2gis/mapgl';
import { useEffect, useState } from 'react';

export type MapGLApi = typeof mapgl;

const loadMapGLWrapper = () => {
	let mapgl: MapGLApi | null = null;
	let promise: Promise<MapGLApi> | null = null;

	return async (): Promise<MapGLApi> => {
		if (mapgl) {
			return mapgl;
		}

		if (!promise) {
			promise = new Promise((resolve, reject) => void load().then(resolve).catch(reject));
		}

		mapgl = await promise;

		return mapgl;
	};
};

const loadMapGL = loadMapGLWrapper();

export const use2GisApi = () => {
	const [module, setModule] = useState<MapGLApi | null>(null);

	useEffect(() => {
		loadMapGL()
			.then((mapglAPI) => {
				setModule(mapglAPI);
			})
			.catch(() => {
				console.error('Cannot load mapgl API');
			});
	}, []);

	return module;
};
