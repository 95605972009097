import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { nextQueryToString } from '@utils';

export const useFilterSearch = () => {
	const { query } = useRouter();
	return useMemo(() => nextQueryToString(query), [query]);
};

export const useFilterQuery = (keys?: Set<string>) => {
	const router = useRouter();

	const hasKeysInQuery = useMemo(() => {
		if (!keys) return false;
		return Object.keys(router.query).some((key) => Array.from(keys).includes(key));
	}, [keys, router.query]);

	const query = useMemo(() => nextQueryToString(router.query), [router.query]);

	return {
		query,
		hasKeysInQuery,
	};
};
