export const componentToHex = (c: string | number): string => {
	const hex = c.toString(16);
	return hex.length === 1 ? '0' + hex : hex;
};

export const rgbToHex = (r: string | number, g: string | number, b: string | number): string =>
	'#' + componentToHex(r) + componentToHex(g) + componentToHex(b);

export const hexToRgb = (hex: string): number[] | null => {
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result
		? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
		: null;
};

export const hexToRgba = (hex: string, alpha: number): string | null => {
	const rgb = hexToRgb(hex);
	return rgb ? `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})` : null;
};

export const lerpHexColor = (a: number, b: number, ratio: number) => {
	const ar = a >> 16;
	const ag = (a >> 8) & 0xff;
	const ab = a & 0xff;

	const br = b >> 16;
	const bg = (b >> 8) & 0xff;
	const bb = b & 0xff;

	const rr = Math.round(ar + ratio * (br - ar));
	const rg = Math.round(ag + ratio * (bg - ag));
	const rb = Math.round(ab + ratio * (bb - ab));

	return rgbToHex(rr, rg, rb);
};
