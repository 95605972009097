import css from './Header.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	HTMLAttributes,
	MouseEventHandler,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Container } from '@shared';
import { Logo, Tools, Navigation, Search } from '@layout/components/Header/components';
import { useHeader, HeaderContextProvider } from '@layout/components/Header/context/Header.context';
import {
	useDelayUnmount,
	useHomePage,
	useIsomorphicLayoutEffect,
	useOutsideClick,
	useScroll,
} from '@hooks';
import { usePageTransition } from '@layout';
import { useViewport } from '@context';
import { useRouter } from 'next/router';
import scrollLocker from '@utils/utils.scroll/ScrollLocker';
// import { useSectionsObserver } from './hooks/useSectionsObserver';

export interface HeaderProps extends HTMLAttributes<HTMLElement> {
	sectionCode?: string;
}

export const Header: FC<HeaderProps> = React.memo(({ sectionCode, children, ...rest }) => {
	return (
		<HeaderContextProvider sectionCode={sectionCode}>
			<HeaderComponent {...rest} />
			{children}
		</HeaderContextProvider>
	);
});

export const HeaderComponent: FC<HeaderProps> = React.memo(() => {
	const { vh, isTablet } = useViewport();
	const { asPath } = useRouter();
	const route = asPath.split('?')[0];

	const pageTransition = usePageTransition();
	const {
		nav,
		search,
		color,
		shown,
		setShown,
		shownForced,
		allowColorAnimation,
		colorAnimation: hasIntroCover,
	} = useHeader();
	const [mouseover, setMouseover] = useState(false);

	const active = mouseover || search.state || nav.state;
	const transition = useDelayUnmount(active, 600);
	const isHomePage = useHomePage();
	const isDesktopHomePage = isHomePage && !isTablet;
	const isBasketOrderPage = route.includes('/basket/order') && !isTablet;

	const mouseEnterHandler: MouseEventHandler = () => {
		setMouseover(true);
	};
	const mouseLeaveHandler: MouseEventHandler = () => {
		setMouseover(false);
	};

	const outsideClickHandler = () => {
		if (search?.setState) search.setState(false);
	};

	const [header, setRef] = useState<HTMLElement | null>(null);
	useOutsideClick(header, outsideClickHandler);

	useEffect(() => {
		if (header && allowColorAnimation) {
			header.style.color = color && !active ? color : '';
			header.style.transition =
				color && !active ? (!transition ? 'opacity var(--trans), transform var(--trans)' : '') : '';
		}
	}, [header, color, active, transition, allowColorAnimation]);

	useScroll(
		useCallback(
			({ top, dir }) => {
				const { state: scrollLocked } = scrollLocker.getData();
				if (
					!setShown ||
					isDesktopHomePage ||
					isBasketOrderPage ||
					(scrollLocked && pageTransition === 'waiting')
				)
					return;
				switch (dir) {
					case 'up':
						setShown(true);
						break;
					case 'down':
						if (allowColorAnimation) return;
						setShown(top <= (isTablet && isHomePage ? vh * 2 : vh / 2));
						break;
				}
			},
			[
				vh,
				pageTransition,
				isTablet,
				isHomePage,
				isDesktopHomePage,
				isBasketOrderPage,
				allowColorAnimation,
				setShown,
			]
		)
	);

	// Обcервер секций для переключения цвета шапки
	// useSectionsObserver(header);

	return (
		<header
			ref={setRef}
			className={classnames(css.header, 'page__header', {
				[css.hover]: active,
				[css.transition]: transition,
				[css.isInner]: isHomePage ? false : !hasIntroCover || !color,
				[css.isHidden]: shownForced ? false : !shown,
				'is-hidden': shownForced ? false : !shown,
			})}
			onMouseEnter={mouseEnterHandler}
			onMouseLeave={mouseLeaveHandler}>
			<Container className={css.container}>
				<Navigation className={css.containerSide} />
				<Logo />
				<Tools className={classnames(css.containerSide, {
					[css.invisiblecontainerSide]: search.state
				})} />
			</Container>
			<Search />
		</header>
	);
});
