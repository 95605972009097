import css from './CheckoutOutposts.module.scss';
import classnames from 'classnames';
import type { DeliveryOutpostsList, OutpostPoint } from '@api/mock/types';
import type { FC, HTMLAttributes, MouseEventHandler, ChangeEventHandler } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Scroller,
	Icon,
	CloseBtn,
	Portal,
	Typography,
	Container,
	Map2Gis,
	Map2GisContextProvider,
	Outpost,
	OutpostPreview,
	MapObject,
	useMap2GisContext,
} from '@shared';
import { Input } from '@shared/Form/Input/Input';
import { useDelayUnmount, useLockBodyByDialogs } from '@hooks';
import { useViewport } from '@context';
import { useHeader } from '@layout/components/Header/context/Header.context';
import { useData } from '@api';
import { CheckoutContext } from '@components';
import { useRouter } from 'next/router';
import { throttle } from '@utils';

interface CheckoutOutpostsProps extends HTMLAttributes<HTMLElement> {
	endpoint?: string;
	selectedOutpost?: OutpostPoint;
	setSelectedOutpost?: React.Dispatch<React.SetStateAction<OutpostPoint | undefined>>;
}

export const CheckoutOutposts: FC<CheckoutOutpostsProps> = (props) => {
	return (
		<Map2GisContextProvider>
			<CheckoutOutpostsComponent {...props} />
		</Map2GisContextProvider>
	);
};

export const CheckoutOutpostsComponent: FC<CheckoutOutpostsProps> = ({
	endpoint,
	selectedOutpost,
	setSelectedOutpost,
}) => {
	const { setShownForced } = useHeader();
	const { textSelectDeliveryPoint, textMapReturn, textMapSelectPoint, textMapSearchPlaceholder } =
		useContext(CheckoutContext);

	const { activeObject, setActiveObjectWithFocus } = useMap2GisContext();

	/*
	 * Рендер поп-апа
	 */
	const [active, setActive] = useState(false);
	const shouldRender = useDelayUnmount(active, 800);

	useLockBodyByDialogs(shouldRender);

	useEffect(() => {
		setShownForced && setShownForced(shouldRender);
	}, [setShownForced, shouldRender]);

	/*
	 * Обработчики
	 */
	const toggleBtnHandler: MouseEventHandler = useCallback(() => {
		setActive(true);
		setActiveObjectWithFocus(
			selectedOutpost && {
				id: selectedOutpost.id,
				coordinates: [selectedOutpost.longitude, selectedOutpost.latitude],
			}
		);
	}, [selectedOutpost, setActiveObjectWithFocus]);

	const backBtnClickHandler: MouseEventHandler = useCallback(() => {
		setActive(false);
	}, []);

	const [searchResetBy, setSearchResetBy] = useState(Math.random());

	const resetBtnClickHandler: MouseEventHandler = useCallback(() => {
		setSearchResetBy(Math.random());
	}, []);

	/*
	 * Data fetching
	 */
	const { data, isLoading } = useData<DeliveryOutpostsList>({
		url: endpoint,
	});

	const { items: objects = [], calculated } = data || {};

	const mapObjects: MapObject[] = useMemo(
		() =>
			objects.map(({ id, latitude, longitude }) => ({
				id,
				coordinates: [longitude, latitude],
			})),
		[objects]
	);

	/*
	 * Выбор ПВЗ по кнопке в карточке
	 */
	const handleOupostSelect = useCallback(
		(object: OutpostPoint) => {
			setSelectedOutpost && setSelectedOutpost(object);
			setActive(false);
		},
		[setSelectedOutpost]
	);

	// useEffect(() => {
	// 	if (selectedOutpost && shouldRender) {
	// 		console.log(selectedOutpost);
	// 		// setFocusObject({
	// 		// 	id: selectedOutpost.id,
	// 		// 	coordinates: [selectedOutpost.longitude, selectedOutpost.latitude],
	// 		// });
	// 	}
	// }, [selectedOutpost, setFocusObject, shouldRender]);

	return (
		<>
			<div className={css.toggle}>
				<Button type="button" design="flat" size="md" color="gray" onClick={toggleBtnHandler}>
					{textSelectDeliveryPoint}
				</Button>
			</div>
			<Portal>
				<aside className={classnames(css.component, { [css.isShown]: active })}>
					{shouldRender && <CheckoutOutpostsMap objects={mapObjects} />}
					<Box className={css.content}>
						{shouldRender && (
							<Box role="dialog" aria-modal="true" aria-label={textMapSelectPoint}>
								<CheckoutOutpostsHead
									title={textMapSelectPoint}
									back={textMapReturn}
									onClickBack={backBtnClickHandler}
									onClickReset={resetBtnClickHandler}
								/>
								<CheckoutOutpostsBody>
									{activeObject && (
										<Outpost
											calculated={calculated}
											outpost={objects.find(({ id }) => id === activeObject?.id)}
											onOutpostSelect={handleOupostSelect}
										/>
									)}
									{!activeObject && (
										<CheckoutOutpostsSearch
											objects={objects}
											placeholder={textMapSearchPlaceholder}
											resetBy={searchResetBy}
										/>
									)}
								</CheckoutOutpostsBody>
							</Box>
						)}
					</Box>
				</aside>
				<Container className={css.headerOverlay}>
					<CloseBtn className={css.headerOverlayBtn} onClick={backBtnClickHandler}>
						Вернуться
					</CloseBtn>
				</Container>
			</Portal>
		</>
	);
};

interface CheckoutOutpostsMapProps extends HTMLAttributes<HTMLElement> {
	objects?: MapObject[];
}

export const CheckoutOutpostsMap: FC<CheckoutOutpostsMapProps> = React.memo(({ objects = [] }) => {
	return (
		<Box className={css.map}>
			<Map2Gis objects={objects} />
		</Box>
	);
});

interface CheckoutOutpostsSearchProps extends HTMLAttributes<HTMLElement> {
	objects?: OutpostPoint[];
	name?: string;
	placeholder?: string;
	resetBy?: string | number;
}

export const CheckoutOutpostsSearch: FC<CheckoutOutpostsSearchProps> = React.memo(
	({ objects = [], name = 'search', placeholder = '', resetBy }) => {
		const { isMob } = useViewport();
		const { locale } = useRouter();
		const cancelText = locale === 'en' ? 'Cancel' : 'Отменить';

		/*
		 * Поиск-фильтрация из списка точек
		 */
		const [results, setResults] = useState<OutpostPoint[]>([]);
		const [valueLocal, setValueLocal] = useState('');

		const indexedObjects = useMemo(() => {
			const map: Record<string, OutpostPoint> = {};

			objects.forEach((item) => {
				const { title, fullAddress, metro } = item;
				const index = [title, fullAddress, metro].join(' ');
				map[index] = item;
			});
			return map;
		}, [objects]);

		const filterObjects = useCallback(
			(value: string) => {
				if (value.length < 2) {
					setResults([]);
				}

				throttle(
					() => {
						const result: OutpostPoint[] = [];

						Object.entries(indexedObjects).forEach(([key, object]) => {
							if (key.indexOf(value) >= 0) {
								result.push(object);
							}
						});

						setResults(result);
					},
					200,
					`throttle-outposts-filter`
				);
			},
			[indexedObjects]
		);

		const changeHandler: ChangeEventHandler<HTMLInputElement> = useCallback(
			({ target }) => {
				setValueLocal(target.value);
				filterObjects(target.value);
			},
			[filterObjects]
		);

		const resetHandler: MouseEventHandler = useCallback(() => {
			setValueLocal('');
		}, []);

		useEffect(() => {
			setValueLocal('');
		}, [resetBy]);

		/*
		 * Обработка клика на элементе списка
		 */
		const { setActiveObjectWithFocus } = useMap2GisContext();

		const showOnMap = useCallback(
			({ id, longitude, latitude }: OutpostPoint) => {
				setActiveObjectWithFocus({
					id,
					coordinates: [longitude, latitude],
				} as MapObject);
			},
			[setActiveObjectWithFocus]
		);

		return (
			<Box className={css.search}>
				<Box className={css.searchSticky}>
					<Box className={css.searchInput}>
						<Input
							type="search"
							name={name}
							placeholder={placeholder}
							value={valueLocal}
							onChange={changeHandler}
						/>
						<Button
							type="button"
							className={classnames(css.searchReset, { [css.isShown]: valueLocal.length > 0 })}
							onClick={resetHandler}>
							{isMob ? cancelText : <Icon id="close" />}
						</Button>
						<Icon id="search" />
					</Box>
				</Box>
				<>
					{results.map((item) => {
						const { id, title, fullAddress } = item;
						return (
							<OutpostPreview
								key={id}
								title={title}
								text={fullAddress}
								onClick={() => {
									showOnMap(item);
								}}
							/>
						);
					})}
				</>
			</Box>
		);
	}
);

interface CheckoutOutpostsHeadProps {
	title?: string;
	back?: string;
	resetActive?: boolean;
	onClickBack?: MouseEventHandler | (() => void);
	onClickReset?: MouseEventHandler | (() => void);
}

export const CheckoutOutpostsHead: FC<CheckoutOutpostsHeadProps> = React.memo(
	({
		title = 'Выбор пункта выдачи',
		back = 'Вернуться',
		resetActive = false,
		onClickBack,
		onClickReset,
	}) => {
		return (
			<Box className={css.heading}>
				<CloseBtn className={css.backBtn} onClick={onClickBack}>
					{back}
				</CloseBtn>
				<Button
					type="button"
					className={classnames(css.resetBtn, { [css.isShown]: resetActive })}
					onClick={onClickReset}>
					<Icon id="close" />
				</Button>
				<Typography className={css.title} type="h5">
					{title}
				</Typography>
			</Box>
		);
	}
);

export const CheckoutOutpostsBody: FC<HTMLAttributes<HTMLDivElement>> = ({ children }) => {
	return (
		<Scroller className={css.body} options={{ wheelPropagation: false }}>
			{children}
		</Scroller>
	);
};
