import css from './PolaroidCard.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

interface PolaroidCardProps extends HTMLAttributes<HTMLElement> {
	mode?: 'responsive' | 'fit';
	size?: 'sm' | 'lg';
}

export const PolaroidCard: FC<PropsWithChildren<PolaroidCardProps>> = ({
	mode = 'responsive',
	size = 'lg',
	className,
	children,
}) => {
	return (
		<picture className={classnames(className, css.component, css[mode], css[size])}>
			<div className={css.inner}>{children}</div>
		</picture>
	);
};
