import css from '../CartElement.module.scss';
import classnames from 'classnames';
import React, { FC } from 'react';
import { CartElemmentComponentProps, Currency, Typography } from '@shared';
import { dischargeNumber } from '@utils';
import { usePrice } from '@hooks';

interface CartElementSumProps extends CartElemmentComponentProps {
	amount?: number;
}

export const CartElementSum: FC<CartElementSumProps> = React.memo(
	({ offer, amount = 1, className }) => {
		const price = usePrice(offer?.price, false);
		const sum = typeof price === 'number' ? price * amount : null;

		if (!sum) return null;

		return (
			<Typography className={classnames(className, css.sum)} size="md">
				{dischargeNumber(sum)} <Currency />
			</Typography>
		);
	}
);
