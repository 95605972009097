import classnames from 'classnames';
import React, {
	FC,
	PropsWithChildren,
	MouseEvent,
	ReactNode,
	HTMLAttributes,
	useMemo,
	useCallback,
	MouseEventHandler,
} from 'react';
import Link from 'next/link';
import { useDelayUnmount } from '@hooks';
import { FetchSpinner, TypographyTagnameType } from '@src/shared';

export type ButtonSizeType = 'sm' | 'md' | 'lg';
export type ButtonDesignType = 'filled' | 'stroked' | 'flat';
export type ButtonColorType = 'white' | 'dark' | 'gray' | 'inherit';

interface ButtonIconInterface {
	position: 'left' | 'right';
}

interface ButtonInterface extends HTMLAttributes<HTMLElement> {
	size?: ButtonSizeType;
	design?: ButtonDesignType;
	color?: ButtonColorType;
	wide?: boolean;
	type?: 'button' | 'submit';
	className?: string;
	to?: string;
	href?: string;
	target?: '_blank';
	onClick?: MouseEventHandler | ((e: MouseEvent<HTMLElement>) => void);
	iconLeft?: ReactNode;
	iconRight?: ReactNode;
	isLoading?: boolean;
	disabled?: boolean;
	as?: TypographyTagnameType;
}

interface ButtonContentInterface
	extends Pick<ButtonInterface, 'iconLeft' | 'iconRight' | 'isLoading'> {}

const ButtonIcon: FC<PropsWithChildren<ButtonIconInterface>> = ({ position, children }) => {
	return <div className={classnames(`btn__icon`, `btn__icon--${position}`)}>{children}</div>;
};

const ButtonContent: FC<PropsWithChildren<ButtonContentInterface>> = ({
	iconLeft,
	iconRight,
	children,
}) => {
	return (
		<>
			{iconLeft && <ButtonIcon position="left">{iconLeft}</ButtonIcon>}
			<span>{children}</span>
			{iconRight && <ButtonIcon position="right">{iconRight}</ButtonIcon>}
		</>
	);
};

export const Button: FC<PropsWithChildren<ButtonInterface>> = ({
	size = 'md',
	design = 'flat',
	color = 'dark',
	type = 'button',
	as: Tag,
	wide,
	className,
	to,
	href,
	target,
	onClick,
	iconLeft,
	iconRight,
	isLoading,
	disabled,
	children,
	...props
}) => {
	const content = useMemo(
		() => (
			<ButtonContent iconLeft={iconLeft} iconRight={iconRight} isLoading={isLoading}>
				{children}
			</ButtonContent>
		),
		[iconLeft, iconRight, isLoading, children]
	);
	const classname = useMemo(() => {
		return classnames(
			`btn`,
			`btn--${size}`,
			`btn--${design}`,
			`btn--${color}`,
			`btn--${wide ? `wide` : `inline`}`,
			{
				'is-loading': isLoading,
				'is-disabled': disabled,
			},
			className
		);
	}, [className, size, design, color, wide, isLoading, disabled]);

	const clickHandler: MouseEventHandler<HTMLElement> = useCallback(
		(e) => {
			onClick && onClick(e);
		},
		[onClick]
	);

	const shouldRenderSpinner = useDelayUnmount(!!isLoading, 600);

	if (to) {
		return (
			<Link href={to}>
				<a className={classname} onClickCapture={clickHandler} {...props}>
					{content}
				</a>
			</Link>
		);
	}

	if (href) {
		return (
			<a
				className={classname}
				onClick={clickHandler}
				href={href}
				target={target}
				rel="noreferrer noopener"
				{...props}>
				{content}
			</a>
		);
	}

	if (Tag) {
		return (
			<Tag className={classname} onClick={clickHandler} {...props}>
				{content}
			</Tag>
		);
	}

	return (
		<button type={type} onClick={clickHandler} className={classname} disabled={disabled} {...props}>
			{content}
			{shouldRenderSpinner && <FetchSpinner className="spinner" />}
		</button>
	);
};
