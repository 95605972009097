import css from './FeaturesStackMobileCard.module.scss';
import { Section, Typography } from '@src/shared';
import Image from 'next/image';
import { FC, memo, useEffect, useState } from 'react';
import { FeaturesStackContentCard } from '@components';

interface FeaturesStackMobileCardProps extends FeaturesStackContentCard {
	index: number;
	seek: number;
}

export const FeaturesStackMobileCard: FC<FeaturesStackMobileCardProps> = memo(
	({ heading, text, smallImage, index, seek = 1 }) => {
		const [section, setSection] = useState<HTMLElement | null>(null);
		useEffect(() => {
			if (section) {
				section.style.setProperty('--seek-open', seek.toString());
			}
		}, [seek, section]);

		return (
			<Section ref={setSection} className={css.sticky} style={{ zIndex: index }}>
				<div className={css.content}>
					<Typography className={css.heading} type="h2">
						{heading}
					</Typography>
					<Typography className={css.text} size="sm" raw>
						{text}
					</Typography>
					<div className={css.polaroidWrap}>
						<div className={css.polaroidImage}>
							{smallImage && <Image src={smallImage} alt="" layout="fill" objectFit="cover" />}
						</div>
					</div>
					<div className={css.figure}></div>
				</div>
			</Section>
		);
	}
);
