import css from '../../Cart.module.scss';
import { CartProps } from '@components';
import { BlockBasketProductsContentBasketPrice } from '@model/blockBasketProductsContentBasketPrice';
import { BlockBasketProductsContent } from '@model/blockBasketProductsContent';
import React, { FC } from 'react';
import { Heading, Typography, Brackets, CartElement, CartTable, Box, Button } from '@shared';
import { CartSummary } from '@components/Cart/components/CartSummary/CartSummary';
import { useBxComponents } from '@context';

interface CartContentProps extends CartProps {
	items?: BlockBasketProductsContent['products'];
	summary?: BlockBasketProductsContentBasketPrice;
}

export const CartContent: FC<CartContentProps> = ({ items = [], summary }) => {
	const { cart: cartTexts } = useBxComponents();
	const { textBasket, toOrder } = cartTexts;
	
	return (
		<>
			<Heading className={css.heading} inContainer={true}>
				<Typography as="h2" type="h2">
					{textBasket}
					{items.length && <Brackets className={css.counter}>{items.length}</Brackets>}
				</Typography>
			</Heading>

			<CartTable>
				{items.map((item, index) => (
					<CartElement withBorderTop={index > 0} key={`cart-item-${item.deleteProductUrl}`} view="full" {...item} />
				))}
			</CartTable>

			<CartSummary summary={summary} />

			<Box className={css.footer}>
				{/*
				{toOrderPrice && (
					<Button to={toOrderPrice.url} design="stroked">
						{toOrderPrice.name}
					</Button>
				)}
				*/}
				{toOrder && (
					<Button to={toOrder.url} design="stroked">
						{toOrder.name}
					</Button>
				)}
			</Box>
		</>
	);
};
