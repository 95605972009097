import css from './ViewSlider.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useState } from 'react';
import { Slider } from '@shared';
import { CatalogViewType, useCatalog } from '@components/Catalog/context/Catalog.context';

interface ViewSliderProps extends HTMLAttributes<HTMLElement> {
	isOpen?: boolean;
}

export const ViewSlider: FC<ViewSliderProps> = ({ isOpen }) => {
	const points: CatalogViewType[] = ['sm', 'md', 'lg'];

	const { setView } = useCatalog();
	const [value, setValue] = useState<CatalogViewType>('md');

	const changeViewHandler = (value: CatalogViewType) => {
		setValue(value);
		setView && setView(value);
	};

	return (
		<div className={classnames(css.component, { [css.isOpen]: isOpen })}>
			<Slider
				axis="y"
				value={value}
				points={points}
				onChange={changeViewHandler}
				isHidden={isOpen}
				className={css.slider}
			/>
			<Slider
				axis="x"
				value={value}
				points={points}
				onChange={changeViewHandler}
				isHidden={!isOpen}
				className={css.slider}
			/>
		</div>
	);
};
