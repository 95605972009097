import css from './LoadingIndicator.module.scss';
import classnames from 'classnames';
import React, { FC } from 'react';
import { Icon } from '@shared';
import { usePageLoadState } from '@context';

export const LoadingIndicator: FC = React.memo(() => {
	const { loading } = usePageLoadState();
	return (
		<div className={classnames(css.loader, { [css.isActive]: loading })}>
			<Icon id="emblem-main" />
		</div>
	);
});
