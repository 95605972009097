import css from './BrandIntro.module.scss';
import classnames from 'classnames';
import { BrandIntroText } from '@src/api/mock/types';
import React, { FC, HTMLAttributes } from 'react';
import { Section, Container, Typography, Brackets } from '@shared';

interface BrandIntroProps extends HTMLAttributes<HTMLElement>, BrandIntroText {}

export const BrandIntro: FC<BrandIntroProps> = React.memo(({ textChunks }) => {
	return (
		<Section className={classnames(css.component)}>
			<Container>
				<Typography className={css.textBlock} as="h1" type="h1">
					<div className={css.firstLine}>
						<Typography as="span" raw={true} className={css.textPre}>
							{textChunks[0]}
						</Typography>
						<div>
							<span className={css.textLine}>{textChunks[1]}</span>
							<Typography className={classnames(css.textBrackets)} as="span">
								&nbsp;<Brackets>{textChunks[2]}</Brackets>&nbsp;
							</Typography>
						</div>
					</div>
					<div className={css.line}>
						<span className={css.textLine}>{textChunks[3]}</span>
					</div>
					<div className={css.line}>
						<Typography className={css.textBrackets} as="span">
							<Brackets>{textChunks[4]}</Brackets>&nbsp;
						</Typography>
						<span className={css.textLine}>{textChunks[5]}</span>
					</div>
					<div className={css.line}>
						<span className={css.textLine}>{textChunks[6]}</span>
					</div>
				</Typography>
			</Container>
		</Section>
	);
});
