import { PageData } from '@api/hooks/types';

export const home: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Agapium',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-intro-cover',
			content: {},
		},
		{
			type: 'block-catalog-sections-stack',
			content: {},
		},
		{
			type: 'block-catalog-collection',
			content: {},
		},
		{
			type: 'block-polaroid-gallery',
			content: {},
		},
	],
};
