import { BlockCatalogAllProductsContent } from '@model/blockCatalogAllProductsContent';

export const wishlistData: BlockCatalogAllProductsContent = {
	/*
	 * По аналогии с каталогом:
	 * блок навгиации для пагинации и ревалидации данных
	 */
	navigation: {
		next: '/ru/account/wishlist?pageNumber=1&limit=10',
		self: '/ru/account/wishlist',
		countPerPage: 2,
		total: 2,
	},
	products: [
		{
			id: 5,
			name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
			code: 'plate-obich',
			detailPageUrl: '/ru/catalog/product/plate-obich',
			color: '#000',
			colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
			images: [
				{
					url: '/temp/pic-04.jpg',
					alt: '',
				},
				{
					url: '/temp/pic-04.jpg',
					alt: '',
				},
			],
			offers: [
				{
					id: 9,
					size: 'L',
					article: '4234612345',
					isAvailable: true,
					price: {
						rub: {
							value: '1967.00',
						},
					},
				},
				{
					id: 10,
					size: 'XS',
					article: '4234612346',
					isAvailable: true,
					price: {
						rub: {
							value: '10000.00',
						},
					},
				},
				{
					id: 11,
					size: 'M',
					article: '4234612348',
					isAvailable: true,
					price: {
						rub: {
							value: '24000.00',
						},
					},
				},
			],
		},
		{
			id: 8,
			name: '\u041d\u043e\u0432\u043e\u0435 \u043f\u043b\u0430\u0442\u044c\u0435 \u043a\u0430\u043c\u0435\u0440\u043e\u043d',
			code: 'plate-test',
			detailPageUrl: '/ru/catalog/product/plate-test',
			color: '#000',
			colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
			images: [
				{
					url: '/temp/pic-04.jpg',
					alt: '',
				},
				{
					url: '/temp/pic-04.jpg',
					alt: '',
				},
			],
			offers: [
				{
					id: 9,
					size: 'L',
					article: '4234612345',
					isAvailable: true,
					price: {
						rub: {
							value: '1967.00',
						},
					},
				},
				{
					id: 10,
					size: 'XS',
					article: '4234612346',
					isAvailable: true,
					price: {
						rub: {
							value: '10000.00',
						},
					},
				},
				{
					id: 11,
					size: 'M',
					article: '4234612348',
					isAvailable: true,
					price: {
						rub: {
							value: '24000.00',
						},
					},
				},
			],
		},
		{
			id: 12,
			name: '\u041f\u043b\u0430\u0442\u044c\u0435-\u0445\u043e\u043c\u0443\u0442',
			code: 'plate-khomut',
			detailPageUrl: '/ru/catalog/product/plate-khomut',
			color: '#000',
			colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
			images: [
				{
					url: '/temp/pic-04.jpg',
					alt: '',
				},
				{
					url: '/temp/pic-04.jpg',
					alt: '',
				},
			],
			offers: [
				{
					id: 9,
					size: 'L',
					article: '4234612345',
					isAvailable: true,
					price: {
						rub: {
							value: '1967.00',
						},
					},
				},
				{
					id: 10,
					size: 'XS',
					article: '4234612346',
					isAvailable: true,
					price: {
						rub: {
							value: '10000.00',
						},
					},
				},
				{
					id: 11,
					size: 'M',
					article: '4234612348',
					isAvailable: true,
					price: {
						rub: {
							value: '24000.00',
						},
					},
				},
			],
		},
	],
};
