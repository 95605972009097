import css from './CheckoutForm.module.scss';
import classnames from 'classnames';
import { CheckoutContext, CheckoutProps } from '@components';
import { BlockBasketOrderDeliverySet, FormElementType } from '@api/mock/types';
import React, { FC, FormEvent, useCallback, useContext } from 'react';
import { FormFieldset } from '@shared/Form/FormFieldset/FormFieldset';
import { CheckoutOutposts } from '@components/Checkout/components/CheckoutOutposts/CheckoutOutposts';
import { FormComponent, FormSumbitHandlerType } from '@shared/Form/FormComponent/FormComponent';
import { FormChild } from '@shared/Form/FormComponent/components/FormChild/FormChild';
import { CheckoutDelivery } from '@components/Checkout/components/CheckoutDelivery/CheckoutDelivery';

interface CheckoutFormProps extends CheckoutProps {
	onSubmit?: FormSumbitHandlerType;
	formRef?: React.MutableRefObject<HTMLFormElement | null>;
	formSubmitRef?: React.MutableRefObject<HTMLButtonElement | null>;
}

export const CheckoutForm: FC<CheckoutFormProps> = ({
	orderForm,
	formRef,
	formSubmitRef,
	onSubmit = () => null,
}) => {
	const { items: formItems, action, method } = orderForm;
	const { setPaymentOnline } = useContext(CheckoutContext);

	const handleChangeInput = useCallback(
		(e: FormEvent) => {
			const target = e.target as HTMLInputElement;
			if (target.name === 'paymentMethod') {
				const newState = !target.value || target.value === 'card';
				setPaymentOnline && setPaymentOnline(newState);
			}
		},
		[setPaymentOnline]
	);

	return (
		<FormComponent
			ref={formRef}
			action={action}
			method={method}
			forceSubmitOnEnter={formSubmitRef || true}
			onSubmit={onSubmit}
			onChange={handleChangeInput}
			className={css.component}>
			{formItems?.map((item, i) => {
				switch (item?.type) {
					case 'fieldset':
						const { legend, items = [], citySelectorUrl } = item as BlockBasketOrderDeliverySet;

						if (citySelectorUrl) {
							return <CheckoutDelivery key={`order-form-group-${i}`} {...item} />;
						} else {
							return (
								<FormFieldset key={`order-form-group-${i}`} 
								style={{display: legend === "Способ оплаты" ? "none" : ''}}
								 legend={legend}>
									{items.map((item: FormElementType, j) => {
										if (!item.name) return null;

										const key = `order-form-child-${i}-${j}`;

										switch (item.type) {
											default:
												return <FormChild key={key} {...(item as FormFieldType)} />;
										}
									})}
								</FormFieldset>
							);
						}
				}
			})}
		</FormComponent>
	);
};
