import '@src/assets/styles/app.scss';
import type { AppProps } from 'next/app';
import { LayoutDefault as Layout } from '@layout';

function App({ Component, pageProps }: AppProps) {
	return (
		<Layout {...pageProps}>
			<Component {...pageProps} />
		</Layout>
	);
}

export default App;
