import css from './Status.module.scss';
import { OrderStatus } from '@api/mock/types';
import React, { FC, HTMLAttributes } from 'react';
import { Icon } from '@shared';

interface StatusProps extends HTMLAttributes<HTMLElement>, OrderStatus {}

export const Status: FC<StatusProps> = ({ text }) => {
	if (!text) return null;
	return (
		<span className={css.component}>
			{text}
			<Icon id="bullet" />
		</span>
	);
};
