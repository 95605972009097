import css from './CollectionProducts.module.scss';
import classnames from 'classnames';
import { BlockCollectionProductsContent } from '@api/mock/types';
import React, { FC, HTMLAttributes } from 'react';
import { Button, Section } from '@shared';
import { RelatedProducts } from '@src/components';

interface CollectionProductsProps
	extends HTMLAttributes<HTMLElement>,
		BlockCollectionProductsContent {}

export const CollectionProducts: FC<CollectionProductsProps> = React.memo(
	({ toAllProducts, textPrev, textNext, products = [] }) => {
		if (!products) return null;

		return (
			<Section className={classnames(css.component)}>
				<RelatedProducts
					perPage={[2, 3, 4]}
					products={products}
					textSize="sm"
					textPrev={textPrev}
					textNext={textNext}
				/>
				{toAllProducts && toAllProducts?.url && (
					<Button className={css.toAllProducts} to={toAllProducts.url} design="stroked" wide={true}>
						{toAllProducts.name}
					</Button>
				)}
			</Section>
		);
	}
);
