import { normalize } from '@src/utils';

const throttlesMap = new Map();

export const throttle = (handler: any, duration: number = 100, id: string = 'general'): void => {
	if (typeof throttlesMap.get(id) !== 'undefined') clearTimeout(throttlesMap.get(id));

	if (typeof handler === 'function') throttlesMap.set(id, setTimeout(handler, duration));
};

export const checkKeyCode = (e: KeyboardEvent, code: number | string): boolean => {
	let check = false;

	if (e.which !== undefined) {
		check = e.which === code;
	} else if (e.keyCode !== undefined) {
		check = e.keyCode === code;
	} else if (e.key !== undefined) {
		check = e.key === code;
	}

	return check;
};

export const getStages = (seek: number, screens: number) => {
	const stages = [];
	const frag = 1 / screens;

	for (let i = 0; i < screens; i++) {
		stages.push(normalize(seek, frag * (i + 1), frag * i, true));
	}

	return stages;
};
