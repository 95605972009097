import css from './Odometer.module.scss';
import classnames from 'classnames';
import React, { FC, useCallback, useEffect, useRef } from 'react';

interface Props {
	value?: number;
	length?: number;
	delay?: number;
}

const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

const getArray = (length: number): Array<string | null> => {
	const array = [];
	for (let i = 0; i < length; i++) {
		array.push(null);
	}
	return array;
};

export const Odometer: FC<Props> = React.memo(({ value, length = 4, delay = 0 }) => {
	length = value && value.toString().length > length ? value.toString().length : length;

	const array = getArray(length);

	if (typeof value !== 'undefined') {
		const shift = length - value.toString().length;
		value
			.toString()
			.split('')
			.forEach((d, i) => {
				array[i + shift] = d;
			});
	}

	const ref = useRef<HTMLDivElement | null>(null);
	const timer = useRef(0);
	const started = useRef(false);

	useEffect(() => {
		if (ref.current) {
			const updateDigits = () => {
				ref.current?.querySelectorAll<HTMLElement>(`.${css.rollTrack}`).forEach((el, i) => {
					const current = value ? (array[i] === null ? 0 : Number(array[i]) + 1) : 0;
					el.style.transform = `translate3d(0, ${-9.090909090909091 * current}%, 0)`;
				});
			};

			if (!started.current) {
				started.current = true;
				updateDigits();
			}

			window.clearTimeout(timer.current);
			timer.current = window.setTimeout(updateDigits, delay);

			return () => {
				window.clearTimeout(timer.current);
			};
		}
	}, [array, value, delay]);

	return <OdometerDigits ref={ref} length={length} />;
});

export const OdometerDigits = React.memo(
	React.forwardRef<HTMLDivElement, { length: number }>(({ length }, ref) => {
		const zeros = getArray(length);

		return (
			<div className={css.module} ref={ref}>
				{zeros.map((_, i) => {
					return <OdometerRoll key={`digit-${i}`} />;
				})}
			</div>
		);
	})
);

export const OdometerRoll = React.memo(
	React.forwardRef<HTMLDivElement, any>(({}, ref) => {
		return (
			<div className={css.roll}>
				<div ref={ref} className={css.rollTrack}>
					<div className={css.digit}>&nbsp;</div>
					{digits.map((d) => {
						return (
							<div key={`digit-${Math.random()}`} className={classnames(css.digit)}>
								{d}
							</div>
						);
					})}
				</div>
			</div>
		);
	})
);
