import css from './Container.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';

interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
	as?: ContainerTagnameType;
	isWide?: boolean;
	isFull?: boolean;
}

export type ContainerTagnameType = keyof Omit<JSX.IntrinsicElements, keyof SVGElementTagNameMap>;

export const Container: FC<PropsWithChildren<ContainerProps>> = React.memo(
	({ as: Tag = 'div', isWide, isFull, className, children }) => {
		return (
			<Tag
				className={classnames(
					css.container,
					className,
					isWide && css.containerWide,
					isFull && css.containerFull
				)}>
				{children}
			</Tag>
		);
	}
);
