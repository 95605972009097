import css from './Typography.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	HTMLAttributes,
	MouseEvent,
	PropsWithChildren,
	useCallback,
	useMemo,
} from 'react';
import { useRouter } from 'next/router';
import { FileLink, FileLinkList, TextBox, TextSpoiler } from '@shared/Typography/components';

export type TypographyTagType =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'p'
	| 'ul'
	| 'ol'
	| 'li'
	| 'blockquote';
export type TypographySizeType = 'xs' | 'sm' | 'md' | 'lg' | 'inherit';
export type TypographyColorType = 'dark' | 'gray' | 'white' | 'accent' | 'inherit';
export type TypographyWeightType = 'light' | 'regular';
export type TypographyFontVariantType = 'text' | 'head';

export type TypographyTagnameType = keyof Omit<JSX.IntrinsicElements, keyof SVGElementTagNameMap>;

interface TypographyProps extends HTMLAttributes<HTMLElement> {
	as?: TypographyTagnameType;
	size?: TypographySizeType;
	type?: TypographyTagType;
	color?: TypographyColorType;
	weight?: TypographyWeightType;
	font?: TypographyFontVariantType;
	raw?: boolean;
	clear?: boolean;
	content?: string;
}

export const Typography: FC<PropsWithChildren<TypographyProps>> = React.memo(
	({
		as: Tag = 'div',
		size = 'sm',
		color,
		weight = 'light',
		font = 'text',
		raw = false,
		clear = false,
		type,
		onClick,
		className,
		content,
		children,
		...rest
	}) => {
		const html = useMemo(() => {
			const child = content || children;
			if (raw && Array.isArray(child)) {
				return child.join('');
			} else {
				return child;
			}
		}, [raw, content, children]);

		const classes = useMemo(() => {
			return clear
				? className
				: classnames(
						type,
						!type &&
							size && {
								[css[size]]: size !== 'inherit',
								[css.inheritSize]: size === 'inherit',
							},
						color && {
							[css[color]]: color && color !== 'inherit',
							[css.inheritColor]: color === 'inherit',
						},
						css[font],
						css[weight],
						className
				  );
		}, [className, clear, color, font, size, type, weight]);

		const router = useRouter();

		const handleClick = useCallback(
			(e: MouseEvent) => {
				const { target } = e;

				if (target instanceof Element) {
					const link = target.closest('a');
					if (!link) return;
					e.preventDefault();

					const { href } = link;
					const { origin } = window.location;

					if (href.includes(origin)) {
						router.push(href.replace(origin, '')).then();
					} else {
						window.open(href, '_blank');
					}
				}
			},
			[router]
		);

		const props = {
			className: classes,
			onClick: onClick || handleClick,
			...rest,
		};

		return raw && html ? (
			<Tag {...props} dangerouslySetInnerHTML={{ __html: html.toString() }} />
		) : (
			<Tag {...props}>{children}</Tag>
		);
	}
);

export interface TypographyFlowProps {
	content?: string | JSX.Element | Array<TextContentNode>;
}

const components: Record<ContentSpecificComponents, any> = {
	spoiler: TextSpoiler,
	textBox: TextBox,
	fileLink: FileLink,
	fileLinkList: FileLinkList,
};

export const TypographyFlow: FC<TypographyFlowProps> = ({ content = [] }) => {
	return (
		<>
			{typeof content === 'string' && (
				<Typography clear={true} raw={true}>
					{content}
				</Typography>
			)}
			{Array.isArray(content) &&
				content?.map((item, i) => {
					const key = `${item.type}-${i}`;

					if (item.type in components && components.hasOwnProperty(item.type)) {
						const Component = components[item.type as ContentSpecificComponents];
						return <Component key={key} {...item} />;
					}

					if (Array.isArray(item.content)) {
						return (
							<Typography key={key} as={item.type as TypographyTagType} clear={true}>
								<TypographyFlow content={item.content} />
							</Typography>
						);
					}

					if (item.type === 'html') {
						return (
							<Typography key={key} clear={true} raw={true}>
								{item.content}
							</Typography>
						);
					}

					return (
						<Typography key={key} as={item.type as TypographyTagType} clear={true} raw={true}>
							{item.content}
						</Typography>
					);
				})}
		</>
	);
};
