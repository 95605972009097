import css from './Contacts.module.scss';
import type { BlockContacts } from '@api/mock/types';
import React, { FC, HTMLAttributes } from 'react';
import { Box, Brackets, Container, TopBlock } from '@shared';
import { LinksGroup } from '@shared/LinksGroup/LinksGroup';
import { ContactsForm } from '@components/Contacts/components/ContactsForm/ContactsForm';

interface ContactsProps extends HTMLAttributes<HTMLElement>, BlockContacts {}

export const Contacts: FC<ContactsProps> = React.memo(
	({ textTitle, textSubtitle, contactLinks, socialLinks, form, formImage }) => {
		return (
			<>
				<TopBlock
					title={textTitle}
					titleAs="h1"
					titleTag="h1"
					titleAlign="center"
					className={css.heading}
					classNameContent={css.headinContent}
					headingContent={<Brackets>{textSubtitle}</Brackets>}
				/>

				<Container className={css.mainContent}>
					<Box className={css.mainLinks}>
						{contactLinks?.map((item, i) => (
							<LinksGroup key={`contact-links-group-${i}`} {...item} />
						))}
					</Box>
					{socialLinks && (
						<Box className={css.socialLinks}>
							<LinksGroup {...socialLinks} />
						</Box>
					)}
				</Container>

				<Box className={css.bubbles} />
				<ContactsForm form={form} formImage={formImage} />
			</>
		);
	}
);
