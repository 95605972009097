import css from '../Header.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { useBxComponents } from '@context';

interface AdditionalLinksProps extends HTMLAttributes<HTMLDivElement> {}

export const SocialLinks: FC<AdditionalLinksProps> = React.memo(() => {
	const { footer = {} } = useBxComponents();
	const { vk, telegram, tikTok } = footer;

	const linksList = [vk, telegram, tikTok].filter((item) => !!item);

	return (
		<div className={classnames(css.socialLinks)}>
			{linksList.map((item) => {
				const link = Array.isArray(item) ? item[0] : item;
				return link ? (
					<a
						key={`footer-social-${link.name}`}
						href={link.url}
						target="_blank"
						rel="noreferrer noopener">
						{link.name}
					</a>
				) : null;
			})}
		</div>
	);
});
