import css from '../../Cart.module.scss';
import { CartProps } from '@components';
import { BlockBasketProductsContentBasketPrice } from '@model/blockBasketProductsContentBasketPrice';
import React, { FC } from 'react';
import { Container, Price, Typography } from '@shared';
import { useBxComponents } from '@context';

interface CartSummaryProps extends CartProps {
	summary?: BlockBasketProductsContentBasketPrice;
}

export const CartSummary: FC<CartSummaryProps> = ({ summary }) => {
	const { cart: cartTexts } = useBxComponents();
	const { textOrderPrice, textDiscount, textTotalPrice } = cartTexts;

	const { sum, discount, total } = summary || {};
	// const hasDiscount = !Array.isArray(discount) && discount;

	return (
		<Container className={css.summary}>
			<Typography className={css.summaryRow} size="md" weight="regular">
				{textOrderPrice}: <Price price={sum} />
			</Typography>
			<Typography className={css.summaryRow} size="md" weight="regular">
				{textDiscount}: <Price price={discount} />
			</Typography>
			<Typography className={css.summaryTotal} size="md" weight="regular">
				{textTotalPrice}: <Price price={total} />
			</Typography>
		</Container>
	);
};
