import { PageData } from '@api/hooks/types';

export const login: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Вход или регистрация | Agapium',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-login',
			content: {
				imageCover: {
					url: '/temp/pic-07.jpg',
					alt: 'Название картинки',
				},
				/*
				 * ${phoneNumber} и ${time} константы, для реплейса в строке
				 */
				loginForm: {
					textTitle: 'Вход или регистрация',
					textPhone: 'Ввведите код, который мы выслали на номер ${phoneNumber}',
					textSubmit: 'Получить код',
					textRepeatBtn: 'Отправить повторно',
					textRepeatTimer: 'Запросить повторный можно будет через: ${time} сек',
					textBackBtn: 'Вернуться назад',
					action: '/mocks/form/login',
					method: 'post',
					repeatTime: 10,
					/*
					 * Намеренно сделал не как типовую форму,
					 * т.к. специфическая логика
					 */
					fields: {
						phone: {
							type: 'tel',
							name: 'phone',
							required: true,
							mask: '+7 (000) 000-00-00',
							pattern: '^(\\+7)\\s\\(\\d{3}\\)\\s\\d{3}-\\d{2}-\\d{2}$',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный номер телефона',
							},
						},
						smsCode: {
							type: 'tel',
							name: 'code',
							label: 'Код из СМС',
							required: true,
							mask: '0000',
							pattern: '^d{4}$',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный формат',
							},
						},
					},
				},
				thirdPartyAuth: {},
			},
		},
	],
};
