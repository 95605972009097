import type { Map } from '@2gis/mapgl/types';
import type { MapObject } from '@shared/Map2Gis/Map2Gis';
import type { Clusterer } from '@2gis/mapgl-clusterer';
import React, {
	FC,
	PropsWithChildren,
	useState,
	createContext,
	useContext,
	useMemo,
	useCallback,
} from 'react';
import { MapGLApi, use2GisApi } from '@shared/Map2Gis/hooks/use2GisApi';
import { nanoid } from 'nanoid';

export type MapInstance = Map | undefined;
export type ClustererInstance = Clusterer | undefined;
export type PropsWithMapInstance<T = unknown> = T & MapInstance;

interface Map2GisContextProps {
	id: string;
	api: MapGLApi | null;
	mapInstance: MapInstance;
	setMapInstance: React.Dispatch<React.SetStateAction<MapInstance>>;
	activeObject: MapObject | undefined;
	setActiveObject: React.Dispatch<React.SetStateAction<MapObject | undefined>>;
	clustererInstance: ClustererInstance;
	setClustererInstance: React.Dispatch<React.SetStateAction<ClustererInstance>>;
	focusOnActive: boolean;
	setFocusOnActive: React.Dispatch<React.SetStateAction<boolean>>;
	setActiveObjectWithFocus: (object?: MapObject) => void;
}
interface Map2GisContextProviderProps {
	initialActiveObject?: MapObject;
}

export const Map2GisContext = createContext<Map2GisContextProps>({
	id: nanoid(),
	api: null,
	mapInstance: undefined,
	setMapInstance: () => {},
	activeObject: undefined,
	setActiveObject: () => {},
	clustererInstance: undefined,
	setClustererInstance: () => {},
	focusOnActive: false,
	setFocusOnActive: () => {},
	setActiveObjectWithFocus: () => null,
});

export const Map2GisContextProvider: FC<PropsWithChildren<Map2GisContextProviderProps>> = ({
	initialActiveObject,
	children,
}) => {
	const id = useMemo(nanoid, []);
	const api = use2GisApi();

	const [mapInstance, setMapInstance] = useState<MapInstance>(undefined);
	const [clustererInstance, setClustererInstance] = useState<ClustererInstance>(undefined);
	const [activeObject, setActiveObject] = useState<MapObject | undefined>(initialActiveObject);
	const [focusOnActive, setFocusOnActive] = useState(false);

	const setActiveObjectWithFocus = useCallback((object?: MapObject) => {
		if (object) {
			setFocusOnActive(true);
			setActiveObject(object);
		}
	}, []);

	return (
		<Map2GisContext.Provider
			value={{
				id,
				api,
				mapInstance,
				setMapInstance,
				activeObject,
				setActiveObject,
				clustererInstance,
				setClustererInstance,
				focusOnActive,
				setFocusOnActive,
				setActiveObjectWithFocus,
			}}>
			{children}
		</Map2GisContext.Provider>
	);
};

export const useMap2GisContext = () => {
	return useContext(Map2GisContext);
};
