import css from './CheckoutSummary.module.scss';
import classnames from 'classnames';
import type { CheckoutProps } from '@components';
import React, { FC, MouseEventHandler, useCallback, useContext, useMemo } from 'react';
import { Button, Price, Typography } from '@shared';
import { useOrderPrices } from '@components/Checkout/hooks';
import { CheckoutContext } from '@components';

interface CheckoutSummaryProps extends CheckoutProps {
	onClickButton?: () => void;
	isSubmiting?: boolean;
}

export const CheckoutSummary: FC<CheckoutSummaryProps> = ({
	onClickButton,
	textToPay,
	textCompletion,
	textDescription,
	textOrderPrice,
	textDiscount,
	textDelivery,
	textTotalPrice,
	price,
	isSubmiting,
}) => {
	const clickHandler: MouseEventHandler = useCallback(
		(e) => {
			e.preventDefault();
			onClickButton && onClickButton();
		},
		[onClickButton]
	);

	const { data, isLoading } = useOrderPrices();
	const { sum, discount, delivery, total } = data?.items || price || {};

	const { paymentOnline } = useContext(CheckoutContext);

	const textButton = useMemo(() => {
		return paymentOnline ? textToPay : textCompletion;
	}, [paymentOnline, textToPay, textCompletion]);

	return (
		<aside className={css.component}>
			<div className={css.summary}>
				<dl className={css.item}>
					<dt>{textOrderPrice}:&nbsp;</dt>
					<dd>
						<Price price={sum} />
					</dd>
				</dl>
				<dl className={css.item}>
					<dt>{textDiscount}:&nbsp;</dt>
					<dd>
						<Price price={discount} />
					</dd>
				</dl>
				<dl className={css.item}>
					<dt>{textDelivery}:&nbsp;</dt>
					<dd>
						<Price price={delivery} />
					</dd>
				</dl>
				<dl className={classnames(css.total, css.item)}>
					<dt>{textTotalPrice}:&nbsp;</dt>
					<dd>
						<Price price={total} />
					</dd>
				</dl>
			</div>
			<div className={css.footer}>
				<Button
					type="button"
					design="stroked"
					wide={true}
					onClick={clickHandler}
					isLoading={isSubmiting}>
					{textButton}
				</Button>
			</div>
			<Typography className={classnames(css.text, 'wysiwyg')} size="xs" color="gray" raw={true}>
				{textDescription}
			</Typography>
		</aside>
	);
};
