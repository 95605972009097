import type { BlockCatalogSectionsStackContent } from '@model/blockCatalogSectionsStackContent';
import React, { FC, HTMLAttributes } from 'react';
import { useViewport } from '@context';
import { CatalogSectionsStackMob } from './components/CatalogSectionsStackMob/CatalogSectionsStackMob';
import { CatalogSectionsStackRegular } from './components/CatalogSectionsStackRegular/CatalogSectionsStackRegular';

export interface CatalogSectionsStackProps
	extends HTMLAttributes<HTMLElement>,
		BlockCatalogSectionsStackContent {}

export const CatalogSectionsStack: FC<CatalogSectionsStackProps> = React.memo((props) => {
	const { isTablet } = useViewport();
	return !isTablet ? (
		<CatalogSectionsStackRegular {...props} />
	) : (
		<CatalogSectionsStackMob {...props} />
	);
});
