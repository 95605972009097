import css from './CheckoutDelivery.module.scss';
import type { OutpostPoint } from '@api/mock/types';
import type { CheckoutProps } from '@components';
import type {
	BlockBasketOrderDeliverySet,
	DeliveryCityType,
	DeliveryTypesList,
} from '@api/mock/types';
import type { InputElement } from '@shared/Form/FormComponent/hooks/useInput';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormFieldset } from '@shared/Form/FormFieldset/FormFieldset';
import { FormElement } from '@shared/Form/FormElement/FormElement';
import { FormChoice } from '@shared/Form/FormChoice/FormChoice';
import { Icon, Price, Select } from '@shared';
import { SelectAsync } from '@shared/Form/SelectAsync/SelectAsync';
import { CheckoutOutposts } from '@components/Checkout/components/CheckoutOutposts/CheckoutOutposts';
import { useData } from '@api';
import { usePageLoadState } from '@context';
import { useSelect } from '@shared/Form/FormComponent/hooks/useSelect';
import { useInput } from '@shared/Form/FormComponent/hooks/useInput';
import { withLocale } from '@utils';
import { getDeliveryDaysMessage } from '@components/Checkout/utils';
import { useRouter } from 'next/router';
import { FormChild } from '@shared/Form/FormComponent/components/FormChild/FormChild';
import { useOrderPrices } from '@components/Checkout/hooks';

interface CheckoutDeliveryProps extends BlockBasketOrderDeliverySet {
	orderForm?: CheckoutProps['orderForm'];
	userAddress?: {
		delivery?: {
			maxDay: number,
			minDay: number,
			price: number,
			name: string
		},
		formSet?: {
			apartment: string,
			cityCode: number,
			cityName: string,
			countryCode: string,
			deliveryType: string,
			entrance: string,
			index: string
		}
	}
}

const COUNTRY_KEY = 'countryCode';
const CITY_KEY = 'cityCode';

export const CheckoutDelivery: FC<CheckoutDeliveryProps> = ({
	legend,
	items = [],
	citySelectorUrl,
	deliveryTypesUrl,
	deliveryTypeKey = 'deliveryType',
	deliveryOutpostsUrl,
	outpostIdKey = 'outpostId',
	deliveryAddressSet,
	userAddress
}) => {
	const { locale } = useRouter();

	/*
	 * Страна и город
	 */
	const countriesSelect = useMemo(
		() => items.find(({ name }) => name === COUNTRY_KEY),
		[items]
	) as SelectType;
	const citiesSelect = useMemo(
		() => items.find(({ name }) => name === CITY_KEY),
		[items]
	) as SelectType;

	const { bind: countriesSelectHandlers, error: countryError } = useSelect<string>(
		citiesSelect.name || '',
		{
			value: '',
		}
	);
	const { bind: citiesSelectHandlers, error: cityError } = useSelect<string>(
		citiesSelect.name || '',
		{
			value: '',
		}
	);

	const [country, setCountry] = useState<SelectOption | undefined>(countriesSelect?.options[0]);
	const [city, setCity] = useState<SelectOption | undefined>(undefined);
	useEffect(() => {
		if (userAddress?.formSet?.cityCode) setCity({
			value: userAddress?.formSet?.cityCode,
			label: userAddress?.formSet?.cityName,
		})
	}, [userAddress?.formSet?.cityCode, userAddress?.formSet?.cityName])

	const getDefaultCitiesUrl = !!country?.value
		? withLocale(`${citySelectorUrl}?${COUNTRY_KEY}=${country.value}&fav=Y`)
		: undefined;
	const getFilteredCitiesUrl = !!country?.value
		? withLocale(`${citySelectorUrl}?${COUNTRY_KEY}=${country.value}`)
		: undefined;

	const { data: citiesData, isLoading: isLoadingCities } = useData<DeliveryCityType>({
		url: getDefaultCitiesUrl,
	});
	const citiesDefaultOptions = citiesData?.items || [];

	/*
	 * Типы доставки для страны и города
	 */
	const getDeliveryTypesUrl =
		!!country?.value && !!city?.value
			? withLocale(`${deliveryTypesUrl}?${COUNTRY_KEY}=${country.value}&${CITY_KEY}=${city.value}`)
			: undefined;
			
			
	const { data: deliveryTypesData, isLoading: isLoadingTypes } = useData<DeliveryTypesList>({
		url: withLocale(`${deliveryTypesUrl}?${COUNTRY_KEY}=${country?.value}&${CITY_KEY}=${city?.value}`),
	});

	const deliveryOptions = deliveryTypesData?.items || [];

	const {
		bind: deliveryTypesBind,
		error: deliveryTypesError,
		value: deliveryTypeValue,
	} = useInput<string | boolean>(deliveryTypeKey, {
		value: false,
	});

	const selectedService = deliveryOptions.find(({ service }) => service === deliveryTypeValue);

	const { mutate } = useOrderPrices();

	useEffect(() => {
		if (city?.value && deliveryTypeValue) {
			const data = {
				cityCode: city.value as number,
				deliveryService: deliveryTypeValue as string,
			};
			mutate(data);
		}
	}, [deliveryTypeValue, city, mutate]);

	const findItemByKey = useCallback((key: keyof {} ) => {
		if (userAddress?.formSet?.[key]) {
			return userAddress?.formSet?.[key]
		}
		return ''
	}, [userAddress?.formSet])

	/*
	 * Доставка в ПВЗ
	 */
	const getOutpostsUrl =
		!!country?.value &&
		!!city?.value &&
		deliveryOutpostsUrl &&
		selectedService?.service &&
		selectedService?.outpostRequired
			? withLocale(
					`${deliveryOutpostsUrl.replace(
						/(\${\w+})/gm,
						selectedService.service.split(':')[0]
					)}?${COUNTRY_KEY}=${country.value}&${CITY_KEY}=${city.value}`
			  )
			: undefined;

	const [selectedOutpost, setSelectedOutpost] = useState<OutpostPoint | undefined>(undefined);
	const selectedOutpostMessage = useMemo(
		() =>
			selectedOutpost ? (
				<span className={css.selectedOutpost}>
					<Icon id="bullet" />
					{selectedOutpost.title}
				</span>
			) : null,
		[selectedOutpost]
	);

	const {
		bind: selectedOutpostBind,
		error: selectedOutpostError,
		setError: setErrorOutpost,
	} = useInput<string>(outpostIdKey, {
		value: '',
	});

	useEffect(() => {
		if (selectedOutpost?.id) {
			setErrorOutpost(undefined);
		}
	}, [setErrorOutpost, selectedOutpost]);

	/*
	 * Включаем/выключаем лоадер на странице
	 */
	const { setLoading } = usePageLoadState();
	const isLoading = isLoadingCities || isLoadingTypes;

	useEffect(() => {
		setLoading(isLoading);
	}, [setLoading, isLoading]);

	return (
		<>
			<FormFieldset legend={legend}>
				<FormElement error={countryError}>
					<Select
						name={countriesSelect.name}
						selected={country}
						setSelected={(selected?: SelectOption) => setCountry(selected)}
						placeholder={countriesSelect.placeholder}
						options={countriesSelect.options}
						onChange={countriesSelectHandlers.onChange}
						onInvalid={countriesSelectHandlers.onInvalid}
					/>
				</FormElement>
				<FormElement error={cityError}>
					<SelectAsync
						id="cityCode"
						name={citiesSelect.name}
						required={citiesSelect.required}
						selected={city}
						setSelected={(selected: SelectOption) => setCity(selected)}
						defaultOptions={citiesDefaultOptions}
						fetchOptionsUrl={getFilteredCitiesUrl}
						transformData={(data: DeliveryCityType) => data?.items || []}
						placeholder={citiesSelect.placeholder || 'Город'}
						noOptionsMessage={citiesSelect.noOptionsMessage || 'Город с таким названием не найден'}
						loadingMessage={citiesSelect.loadingMessage || 'Загрузка'}
						onChange={citiesSelectHandlers.onChange}
						onInvalid={citiesSelectHandlers.onInvalid}
					/>
				</FormElement>
				<FormElement error={deliveryTypesError} className="radio-group">
					{deliveryOptions?.map((item, i) => {
						const deliveryTime = getDeliveryDaysMessage(1, 2, locale);
						const deliveryInfo = (
							<span className={css.deliveryChoiceText}>
								{item.name} (<Price price={item.price} />, {deliveryTime})
							</span>
						);
						const checked = item.service === deliveryTypeValue;
						return (
							<FormChoice
								key={`delivery-type-${item.service}-${i}`}
								type="radio"
								name={deliveryTypeKey}
								value={item.service}
								checked={checked}
								required={true}
								onChange={(e) => {
									deliveryTypesBind.onChange(e as ChangeEvent<InputElement>);
								}}
								onInvalid={deliveryTypesBind.onInvalid}
								rawHtml={false}
								className={css.deliveryChoice}>
								{deliveryInfo}
								{item.outpostRequired && checked && selectedOutpostMessage}
							</FormChoice>
						);
					})}
				</FormElement>

				{selectedService?.outpostRequired && (
					<FormElement error={selectedOutpostError}>
						<CheckoutOutposts
							endpoint={getOutpostsUrl}
							selectedOutpost={selectedOutpost}
							setSelectedOutpost={setSelectedOutpost}
						/>
						<input
							type="text"
							name={outpostIdKey}
							value={selectedOutpost?.id || ''}
							onChange={() => null}
							onInvalid={selectedOutpostBind.onInvalid}
							required={true}
							className="visually-hidden"
						/>
					</FormElement>
				)}
			</FormFieldset>

			{selectedService?.addressRequired && deliveryAddressSet && (
				<FormFieldset
					className={css.addressSet}
					legend={deliveryAddressSet.legend}
					disabled={!selectedService?.addressRequired}>
					{deliveryAddressSet.items?.map((item, i) => {
						return (
							<FormChild
								className={css.addressField}
								//@ts-ignore
								value={userAddress?.formSet?.[item?.name] || ''}
								key={`courier-address-field-${i}`}
								{...(item as FormFieldType)}
							/>
						);
					})}
				</FormFieldset>
			)}
		</>
	);
};
