import 'react-perfect-scrollbar/dist/css/styles.css';
import css from './Scroller.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import PerfectScrollbar, { ScrollBarProps } from 'react-perfect-scrollbar';

interface ScrollerProps extends HTMLAttributes<HTMLElement> {
	axis?: 'x' | 'y' | 'both';
	options?: ScrollBarProps['options'];
}

export const Scroller: FC<ScrollerProps> = ({ axis = 'both', options, className, children }) => {
	return (
		<PerfectScrollbar
			options={{ suppressScrollX: axis === 'y', suppressScrollY: axis === 'x', ...options }}
			className={classnames(className, css.component, css[axis])}>
			{children}
		</PerfectScrollbar>
	);
};
