import { PageData } from '@api/hooks/types';

export const brand: PageData = {
	meta: [
		{
			type: 'title',
			content: 'О бренде | Agapium',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-brand-intro',
			content: {
				textChunks: [
					'Мы бренд,<br /> раскрывающий',
					'Магическую',
					'и',
					'естественную',
					'силу',
					'притяжения',
					'женщины',
				],
			},
		},
		{
			type: 'block-polaroid-line',
			content: {
				grayscale: false,
				galleryImages: [
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 1080,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
				],
			},
		},
		{
			type: 'block-figure-text',
			content: {
				pre: 'Наш бренд',
				title: 'Мы создаём одежду, <br />которая станет атрибутом женской магии притяжения',
				text: 'Чётко выверенные стилевые решения, безупречный крой, бескомпромиссное качество исполнения и профессионализм стилиста — основателя бренда Ольги Агапитовой. ',
			},
		},
		{
			type: 'block-media',
			content: {
				textPlay: 'Воспроизвести',
				textPause: 'Остановить',
				src: 'https://previews.customer.envatousercontent.com/h264-video-previews/3e7d95db-2c8a-4a93-bad1-c3f1cfbd8a40/5346243.mp4?_=2',
				poster: '/temp/mediaSectionPoster.jpg',
				// youtube: 'https://www.youtube.com/watch?v=bOFkda4DbJ0'
			},
		},
		{
			type: 'block-teaser',
			content: {
				textWithImages: [
					{
						text: 'Ты уверенная',
						image: { url: '/temp/FigureTeaser1.png' },
					},
					{ text: 'сильная', image: { url: '/temp/FigureTeaser2.png' } },
					{
						text: 'притягательная',
						image: { url: '/temp/FigureTeaser3.png' },
					},
					{
						text: 'независимая',
						image: { url: '/temp/FigureTeaser4.png' },
					},
				],
				subText:
					'Наш бренд создан, чтобы помочь тем женщинам, которые ищут воплощение своей природы чувственности, проявить женственность и сексуальность. С помощью одежды мы стремимся к гармоничному отражению глубокой внутренней силы во внешнем образе.',
			},
		},
		{
			type: 'block-figure-text',
			content: {
				pre: 'История бренда',
				quote:
					'Я хочу, чтобы каждая женщина в одежде нашего бренда знала, что она достойна всего, что пожелает.',
				text: 'Мой бренд про женщину. Прекрасную, красивую, уверенную в себе, которая может позволить себе собирать тысячи восхищенных взглядов.',
			},
		},
		{
			type: 'block-twin-images',
			content: {
				images: [
					{
						url: '/temp/pic-06.jpg',
						width: 740,
						height: 1080,
						alt: '',
					},
					{
						url: '/temp/pic-06.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
				],
				textTicker: 'Fact: you attract',
			},
		},
		{
			type: 'block-features',
			content: {
				cards: [
					{
						leftImage: '/temp/FeatureCardLeftImage1.png',
						smallImage: '/temp/FeatureCardSmallImage1.jpg',
						heading: 'Мы ценим качество',
						text: 'В наших моделях мы учитываем всё: качество ткани и материалов, точность кроя, профессионализм исполнения, актуальность коллекций. Мы сосредоточены на разработке высокого качества продукции и гордимся собственным производством в России. Это позволяет обеспечить 100% контроль на всех этапах производства.',
					},
					{
						leftImage: '/temp/FeatureCardLeftImage2.jpg',
						smallImage: '/temp/FeatureCardSmallImage2.jpg',
						heading: 'Наша команда',
						text: 'В наших моделях мы учитываем всё: качество ткани и материалов, точность кроя, профессионализм исполнения, актуальность коллекций. Мы сосредоточены на разработке высокого качества продукции и гордимся собственным производством в России. Это позволяет обеспечить 100% контроль на всех этапах производства.',
					},
				],
			},
		},
	],
};
