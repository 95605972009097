import css from './SliderCursor.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { Move } from '@utils';
import { Typography } from '@src/shared';

interface SliderCursorProps extends HTMLAttributes<HTMLElement> {
	state?: 'next' | 'prev' | null;
	textNext?: string;
	textPrev?: string;
}

export const SliderCursor: FC<SliderCursorProps> = React.memo(
	({ state, textNext = 'Смотреть еще', textPrev = 'Вернуть модели', className }) => {
		const [point, setPointRef] = useState<HTMLElement | null>(null);

		useEffect(() => {
			if (!point) return;

			const onMouseMove = ({ x, y }: { x: number; y: number }) => {
				point.style.top = `${(y + 0.5) * 100}%`;
				point.style.left = `${(x + 0.5) * 100}%`;
			};

			Move.addListener(onMouseMove);

			return () => {
				Move.removeListener(onMouseMove);
			};
		}, [point]);

		const label = useMemo(() => {
			switch (state) {
				case 'prev':
					return textPrev;
				case 'next':
				default:
					return textNext;
			}
		}, [state, textPrev, textNext]);

		return (
			<div
				ref={setPointRef}
				className={classnames(className, css.point, { [css.isActive]: !!state })}
				aria-hidden="true">
				<div className={classnames(css.cursor, { [css.isPrev]: state === 'prev' })}>
					<Typography className={css.ticker} type="h5" data-title={label}>
						<span>{label}</span>
					</Typography>
				</div>
			</div>
		);
	}
);

SliderCursor.displayName = 'DragCursor';
