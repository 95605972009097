import css from '../Header.module.scss';
import classnames from 'classnames';
import { MainMenu } from '@model/models';
import React, {
	FC,
	HTMLAttributes,
	MouseEventHandler,
	PropsWithChildren,
	useCallback,
	useEffect,
} from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { useBxComponents, useViewport } from '@context';
import { Box, Button, CloseBtn, Container, Icon, Spoiler } from '@shared';
import { Sandwich } from '@shared/Sandwich/Sandwich';
import { useHeader } from '@layout/components/Header/context/Header.context';
import { DefaultWrapper } from '@layout';
import { useAccessibility, useRouterPath } from '@hooks';
import { AdditionalLinks } from '@layout/components/Header/components/AdditionalLinks';
import { SocialLinks } from '@layout/components/Header/components/SocialLinks';
import { ToolsMob } from '@layout/components/Header/components/Tools';
import { trimLocale } from '@utils';
import { useRouter } from 'next/router';
import classNames from 'classnames';

interface NavigationProps extends HTMLAttributes<HTMLDivElement> {}

const checkIsActiveRoute = (url?: string, path?: string, isSub = false, sectionCode?: string) => {
	if (!url || !path || path === '/') return false;

	if (isSub) {
		const isChildOfSection = sectionCode ? url.includes(`section/${sectionCode}`) : false;
		return isChildOfSection || trimLocale(path) === trimLocale(url);
	} else {
		return path.includes(url);
	}
};

export const Navigation: FC<NavigationProps> = React.memo(({ className }) => {
	const router = useRouter();
	const { nav, search } = useHeader();
	const { isTablet } = useViewport();

	const sandwichClickHandler: MouseEventHandler = () => {
		if (nav.setState) nav.setState((prev) => !prev);
	};
	const searchClickHandler: MouseEventHandler = () => {
		if (search?.setState) search.setState((prev) => !prev);
	};
	const menuHoverHandler: MouseEventHandler = () => {
		if (search?.setState) search.setState(false);
	};

	useEffect(() => {
		const onRouteStart = () => {
			nav.setState && nav.setState(false);
			search?.setState && search.setState(false);
		};

		router.events.on('routeChangeStart', onRouteStart);

		return () => {
			router.events.off('routeChangeStart', onRouteStart);
		};
	}, [nav, router, search]);

	return (
		<div className={classnames(className, css.navWrapper)}>
			{isTablet && (
				<>
					<div className={classnames({ [css.lefNavWrapperInVisible]: search.state, 
							[css.searchHidden]: !search.state })}>
						<Sandwich
							// active={nav.state}
							onClick={sandwichClickHandler}
						/>
						<Button className={classnames(css.toolsBtn)} onClick={searchClickHandler}>
							<Icon id="search" />
						</Button>
					</div>
					<div
						className={classnames(css.lefNavWrapperVisible, {
							[css.lefNavWrapperInVisible]: !search.state,
						})}>
						<CloseBtn
							className={classnames(css.searchClose, css.searchCloseMobile)}
							onClick={() => search?.setState && search.setState(false)}>
							{'Назад'}
						</CloseBtn>
					</div>
				</>
			)}
			<NavigationContainer>
				{isTablet ? (
					<>
						<NavigationMenuMob />
						<ToolsMob />
						<AdditionalLinks />
						<SocialLinks />
					</>
				) : (
					<NavigationMenu onHover={menuHoverHandler} />
				)}
			</NavigationContainer>
		</div>
	);
});

const NavigationMenuMob: FC<PropsWithChildren> = ({}) => {
	const path = useRouterPath();
	const { nav, sectionCode } = useHeader();

	const { header } = useBxComponents();
	const data = header?.menu?.main;

	const handleSpoilerClick: MouseEventHandler<HTMLDivElement> = useCallback(
		(e) => {
			if ((e.target as HTMLElement).closest('a')) {
				nav.setState && nav.setState(false);
			}
		},
		[nav]
	);

	return (
		<ul className={css.navigationMob}>
			{data?.map(({ name, url, subMenu }, i) => {
				const isActive = checkIsActiveRoute(url, path);
				const levelLink = (
					!subMenu ? <Link href={url || ''}>
						<a
							className={classnames(css.navigationMobLevel, {
								[css.isActive]: isActive,
							})}>
							{name}
						</a>
					</Link> : <div
							className={classnames(css.navigationMobLevel, {
								[css.isActive]: isActive,
							})}>
							{name}
						</div>
				);

				return (
					<li key={`main-menu-mob-${url}-${i}`}>
						{subMenu ? (
							<Spoiler
								head={levelLink}
								onClick={handleSpoilerClick}
								classNameBody={css.navigationMobSub}>
								<ul className={css.navigationSubMenu}>
									{subMenu.map(({ name, url }) => (
										<li key={`main-menu-mob-sub-${url}-${i}`}>
											<Link href={url || '/'}>
												<a
													className={classnames({
														[css.isActive]: checkIsActiveRoute(url, path, true, sectionCode),
													})}>
													{name}
												</a>
											</Link>
										</li>
									))}
								</ul>
							</Spoiler>
						) : (
							levelLink
						)}
					</li>
				);
			})}
		</ul>
	);
};

const NavigationMenu: FC<{ onHover?: MouseEventHandler }> = ({ onHover }) => {
	const { header } = useBxComponents();
	const data = header?.menu?.main;

	return (
		<ul className={css.navigationMenu}>
			{data?.map((item, i) => {
				return (
					<NavigationMenuItem
						{...item}
						onHover={item.subMenu && onHover}
						key={`main-menu-level-${item.url}-${i}`}
					/>
				);
			})}
		</ul>
	);
};

const NavigationMenuItem: FC<MainMenu & { onHover?: MouseEventHandler }> = ({
	name,
	url,
	subMenu,
	onHover,
}) => {
	const path = useRouterPath();
	const isActive = checkIsActiveRoute(url, path);

	const item =
		url && url !== '/catalog/' ? (
			<Link href={url}>
				<a className={classnames({ [css.isActive]: isActive })}>
					{name}
					<Icon id="bullet" />
				</a>
			</Link>
		) : (
			<span className={classnames({ [css.isActive]: isActive })} tabIndex={0} role="button">
				{name}
				<Icon id="bullet" />
			</span>
		);

	return (
		<li onMouseEnter={onHover}>
			{item}
			{subMenu && <NavigationSubMenu subMenu={subMenu} />}
		</li>
	);
};

const NavigationSubMenu: FC<Pick<MainMenu, 'subMenu'>> = ({ subMenu }) => {
	const path = useRouterPath();
	const { isTablet } = useViewport();
	const { header } = useBxComponents();
	const { sectionCode } = useHeader();
	const Wrapper = isTablet ? DefaultWrapper : Container;

	return (
		<Box className={css.navigationSub}>
			<Wrapper className={css.navigationSubContainer}>
				<ul className={css.navigationSubMenu}>
					{subMenu?.map(({ name, url }, i) => {
						return (
							<li key={`main-menu-sub-${url}-${i}`}>
								<Link href={url || '/'}>
									<a
										className={classnames({
											[css.isActive]: checkIsActiveRoute(url, path, true, sectionCode),
										})}>
										{name}
										<Icon id="bullet" />
									</a>
								</Link>
							</li>
						);
					})}
				</ul>
				<picture className={css.navigationSubFigure}>
					{header?.menu?.image?.url && (
						<Image
							src={header?.menu?.image?.url}
							alt={header?.menu?.image?.alt || ''}
							layout="fill"
							quality={100}
							priority={true}
						/>
					)}
				</picture>
			</Wrapper>
		</Box>
	);
};

const NavigationContainer: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({
	className,
	children,
}) => {
	const { nav } = useHeader();
	const { isTablet } = useViewport();

	const handleClose = useCallback(() => {
		nav.setState && nav.setState(false);
	}, [nav]);

	return isTablet ? (
		<Container
			as="nav"
			className={classnames(className, css.navigation, { [css.isShown]: nav.state })}>
			<CloseBtn className={css.navigationClose} onClick={handleClose}>
				<Icon id="close" />
			</CloseBtn>
			{children}
		</Container>
	) : (
		<nav className={classnames(className, css.navigation)}>{children}</nav>
	);
};
