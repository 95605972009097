import React, { FC, PropsWithChildren, MouseEvent } from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';

interface Props {
	content?: string;
	className?: string;
}

export const RawHtml: FC<PropsWithChildren<Props>> = React.memo(
	({ className, content, children }) => {
		const router = useRouter();

		if (Array.isArray(children)) {
			content = children.join('');
		} else if (typeof children === 'string') {
			content = children;
		}

		if (!content) {
			return null;
		}

		const onClick = (e: MouseEvent) => {
			const { target } = e;

			if (target instanceof Element) {
				const targetLink = target.closest('a');
				if (!targetLink) return;
				e.preventDefault();

				const { href } = targetLink;
				const { origin } = window.location;

				if (href.includes(origin)) {
					router.push(href.replace(origin, ''));
				} else {
					window.open(href, '_blank');
				}
			}
		};

		return (
			<div
				className={classnames(className)}
				onClick={onClick}
				dangerouslySetInnerHTML={{ __html: content }}
			/>
		);
	}
);
