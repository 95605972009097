import css from './TextBox.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { TypographyFlow, TypographyTagnameType } from '@shared';

interface TextBoxProps extends HTMLAttributes<HTMLElement> {
	as?: TypographyTagnameType;
	content?: Array<TextContentNode>;
}

export const TextBox: FC<TextBoxProps> = React.memo(({ as: Tag = 'div', content, children }) => {
	return (
		<Tag className={classnames(css.component)}>
			{content && <TypographyFlow content={content} />}
			{children}
		</Tag>
	);
});
