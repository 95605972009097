import { useCallback, useEffect, useState } from 'react';

import { isTouchDevice } from '@src/utils'; 

const isTouch = isTouchDevice();

export function useAccessibility() {
	const [usingMouse, setUsingMouse] = useState<boolean>(true);
	const [touch, setTouch] = useState<boolean>(isTouch);

	const keyboardActions = useCallback(
		(e: KeyboardEvent) => {
			if (!usingMouse) return;
			if (e && e.keyCode !== 9) return;

			setUsingMouse(false);
			document.body.classList.toggle('keyboard', true);
		},
		[usingMouse]
	);

	const mouseActions = useCallback(() => {
		if (usingMouse) return;

		setUsingMouse(true);
		document.body.classList.toggle('keyboard', false);
	}, [usingMouse]);

	useEffect(() => {
		if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
			setTouch(false);
			setUsingMouse(false);
		} else {
			setTouch(true);
		}
	}, []);

	useEffect(() => {
		if (!touch) return;

		window.addEventListener('keydown', keyboardActions);
		window.addEventListener('mousedown', mouseActions);
	}, [keyboardActions, mouseActions, touch]);

	return usingMouse;
}
