import { ValueType } from '@shared/Form/FormComponent/hooks/useInput';

export const getValidationConfig = (field: FormFieldType) => {
	switch (field.type) {
		case 'file':
			return {
				onBlur: true,
				onChange: true,
				handler: validateFile({
					accept: field.accept,
					limit: field.sizeLimit,
				}),
				...getMessagesFromProps(field.validation),
			};

		default:
			return {
				// onBlur: true,
				// onChange: true,
				...getMessagesFromProps(field.validation),
			};
	}
};

export const getMessagesFromProps = (validation?: FormFieldValidationType) => {
	return validation ? { messages: validation } : {};
};

export const getStatus = (status: boolean, key: string) => ({
	status,
	key,
});

export const validateFile = ({ accept, limit }: { accept?: string; limit?: number }) => {
	return (value: ValueType) => {
		const keys = [];
		const ext = accept ? validateFileExt(value, accept) : getStatus(true, 'accept');
		const size = limit ? validateFileSize(value, limit) : getStatus(true, 'size');

		if (ext.status) keys.push(ext.key);
		if (size.status) keys.push(size.key);

		return {
			status: !(size.status || ext.status),
			key: keys as string[],
		};
	};
};

const validateFileExt = (value: ValueType, accept: string) => {
	let error = false;
	const types = accept.split(',');
	if (value instanceof FileList) {
		error = Array.from(value).some((file) => !types.includes(file.type));
	}
	return getStatus(error, 'accept');
};

const validateFileSize = (value: ValueType, limit: number) => {
	let overlimit = false;
	if (value instanceof FileList) {
		overlimit = Array.from(value).some((file) => file.size > limit);
	}
	return getStatus(overlimit, 'size');
};
