import React, { FC, HTMLAttributes } from 'react';
import { PriceType, usePrice } from '@hooks';
import { Currency, CurrencyMode } from '@shared';

interface PriceProps extends HTMLAttributes<HTMLSpanElement> {
	price?: PriceType;
	mode?: CurrencyMode;
	separator?: string;
}

export const Price: FC<PriceProps> = React.memo(({ price, mode = 'symbol', separator = ' ' }) => {
	const value = usePrice(price, separator);
	return (
		<>
			{value || 0} <Currency mode={mode} />
		</>
	);
});
