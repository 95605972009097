import css from '../../CatalogSectionsStack.module.scss';
import classnames from 'classnames';
import type { CatalogSectionsStackProps } from '@components';
import React, { FC } from 'react';
import { Box, Section } from '@shared';
import { CatalogSectionsCard } from '@components/CatalogSectionsStack/components/CatalogSectionsCard/CatalogSectionsCard';

export const CatalogSectionsStackMob: FC<CatalogSectionsStackProps> = ({ productsSections }) => {
	return (
		<Section className={classnames(css.component)}>
			{productsSections?.map((section, i) => (
				<Box className={css.stickyCardTrack} key={`catalog-stack-section-${section.id || i}`}>
					<CatalogSectionsCard className={css.stickyCard} {...section} />
				</Box>
			))}
		</Section>
	);
};
