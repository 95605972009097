import css from './SearchFeed.module.scss';
import classnames from 'classnames';
import type { EmptyBannerType } from '@api/mock/types';
import type { BlockCatalogAllProductsContent } from '@model/blockCatalogAllProductsContent';
import type { CatalogProductsElements } from '@model/catalogProductsElements';
import React, { FC, HTMLAttributes, useCallback, useRef, useState } from 'react';
import { EmptyBanner } from '@components/Account/components/EmptyBanner/EmptyBanner';
import { useCatalog } from '@components/Catalog/context/Catalog.context';
import { useViewport } from '@context';
import { useScrollSeek } from '@hooks';
import { useDataLazy } from '@api';
import { ProductCard } from '@shared';

interface SearchFeedProps extends HTMLAttributes<HTMLElement>, BlockCatalogAllProductsContent {
	staticData?: BlockCatalogAllProductsContent['products'];
	skipDataFetching?: boolean;
	emptyBanner?: EmptyBannerType;
}

export const SearchFeed: FC<SearchFeedProps> = React.memo(
	({ navigation, staticData = [], skipDataFetching = false, emptyBanner, className }) => {
		const ref = useRef<HTMLDivElement | null>(null);
		const { vh } = useViewport();
		const { limit, query } = useCatalog();

		/*
		 * Data fetching
		 */
		/* ToDo: по аналогии с каталогом:
		 *  типизировать useDataLazy
		 *  типизировать data в transform
		 */
		const { data, size, setSize, isLoading, isLoadingMore, isRefreshing, isReachingEnd } =
			useDataLazy<CatalogProductsElements>(
				{
					url: skipDataFetching ? undefined : navigation?.self,
					total: navigation?.total,
					limit,
					query,
					transform: (data: BlockCatalogAllProductsContent) => data?.products || [],
				},
				// ToDo: отключить моки
				false
			);

		// ToDo: проверить skipLazy и skipRequest на большом массиве товаров
		const skipLazy = skipDataFetching && (data?.length || 0) >= limit;
		const skipRequest = isLoadingMore || isRefreshing || isReachingEnd || skipLazy || true;

		/*
		 * Бесконечный скролл
		 */
		useScrollSeek(
			ref,
			useCallback(
				({ bounding }) => {
					if (skipRequest) return;

					const end = bounding.bottom - vh;
					const offset = vh * 1.25;

					if (end - offset <= 0) {
						setSize(size + 1);
					}
				},
				[setSize, size, skipRequest, vh]
			),
			{
				checkInView: true,
			}
		);

		/*
		 * Результат выборки: статика с сервера или запрошенные на клиенте данные
		 */
		const items = data || staticData;
		const isEmpty = items.length === 0;
		const { isMob } = useViewport()
		return (
			<div ref={ref} className={classnames(className, css.catalog, css[`grid-sm`], isMob ? 'grid-md' : `grid-sm`)}>
				{/* {isEmpty && <EmptyBanner {...emptyBanner} />} */}
				{!isEmpty && <SearchFeedCards items={items} />}
			</div>
		);
	}
);

export const SearchFeedCards: FC<{ items?: SearchFeedProps['staticData'] }> = React.memo(
	({ items = [] }) => {
		return (
			<>
				{items?.map((item, i) => {
					return (
						<ProductCard
							{...item}
							index={i}
							textSize="sm"
							className={css.card}
							key={`wishlist-card-${item?.id}`}
						/>
					);
				})}
			</>
		);
	}
);
