import css from './FormComponent.module.scss';
import classnames from 'classnames';
import type { UseInputReturn } from '@shared/Form/FormComponent/hooks/useInput';
import React, {
	PropsWithChildren,
	HTMLAttributes,
	Dispatch,
	SetStateAction,
	useState,
	useEffect,
	useCallback,
	FormEvent,
	useRef,
	LegacyRef,
} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {
	FormResponse as FormResponseComponent,
	FormResponseProps,
} from '@shared/Form/FormComponent/components/FormResponse/FormResponse';
import { TypographySizeType, TypographyTagType } from '@shared';
import { FormErrorHandle } from '@shared/Form/FormComponent/components/FormErrorHandle/FormErrorHandle';

export type FormSumbitHandlerType = (e: FormEvent, ctx?: FormContextProps) => void;

export type FormState = 'waiting' | 'pending' | 'success' | 'reject';

export type FormStorePayload = Pick<
	UseInputReturn<any>,
	'error' | 'value' | 'setError' | 'setValue'
>;
export type FormStore = Record<string, FormStorePayload>;

export interface FormProps extends HTMLAttributes<HTMLFormElement> {
	onSubmit: FormSumbitHandlerType;
	grcKey?: string;
	responseAlign?: FormResponseProps['align'];
	responseJustify?: FormResponseProps['justify'];
	responseTitleType?: TypographyTagType;
	responseTextSize?: TypographySizeType;
	responseClassName?: string;
	errorClassName?: string;
	action?: string;
	method?: CRUDType;
	forceSubmitOnEnter?: boolean | React.MutableRefObject<HTMLButtonElement | null>;
}

export interface FormContextProps {
	form: HTMLFormElement | null;
	formState?: FormState;
	setFormState?: Dispatch<SetStateAction<FormState>>;
	response?: FormResponse | null;
	storeResponse?: Dispatch<SetStateAction<FormResponse | null>>;
	store?: FormStore;
	register?: (name: string, payload: FormStorePayload) => void;
	checkCaptcha?: () => Promise<string | null>;
	resetCaptcha?: () => void;
}

export const FormContext = React.createContext<FormContextProps>({
	form: null,
});

export const FormComponent = React.forwardRef<HTMLFormElement, PropsWithChildren<FormProps>>(
	(
		{
			onSubmit,
			grcKey,
			responseAlign = 'center',
			responseJustify = 'center',
			responseTitleType,
			responseTextSize,
			responseClassName,
			errorClassName,
			action,
			method,
			forceSubmitOnEnter,
			className,
			children,
			...props
		},
		ref
	) => {
		const [formState, setFormState] = useState<FormState>('waiting');
		const [response, storeResponse] = useState<FormResponse | null>(null);

		const [formElement, setFormElement] = useState<HTMLFormElement | null>(null);

		const formRefCb = useCallback(
			(node: HTMLFormElement | null) => {
				setFormElement(node || null);

				if (node && ref) {
					if (typeof ref === 'function') {
						ref(node);
					} else {
						ref.current = node;
					}
				}
			},
			[ref]
		);

		useEffect(() => {
			return () => {
				storeResponse(null);
				setFormState('waiting');
			};
		}, []);

		const [store, setStore] = useState<FormStore>({});

		const register = useCallback((name: string, payload: FormStorePayload) => {
			setStore((prev) => ({
				...prev,
				[name]: payload,
			}));
		}, []);

		const [showError, setShowError] = useState(false);
		const [showResponse, setShowResponse] = useState(false);

		useEffect(() => {
			setShowError(formState === 'reject');
			setShowResponse(formState === 'success');

			return () => {
				setShowError(false);
				setShowResponse(false);
			};
		}, [formState]);

		const recaptcha = useRef<ReCAPTCHA>();

		const checkCaptcha = useCallback(async () => {
			if (grcKey && recaptcha.current) {
				return recaptcha.current.executeAsync();
			} else {
				return Promise.resolve(null);
			}
		}, [grcKey, recaptcha]);

		const resetCaptcha = useCallback(() => {
			if (grcKey && recaptcha.current) {
				recaptcha.current.reset();
			}
		}, [grcKey, recaptcha]);

		const ctxData = {
			form: formElement,
			formState,
			setFormState,
			response,
			storeResponse,
			store,
			register,
			checkCaptcha,
			resetCaptcha,
		};

		return (
			<FormContext.Provider value={ctxData}>
				<div className={css.formWrapper}>
					<form
						ref={formRefCb}
						action={action}
						method={method}
						className={classnames(className, css.form, css[formState])}
						onSubmit={(e) => {
							e.preventDefault();
							onSubmit(e, ctxData);
						}}
						{...props}>
						{forceSubmitOnEnter && (
							<button
								ref={(ref) => {
									if (
										typeof forceSubmitOnEnter !== 'boolean' &&
										forceSubmitOnEnter.hasOwnProperty('current')
									) {
										forceSubmitOnEnter.current = ref;
									}
								}}
								type="submit"
								tabIndex={-1}
								className="visually-hidden"
							/>
						)}
						{children}
						{grcKey && (
							<div tabIndex={-1}>
								<ReCAPTCHA
									ref={recaptcha as LegacyRef<ReCAPTCHA>}
									size="invisible"
									sitekey={grcKey}
								/>
							</div>
						)}
					</form>
					<FormErrorHandle
						active={showError}
						response={response}
						align={responseAlign}
						className={errorClassName}
					/>
					<FormResponseComponent
						active={showResponse}
						response={response}
						align={responseAlign}
						justify={responseJustify}
						titleType={responseTitleType}
						textSize={responseTextSize}
						className={responseClassName}
					/>
				</div>
			</FormContext.Provider>
		);
	}
);
