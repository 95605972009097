import { PageData } from '@api/hooks/types';

export const contacts: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Контакты',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-main-contacts',
			content: {
				textTitle: 'Контакты',
				textSubtitle: 'Мы рады ответить на ваши вопросы каждый день с 10:00 до 20:00',
				contactLinks: [
					{
						textTitle: 'Телефон интернет-магазина',
						links: [
							{
								name: '+7 (800) 320-72-65',
								url: 'tel:+78003207265',
							},
						],
					},
					{
						textTitle: 'По вопросам клиентского сервиса',
						links: [
							{
								name: 'cs@agapium.com',
								url: 'mailto:cs@agapium.com',
							},
						],
					},
					{
						textTitle: 'По вопросам клиентского сервиса',
						links: [
							{
								name: 'agapium@agapium.com',
								url: 'mailto:agapium@agapium.com',
							},
						],
					},
				],
				socialLinks: {
					textTitle: 'Мы в социальных сетях',
					links: [
						{
							name: 'Telegram',
							url: 'https://tg.com',
						},
						{
							name: 'TikTok',
							url: 'https://tiktok.com',
						},
						{
							name: 'Вконтакте',
							url: 'https://vk.com',
						},
					],
				},
				form: {
					/*
					 * @grcKey? публичный ключ рекаптчи
					 * @providedData? любые данные вида [key: string]: string, передадутся вместе с данными формы
					 */
					textTitle: 'Обратная связь',
					textButton: 'Отправить',
					action: '/mocks/form/error',
					method: 'post',
					grcKey: null, // '6LfURdcZAAAAAAz9aJ1T0WeUAeVGQI3RsarvmhRi',
					providedData: {
						foo: 'bar',
					},
					items: [
						{
							type: 'text',
							name: 'name',
							label: 'Имя',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'tel',
							name: 'phone',
							label: 'Номер телефона',
							required: true,
							mask: '+0 000 0000000',
							pattern: '^(\\+\\d)\\s\\d{3}\\s\\d{7}$',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный номер телефона',
							},
						},
						{
							type: 'email',
							name: 'email',
							label: 'Электронная почта',
							required: true,
							pattern: '[0-9a-z_.-]+@[0-9a-z_]+\\.[a-z]{2,5}',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный адрес email',
							},
						},
						{
							type: 'textarea',
							name: 'name',
							label: 'Введите сообщение',
							required: false,
							validation: {
								required: 'Заполните это поле',
							},
						},
					],
				},
				formImage: {
					url: '/temp/pic-06.jpg',
					alt: 'Обратная связь',
					width: 1920,
					height: 2880,
				},
			},
		},
	],
};
