import { fetcher, useData } from '@api';
import { BlockBasketProductsContent, BasketRequestBody } from '@model/models';
import { BASKET_ROUTE, withLocale } from '@utils';

export const useCartData = () => {
	return useData<BlockBasketProductsContent>({ url: withLocale(BASKET_ROUTE) });
};

export const updateCart = (data: BasketRequestBody) => {
	return fetcher({
		url: BASKET_ROUTE,
		method: 'POST',
		body: data,
	});
};
