import css from './CatalogFeed.module.scss';
import classnames from 'classnames';
import type { CatalogViewType } from '@components/Catalog/context/Catalog.context';
import type { BlockCatalogAllProductsContent } from '@model/blockCatalogAllProductsContent';
import type { CatalogProductsElements } from '@model/catalogProductsElements';
import React, { FC, HTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { ProductCard } from '@shared/ProductCard/ProductCard';
import { useCatalog } from '@components/Catalog/context/Catalog.context';
import { useDataLazy } from '@api';
import { useViewport } from '@context';
import { useScrollSeek } from '@hooks';

interface CatalogFeedProps extends HTMLAttributes<HTMLElement>, BlockCatalogAllProductsContent {
	staticData?: BlockCatalogAllProductsContent['products'];
	skipDataFetching?: boolean;
}



export const CatalogFeed: FC<CatalogFeedProps> = React.memo(
	({ navigation, staticData = [], skipDataFetching = false, className }) => {
		const ref = useRef<HTMLDivElement | null>(null);

		const { vh } = useViewport();
		const { view = 'md', limit, query } = useCatalog();

		const [localView, setLocalView] = useState<CatalogViewType>(view);

		/*
		 * Data fetching
		 */
		const { data, size, setSize, isLoading, isLoadingMore, isRefreshing, isReachingEnd } =
			useDataLazy<CatalogProductsElements>({
				url: skipDataFetching ? undefined : navigation?.self,
				total: navigation?.total,
				limit,
				query,
				transform: (data: BlockCatalogAllProductsContent) => data?.products || [],
			});

		// ToDo: проверить skipLazy и skipRequest на большом массиве товаров
		const skipLazy = skipDataFetching && (data?.length || 0) >= limit;
		const skipRequest = isLoadingMore || isRefreshing || isReachingEnd || skipLazy;

		/*
		 * Бесконечный скролл
		 */
		useScrollSeek(
			ref,
			useCallback(
				({ bounding }) => {
					if (skipRequest) return;

					const end = bounding.bottom - vh;
					const offset = vh * 1.25;

					if (end - offset <= 0) {
						setSize(size + 1);
					}
				},
				[vh, size, setSize, skipRequest]
			),
			{
				checkInView: true,
			}
		);

		/*
		 * Прячет ленту при смене вида
		 */
		const [fade, setFade] = useState(false);

		useEffect(() => {
			let timer = 0;
			let mounted = true;

			if (mounted) {
				setFade(true);

				timer = window.setTimeout(() => {
					setLocalView(view);
					setFade(false);
				}, 400);
			}

			return () => {
				mounted = false;
				window.clearTimeout(timer);
			};
		}, [localView, view, limit, query]);

		/*
		 * Результат выборки: статика с сервера или запрошенные на клиенте данные
		 */
		const items = data || staticData;

		return (
			<div
				ref={ref}
				className={classnames(
					className,
					css.catalog,
					css[`grid-${localView}`],
					`grid-${localView}`,
					{
						[css.fade]: fade || isLoading,
					}
				)}
				itemType="https://schema.org/ItemList"
				itemScope>
				<CatalogFeedCards items={items} />
			</div>
		);
	}
);

export const CatalogFeedCards: FC<{ items?: CatalogFeedProps['staticData'] }> = React.memo(
	({ items = [] }) => {
		const { view = 'md' } = useCatalog();
		const giveBorderByIndex = () => {
			let index = 1;
			return () => {
				if (index < 7) index++
				else index = 2
				switch(index - 1) {
					case 1: {
						return 'left-top'
					}
					case 2: {
						return 'right-bottom'
					}
					case 3: {
						return 'none-border'
					}
					case 4: {
						return 'none-border'
					}
					case 5: {
						return 'left-top'
					}
					case 6: {
						return 'right-bottom'
					}
					default:
						 return 'left-top'
				}
			}
		}
		const recieveStyle = giveBorderByIndex()
		return (
			<>
				{items?.map((item, i) => {
					return (
						<ProductCard
							key={`catalog-card-${item?.id}`}
							className={css.card}
							index={i}
							textSize={view === 'sm' ? view : 'md'}
							recieveStyle={recieveStyle()}
							{...item}
						/>
					);
				})}
			</>
		);
	}
);
