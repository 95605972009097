import css from './FormChoice.module.scss';
import classnames from 'classnames';
import React, { PropsWithChildren, ComponentPropsWithoutRef, useState, useEffect } from 'react';

export interface FormChoiceProps {
	type: 'radio' | 'checkbox';
	name?: string;
	value?: string | number;
	disabled?: boolean;
	className?: string;
	rawHtml?: boolean;
}

export const FormChoice = React.forwardRef<
	HTMLInputElement,
	PropsWithChildren<FormChoiceProps & ComponentPropsWithoutRef<'input'>>
>(
	(
		{
			type = 'checkbox',
			name,
			value,
			disabled = false,
			className,
			children,
			rawHtml = true,
			...props
		},
		forwardedRef
	) => {
		const [checked, setChecked] = useState(false);
		useEffect(() => {
		  if (value === 'card') setChecked(true)
		}, [props])
		
		return (
			<label className={classnames(css.choice, className)}>
				<input
					{...props}
					type={type}
					name={name}
					value={value}
					disabled={disabled}
					ref={forwardedRef}
					checked={value === 'card' ? checked : props.checked}
				/>
				{children ? (
					rawHtml ? (
						<span dangerouslySetInnerHTML={{ __html: children.toString() }} />
					) : (
						<span>{children}</span>
					)
				) : (
					<span />
				)}
			</label>
		);
	}
);
