import { getNumEndings } from '@utils';

export const getDeliveryDays = (min?: number, max?: number, text?: [string, string, string]) => {
	if (!min && !max) return undefined;

	let maximum = max || min || 0;

	const range = min && min !== maximum ? `${min}-${maximum}` : maximum;
	const desc = text && text.length === 3 ? getNumEndings(maximum, text) : '';

	return `${range} ${desc}`;
};

export const getDeliveryDaysMessage = (min?: number, max?: number, locale?: string) => {
	const en = ['day', 'days', 'days'] as [string, string, string];
	const ru = ['день', 'дня', 'дней'] as [string, string, string];
	const suffix = locale === 'en' ? en : ru;

	return getDeliveryDays(min, max, suffix);
};
