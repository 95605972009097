import { PageData } from '@api/hooks/types';

export const cookies: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Настройки файлов Cookie | Политика',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-text-page',
			content: {
				textTitle: 'Политика',
				sectionsNav: [
					{
						name: 'Политика конфиденциальности',
						url: '/client-service/privacy-police',
					},
					{
						name: 'Настройки файлов Cookie',
						url: '/client-service/cookies-police',
					},
				],
				textMain: [
					{
						type: 'html',
						content:
							'<h4>1. Что такое файлы Сookie</h4>' +
							'<p>Файл cookie представляет собой небольшой текстовый файл, который сохраняется и&nbsp;во&nbsp;время последующих посещений извлекается с&nbsp;вашего компьютера или мобильного устройства. Если вы&nbsp;используете наши услуги, мы&nbsp;предположим, что вы&nbsp;согласны с&nbsp;использованием такого файла cookie.</p>' +
							'<h4>2. Как мы используем файлы Сookie?</h4>' +
							'<p>Мы&nbsp;используем постоянные файлы cookie, чтобы сохранить ваш выбор стартовой страницы и&nbsp;сохранить ваши данные, если вы&nbsp;выберете &laquo;Запомнить меня&raquo; при входе в&nbsp;систему.</p>' +
							'<p>Мы&nbsp;будем использовать файлы cookie для сохранения ваших любимых продуктов.</p>' +
							'<p>Мы&nbsp;используем файлы cookie сеанса, например, когда вы&nbsp;используете функцию фильтрации продукта, чтобы проверить, вошли&nbsp;ли вы&nbsp;в&nbsp;систему, или если вы&nbsp;поместили элемент в&nbsp;корзину.</p>' +
							'<p>Мы&nbsp;используем как cookie первой, так и&nbsp;третьей стороны для сбора статистики и&nbsp;пользовательских данных в&nbsp;совокупной и&nbsp;индивидуальной форме в&nbsp;инструментах анализа, чтобы оптимизировать наш сайт и&nbsp;представить вам соответствующие маркетинговые материалы.</p>' +
							'<p>Некоторые сторонние файлы cookie устанавливаются службами, которые появляются на&nbsp;наших страницах и&nbsp;не&nbsp;находятся под нашим контролем. Они устанавливаются такими поставщиками социальных сетей, как Twitter, Facebook и&nbsp;Vimeo, а&nbsp;также способностью пользователей делиться контентом на&nbsp;этом сайте, как указано в&nbsp;соответствующем значке.</p>' +
							'<p>Мы&nbsp;также используем сторонние файлы cookie, которые выполняют межсайтовое отслеживание, чтобы мы&nbsp;могли дать вам маркетинг на&nbsp;других сайтах / каналах.</p>' +
							'<h4>3. Какие типы персональных данных мы обрабатываем?</h4>' +
							'<p>Мы&nbsp;свяжемся с&nbsp;вашим идентификатором cookie только с&nbsp;вашими личными данными, собранными и&nbsp;собранными в&nbsp;связи с&nbsp;вашей учетной записью, если вы&nbsp;вошли в&nbsp;свою учетную запись.</p>' +
							'<h4>4. Кто имеет доступ к вашим личным данным?</h4>' +
							'<p>Данные, передаваемые третьим сторонам, используются только для предоставления вам упомянутых выше услуг, инструмента анализа, чтобы собирать статистику для оптимизации нашего сайта и&nbsp;предоставления вам соответствующих материалов.</p>' +
							'<h4>5. Какова правовая основа для обработки ваших личных данных?</h4>' +
							'<p>Мы&nbsp;свяжем ваши файлы cookie только с&nbsp;вашими личными данными, если вы&nbsp;вошли в&nbsp;учетную запись Agapium. Если вы&nbsp;вошли в&nbsp;свою учетную запись, юридическое основание основывается на&nbsp;наших законных интересах.</p>' +
							'<h4>6. Как долго мы сохраняем ваши данные?</h4>' +
							'<p>Cookie-файл храниться исключительно на&nbsp;вашем устройстве и&nbsp;Вы&nbsp;можете легко удалить cookie-файлы с&nbsp;вашего компьютера или мобильного устройства, используя ваш браузер. Инструкции по&nbsp;обработке и&nbsp;удалению <nobr>cookie-файлов</nobr> см.&nbsp;В&nbsp;разделе &laquo;Справка&raquo; в&nbsp;вашем браузере. Вы&nbsp;можете отключить <nobr>Cookie-файлы</nobr> или получать уведомление каждый раз, когда новый <nobr>Cookie-файл</nobr> отправляется на&nbsp;ваш компьютер или мобильное устройство. Обратите внимание: если вы&nbsp;решите отключить файлы cookie, вы&nbsp;не&nbsp;сможете воспользоваться всеми нашими функциями.</p>' +
							'<p>Мы&nbsp;также используем рекламные функции Google Analytics. Подробнее об&nbsp;этом и&nbsp;о&nbsp;том, как вы&nbsp;можете отказаться здесь. Просмотрите наше полное уведомление о&nbsp;конфиденциальности здесь.</p>',
					},
				],
			},
		},
		{
			type: 'block-still-have-questions',
			content: {
				textTitle: 'Остались вопросы',
				contactLinks: [
					{
						textTitle: 'Телефона интернет-магазина',
						links: [
							{
								name: '+7 (800) 320-72-65',
								url: '+78003207265',
							},
						],
					},
					{
						textTitle: 'По вопросам клиентского сервиса',
						links: [
							{
								name: 'cs@agapium.com',
								url: 'cs@agapium.com',
							},
						],
					},
					{
						textTitle: 'Связаться с нами в социальных сетях',
						links: [
							{
								name: 'Вконтакте',
								url: 'https://vk.com',
							},
							{
								name: 'Telergram',
								url: 'https://tg.com',
							},
						],
					},
				],
			},
		},
	],
};
