import css from './Heading.module.scss';
import classnames from 'classnames';
import { TypographyTagnameType, TypographyTagType } from '@shared/Typography/Typography';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import Link from 'next/link';
import { Container, Typography as Typo } from '@shared';

interface HeadingProps extends HTMLAttributes<HTMLDivElement> {
	as?: TypographyTagnameType;
	title?: string;
	url?: string;
	tag?: TypographyTagType;
	align?: 'left' | 'center' | 'right';
	inContainer?: boolean;
	classNameTitle?: string;
}

export const Heading: FC<PropsWithChildren<HeadingProps>> = React.memo(
	({
		title,
		url,
		as,
		tag = 'h1',
		align = 'center',
		className,
		classNameTitle,
		inContainer,
		children,
	}) => {
		const Wrapper = inContainer ? Container : DefaultWrapper;

		return (
			<hgroup className={classnames(className, css.heading, css[align])}>
				<Wrapper>
					{url && title && (
						<Typo as={as} type={tag} className={classnames(classNameTitle, css.title)}>
							<Link href={url}>
								<a
									className={css.titleLink}
									dangerouslySetInnerHTML={{ __html: [title].join('') }}
								/>
							</Link>
						</Typo>
					)}
					{!url && title && (
						<Typo as={as} type={tag} raw={true} className={classnames(classNameTitle, css.title)}>
							{title}
						</Typo>
					)}
					{children}
				</Wrapper>
			</hgroup>
		);
	}
);

const DefaultWrapper: FC<PropsWithChildren> = ({ children = [] }) => {
	return <>{children}</>;
};
