import { PageData } from '@api/hooks/types';

export const personal: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Личная информация | Личный кабинет',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-account-personal',
			content: {
				sectionsNav: [
					{
						name: 'Личная информация',
						url: '/mocks/account',
					},
					{
						name: 'Заказы',
						url: '/mocks/account/orders',
					},
					{
						name: 'Понравившиеся',
						url: '/mocks/account/wishlist',
					},
					{
						name: 'Мои адреса',
						url: '/mocks/account/addresses',
					},
				],
				logOut: {
					name: 'Выйти',
				},
				form: {
					textButton: 'Сохранить изменения',
					action: '/mocks/form/success',
					method: 'post',
					providedData: {
						// Любые данные вида [key: string]: string
						// Передадутся вместе с данными формы
						foo: 'bar',
					},
					items: [
						{
							type: 'text',
							name: 'surname',
							label: 'Фамилия',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'text',
							name: 'name',
							label: 'Имя',
							required: true,
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'text',
							name: 'birthday',
							label: 'Дата рождения',
							required: true,
							mask: '00.00.0000',
							pattern: '^(\\d{2})\\.(\\d{2})\\.(\\d{4})$',
							validation: {
								required: 'Заполните это поле',
							},
						},
						{
							type: 'email',
							name: 'email',
							label: 'Электронная почта',
							required: true,
							pattern: '[0-9a-z_.-]+@[0-9a-z_]+\\.[a-z]{2,5}',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный адрес email',
							},
						},
						{
							type: 'tel',
							name: 'phone',
							label: 'Номер телефона',
							value: '+79114000000',
							required: true,
							mask: '+0 000 0000000',
							pattern: '^(\\+\\d)\\s\\d{3}\\s\\d{7}$',
							validation: {
								required: 'Заполните это поле',
								pattern: 'Некорректный номер телефона',
							},
						},
					],
				},
			},
		},
	],
};
