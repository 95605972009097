import { PageData } from '@api/hooks/types';

export const payment: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Оплата и доставка | Клиентский сервис',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		/*
		 * Вариант с выносом заголовка и навигации в отдельный блок
		 */
		{
			type: 'block-text-page-header',
			content: {
				textTitle: 'Клиентский сервис',
				sectionsNav: [
					{
						name: 'Оплата и доставка',
						url: '/client-service/payment-delivery',
					},
					{
						name: 'Возврат',
						url: '/client-service/refund',
					},
					{
						name: 'FAQ',
						url: '/client-service/faq',
					},
				],
			},
		},
		{
			type: 'block-text-page',
			content: {
				/*
				 * Вариант с заголовком и навигацией внутри блока контента
				 */
				// textTitle: 'Клиентский сервис',
				// sectionsNav: [
				// 	{
				// 		name: 'Оплата и доставка',
				// 		url: '/client-service/payment-delivery',
				// 	},
				// 	{
				// 		name: 'Возврат',
				// 		url: '/client-service/refund',
				// 	},
				// 	{
				// 		name: 'FAQ',
				// 		url: '/client-service/faq',
				// 	},
				// ],
				textMain: [
					{
						type: 'h4',
						content: 'Доставка курьером',
					},
					{
						type: 'p',
						content:
							'Стоимость: 490 &#8381;<br />Сроки: до&nbsp;10 рабочих дней<br />Способ оплаты: Наличными, картой онлайн или банковской картой при получении',
					},
					{
						type: 'textBox',
						content: [
							{
								type: 'p',
								content: 'Подробности:',
							},
							{
								type: 'ul',
								/*
								 * Можно разбить по примитивам
								 */
								content: [
									{
										type: 'li',
										content:
											'Если в&nbsp;вашей корзине больше 10 вещей, заказ можно оплатить только онлайн',
									},
									{
										type: 'li',
										content:
											'Чтобы получить предоплаченный заказ, предъявите курьеру паспорт и&nbsp;распишитесь',
									},
									{
										type: 'li',
										content:
											'Если вы&nbsp;отказываетесь от&nbsp;заказа, стоимость доставки не&nbsp;возвращается',
									},
									{
										type: 'li',
										content:
											'Если вы&nbsp;отказались от&nbsp;заказа, промокоды и&nbsp;бонусные баллы не&nbsp;восстанавливаются',
									},
								],
							},
						],
					},
					{
						type: 'h4',
						content: 'Самовывоз из пункта выдачи Boxberry',
					},
					{
						type: 'p',
						content:
							'Стоимость: 190 &#8381;<br />Сроки: 2&ndash;10 дней<br />Способ оплаты: Картой онлайн',
					},
					{
						type: 'textBox',
						content: [
							{
								type: 'p',
								content: 'Подробности:',
							},
							{
								type: 'ul',
								/*
								 * А можно передать как html
								 */
								content:
									'<li>Если в&nbsp;вашей корзине больше 10 вещей, заказ можно оплатить только онлайн</li>' +
									'<li>Чтобы получить предоплаченный заказ, предъявите курьеру паспорт и&nbsp;распишитесь</li>' +
									'<li>Если вы&nbsp;отказываетесь от&nbsp;заказа, стоимость доставки не&nbsp;возвращается</li>' +
									'<li>Если вы&nbsp;отказались от&nbsp;заказа, промокоды и&nbsp;бонусные баллы не&nbsp;восстанавливаются</li>',
							},
						],
					},

					{
						type: 'h4',
						content: 'Экспресс-доставка',
					},
					{
						type: 'p',
						content: 'Стоимость: 500 &#8381;<br />Сроки: 1 день<br />Способ оплаты: Картой онлайн',
					},
					{
						type: 'textBox',
						content: [
							{
								type: 'p',
								content: 'Подробности:',
							},
							{
								type: 'ul',
								content:
									'<li>Если в&nbsp;вашей корзине больше 10 вещей, заказ можно оплатить только онлайн</li>' +
									'<li>При оформлении заказа в праздничные дни, доставка осуществдяется в первый рабочий день недели</li>' +
									'<li>Заказ должен быть совершён до 15:00</li>' +
									'<li>Заранее уточним у вас удобное время доставки</li>' +
									'<li>После отправки заказа пришлем смс с возможностью отследить местонахождение курьера</li>',
							},
						],
					},

					{
						type: 'h4',
						content: 'Оплата',
					},
					{
						type: 'p',
						content:
							'На странице для ввода данных банковской карты потребуется ввести: номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard).<br />Все необходимые данные пропечатаны на самой карте. Трёхзначный код безопасности — это три цифры, находящиеся на обратной стороне карты.',
					},
					{
						type: 'p',
						content:
							'Услуга оплаты через интернет осуществляется в соответствии с Правилами международных платежных систем Visa, MasterCard и Платежной системы МИР на принципах соблюдения конфиденциальности и безопасности совершения платежа. Для этого используются современные методы проверки, шифрования и передачи данных по закрытым каналам связи.',
					},
					{
						type: 'p',
						content:
							'Далее вы будете перенаправлены на страницу вашего банка для ввода кода безопасности, который придет вам в СМС. Если код безопасности вам не пришел, то следует обратиться в банк, выдавший Вам карту.',
					},
					{
						type: 'textBox',
						content: [
							{
								type: 'p',
								content: 'Подробности:',
							},
							{
								type: 'ul',
								content:
									'<li>Если в&nbsp;вашей корзине больше 10 вещей, заказ можно оплатить только онлайн</li>' +
									'<li>При оформлении заказа в праздничные дни, доставка осуществдяется в первый рабочий день недели</li>' +
									'<li>Заказ должен быть совершён до 15:00</li>' +
									'<li>Заранее уточним у вас удобное время доставки</li>' +
									'<li>После отправки заказа пришлем смс с возможностью отследить местонахождение курьера</li>',
							},
						],
					},
					{
						type: 'fileLinkList',
						content: [
							{
								type: 'fileLink',
								content: {
									url: '/',
									name: 'Заявление на возврат',
								},
							},
							{
								type: 'fileLink',
								content: {
									url: '/',
									name: 'Заявление на возврат',
								},
							},
						],
					},

					/*
					 * Любой кусок html-кода можно вставить так
					 *
					{
						type: 'html',
						content:
							'<h4>Доставка курьером</h4>' +
							'<p>Стоимость: 490 &#8381;<br />Сроки: до&nbsp;10 рабочих дней<br />Способ оплаты: Наличными, картой онлайн или банковской картой при получении</p>' +
							'<div>' +
							'Подробности:' +
							'<ul><li>Если в&nbsp;вашей корзине больше 10 вещей, заказ можно оплатить только онлайн</li><li>Чтобы получить предоплаченный заказ, предъявите курьеру паспорт и&nbsp;распишитесь</li><li>Если вы&nbsp;отказываетесь от&nbsp;заказа, стоимость доставки не&nbsp;возвращается</li><li>Если вы&nbsp;отказались от&nbsp;заказа, промокоды и&nbsp;бонусные баллы не&nbsp;восстанавливаются</li></ul>' +
							'</div>' +
							'<h4>Самовывоз из&nbsp;пункта выдачи Boxberry</h4>' +
							'<p>Стоимость: 190 &#8381;<br />Сроки: 2&ndash;10 дней<br />Способ оплаты: Картой онлайн</p>' +
							'<p>Подробности:</p>' +
							'<ul><li>Если в&nbsp;вашей корзине больше 10 вещей, заказ можно оплатить только онлайн</li><li>Найти пункт выдачи в&nbsp;своем городе и&nbsp;уточнить сроки доставки вы&nbsp;можете на&nbsp;сайте Boxberry</li><li>Если вы&nbsp;отказываетесь от&nbsp;заказа, стоимость доставки не&nbsp;возвращается</li><li>Если вы&nbsp;отказались от&nbsp;заказа, промокоды и&nbsp;бонусные баллы не&nbsp;восстанавливаются</li></ul>' +
							'<h4>Экспресс-доставка</h4>' +
							'<p>Стоимость: 500 &#8381;<br />Сроки: 1 день<br />Способ оплаты: Картой онлайн</p>' +
							'<p>Подробности:</p>' +
							'<ul><li>Если в&nbsp;вашей корзине больше 10 вещей, заказ можно оплатить только онлайн</li><li>При оформлении заказа в&nbsp;праздничные дни, доставка осуществдяется в&nbsp;первый рабочий день недели</li><li>Заказ должен быть совершён до&nbsp;15:00</li><li>Заранее уточним у&nbsp;вас удобное время доставки</li><li>После отправки заказа пришлем смс с&nbsp;возможностью отследить местонахождение курьера</li></ul>' +
							'<h4>Оплата</h4>' +
							'<p>На&nbsp;странице для ввода данных банковской карты потребуется ввести: номер карты, имя владельца карты, срок действия карты, трёхзначный код безопасности (CVV2 для VISA, CVC2 для MasterCard).<br />Все необходимые данные пропечатаны на&nbsp;самой карте. Трёхзначный код безопасности&nbsp;&mdash; это три цифры, находящиеся на&nbsp;обратной стороне карты.</p>' +
							'<p>Услуга оплаты через интернет осуществляется в&nbsp;соответствии с&nbsp;Правилами международных платежных систем Visa, MasterCard и&nbsp;Платежной системы МИР на&nbsp;принципах соблюдения конфиденциальности и&nbsp;безопасности совершения платежа. Для этого используются современные методы проверки, шифрования и&nbsp;передачи данных по&nbsp;закрытым каналам связи.</p>' +
							'<p>Далее вы&nbsp;будете перенаправлены на&nbsp;страницу вашего банка для ввода кода безопасности, который придет вам в&nbsp;СМС. Если код безопасности вам не&nbsp;пришел, то&nbsp;следует обратиться в&nbsp;банк, выдавший Вам карту.</p>' +
							'<p>Подробности:</p>' +
							'<ul><li>Если в&nbsp;вашей корзине больше 10 вещей, заказ можно оплатить только онлайн</li><li>При оформлении заказа в&nbsp;праздничные дни, доставка осуществдяется в&nbsp;первый рабочий день недели</li><li>Заказ должен быть совершён до&nbsp;15:00</li><li>Заранее уточним у&nbsp;вас удобное время доставки</li><li>После отправки заказа пришлем смс с&nbsp;возможностью отследить местонахождение курьера</li></ul>',
					},
					*/
				],
			},
		},
		{
			type: 'block-still-have-questions',
			content: {
				textTitle: 'Остались вопросы',
				contactLinks: [
					{
						textTitle: 'Телефона интернет-магазина',
						links: [
							{
								name: '+7 (800) 320-72-65',
								url: '+78003207265',
							},
						],
					},
					{
						textTitle: 'По вопросам клиентского сервиса',
						links: [
							{
								name: 'cs@agapium.com',
								url: 'cs@agapium.com',
							},
						],
					},
					{
						textTitle: 'Связаться с нами в социальных сетях',
						links: [
							{
								name: 'Вконтакте',
								url: 'https://vk.com',
							},
							{
								name: 'Telergram',
								url: 'https://tg.com',
							},
						],
					},
				],
			},
		},
	],
};
