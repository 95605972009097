import css from './FileLinkList.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { TypographyFlow, TypographyTagnameType } from '@shared';

interface FileLinkListProps extends HTMLAttributes<HTMLElement> {
	as?: TypographyTagnameType;
	content?: Array<TextContentNode>;
}

export const FileLinkList: FC<FileLinkListProps> = React.memo(
	({ as: Tag = 'div', content, children }) => {
		return (
			<Tag className={classnames(css.component)}>
				{content && <TypographyFlow content={content} />}
				{children}
			</Tag>
		);
	}
);
