import css from './ProductCard.module.scss';
import classnames from 'classnames';
import type { HTMLElementProps } from '@api/hooks/types';
import type { CatalogProductsElements, OffersCatalogProductsElements } from '@model/models';
import React, { Dispatch, FC, MouseEventHandler, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Currency, Typography, ColorDot, Button, Icon, TypographySizeType } from '@shared';
import { PopUp, PopUpBody, PopUpHead } from '@shared/PopUp';
import Link from 'next/link';
import Image from 'next/image';
import { fetcher, getPageData, usePageData } from '@api';
import { cleanupUrl, withLocale } from '@utils';
import { usePrice } from '@hooks';
import { Container } from '@shared'
import { updateCart, useCartData } from '@src/components/Cart/hooks';
import { useRouter } from 'next/router';

interface ProductCardProps extends HTMLElementProps, CatalogProductsElements {
	index?: number;
	inWishlist?: boolean;
	updateWishlist?: string;
	textSize?: TypographySizeType;
	allowAddToCart?: boolean;
	recieveStyle?: string;
	setItems?: Dispatch<SetStateAction<CatalogProductsElements[] | null>>
}



export const ProductCard: FC<ProductCardProps> = React.memo(
	({
		name,
		images,
		offers,
		differentColors,
		detailPageUrl = '/',
		inWishlist,
		updateWishlist,
		textSize = 'md',
		allowAddToCart,
		className,
		id,
		recieveStyle,
		index,
		setItems,
		color,
		colorText
	}) => {
		const offer = (offers && offers[0]) || {};
        const price = usePrice(offer?.price);
        const [addedToWishlist, setAddedToWishlist] = useState(inWishlist);
        const [buttonVisible, setButtonVisible] = useState(false);
        const {asPath} = useRouter();
        const handleWishList: MouseEventHandler = useCallback(
            (e) => {
                e.preventDefault();
                if (!updateWishlist) return;

                fetcher<FormResponse>({
                    url: updateWishlist,
                    method: 'put',
                }).then((response) => {
                    setAddedToWishlist((prev) => !prev);
                    setItems && setItems(prev => prev && prev.filter(item => item.id !== id))
                });
            },
            [asPath, setItems, updateWishlist]
        );

        const [sizePopupState, setSizePopupState] = useState(false);

        const handleAddToCartClick: MouseEventHandler = useCallback((e) => {
            e.preventDefault();
            setSizePopupState(true);
        }, []);


        const { data: cart, revalidate } = useCartData();
        const addToCart = ((offerId: number, closePopup: Dispatch<SetStateAction<boolean>>) => {
            updateCart({
                productId: id,
                offerId,
                quantity: 1
            }).then(() => closePopup(false)).finally(() => revalidate && revalidate())
        })

		return (
			<Box
				className={classnames(css.card, className)}
				itemType="https://schema.org/Product"
				itemProp="itemListElement"
				itemScope
				onMouseEnter={() => setButtonVisible(true)}
				onMouseLeave={() => setButtonVisible(false)}>
				<button className={css.addToWishListBtn} onClick={handleWishList}>
					<Icon
						id="fav"
						className={classnames(css.wishListIcon, css.addToWishListBtn, {
							[css.wishListIconVisible]: buttonVisible,
							[css.wishListIconDisabled]: addedToWishlist || asPath === '/account/wishlist',
						})}
					/>

					<Icon
						id="heart"
						className={classnames(css.wishListIcon, {
							[css.wishListIconDisabled]: !addedToWishlist,
							[css.wishListIconVisible]: addedToWishlist,
						})}
					/>
				</button>
				<Link href={cleanupUrl(detailPageUrl)}>
					<a className={css.link} aria-label="Жакет двубортый" />
				</Link>
				<Box className={classnames(css.figure, {
					[css[recieveStyle || '']]: recieveStyle
				})}>
					<ProductCardPicture images={images} />
					<ProductCardSizes offers={offers} />
				</Box>
				<Box className={css.info}>
					<Typography
						className={css.title}
						as="h2"
						size={textSize}
						title="Жакет двубортый"
						itemProp="name">
						{name}
					</Typography>

					{price && (
						<Typography
							className={css.price}
							as="div"
							size={textSize}
							itemType="http://schema.org/Offer"
							itemProp="offers"
							itemScope>
							<span itemProp="price">{price}</span> <Currency />
						</Typography>
					)}

					<ProductCardColors prodId={id} color={color} colorText={colorText} differentColors={differentColors} size={textSize} />
				</Box>
				{allowAddToCart && (
					<>
						<Button
							className={css.addToCartBtn}
							onClick={handleAddToCartClick}
							color="gray"
							size="sm">
							В корзину
						</Button>
						<ProductCardSizesPopup
							offers={offers}
							active={sizePopupState}
							setActive={setSizePopupState}
							addToCart={addToCart}
						/>
					</>
				)}
			</Box>
		);
	}
);

export type ProductCardSizesPopupProps = Pick<CatalogProductsElements, 'offers'> & {
	active: boolean;
	setActive: React.Dispatch<React.SetStateAction<boolean>>;
	textHide?: string;
	addToCart: (offerId: number, closePopup: Dispatch<SetStateAction<boolean>>) => void;
};

export const ProductCardSizesPopup: FC<ProductCardSizesPopupProps> = React.memo(
	({ active, setActive, textHide = 'Скрыть', offers = [], addToCart }) => {
		const [activeItem, setActiveItem] = useState(0);

		const onSizeClickHandler = useCallback((index: number, offerId: number) => {
			setActiveItem(index);
			addToCart(offerId, setActive);
		}, [addToCart, setActive])
		return (
			<PopUp
				active={active}
				closeHandler={() => setActive(false)}
				closeButtonText={textHide}
				boxWidth={484}>
				<PopUpHead />
				<PopUpBody className={css.container}>
					<Container>
						<>
							<Typography size="md" className={css.popupHeading}>
								Выберите размер
							</Typography>
							<Container className={css.sizesContainer}>
								{offers.map((item, i) => (
									<Typography
										onClick={() => onSizeClickHandler(i, item.id!)}
										size="sm"
										className={classnames(css.sizeInPopup, { [css.activeSize]: activeItem === i })}
										key={item.id && item.size ? item.id + item.size : i}>
										{item.size}
									</Typography>
								))}
							</Container>
						</>
					</Container>
				</PopUpBody>
			</PopUp>
		);
	}
);

export type ProductCardSizesProps = Pick<CatalogProductsElements, 'offers'>;


export const ProductCardSizes: FC<ProductCardSizesProps> = React.memo(({ offers = [] }) => {
	return (
		<div className={css.panel}>
			{offers?.map(({ id, size, isAvailable, restCount }) => {
				return (
					<div
						key={`offer-${id}-size-${size}`}
						className={classnames(css.size, { [css.sizeDisabled]: !restCount })}>
						{size}
					</div>
				);
			})}
		</div>
	);
});

export type ProductCardColorsProps = Pick<
	CatalogProductsElements,
	'differentColors' | 'color' | 'colorText'
> & {
	size?: TypographySizeType;
	prodId: string | number
};

const needsBorder = (color: string | undefined) => {
	if (!!color) {
		return ['#FCFCF8', '#FFFAF6', '#FFFFFF', '#FBF4EA', '#A19788', '#FFFCEF', '#C3A27F', '#F7F3ED'].find(item => item === color.toUpperCase())
	}
	return
}

export const ProductCardColors: FC<ProductCardColorsProps> = React.memo(
	({ size = 'md', differentColors = [], color, colorText, prodId }) => {
		return differentColors ? (
			<div className={classnames(css.colors, css[size])}>
				{color && colorText && (
					<ColorDot color={color} title={colorText} className={classnames(css.colorsDot, {
						[css.withBorder]: needsBorder(color)
					})} />
				)}
				{differentColors.map(({ color, colorText }, i) => (
					<ColorDot
						color={color}
						title={colorText}
						className={classnames(css.colorsDot, {
							[css.withBorder]: needsBorder(color)
						})}
						key={`offer-color-${color}-${prodId}-${i}`}
					/>
				))}
			</div>
		) : null;
	}
);

export type ProductCardPictureProps = Pick<CatalogProductsElements, 'images'>;

export const ProductCardPicture: FC<ProductCardPictureProps> = React.memo(({ images }) => {
	return (
		<picture className={css.pic}>
			{images && images[0]?.url ? (
				<Image
					src={images[0].url}
					alt={images[0]?.alt || ''}
					layout="fill"
					objectFit="cover"
					quality={100}
					priority={true}
					sizes="(max-width: 1023px) 50vw, 30vw"
					itemProp="image"
				/>
			) : null}
		</picture>
	);
});
