import css from './FeaturesStack.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useState } from 'react';
import { Section } from '@shared';
import { useViewport } from '@src/context';
import { FeaturesStackMobile } from './FeaturesStackMobile/FeaturesStackMobile';
import { FeaturesStackDesktop } from './FeaturesStackDesktop/FeaturesStackDesktop';

export interface FeaturesStackProps extends HTMLAttributes<HTMLElement>, FeaturesStackContent {}

type FeaturesStackContent = {
	cards: Array<FeaturesStackContentCard>
}

export type FeaturesStackContentCard = {
	leftImage: string,
	heading: string,
	text: string,
	smallImage: string,
}

/*
 * ToDo: "стопка" секций "Мы ценим качество", "Наша команда" и т. п.,
 *  то есть секции с картинкой-обложкой слева, заголовком, текстом, картинкой
 *
 * Механика работы по скроллу как разделах каталога на главной (для десктопа):
 * у каждой секции обертка (трек), которая по высоте больше экрана (сама секция в экран),
 * секция прилеплена к ней через position: sticky,
 * наложения - через отрицательные отступы самих треков
 *
 * В мобильной похожим образом, но сама секция должна выползать по горизонтали,
 * поэтому нужно дополнительно анимировать, условно: пересчитывать скролл в смещение по горизонтали
 * см. примеры с хуком useScrollSeek
 */

export const FeaturesStack: FC<FeaturesStackProps> = React.memo((props) => {
	const { isTablet } = useViewport();
	return isTablet ? <FeaturesStackMobile {...props} /> : <FeaturesStackDesktop {...props} />;
});
