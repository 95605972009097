import { CSSObjectWithLabel, GroupBase, MenuProps } from 'react-select';

export const asyncSelectStyles = {
	control: (provided: CSSObjectWithLabel, state: any) => {
		const isFocused = !!state?.isFocused;
		return {
			...provided,
			display: 'flex' as const,
			alignItems: 'center' as const,
			position: 'relative' as const,
			minHeight: '38px',
			width: '100%',
			font: '300 var(--text-md) var(--font)',
			color: 'var(--text)',
			background: 'transparent',
			boxShadow: 'none',
			borderColor: isFocused ? 'var(--text)' : 'var(--gray)',
			borderWidth: '0 0 1px 0',
			borderRadius: '0',
			overflow: 'visible',
			transition: 'color var(--trans),\n\t\tborder-color var(--trans)',
			cursor: 'pointer',

			'&:hover': {
				borderColor: 'var(--text)',
			},
		};
	},

	valueContainer: (provided: CSSObjectWithLabel) => ({
		...provided,
		padding: 0,
	}),

	placeholder: (provided: CSSObjectWithLabel) => ({
		...provided,
		margin: 0,
	}),

	input: (provided: CSSObjectWithLabel) => ({
		...provided,
		margin: 0,
		padding: 0,
	}),

	singleValue: (provided: CSSObjectWithLabel) => ({
		...provided,
		margin: 0,
		padding: 0,
	}),

	menuPortal: (provided: CSSObjectWithLabel) => {
		return {
			...provided,
			zIndex: 35,
		};
	},

	menu: (provided: CSSObjectWithLabel) => {
		return {
			...provided,
			zIndex: 35,
			marginTop: '4px',
			marginBottom: '4px',
			padding: 0,
			textAlign: 'left' as const,
			color: 'var(--text)',
			background: 'var(--bg)',
			border: '1px solid var(--dark)',
			borderRadius: '0',
			boxShadow: 'none',
			boxSizing: 'border-box' as const,
			animation: 'globalFadeIn 600ms var(--ease) forwards',
		};
	},

	menuList: (provided: CSSObjectWithLabel) => {
		return {
			...provided,
			padding: '12px 0',
			maxHeight: 306,
			backfaceVisibility: 'hidden' as const,
		};
	},

	option: (provided: CSSObjectWithLabel, state: any) => {
		const isSelected = !!state?.isSelected;
		const isDisabled = !!state?.isDisabled;
		const isFocused = !!state?.isFocused;

		return {
			...provided,

			display: 'block',
			alignItems: 'center',
			padding: '4px 16px',
			width: '100%',
			textAlign: 'inherit' as const,
			font: '300 var(--text-sm) var(--font)',
			color: isDisabled
				? 'var(--gray)'
				: isSelected || isFocused
				? 'var(--accent)'
				: ('inherit' as const),
			pointerEvents: isDisabled || isSelected ? ('none' as const) : ('auto' as const),
			background: 'transparent !important' as const,
			transition: 'color var(--trans)',
			cursor: 'pointer',

			'&:hover': {
				color: 'var(--accent)',
			},
		};
	},

	noOptionsMessage: (provided: CSSObjectWithLabel) => ({
		...provided,
		font: '300 var(--text-sm) var(--font)',
		textAlign: 'inherit' as const,
	}),

	loadingMessage: (provided: CSSObjectWithLabel) => ({
		...provided,
		font: '300 var(--text-sm) var(--font)',
		textAlign: 'inherit' as const,
	}),
};
