import css from '../ProductGallery.module.scss';
import classnames from 'classnames';
import type { ProductGalleryProps } from '../ProductGallery';
import React, { FC, useRef, useState } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { ProductGalleryFull } from './ProductGalleryRegular';

export const ProductGalleryMobile: FC<ProductGalleryProps> = ({ images = [], className }) => {
	const splide = useRef<Splide | null>(null);
	const [progress, setProgress] = useState<number>(0);

	const handleSplideUpdate = (splide: any) => {
		if (splide) {
			const end = splide.Components.Controller.getEnd() + 1; // +1, чтобы показывать прогресс первого слайда
			const current = splide.index + 1; // +1, чтобы показывать прогресс первого слайда

			setProgress((100 * current) / end);
		}
	};

	return (
		<aside className={classnames(className, css.gallery)}>
			<Splide
				ref={splide}
				onMove={handleSplideUpdate}
				onMounted={handleSplideUpdate}
				options={{
					arrows: false,
					pagination: false,
					drag: images.length > 1,
					speed: 600,
				}}
				className={css.slider}>
				{images.map((item, i) => (
					<SplideSlide key={`slide-${i}`}>
						<ProductGalleryFull {...item} />
					</SplideSlide>
				))}
			</Splide>
			{images.length > 1 && (
				<div className={css.progress}>
					<div className={css.progressBar} style={{ width: `${progress}%` }} />
				</div>
			)}
		</aside>
	);
};
