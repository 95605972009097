import css from './StillHaveQuestions.module.scss';
import classnames from 'classnames';
import { BlockStillHaveQuestions } from '@api/mock/types';
import React, { FC, HTMLAttributes, useMemo } from 'react';
import { Container, Box, Section, Typography, Icon } from '@shared';
import { LinksGroup } from '@shared/LinksGroup/LinksGroup';

interface StillHaveQuestionsProps extends HTMLAttributes<HTMLElement>, BlockStillHaveQuestions {}

export const StillHaveQuestions: FC<StillHaveQuestionsProps> = React.memo(
	({ textTitle, contactLinks }) => {
		const titleLine = useMemo(
			() => (
				<Box className={css.blockHeadingTitleTicker}>
					<span>
						{textTitle} <Icon id="bullet" /> {textTitle} <Icon id="bullet" />
					</span>
					<span>
						{textTitle} <Icon id="bullet" /> {textTitle} <Icon id="bullet" />
					</span>
				</Box>
			),
			[textTitle]
		);
		return (
			<Section className={classnames(css.component)}>
				<Box className={css.blockHeading}>
					<Typography className={css.blockHeadingTitle} type="h1">
						{titleLine}
					</Typography>
					<Typography className={css.blockHeadingTitle} type="h1">
						{titleLine}
					</Typography>
				</Box>
				<Box className={css.linksBlock}>
					<Container className={css.linksBlockFlow}>
						{contactLinks?.map((item, i) => (
							<LinksGroup key={`contact-links-group-${i}`} {...item} />
						))}
					</Container>
				</Box>
			</Section>
		);
	}
);
