import css from './Input.module.scss';
import classnames from 'classnames';
import React, {
	ComponentPropsWithoutRef,
	useState,
	useCallback,
	useRef,
	ChangeEvent,
	FocusEvent,
} from 'react';

interface TextAreaInterface extends ComponentPropsWithoutRef<'textarea'> {
	label?: string;
	labelAsPlaceholder?: boolean;
	hasError?: boolean;
	size?: 'sm' | 'md' | 'lg';
	autoGrow?: boolean;
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaInterface>(
	(
		{
			label,
			labelAsPlaceholder = true,
			hasError,
			className,
			value,
			size = 'md',
			autoGrow = true,
			...props
		}: TextAreaInterface,
		forwardedRef
	) => {
		const [inputValue, setInputValue] = useState(value);
		const [placeholderFocused, setPlaceholderFocused] = useState(false);
		const [transitionClass, setTransitionClass] = useState(false);

		const handleFocus = useCallback(
			(e: FocusEvent<HTMLTextAreaElement>) => {
				setTransitionClass(true);
				setPlaceholderFocused(true);
				props?.onFocus && props.onFocus(e);
			},
			[props]
		);

		const handleBlur = useCallback(
			(e: FocusEvent<HTMLTextAreaElement>) => {
				setPlaceholderFocused(!!e.target.value.length);
				props?.onBlur && props.onBlur(e);
			},
			[props]
		);

		const handleChange = useCallback(
			(e: ChangeEvent<HTMLTextAreaElement>) => {
				setInputValue(e.target.value || '');
				setPlaceholderFocused(!!e.target.value.length || e.target === document.activeElement);
				props?.onChange && props.onChange(e);
			},
			[props]
		);

		const refCallback = useCallback(
			(node: HTMLTextAreaElement) => {
				if (!node) return;

				if (typeof forwardedRef === 'function') {
					forwardedRef(node);
				} else if (forwardedRef !== null) {
					forwardedRef.current = node;
				}

				if (labelAsPlaceholder) {
					setPlaceholderFocused(!!node.value.length);
				}
			},
			[forwardedRef, labelAsPlaceholder]
		);

		return (
			<label className={classnames(css.input, { [css.hasError]: hasError, 'has-error': hasError })}>
				{label && (
					<div
						className={classnames(css.input__label, {
							[css.isPlaceholder]: labelAsPlaceholder,
							[css.isPlaceholderFocused]: labelAsPlaceholder && placeholderFocused,
							[css.hasTransition]: transitionClass,
						})}>
						{label}
					</div>
				)}
				<div
					className={classnames(css.input__field, css[size], {
						[css.isAutoGrow]: autoGrow,
						[css.disabled]: props.disabled,
					})}
					data-replicated={autoGrow ? inputValue : undefined}>
					<textarea
						{...props}
						ref={refCallback}
						className={classnames(className, css[size])}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onChange={handleChange}
						value={value}
					/>
				</div>
			</label>
		);
	}
);
