import css from '../ProductGallery.module.scss';
import classnames from 'classnames';
import type { ProductGalleryProps } from '../ProductGallery';
import type { ImageType } from '@model/imageType';
import React, { FC, useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Button } from '@shared';
import { useViewport } from '@src/context';

export const ProductGalleryRegular: FC<ProductGalleryProps> = ({ images = [], className }) => {
	const splide = useRef<Splide | null>(null);
	const [currentSlide, setCurrentSlide] = useState(0);
	const [galleryRef, setGalleryRef] = useState<HTMLDivElement | null>(null)
	const { vh, vw } = useViewport()
	useEffect(() => {
		const ratio = 1.6 / (vw / vh)
		galleryRef?.style.setProperty('--ratio', `${ratio}`)

	}, [
		vh, vw, galleryRef
	])

	const handleSplideUpdate = (splide: any) => {
		if (splide) {
			const current = splide.Components.Controller.getIndex();
			setCurrentSlide(current);
		}
	};

	const handleThumbClick = (to: number) => {
		const instance = splide?.current?.splide;
		if (instance) {
			instance.Components.Controller.go(to);
		}
	};

	return (
		<aside className={classnames(className, css.gallery)}>
			{images.length > 1 && (
				<div className={css.thumbs}>
					<div className={css.thumbsSticky}>
						{images.map((item, i) => (
							<Button
								key={`thumbs-${i}`}
								onClick={() => handleThumbClick(i)}
								className={classnames(css.thumbBtn, { [css.isActive]: currentSlide === i })}>
								<ProductGalleryThumb {...item} />
							</Button>
						))}
					</div>
				</div>
			)}
			<div className={css.mainGallery} ref={setGalleryRef}>
				<div className={css.stickyGallery}>
					<Splide
						ref={splide}
						onMove={handleSplideUpdate}
						onUpdated={handleSplideUpdate}
						onMounted={handleSplideUpdate}
						options={{
							direction: 'ttb',
							height: '100vh - var(--hdr-h)',
							arrows: false,
							pagination: false,
							releaseWheel: true,
							wheel: true,
							wheelMinThreshold: 10,
							wheelSleep: 1200,
							speed: 1200,
						}}>
						{images.map((item, i) => (
							<SplideSlide key={`slide-${i}`}>
								<ProductGalleryFull {...item} />
							</SplideSlide>
						))}
					</Splide>
				</div>
			</div>
		</aside>
	);
};

export const ProductGalleryThumb: FC<ImageType> = React.memo(({ url, alt }) => {
	return (
		<picture>
			{url ? (
				<Image
					src={url}
					alt={alt || ''}
					layout="fill"
					objectFit="cover"
					quality={100}
					priority={true}
					sizes="8vw"
					itemProp="image"
				/>
			) : null}
		</picture>
	);
});

export const ProductGalleryFull: FC<ImageType> = React.memo(({ url, alt }) => {
	return (
		<picture>
			{url ? (
				<Image
					src={url}
					alt={alt || ''}
					layout="fill"
					objectFit="cover"
					quality={100}
					priority={true}
					sizes="(max-width: 1023px) 100vw, 50vw"
					itemProp="image"
				/>
			) : null}
		</picture>
	);
});
