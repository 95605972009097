import { Performance as PerformanceClass, MoveClass } from './utils.performance';
import { ScrollClass } from '@utils/utils.performance/scroll';
export { MoveEvent } from './utils.performance';

export const Performance = new PerformanceClass();
export const Scroll = new ScrollClass();
export const Move = new MoveClass(Performance, 0.125);
export const MoveSmooth = new MoveClass(Performance, 0.025);

export * from './utils.array';
export * from './utils.string';
export * from './utils.clipboard';
export * from './utils.colors';
export * from './utils.cookie';
export * from './utils.obj';
export * from './utils.number';
export * from './utils.dom';
export * from './utils.scroll';
export * from './utils.api';
export * from './utils.test';
export * from './utils.viewport';
export * from './utils.functions';
export * from './utils.date';

export * from './const';
