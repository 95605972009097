import React, { FC, HTMLAttributes, useMemo } from 'react';
import { IconId } from '@layout/components/SvgSprite/SvgSprite';
import { OAuthIconId } from '@layout/components/SvgSprite/SvgSpriteOAuth';

interface Props extends HTMLAttributes<SVGElement> {
	id: IconId | OAuthIconId;
	width?: number;
	height?: number;
}

export const Icon: FC<Props> = React.memo(({ id, width, height, className }) => {
	const css = useMemo(() => ({ width, height }), [width, height]);
	return (
		<svg className={className} style={css} aria-hidden="true">
			<use xlinkHref={`#${id}`} />
		</svg>
	);
});
