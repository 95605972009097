import css from './Search.module.scss';
import classnames from 'classnames';
import { CatalogSearchProductsContent } from '@api/mock/types';
import React, { FC, HTMLAttributes } from 'react';
import { Container, Section, Typography } from '@shared';
import { CatalogContextProvider } from '@components/Catalog/context/Catalog.context';
import { SearchFeed } from '@components/Search/components/SearchFeed/SearchFeed';

export interface AccountWishlistProps
	extends HTMLAttributes<HTMLElement>,
		CatalogSearchProductsContent {}

export const Search: FC<AccountWishlistProps> = React.memo(
	({ textTitle, textFound, empty, products = [], navigation }) => {
		return (
			<Section className={classnames(css.component)}>
				<CatalogContextProvider view="sm" navigation={navigation}>
					<Container>
						<Typography className={css.heading} as="hgroup" size="sm" color="gray">
							<Typography className={css.title} as="h1" type="h2" raw={true}>
								{textTitle}
							</Typography>
							<p>{textFound}</p>
						</Typography>
						<SearchFeed navigation={navigation} staticData={products} emptyBanner={empty} />
					</Container>
				</CatalogContextProvider>
			</Section>
		);
	}
);
