import css from './FigureText.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useEffect, useMemo, useState } from 'react';
import { Container, Icon, Section, Typography } from '@shared';
import { splitByLines } from '@utils';
import { useViewport } from '@src/context';
import { title } from 'process';

interface FigureTextProps extends HTMLAttributes<HTMLElement> {
	pre?: string;
	title?: string;
	quote?: string;
	text?: string;
	mobileQuote?: string;
	mobileTitle?: string;
	mobileText?: string;
}

export const FigureText: FC<FigureTextProps> = React.memo(
	({ pre, title, mobileTitle, quote, mobileQuote, text, mobileText }) => {
		const [titleText, setTitleText] = useState<string>('');
		const [texText, setTextText] = useState<string>('');
		const [quoteText, setQuoteText] = useState<string>('');
		const { isMob } = useViewport();
		useEffect(() => {
			if (isMob && mobileTitle) {
				setTitleText(mobileTitle)
			};
			if ((title && !isMob) || title && !mobileTitle) {
				setTitleText(title)
			}
			if (isMob && mobileText) {
				setTextText(mobileText)
			}
			if ((text && !isMob) || text && !mobileText) {
				setTextText(text)
			}

			if (isMob && mobileQuote) {
				setQuoteText(mobileQuote)
			}
			if ((quote && !isMob) || quote && !mobileQuote) {
				setQuoteText(quote)
			}
		} ,[title, mobileTitle, quote, mobileQuote, text, mobileText, isMob])
		return (
			<Section className={classnames(css.component)}>
				<Container className={css.container}>
					{pre && (
						<Typography className={css.pre} size="sm" weight="regular">
							<Icon id="bullet" />
							{pre}
						</Typography>
					)}
					{title && (
						<Typography className={css.title} type="h2" raw={true}>
							{titleText}
						</Typography>
					)}
					{quote && <FigureTextQuote quote={quoteText} />}
					{text && (
						<Typography className={css.text} size="sm" raw>
							{texText}
						</Typography>
					)}
				</Container>
			</Section>
		);
	}
);

export const FigureTextQuote: FC<Pick<FigureTextProps, 'quote'>> = React.memo(({ quote }) => {
	const [ref, setRef] = useState<HTMLDivElement | null>(null);
	const [text, setText] = useState<string | string[] | undefined>([]);

	useEffect(() => {
		// if (!ref) return;

		// const resizeObserver = new ResizeObserver(() => {
		// 	if (!quote || !ref) return [];
		// 	if (quote.split('<br />').length > 1) setText(quote.split('<br />'));
		// 	else setText(splitByLines(quote, ref));
		// });

		// resizeObserver.observe(ref);

		// return () => {
		// 	resizeObserver.unobserve(ref);
		// 	resizeObserver.disconnect();
		// };

		if (quote && quote.split('<br />').length > 1) setText(quote.split('<br />'));
		else quote && ref && setText(splitByLines(quote, ref));
	}, [ref, quote]);

	return quote ? (
		<div className={css.quote}>
			<Typography className={css.quoteText} type="h2">
				{Array.isArray(text)
					? text.map((line, i) => {
							return (
								<div key={`quote-${line.slice(0, 10)}-${i}}`}>
									{i === 0 && <Icon id="quote" />}
									{line}
									{i === 0 && <Icon id="quote" />}
								</div>
							);
					  })
					: text}
			</Typography>
			<div ref={setRef} className={classnames(css.quoteHelper, 'h2')} />
		</div>
	) : null;
});
