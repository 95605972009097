import { PageData } from '@api/hooks/types';

export const orders: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Заказы | Личный кабинет',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-account-orders',
			content: {
				sectionsNav: [
					{
						name: 'Личная информация',
						url: '/mocks/account',
					},
					{
						name: 'Заказы',
						url: '/mocks/account/orders',
					},
					{
						name: 'Понравившиеся',
						url: '/mocks/account/wishlist',
					},
					{
						name: 'Мои адреса',
						url: '/mocks/account/addresses',
					},
				],
				logOut: {
					name: 'Выйти',
				},
				empty: {
					title: 'Здесь пока пусто',
					text: 'Перейдите в магазин, чтобы выбрать необходимые модели',
					link: {
						url: '/catalog/sections',
						name: 'Перейти в магазин',
					},
				},
				/*
				 * По аналогии с каталогом:
				 * блок навгиации для пагинации и ревалидации данных
				 */
				navigation: {
					next: '/ru/mocks/account/orders?pageNumber=1&limit=10',
					self: '/ru/mocks/account/orders',
					countPerPage: 2,
					total: 2,
				},
				orders: [
					{
						id: '168905',
						textDate: 'Заказ от 8 апреля 2022',
						textNumber: '№168905',
						textDelivery: 'Адрес доставки',
						textTotalSum: 'Стоимость',
						textCancelBtn: 'Отменить',
						delivery: 'г. Санкт-Петербург, Тверская 41, кв.6',
						totalSum: {
							rub: {
								value: '12500.00',
							},
						},
						cancelUrl: '/',
						status: {
							type: 'foo',
							text: 'Принят',
						},
						products: [
							{
								id: 8,
								name: '\u041d\u043e\u0432\u043e\u0435 \u043f\u043b\u0430\u0442\u044c\u0435 \u043a\u0430\u043c\u0435\u0440\u043e\u043d',
								code: 'plate-test',
								quantity: 3,
								detailPageUrl: '/ru/catalog/product/plate-test',
								deleteProductUrl: '/basket/product/8/offer/7',
								inWishlist: true,
								offers: [
									{
										id: 7,
										size: 'XS',
										color: '#000',
										colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
										article: '2345901283',
										isAvailable: true,
										price: {
											rub: {
												value: '45000.00',
											},
										},
										images: [
											{
												url: '/temp/pic-04.jpg',
												alt: '',
											},
										],
										isSelected: true,
										restCount: '120',
									},
								],
							},
							{
								id: 12,
								name: '\u041f\u043b\u0430\u0442\u044c\u0435-\u0445\u043e\u043c\u0443\u0442',
								code: 'plate-khomut',
								quantity: 1,
								detailPageUrl: '/ru/catalog/product/plate-khomut',
								deleteProductUrl: '/basket/product/12/offer/10',
								inWishlist: true,
								offers: [
									{
										id: 10,
										size: 'XS',
										color: '#FFC0CB',
										colorText: '\u0420\u043e\u0437\u043e\u0432\u044b\u0439',
										article: '4234612346',
										isAvailable: true,
										price: {
											rub: {
												value: '10000.00',
											},
										},
										images: [
											{
												url: '/temp/pic-05.jpg',
												alt: '',
											},
										],
										isSelected: true,
										restCount: '345',
									},
								],
							},
						],
					},
					{
						id: '168826',
						textDate: 'Заказ от 8 апреля 2022',
						textNumber: '№168826',
						textDelivery: 'Адрес доставки',
						textTotalSum: 'Стоимость',
						textPaymentBtn: 'Оплатить',
						textCancelBtn: 'Отменить',
						delivery: 'г. Санкт-Петербург, Тверская 41, кв.6',
						totalSum: {
							rub: {
								value: '12500.00',
							},
						},
						paymentUrl: '/',
						cancelUrl: '/',
						status: {
							type: 'foo',
							text: 'Ожидается оплата',
						},
						products: [
							{
								id: 8,
								name: '\u041d\u043e\u0432\u043e\u0435 \u043f\u043b\u0430\u0442\u044c\u0435 \u043a\u0430\u043c\u0435\u0440\u043e\u043d',
								code: 'plate-test',
								quantity: 3,
								detailPageUrl: '/ru/catalog/product/plate-test',
								deleteProductUrl: '/basket/product/8/offer/7',
								inWishlist: true,
								offers: [
									{
										id: 7,
										size: 'XS',
										color: '#000',
										colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
										article: '2345901283',
										isAvailable: true,
										price: {
											rub: {
												value: '45000.00',
											},
										},
										images: [
											{
												url: '/temp/pic-04.jpg',
												alt: '',
											},
										],
										isSelected: true,
										restCount: '120',
									},
								],
							},
						],
					},
				],
			},
		},
	],
};
