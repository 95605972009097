import css from './Outpost.module.scss';
import { HTMLElementProps } from '@api/hooks/types';
import { DeliveryOutpostsList, OutpostPoint } from '@api/mock/types';
import React, { FC, HTMLAttributes, MouseEventHandler, useContext, useMemo } from 'react';
import { Box, Button, Currency, Typography } from '@shared';
import { CheckoutContext } from '@components';
import { useRouter } from 'next/router';
import { getDeliveryDays } from '@components/Checkout/utils';
import { usePrice } from '@hooks';

interface OutpostProps extends HTMLElementProps {
	outpost?: OutpostPoint;
	onOutpostSelect?: (outpost: OutpostPoint) => void;
	calculated?: DeliveryOutpostsList['calculated'];
}

export const Outpost: FC<OutpostProps> = ({ outpost, onOutpostSelect, calculated = {} }) => {
	const { locale } = useRouter();
	const {
		textMapSelectedPoint,
		textMapPrice,
		textMapDeliveryTime,
		textMapPointInfo,
		textMapWorkTime,
		textMapPhone,
	} = useContext(CheckoutContext);
	const { id, title, fullAddress, addressDescription, time, metro } = outpost || {};

	const station = useMemo(() => {
		const en = 'st. ';
		const ru = 'м. ';
		const prefix = locale === 'en' ? en : ru;
		return metro ? prefix + metro : metro;
	}, [metro, locale]);

	const duration = useMemo(() => {
		const en = ['day', 'days', 'days'] as [string, string, string];
		const ru = ['рабочий день', 'рабочих дня', 'рабочих дней'] as [string, string, string];
		const text = locale === 'en' ? en : ru;
		return getDeliveryDays(calculated.deliveryMinDay, calculated.deliveryMaxDay, text);
	}, [calculated, locale]);

	const price = usePrice(calculated?.price, false);

	const phones = useMemo(
		() => (Array.isArray(outpost?.phones) ? outpost?.phones.slice(0, 2) : [outpost?.phones]),
		[outpost?.phones]
	);

	return (
		<Box className={css.card}>
			<Typography className={css.title} size="md" weight="regular">
				{title}
			</Typography>
			<Typography className={css.address} size="sm" color="gray">
				{fullAddress}
				<br />
				{station}
			</Typography>

			<Box className={css.prop}>
				<Typography size="sm">{textMapPrice}</Typography>
				<Typography className="wysiwyg" size="sm" color="gray">
					{price} <Currency />
				</Typography>
			</Box>

			<Box className={css.prop}>
				<Typography size="sm">{textMapDeliveryTime}</Typography>
				<Typography className="wysiwyg" size="sm" color="gray">
					{duration}
				</Typography>
			</Box>

			<Box className={css.prop}>
				<Typography size="sm">{textMapPointInfo}</Typography>
				<Typography className="wysiwyg" size="sm" color="gray">
					{addressDescription?.replace(/,(?=[^\s])/g, ', ')}
				</Typography>
			</Box>

			<Box className={css.prop}>
				<Typography size="sm">{textMapWorkTime}</Typography>
				<Typography className="wysiwyg" size="sm" color="gray">
					{time}
				</Typography>
			</Box>

			<Box className={css.prop}>
				<Typography size="sm">{textMapPhone}</Typography>
				<Typography className="wysiwyg" size="sm" color="gray">
					{phones?.map((item, i) => {
						return (
							<div key={`outpost-${id}-phones=${i}`}>
								<a href={`tel:+${item?.replace(/\D/g, '')}`}>{item}</a>
							</div>
						);
					})}
				</Typography>
			</Box>

			<Box className={css.foot}>
				<Button
					type="button"
					design="stroked"
					wide={true}
					onClick={() => {
						outpost && onOutpostSelect && onOutpostSelect(outpost);
					}}>
					{textMapSelectedPoint}
				</Button>
			</Box>
		</Box>
	);
};

interface OutpostPreviewProps extends HTMLAttributes<HTMLElement> {
	title?: string;
	text?: string;
	metro?: string;
	onClick?: MouseEventHandler;
}

export const OutpostPreview: FC<OutpostPreviewProps> = ({ title, text, metro, onClick }) => {
	const { locale } = useRouter();

	const station = useMemo(() => {
		const en = 'st. ';
		const ru = 'м. ';
		const prefix = locale === 'en' ? en : ru;
		return metro ? prefix + metro : metro;
	}, [metro, locale]);

	return (
		<button className={css.preview} type="button" onClick={onClick}>
			<Typography className={css.previewTitle} size="md">
				{title}
			</Typography>
			<Typography className={css.previewText} size="sm">
				{text}
				<br />
				{station}
			</Typography>
		</button>
	);
};
