import React from 'react';
import dynamic from 'next/dynamic';
import { AccountWishlistProps } from '@components/AccountWishlist/AccountWishlist';

export const AccountWishlistDynamic = dynamic<AccountWishlistProps>(
	() => import('./AccountWishlist').then((module) => module.AccountWishlist),
	{
		ssr: true,
		loading: () => <></>,
	}
);
