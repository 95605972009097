import React, { ComponentPropsWithoutRef, HTMLAttributes, PropsWithChildren } from 'react';
import { TypographyTagnameType } from '@src/shared';

interface BoxProps extends HTMLAttributes<HTMLElement> {
	as?: TypographyTagnameType;
}

export const Box = React.forwardRef<HTMLDivElement, BoxProps>(
	({ as: Tag = 'div', ...props }, ref) => {
		return Tag === 'div' ? (
			<div ref={ref} {...props}>
				{props.children}
			</div>
		) : (
			<Tag {...props}>{props.children}</Tag>
		);
	}
);
