import { Section } from '@src/shared';
import classNames from 'classnames';
import { FC, memo } from 'react';
import { FeaturesStackProps } from '@components';
import css from './FeaturesStackDesktop.module.scss';
import { FeaturesStackDesktopCard } from './FeaturesStackDesktopCard/FeaturesStackDesktopCard';

export const FeaturesStackDesktop: FC<FeaturesStackProps> = memo(({ cards = [] }) => {
	return (
		<Section className={classNames(css.component)}>
			{cards.map((item, i) => (
				<FeaturesStackDesktopCard key={`features-stack-${i}`} {...item} />
			))}
		</Section>
	);
});
