import css from './TextPage.module.scss';
import classnames from 'classnames';
import type { BlockTextPage } from '@api/mock/types';
import React, { FC, HTMLAttributes } from 'react';
import { Section, Container, TopBlock, TypographyFlow } from '@shared';
import { TextPageNav } from '@components/TextPage/components/TextPageNav/TextPageNav';

interface TextPageProps extends HTMLAttributes<HTMLElement>, BlockTextPage {}

export const TextPage: FC<TextPageProps> = React.memo(({ textTitle, sectionsNav, textMain }) => {
	return (
		<Section className={classnames(css.component)}>
			{textTitle && (
				<TopBlock
					title={textTitle}
					titleAs="h1"
					titleTag="h2"
					titleAlign="center"
					className={css.heading}
				/>
			)}

			{sectionsNav && <TextPageNav sectionsNav={sectionsNav} />}

			<Container as="article" className={classnames(css.content, 'wysiwyg')}>
				<TypographyFlow content={textMain} />
			</Container>
		</Section>
	);
});
