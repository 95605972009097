import React, { FC, PropsWithChildren } from 'react';
import { SWRConfig } from 'swr';
import { Header, Footer, SvgSprite, PageTransition } from '@layout';
import { AppDataProvider, PageLoaderProvider, ViewportProvider } from '@context';
import { CartProvider, PopUpProvider } from '@shared';
import { CookieConsent } from '@shared/CookieConsent/CookieConsent';
import { useAccessibility } from '@src/hooks';
// import { PageUp } from '@shared/PageUp/PageUp';

export interface LayoutProps {
	fallback?: Record<string, any>;
	simpleLayout?: boolean;
	sectionCode?: string;
}

export const LayoutDefault: FC<PropsWithChildren<LayoutProps>> = ({
	fallback,
	simpleLayout,
	sectionCode,
	children,
}) => {
	useAccessibility();
	return (
		<SWRConfig value={{ fallback }}>
			<AppDataProvider>
				<ViewportProvider>
					<PageLoaderProvider>
						<PageTransition>
							<PopUpProvider>
								<CartProvider>
									<SvgSprite />
									<Header sectionCode={sectionCode}>
										<main className="page__main">{children}</main>
									</Header>
									{!simpleLayout && <Footer />}
									{/*<CookieConsent />*/}
								</CartProvider>
							</PopUpProvider>
						</PageTransition>
					</PageLoaderProvider>
				</ViewportProvider>
			</AppDataProvider>
		</SWRConfig>
	);
};
