/*
 * Документация:
 * https://docs.2gis.com/ru/mapgl/overview
 * https://www.npmjs.com/package/@2gis/mapgl
 *
 * Ключ 2gis:
 * b490985b-7c81-4728-9677-d1b5bf81084a
 */
import css from './Map2Gis.module.scss';
import classnames from 'classnames';
import type { Map, MapOptions } from '@2gis/mapgl/types';
import { Clusterer } from '@2gis/mapgl-clusterer';
import React, {
	FC,
	HTMLAttributes,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Box, Button, useMap2GisContext } from '@src/shared';
import { Map2GisHtmlMarker } from '@shared/Map2Gis/components/Map2GisMarker/Map2GisHtmlMarker';
import { Map2GisClusterer } from '@shared/Map2Gis/components/Map2GisMarker/Map2GisClusterer';
import { getBounds } from '@shared/Map2Gis/utils';

export type MapObject = {
	id: string;
	coordinates: [number, number];
};
export type PropsWith2GisInstance<T = unknown> = T & { map?: Map | null };

export interface Map2GisProps extends HTMLAttributes<HTMLDivElement> {
	objects: MapObject[];
	initialOptions?: {
		clusterize: boolean;
	} & MapOptions;
}

const API_KEY = 'b490985b-7c81-4728-9677-d1b5bf81084a';

export const Map2Gis: FC<Map2GisProps> = React.memo(
	({ className, initialOptions = {}, objects }) => {
		const {
			id,
			api,
			mapInstance,
			setMapInstance,
			setClustererInstance,
			activeObject,
			setActiveObject,
			focusOnActive,
			setFocusOnActive,
		} = useMap2GisContext();

		const initial = useRef(initialOptions);
		const options = useMemo(
			() => ({
				center: [37.617644, 55.755819],
				zoom: 15,
				zoomControl: false,
				padding: {
					top: 100,
					right: 100,
					bottom: 100,
					left: 100,
				},
				clusterize: true,
				...initial.current,
			}),
			[]
		);

		useEffect(() => {
			if (!api) return;

			const instance = new api.Map(id, {
				...options,
				key: API_KEY,
			});

			if (initialOptions.clusterize) {
				setClustererInstance(
					new Clusterer(instance, {
						radius: 160,
					})
				);
			}

			setMapInstance(instance);

			return () => {
				instance && instance.destroy();
				setMapInstance(undefined);
			};

			// eslint-disable-next-line
		}, [api]);

		useEffect(() => {
			if (objects?.length > 1) {
				const bounds = getBounds(objects.map(({ coordinates }) => coordinates));
				mapInstance && mapInstance.fitBounds(bounds);
			}
		}, [objects, mapInstance]);

		useEffect(() => {
			if (focusOnActive && mapInstance && activeObject) {
				mapInstance.setCenter(activeObject.coordinates);
				mapInstance.setZoom(12);
				setFocusOnActive(false);
			}
		}, [focusOnActive, setFocusOnActive, mapInstance, activeObject]);

		const handleSelectObject = useCallback(
			(object: MapObject) => {
				setActiveObject && setActiveObject(object.id === activeObject?.id ? undefined : object);
			},
			[activeObject?.id, setActiveObject]
		);

		return (
			<Box className={classnames(className, css.component)}>
				<Map2GisMemo id={id} />
				<Map2GisControls />
				{options.clusterize ? (
					<Map2GisClusterer objects={objects} onSelectObject={handleSelectObject} />
				) : (
					<>
						{objects?.map((object) => (
							<Map2GisHtmlMarker
								key={object.id}
								coordinates={object.coordinates}
								isActive={object.id === activeObject?.id}
								onClick={() => {
									setActiveObject &&
										setActiveObject(object.id === activeObject?.id ? undefined : object);
								}}
							/>
						))}
					</>
				)}
			</Box>
		);
	}
);

export const Map2GisControls: FC = React.memo(() => {
	const { mapInstance: map } = useMap2GisContext();

	const zoomChangeHandler = useCallback(
		(zoom: number) => {
			map && map.setZoom(map.getZoom() + zoom);
		},
		[map]
	);

	return (
		<div className={css.zoomControl}>
			<Button
				type="button"
				className={classnames(css.zoomBtn, css.zoomIn)}
				onClick={() => zoomChangeHandler(1)}
			/>
			<Button
				type="button"
				className={classnames(css.zoomBtn, css.zoomOut)}
				onClick={() => zoomChangeHandler(-1)}
			/>
		</div>
	);
});

export const Map2GisMemo = React.memo(
	React.forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ id, className }, ref) => {
		return <div ref={ref} id={id} className={classnames(className, css.canvas)} />;
	}),
	() => true
);
