import { BlockAccountAddressesContent } from '@api/mock/types';

export const addressesData: BlockAccountAddressesContent = {
	/*
	 * По аналогии с каталогом:
	 * блок навгиации для пагинации и ревалидации данных
	 */
	navigation: {
		next: '/ru/mocks/account/addresses?pageNumber=1&limit=10',
		self: '/ru/mocks/account/addresses',
		countPerPage: 2,
		total: 2,
	},
	addresses: [
		{
			id: 1,
			updateUrl: '/account/addresses/1',
			isSelected: true,
			data: {
				country: 'Россия',
				city: 'Санкт-Петербург',
				address: 'ул. Тверская 41',
				entrance: '7',
				door: '4',
				zip: '192300',
			},
		},
		{
			id: 2,
			updateUrl: '/account/addresses/2',
			isSelected: false,
			data: {
				country: 'Россия',
				city: 'Санкт-Петербург',
				address: 'пр. Литейный 200',
				entrance: '7',
				door: '4',
				zip: '192300',
			},
		},
	],
};
