import { useEffect } from 'react';

type AxisType = 'x' | 'y';
type CoordType = `client${Uppercase<AxisType>}`;

type UseSwipableToolsElement = HTMLElement | null;
type UseSwipableToolsOptions = {
	size: number;
	skip: boolean;
};

const getCoord = (e: MouseEvent | TouchEvent, axis: AxisType = 'x') => {
	const param: CoordType = `client${axis.toUpperCase() as Uppercase<AxisType>}`;
	return (e as TouchEvent).touches ? (e as TouchEvent).touches[0][param] : (e as MouseEvent)[param];
};

const axis = 'x';

export const useSwipableTools = (
	element: UseSwipableToolsElement,
	{ size = 100, skip = false }: UseSwipableToolsOptions
) => {
	useEffect(() => {
		if (!element || skip) return;

		const state = {
			start: 0,
			move: 0,
			moving: false,
			diff: 0,
			position: 0,
			shift: 0,
			dir: -1,
		};

		const rewindToAnchor = () => {
			let dest = 0;

			if (state.diff > 10) {
				dest = state.dir > 0 ? 0 : size;
			} else {
				dest = state.position > size / 2 ? size : 0;
			}

			const onTransitionEnd = () => {
				element.style.transition = '';
				element.removeEventListener('transitionend', onTransitionEnd);
			};

			element.addEventListener('transitionend', onTransitionEnd);
			element.style.transition = `transform .4s ease`;

			element.style.zIndex = dest > 0 ? `10` : ``;

			setTimeout(() => {
				switch (axis) {
					case 'x':
						element.style.transform = dest > 0 ? `translateX(-${dest}px)` : ``;
						break;
				}
			}, 1);
		};

		const computeSlide = () => {
			state.shift = Math.max(0, Math.min(state.position - state.diff, size));

			switch (axis) {
				case 'x':
					element.style.transform = `translateX(-${state.shift}px)`;
					break;
			}
		};

		const onUp = () => {
			if (state.moving) {
				state.moving = false;
				state.position = state.shift;

				rewindToAnchor();
			}

			window.removeEventListener('mouseup', onUp);
			window.removeEventListener('touchend', onUp);

			window.removeEventListener('mousemove', onMove);
			window.removeEventListener('touchmove', onMove);

			element.style.userSelect = '';
		};

		const onMove = (e: MouseEvent | TouchEvent) => {
			state.move = getCoord(e, axis);

			if (state.moving) {
				state.diff = state.move - state.start;
				state.dir = state.diff < 0 ? -1 : 1;
				computeSlide();
			}
		};

		const onDown = (e: MouseEvent | TouchEvent) => {
			state.moving = true;
			state.start = getCoord(e, axis);
			state.position = Math.abs(element.getBoundingClientRect().left);

			window.addEventListener('mouseup', onUp);
			window.addEventListener('touchend', onUp);

			window.addEventListener('mousemove', onMove);
			window.addEventListener('touchmove', onMove);

			element.style.userSelect = 'none';
		};

		element.addEventListener('mousedown', onDown);
		element.addEventListener('touchstart', onDown);

		return () => {
			element.removeEventListener('mousedown', onDown);
			element.removeEventListener('touchstart', onDown);
		};
	}, [element]);
};
