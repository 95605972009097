import css from './Order.module.scss';
import classnames from 'classnames';
import React, { Dispatch, FC, HTMLAttributes, SetStateAction, useCallback } from 'react';
import { Box, Button, CartElement, Currency, Flex, Typography } from '@shared';
import { OrderType } from '@api/mock/types';
import { usePrice } from '@hooks';
import { Status } from '@shared/Status/Status';
import { useViewport } from '@context';
import { fetcher, getPageData, usePageData } from '@src/api';
import { withLocale } from '@src/utils';
import { useRouter } from 'next/router';

interface OrderProps extends HTMLAttributes<HTMLElement>, OrderType {
	setItems?: Dispatch<SetStateAction<OrderType[]>>
}

export const Order: FC<OrderProps> = React.memo(
	({
		id,
		textDate,
		textNumber,
		textDelivery,
		textTotalSum,
		textPaymentBtn,
		textCancelBtn,
		delivery,
		totalSum,
		paymentUrl,
		cancelUrl,
		status,
		products,
		className,
		setItems
	}) => {
		const { isTablet } = useViewport();
		const sum = usePrice(totalSum);
		
		const { asPath } = useRouter()
		const {revalidate} = usePageData(withLocale(asPath))
		const handleCancel = useCallback(() => {
			const headers = new Headers();
			headers.set('Content-Type', 'x-www-form-urlencoded')
			fetcher({
				url: cancelUrl || '',
				method: "PUT",
				headers: headers
			}).then(() => {
				revalidate && revalidate()
				getPageData(withLocale(asPath)).then(res => setItems && setItems(res.items[0].content.orders))
			})
		}, [asPath, cancelUrl, revalidate, setItems]);

		
		const buttonsBlock = (
			<Box className={css.detailsTools}>
				{textPaymentBtn && paymentUrl && (
					<Button to={paymentUrl} design="flat" color="gray" size="sm">
						{textPaymentBtn}
					</Button>
				)}
				{textCancelBtn && cancelUrl && (
					<Button onClick={handleCancel} design="flat" color="gray" size="sm">
						{textCancelBtn}
					</Button>
				)}
			</Box>
		);

		return (
			<Flex className={classnames(className, css.component)}>
				<Box className={css.details}>
					<Box className={css.detailsHead}>
						<Status {...status} />
						{isTablet && buttonsBlock}
					</Box>
					<Typography size="md" className={css.detailsList}>
						{textDate && <p>{textDate}</p>}
						{textNumber && <p>{textNumber}</p>}
						{textDelivery && delivery && (
							<p>
								{textDelivery}: {delivery}
							</p>
						)}
						{textTotalSum && sum && (
							<p>
								{textTotalSum}: {sum} <Currency />
							</p>
						)}
					</Typography>
					{!isTablet && buttonsBlock}
				</Box>
				<Box className={css.products}>
					{products?.map((item, i) => {
						const key = `order-${id}-${item.deleteProductUrl || i}`;
						return (
							<CartElement key={key} {...item} selectable={false} className={css.productsItem} />
						);
					})}
				</Box>
			</Flex>
		);
	}
);
