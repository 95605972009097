import css from './IntroCover.module.scss';
import classnames from 'classnames';
import fastdom from 'fastdom';
import type { BlockIntroCoverContent } from '@model/models';
import React, { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import {
	normalize,
	lerpHexColor,
	DARK_COLOR_HEX,
	WHITE_COLOR_HEX,
	// HEADER_HEIGHT,
	// HEADER_HEIGHT_SM,
} from '@utils';
import { usePageLoadState, useViewport } from '@context';
import { useHomePage, useIsomorphicLayoutEffect, useScrollSeek } from '@hooks';
import { Container, Section, Icon, Typography as Typo } from '@shared';
import { useHeader } from '@layout/components/Header/context/Header.context';

interface IntroCoverProps extends HTMLAttributes<HTMLElement>, BlockIntroCoverContent {}

const topOffsetInVw = 0.112;
const borderRadiusInVw = 0.3194;

export const IntroCover: FC<IntroCoverProps> = React.memo((props) => {
	const { setColorAnimation } = useHeader();

	/*
	 * Уведомляем шапку, что анимация с цветом
	 */
	useIsomorphicLayoutEffect(() => {
		setColorAnimation && setColorAnimation(true);
		return () => setColorAnimation && setColorAnimation(false);
	}, []);

	return <IntroCoverComponent {...props} />;
});

export const IntroCoverComponent: FC<IntroCoverProps> = React.memo(
	({ mainPhoto, textCollection }) => {
		const width = mainPhoto?.width ? Number(mainPhoto?.width) : 2733;
		const height = mainPhoto?.height ? Number(mainPhoto?.height) : 4096;

		const [section, setSection] = useState<HTMLElement | null>(null);
		const [cover, setCover] = useState<HTMLDivElement | null>(null);
		const [coverTrack, setCoverTrack] = useState<HTMLDivElement | null>(null);
		const [track, setTrack] = useState<HTMLDivElement | null>(null);
		const [label, setLabel] = useState<HTMLDivElement | null>(null);

		const { vw, vh, isTablet } = useViewport();
		const aspect = height / width;
		const scrollWay = vh * 0.75;
		// const hdrHeight = isMob ? HEADER_HEIGHT_SM : HEADER_HEIGHT;

		/*
		 * Считаем начальный скейл фотки (в верхней точке)
		 */
		const computeScale = vh / (vw * aspect);
		const baseScale = Math.max(0.527, computeScale);
		const baseScaleInvert = 1 / baseScale;

		const isHomePage = useHomePage();
		const { setColor, setShown, setColorAnimation, setAllowColorAnimation } = useHeader();

		useScrollSeek(
			section,
			useCallback(
				({ bounding, boundingAnimated }) => {
					const scrolled = Math.max(0, -boundingAnimated.top);
					const progress = Math.min(1, scrolled / scrollWay);

					const hdrRatio = 1 - normalize(progress, 0.15, 0.85, true);
					const labelRatio = 1 - normalize(progress, 0.45, 0.85, true);

					if (!isTablet) {
						const end = Math.floor(bounding.bottom) >= 0;
						const above = Math.floor(bounding.bottom - vh) >= 0;
						const lerpColor = lerpHexColor(DARK_COLOR_HEX, WHITE_COLOR_HEX, hdrRatio);

						setShown && setShown(above);
						setColor && setColor(end ? lerpColor : null);
						setAllowColorAnimation && setAllowColorAnimation(above);
					}
					if (coverTrack && isHomePage && track) {
						const bottomPoint = bounding.bottom;

						coverTrack.classList.toggle(css.fixed, bottomPoint <= vh);
						coverTrack.classList.toggle(css.stuck, bottomPoint <= 0);
						track.classList.toggle(css.fixed, bottomPoint <= vh);
						track.classList.toggle(css.stuck, bottomPoint <= 0);
					}

					if (cover && label && !isTablet) {
						const scale = baseScale + 0.473 * progress;
						const translateY = vw * topOffsetInVw * (1 - progress);
						const borderRadius = vw * borderRadiusInVw * baseScaleInvert * (1 - progress);

						fastdom.mutate(() => {
							label.style.color = lerpHexColor(DARK_COLOR_HEX, WHITE_COLOR_HEX, labelRatio);

							cover.style.transform = `translate3d(0, ${translateY}px, 0) scale(${scale}) translateZ(0)`;
							cover.style.borderRadius = `0 ${borderRadius}px 0 0`;
						});
					}
				},
				[
					baseScale,
					baseScaleInvert,
					cover,
					coverTrack,
					track,
					label,
					scrollWay,
					setColor,
					setShown,
					setAllowColorAnimation,
					vh,
					vw,
					isTablet,
					isHomePage,
				]
			),
			{
				checkInView: true,
				resist: 0.35,
			}
		);

		const { ready } = usePageLoadState();

		return (
			<Section
				ref={setSection}
				className={classnames('intro-cover', css.component, {
					[css.isShown]: ready,
					[css.isHomePage]: isHomePage,
				})}
				style={{
					height: `calc(calc(100vw * ${aspect}) + 75vh)`,
				}}>
				<div ref={setCoverTrack} className={css.sticky}>
					<div ref={setCover} className={css.cover}>
						{mainPhoto?.url && (
							<>
								{isTablet ? (
									<Image
										src={mainPhoto.url}
										alt={mainPhoto.alt || textCollection || ''}
										layout="fill"
										objectFit="cover"
										objectPosition="left center"
										priority={true}
									/>
								) : (
									<Image
										src={mainPhoto.url}
										alt={mainPhoto.alt || textCollection || ''}
										layout="responsive"
										width={width}
										height={height}
										priority={true}
										quality={100}
										sizes="100vw"
									/>
								)}
							</>
						)}
					</div>
				</div>
				<div ref={setTrack} className={css.track}>
					<Container className={classnames(css.content, css.sticky)}>
						<div ref={setLabel} className={css.leftLabel}>
							<Typo className={css.leftLabelText} as="h1" type="h5">
								<span>{textCollection}</span>
								<Icon id="bullet-stroke" />
							</Typo>
						</div>
					</Container>
				</div>
			</Section>
		);
	}
);
