import css from './PageTransition.module.scss';
import classnames from 'classnames';
import React, { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { useIsomorphicLayoutEffect } from '@hooks';
import scrollLocker from '@utils/utils.scroll/ScrollLocker';
import { usePageLoadState } from '@src/context';

export type PageTransitionState = 'enter' | 'inter' | 'exit' | 'waiting';

interface PageTransitionProps {
	color?: string;
}
interface PageTransitionElementsProps {
	color: string;
	transition: PageTransitionState;
}

const duration = 800;
const routingClass = 'is-routing';
const scrollReadyClass = 'is-ready-to-scroll';

export const PageTransitionContext = createContext<PageTransitionState>('waiting');

export const PageTransition: FC<PropsWithChildren<PageTransitionProps>> = React.memo(
	({ color = 'white', children }) => {
		const router = useRouter();
		
		const [transition, setTransition] = useState<PageTransitionState>('waiting');
		const [displayChildren, setDisplayChildren] = useState(children);

		const { setLoading } = usePageLoadState()

		useIsomorphicLayoutEffect(() => {
			if (children !== displayChildren && transition === 'exit') {
				setDisplayChildren(children);
			}
		}, [children, displayChildren, transition]);

		useIsomorphicLayoutEffect(() => {
			let timer: number = 0;
			let routingResolve: (value: unknown) => void;

			const transitionProgress = () => {
				return new Promise((resolve) => {
					window.setTimeout(resolve, duration);
				});
			};

			const routingProgress = () => {
				return new Promise((resolve) => {
					routingResolve = resolve;
				});
			};

			const onRouteEnd = () => {
				setTimeout(() => setLoading(false), 300);
				routingResolve('exit');
			};

			const onRouteStart = (url: string, { shallow }: { shallow: boolean }) => {
				if (shallow) return;
				setLoading(true);
				window.clearTimeout(timer);

				router.events.on('routeChangeError', onRouteEnd);
				router.events.on('routeChangeComplete', onRouteEnd);

				document.documentElement.classList.add(routingClass);

				setTransition('enter');
				scrollLocker.lock({ fixed: true });

				timer = window.setTimeout(() => {
					window.scrollTo(0, 0);
					document.body.style.top = '0px';
					(document.activeElement as HTMLElement)?.blur();
					setTransition('inter');
				}, duration);

				Promise.all([routingProgress(), transitionProgress()]).then(() => {
					router.events.off('routeChangeError', onRouteEnd);
					router.events.off('routeChangeComplete', onRouteEnd);

					timer = window.setTimeout(() => {
						scrollLocker.unlock({ fixed: true }).then(() => {
							window.scrollTo(0, 0);
							document.documentElement.classList.remove(routingClass);
							document.documentElement.classList.remove(scrollReadyClass);
						});

						setTransition('exit');

						timer = window.setTimeout(() => {
							setTransition('waiting');

							timer = window.setTimeout(() => {
								document.documentElement.classList.add(scrollReadyClass);
							}, duration);
						}, duration);
					}, duration / 2);
				});
			};

			router.events.on('routeChangeStart', onRouteStart);

			return () => {
				window.clearTimeout(timer);
				router.events.off('routeChangeStart', onRouteStart);
			};
		}, []);

		return (
			<PageTransitionContext.Provider value={transition}>
				{displayChildren}
				<PageTransitionElements transition={transition} color={color} />
			</PageTransitionContext.Provider>
		);
	}
);

const PageTransitionElements: FC<PageTransitionElementsProps> = React.memo(
	({ color, transition }) => {
		return (
			<div className={classnames(css.component, css[transition])}>
				{/*<div className={classnames(css.curtain, css[color])} />
				<div className={classnames(css.curtain, css[color])} />*/}
			</div>
		);
	}
);

export const usePageTransition = () => {
	return useContext(PageTransitionContext);
};
