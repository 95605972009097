import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from "react";
import css from './FilterRange.module.scss';
import ReactSlider from "react-slider"
import { Currency, Icon } from "@src/shared";
import { throttle } from "lodash";
import { useRouter } from "next/router";

interface IFilterRange extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	name: 'price';
	label?: string;
	min: number;
	max: number;
}

export const FilterRange = ({ name, min, max, ...rest }: IFilterRange) => {
	const [minVal, setMinVal] = useState(min);
	const [maxVal, setMaxVal] = useState(max);
	const { asPath, replace, query} = useRouter();
	const [isChanging, setIsChanging] = useState(false)
    const updateMax = (value: number) => {
        setMaxVal(value);
    }
    const updateMin = (value: number) => {
        setMinVal(value)
    }

    const [querystring, setquerystring] = useState('');
		useEffect(() => {
			if (!!query.length) {
				setTimeout(() => setquerystring(''), 0);
			}
		}, [query]);
	useEffect(() => setquerystring(`filter[${name}]=${minVal}-${maxVal}`),[minVal, maxVal])
	useEffect(() => {
		if (minVal && maxVal &&  (min !== minVal || max !== maxVal)) {
					const search = Object.entries({ ...query, [name]: querystring })
						.filter((item) => item[0] !== 'slug')
						.filter((item) => item[1]);
					const searchString = search.map((item) => item[0] + '=' + item[1]).join('&');
					const pathname = asPath.split('?')[0];
					const url = pathname + (searchString ? '?' + searchString : '');

					replace(url, undefined, { shallow: true }).then();
		}
	}, [asPath, max, maxVal, min, minVal, name, query, querystring, replace])
    

	return (
		<div>
			<div className={css.prices}>
				<span>
					{minVal} <Currency />
				</span>{' '}
				<span>
					{maxVal} <Currency />
				</span>
			</div>
			<ReactSlider
				min={min}
				max={max}
				defaultValue={[min, max]}
				minDistance={50}
				step={50}
				pearling={true}
				className={css.slider}
				trackClassName={css.track}
				thumbClassName={css.thumb}
				renderThumb={(props) => {
					return (
						<div
							{...props}
							>
							<Icon className={css.thumbIcon} id="bullet-quatro" />
						</div>
					);
				}}
				renderTrack={(props) => {
					return <div {...props} />;
				}}
				onChange={([min, max]) => {
					updateMin(min);
					updateMax(max);
				}}
			/>
		</div>
	);
};