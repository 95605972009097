import { HTMLElementProps } from '@api/hooks/types';
import React, { ChangeEvent, FC, useMemo } from 'react';
import { FormElement } from '@shared/Form/FormElement/FormElement';
import { Input } from '@shared/Form/Input/Input';
import { TextArea } from '@shared/Form/Input/TextArea';
import { FormChoice } from '@shared/Form/FormChoice/FormChoice';
import { FormRadiogroup } from '@shared/Form/FormChoice/components/FormRadiogroup';
import { Select } from '@shared/Form/Select/Select';
import { useInput } from '@shared/Form/FormComponent/hooks/useInput';
import { getValidationConfig } from '@shared/Form/FormComponent/utils/validation';
import classnames from 'classnames';

interface FormChildProps extends HTMLElementProps, FormFieldType {
	maskLazy?: boolean;
	onCompleteMask?: (e: ChangeEvent<HTMLInputElement>) => void;
}

export const FormChild: FC<FormChildProps> = ({ className, ...props }) => {
	const { validation, value: initialValue, ...rest } = props;
	const { bind, error, value } = useInput<string | boolean>(props.name || '', {
		value: initialValue || '',
		type: props.type,
		validation: getValidationConfig(props),
	});

	const child = useMemo(() => {
		switch (rest.type) {
			case undefined:
				return null;
			case 'select':
				return rest.options ? (
					/*
					 * ToDo: дописать селект по аналогии с оформлением заказа (useSelect)
					 */
					<Select
						name={rest.name}
						selected={rest.options[0]}
						setSelected={(selected: any) => null}
						placeholder={rest.label}
						options={rest.options}
					/>
				) : null;

			case 'radio':
				return <FormRadiogroup {...rest} value={value as string} bind={bind} />;

			case 'checkbox':
				return (
					<FormChoice {...rest} {...bind} type={rest.type as 'checkbox'}>
						{rest.label}
					</FormChoice>
				);

			case 'textarea':
				return <TextArea hasError={!!error} {...rest} {...bind} />;

			default:
				return <Input hasError={!!error} {...rest} {...bind} />;
		}
	}, [rest, bind, error, value]);

	return props ? (
		<FormElement
			className={classnames(className, { 'radio-group': rest.type === 'radio' })}
			error={error}>
			{child}
		</FormElement>
	) : null;
};
