import React, {
	FC,
	PropsWithChildren,
	useEffect,
	useState,
	createContext,
	useContext,
} from 'react';
import { PageLoadScreen } from '@layout';
import { LoadingIndicator } from '@shared';
import { useRouter } from 'next/router';

export type LoadingState = 'waiting' | 'loading' | 'complete';

interface ContextProps {
	ready: boolean;
	onceLoaded: boolean;
	loading: boolean;
	setLoading: React.Dispatch<React.SetStateAction<boolean>>;
	loadingState: LoadingState;
	setReadyState?: React.Dispatch<React.SetStateAction<boolean>>;
	setLoadingState?: React.Dispatch<React.SetStateAction<LoadingState>>;
}

export const PageLoaderContext = createContext<ContextProps>({
	ready: false,
	onceLoaded: false,
	loading: false,
	setLoading: () => false,
	loadingState: 'waiting',
	setLoadingState: undefined,
	setReadyState: undefined,
});

const duration = 200;
const isProd = process.env.NODE_ENV === 'production';

export const PageLoaderProvider: FC<PropsWithChildren> = ({ children }) => {
	const [ready, setReady] = useState(false);
	const [once, setOnce] = useState(false);
	const [loading, setLoading] = useState(false);
	const [state, setState] = useState<LoadingState>('waiting');

	const { asPath } = useRouter();

	useEffect(() => {
		if (isProd) return;

		let timer = 0;

		const onLoad = () => {
			timer = window.setTimeout(() => {
				setReady(true);
				setState('complete');
			}, duration);
		};

		if (document.readyState === 'complete') {
			onLoad();

			return () => {
				window.clearTimeout(timer);
			};
		} else {
			window.addEventListener('load', onLoad);

			return () => {
				window.clearTimeout(timer);
				window.removeEventListener('load', onLoad);
			};
		}
	}, []);

	useEffect(() => {
		if (state === 'complete') {
			document.documentElement.classList.add('is-loaded');
			document.documentElement.classList.add('is-ready-to-scroll');

			if (!once) {
				setOnce(true);
			}
		}
	}, [once, state]);

	return (
		<PageLoaderContext.Provider
			value={{
				ready,
				onceLoaded: once,
				loadingState: state,
				setLoadingState: setState,
				setReadyState: setReady,
				loading,
				setLoading,
			}}>
			{children}
			<LoadingIndicator />
			{isProd && <PageLoadScreen />}
		</PageLoaderContext.Provider>
	);
};

export const usePageLoadState = () => {
	return useContext(PageLoaderContext);
};
