import css from './CatalogSectionsTwin.module.scss';
import classnames from 'classnames';
import type { BlockCatalogSectionsStackContent } from '@model/blockCatalogSectionsStackContent';
import React, { FC, HTMLAttributes, useCallback, useState } from 'react';
import { CatalogSectionsCard } from '../CatalogSectionsCard/CatalogSectionsCard';
import { useScrollSeek } from '@hooks';
import { useViewport } from '@context';
import { normalize } from '@utils';

interface CatalogSectionsTwinProps extends HTMLAttributes<HTMLElement> {
	items?: BlockCatalogSectionsStackContent['productsSections'];
}

export const CatalogSectionsTwin: FC<CatalogSectionsTwinProps> = ({ items = [], ...rest }) => {
	const [section, setSection] = useState<HTMLElement | null>(null);

	const { vh } = useViewport();

	useScrollSeek(
		section,
		useCallback(
			({ bounding }) => {
				const scrollIn = Math.max(0, -(bounding.top - vh));
				const progressIn = Math.min(1, scrollIn / vh);

				const cover = bounding.height - vh;
				const scroll = Math.max(0, -bounding.top);
				const progress = Math.min(1, scroll / cover);

				if (section) {
					section.style.setProperty('--seek-in', (1 - progressIn).toString());
					section.style.setProperty('--seek-stage', normalize(progress, 0.333, 0, true).toString());
				}
			},
			[section, vh]
		),
		{
			checkInView: true,
		}
	);

	if (items.length < 2) return null;

	return (
		<div ref={setSection} className={classnames(css.component)} {...rest}>
			<div className={css.sticky}>
				<CatalogSectionsCard className={css.card} {...items[0]} />
				<CatalogSectionsCard className={css.card} {...items[1]} />
			</div>
		</div>
	);
};
