import {
	ChangeEventHandler,
	FocusEventHandler,
	InvalidEvent,
	useState,
	useCallback,
	useMemo,
	useEffect,
	useContext,
} from 'react';
import { FormContext } from '@shared/Form/FormComponent/FormComponent';
import { getErrorRecord } from '@shared/Form/FormComponent/hooks/useInput';

export type ValueType = string | boolean | (FileList | File);
export type ValidationMessagesType = Partial<Record<ErrorKey, string>>;

export type ErrorKey = 'required' | 'pattern' | 'default' | 'custom' | `custom${string}`;
export type ErrorType = {
	type: ErrorKey;
	message?: string | string[];
};

export type UseInputBindType<E = HTMLSelectElement> = {
	onChange: ChangeEventHandler<E>;
	onFocus: FocusEventHandler<E>;
	onBlur: FocusEventHandler<E>;
	onInvalid: (e: InvalidEvent<E>) => void;
} & ({ value: string } | { checked: boolean } | {});

export interface UseInputInterface<T> {
	name?: string;
	value?: T;
	validation?: {
		onBlur?: boolean;
		onChange?: boolean;
		resetOnFocus?: boolean;
		handler?: (value: ValueType) => { status: boolean; key?: string[] };
		messages?: ValidationMessagesType;
	};
}

export interface UseInputReturn<T> {
	bind: UseInputBindType;
	value: T;
	error?: ErrorType[];
	setValue: (value: any) => void;
	setError: (value: any) => void;
}

export const useSelect = <T>(
	name: string,
	{ value: defaultValue, validation }: UseInputInterface<T>
): UseInputReturn<T> => {
	const [valueState, setValueState] = useState<any>(defaultValue);
	const [errorState, setErrorState] = useState<ErrorType[] | undefined>(undefined);

	const setValue = useCallback((value: any) => setValueState(value), []);
	const setError = useCallback(
		(value?: ErrorType) => setErrorState(value ? getErrorRecord(value.type, value) : undefined),
		[]
	);

	/*
	 * Значение по умолчанию
	 */
	useEffect(() => {
		setValueState(defaultValue);
	}, [defaultValue]);

	/*
	 * Обработчик кастомной валидации
	 * вызывает validation.handler, если он передан
	 */
	const customValidation = useCallback(
		(value?: any) => {
			if (!validation?.handler) return;

			const { status, key } = validation.handler(value || valueState);
			const errorKey = key ? key : `custom`;

			setErrorState(
				!status ? getErrorRecord(errorKey as ErrorKey, validation?.messages) : undefined
			);
		},
		[validation, valueState]
	);

	/*
	 * Обработчик смены значения
	 * validation.onChange тригерит валидацию на расфокус
	 */
	const changeHandler: ChangeEventHandler<HTMLSelectElement> = useCallback(
		({ target }) => {
			const value = target.value;

			setValueState(value);
			setErrorState(undefined);

			if (validation?.onChange) {
				validation?.handler ? customValidation(value) : target.checkValidity();
			}
		},
		[customValidation, validation?.handler, validation?.onChange]
	);

	/*
	 * Обработчик сброса фокуса
	 * validation.onBlur тригерит валидацию на расфокус
	 */
	const blurHandler: FocusEventHandler<HTMLSelectElement> = useCallback(
		({ target }) => {
			if (validation?.onBlur) {
				validation?.handler ? customValidation() : target.checkValidity();
			}
		},
		[customValidation, validation?.handler, validation?.onBlur]
	);

	/*
	 * Обработчик фокуса
	 * validation.resetOnFocus сбрасывает ошибку при новом фокусе
	 */
	const focusHandler: FocusEventHandler<HTMLSelectElement> = useCallback(() => {
		if (validation?.resetOnFocus) {
			setErrorState(undefined);
		}
	}, [validation?.resetOnFocus]);

	/*
	 * Обработчик невалидных полей
	 * пишет в error-стейт оишбку с типом и сообщением из validation.messages или нативным
	 */
	const invalidHandler = useCallback(
		(e: InvalidEvent<HTMLSelectElement>) => {
			e.preventDefault();
			const defaultMessage = e.target?.validationMessage;

			if (e.target.validity.valueMissing) {
				setErrorState(getErrorRecord('required', validation?.messages, defaultMessage));
			} else if (e.target.validity.typeMismatch || e.target.validity.patternMismatch) {
				setErrorState(getErrorRecord('pattern', validation?.messages, defaultMessage));
			} else {
				setErrorState(getErrorRecord('default', validation?.messages, defaultMessage));
			}
		},
		[validation?.messages]
	);

	const value = useMemo(() => ({ value: valueState as string }), [valueState]);

	/*
	 * Регистрация в контексте формы
	 */
	const { register } = useContext(FormContext);

	useEffect(() => {
		if (name && register) {
			register(name, { value: valueState, error: errorState, setValue, setError });
		}
	}, [name, register, errorState, setError, valueState, setValue]);

	return {
		bind: {
			...value,
			onChange: changeHandler,
			onFocus: focusHandler,
			onBlur: blurHandler,
			onInvalid: invalidHandler,
		},
		value: valueState,
		error: errorState,
		setValue,
		setError,
	};
};
