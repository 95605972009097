import css from '../Header.module.scss';
import classnames from 'classnames';
import React, { FC, FormEventHandler, MouseEventHandler, useCallback, useState } from 'react';
import { Box, Button, CloseBtn, Container, Icon, Typography } from '@shared';
import { Input } from '@shared/Form/Input/Input';
import { useInput } from '@shared/Form/FormComponent/hooks/useInput';
import { useHeader } from '@layout/components/Header/context/Header.context';
import { useBxComponents } from '@context';
import { useRouter } from 'next/router';
import { withoutTrailingSlash } from '@utils';

interface SearchProps {}

export const Search: FC<SearchProps> = React.memo(() => {
	const { push } = useRouter();
	const { search } = useHeader();
	const [searchForm, setSearchForm] = useState<HTMLFormElement | null>(null)

	let { bind, value, setValue } = useInput<string | boolean>('search', {
		value: '',
		type: 'search',
	});

	const closeHandler: MouseEventHandler = () => {
		if (search?.setState) search.setState(false);
	};

	const { header } = useBxComponents();
	const { searchComponent } = header?.menu || {};
	

	const submitHandler: FormEventHandler = useCallback(
		(e) => {
			e.preventDefault();

			if (!searchComponent) {
				console.warn(`Search submit failed: searchComponent not found`);
				return;
			}

			/*
			 * ToDo: заменить мок /mocks/catalog/search на настоящий роут
			 *  завести для поиска страницу в pages (по SSR варианту)
			 */

			const { searchPage } = searchComponent;
			const url = `/catalog/search`; // withoutTrailingSlash(searchPage?.url || '/');
			const key = searchPage?.name || 'q';
			const route = `${url}/?${key}=${value}`;

			push(route).then(() => {
				search.setState && search.setState(false);
			});
		},
		[search, searchComponent, value, push]
	);

	const popularRequest = useCallback((item: string) => {
		setValue(item);
		setTimeout(() => searchForm?.requestSubmit(), 0);
	}, [searchForm, setValue]);

	return (
		<Box className={css.search}>
			<Box className={classnames(css.searchBox, { [css.isShown]: search.state })}>
				<Container className={css.searchContainer}>
					<form ref={setSearchForm} className={css.searchInput} onSubmitCapture={submitHandler}>
						<Input type="search" name="search" placeholder="Поиск" autoComplete="off" {...bind} />
						<Button className={css.searchBtn} type="submit">
							<Icon id="search" />
						</Button>
						<CloseBtn className={css.searchClose} onClick={closeHandler}>
							{searchComponent?.textHide || 'Скрыть'}
						</CloseBtn>
					</form>
				</Container>
				<Container>
					<Typography weight="regular" size="md">
						Популярные запросы
					</Typography>
					<Box className={css.popular}>
						{searchComponent?.phrases?.map((item) => (
							<Typography
								size="xs"
								className={css.popularItem}
								onClick={() => popularRequest(item)}
								key={item + 'запрос'}>
								{item}
							</Typography>
						))}
					</Box>
				</Container>
			</Box>
		</Box>
	);
});
