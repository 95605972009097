import React, { FC, PropsWithChildren, useMemo } from 'react';
import { PageProps } from '@api/hooks/types';
import { sections } from './components';
import { nanoid } from 'nanoid';

interface PageFlowProps extends PageProps {
	options?: {};
}

const WRAP_SECTIONS = false;

export const PageFlow: FC<PageFlowProps> = ({ data = [], options = {} }) => {
	const uid = useMemo(nanoid, [data]);

	const items = data!.map((item, i) => {
		if (!item.type) {
			console.warn(`Type is missing for "${JSON.stringify(item)}"`);
			return null;
		}

		if (!(item.type in sections)) {
			console.warn(`Section with type "${item.type}" is not exist`);
			return null;
		}

		const Wrapper = DefaultWrapper;
		const Section = sections[item.type];

		return (
			<Wrapper key={`content-${uid}-item-${item.type}-${i}`}>
				<Section typeKey={item.type} {...item.content} {...options} />
			</Wrapper>
		);
	});

	return WRAP_SECTIONS ? (
		<>
			{items.shift()}
			<div className="page__content">{items}</div>
		</>
	) : (
		<>{items}</>
	);
};

export const DefaultWrapper: FC<PropsWithChildren> = ({ children = [] }) => {
	return <>{children}</>;
};
