import { PageData } from '@api/hooks/types';
import { BlockCatalogAllProductsContent } from '@model/blockCatalogAllProductsContent';
import {
	BlockAccountAddressesContent,
	BlockAccountOrdersContent,
	LoginResponse,
} from '@api/mock/types';
import { home } from '@api/mock/pages/home';
import { catalog } from '@api/mock/pages/catalog';
import { product } from '@api/mock/pages/product';
import { cart } from '@api/mock/pages/cart';
import { checkout } from '@api/mock/pages/checkout';
import { formError } from '@api/mock/forms/error';
import { formSuccess } from '@api/mock/forms/success';
import { contacts } from '@api/mock/pages/contacts';
import { payment } from '@api/mock/pages/client/payment-delivery';
import { refund } from '@api/mock/pages/client/refund';
import { faq } from '@api/mock/pages/client/faq';
import { privacy } from '@api/mock/pages/client/privacy-police';
import { cookies } from '@api/mock/pages/client/cookies-police';
import { login } from '@api/mock/pages/login';
import { loginFormSubmitPhone } from '@api/mock/forms/login';
import { personal, orders, wishlist, addresses } from '@api/mock/pages/account';
import { ordersData, wishlistData } from '@api/mock/data/account';
import { orderSuccess } from '@api/mock/pages/basket/order-success';
import { search } from '@api/mock/pages/search';
import { searchData } from '@api/mock/data/account/search';
import { addressesData } from '@api/mock/data/account/addresses';
import { brand } from '@api/mock/pages/brand';
import { collection } from '@api/mock/pages/collection';

export const mocks: Record<string, PageData | FormResponse | LoginResponse> = {
	/*
	 * Эндпоинты страниц
	 */
	'/ru/pages/mocks/home': home,

	'/ru/pages/mocks/catalog': catalog,
	'/ru/pages/mocks/catalog/search': search,
	'/ru/pages/mocks/product': product,
	'/ru/pages/mocks/cart': cart,
	'/ru/pages/mocks/checkout': checkout,
	'/ru/pages/mocks/checkout/success': orderSuccess,

	'/ru/pages/mocks/contacts': contacts,

	'/ru/pages/mocks/client-service/payment-delivery': payment,
	'/ru/pages/mocks/client-service/refund': refund,
	'/ru/pages/mocks/client-service/faq': faq,
	'/ru/pages/mocks/politic/privacy-police': privacy,
	'/ru/pages/mocks/politic/cookies-police': cookies,

	'/ru/pages/mocks/login': login,
	'/ru/pages/mocks/account': personal,
	'/ru/pages/mocks/account/orders': orders,
	'/ru/pages/mocks/account/wishlist': wishlist,
	'/ru/pages/mocks/account/addresses': addresses,

	'/ru/pages/mocks/brand': brand,
	'/ru/pages/mocks/collection': collection,

	/*
	 * Эндпоинты форм
	 */
	'/mocks/form/error': formError,
	'/mocks/form/success': formSuccess,
	'/mocks/form/login': loginFormSubmitPhone,
};

export const mocksData: Record<string, BlockCatalogAllProductsContent | BlockAccountOrdersContent> =
	{
		/*
		 * Эндпоинты выборки данных
		 */
		'/ru/mocks/account/orders': ordersData,
		'/ru/mocks/account/wishlist': wishlistData,
		'/ru/mocks/account/addresses': addressesData,
		'/ru/catalog/search/product': searchData,
	};

export const getMock = <T = PageData>(url: string): T | undefined => {
	let mock;

	Object.keys(mocks).forEach((r) => {
		const pattern = r.replace(/(\(\?)?:\w+/g, (match, optional) => {
			return optional ? match : '([^/?]+)';
		});

		const regExp = new RegExp(`^` + pattern + `(?:\\\\?([\\\\s\\\\S]*))?$`);
		let result = regExp.exec(url);

		if (!result) {
			return;
		}

		mock = mocks[r];
	});

	return mock;
};

export const getDataMock = async (url: string) => {
	const frags = url.split('?');
	const pathname = frags[0];
	const query = frags[1];
	const search = new URLSearchParams(query);
	const limit = Number(search?.get('limit')) || 17;

	return await new Promise((resolve) => {
		setTimeout(() => {
			let mock;
			switch (pathname) {
				case '/ru/mocks/account/orders':
					mock = { ...(mocksData[pathname] || {}) } as BlockAccountOrdersContent;
					if (mock?.orders) {
						mock.orders = mock.orders.slice(0, limit);
					}
					break;
				case '/ru/mocks/account/wishlist':
					mock = { ...(mocksData[pathname] || {}) } as BlockCatalogAllProductsContent;
					if (mock?.products) {
						mock.products = mock.products.slice(0, limit);
					}
					break;
				case '/ru/mocks/account/addresses':
					mock = { ...(mocksData[pathname] || {}) } as BlockAccountAddressesContent;
					if (mock?.addresses) {
						mock.addresses = mock.addresses.slice(0, limit);
					}
					break;
				case '/ru/catalog/search/product':
					mock = { ...(mocksData[pathname] || {}) } as BlockCatalogAllProductsContent;
					if (mock?.products) {
						mock.products = mock.products.slice(0, limit);
					}
					break;
			}
			resolve(mock);
		}, 200);
	});
};
