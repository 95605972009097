import { ContentTypeKeys } from '@api/hooks/types';
import { FC, ComponentType, PropsWithChildren } from 'react';
import { Empty } from '@shared';
import {
	ErrorPage,
	IntroCover,
	PolaroidGallery,
	Catalog,
	CatalogCollection,
	CatalogSectionsStack,
	ProductDetails,
	RelatedProducts,
	ProductTableSize,
	Cart,
	Checkout,
	CheckoutStatus,
	Contacts,
	TextPage,
	StillHaveQuestions,
	Login,
	AccountPersonalDynamic,
	AccountOrdersDynamic,
	AccountWishlistDynamic,
	AccountAddressesDynamic,
	Search,
	TwinImages,
	FeaturesStack,
	CollectionGallery,
	CollectionProducts,
	FigureTeaser,
	MediaSection,
	FigureText,
	PolaroidLine,
	BrandIntro,
	TextPageHeader,
} from '@components';

export const sections: Record<
	ContentTypeKeys,
	FC<PropsWithChildren<any>> | ComponentType<PropsWithChildren<any>>
> = {
	'block-empty': Empty,
	'block-error': ErrorPage,
	'block-intro-cover': IntroCover,
	'block-polaroid-gallery': PolaroidGallery,
	'block-catalog-collection': CatalogCollection,
	'block-catalog-sections-stack': CatalogSectionsStack,
	'block-products-all': Catalog,
	'block-section-products': Catalog,
	'block-catalog-product': ProductDetails,
	'block-catalog-recommendations': RelatedProducts,
	'block-catalog-sizes-table': ProductTableSize,
	'block-search-products': Search,
	'block-basket': Cart,
	'block-basket-recommendations': RelatedProducts,
	'block-order-main': Checkout,
	'block-order-status': CheckoutStatus,
	'block-main-contacts': Contacts,
	'block-text-page': TextPage,
	'block-text-page-header': TextPageHeader,
	'block-still-have-questions': StillHaveQuestions,
	'block-login': Login,
	'block-account-personal': AccountPersonalDynamic,
	'block-account-orders': AccountOrdersDynamic,
	'block-account-wishlist': AccountWishlistDynamic,
	'block-account-addresses': AccountAddressesDynamic,
	'block-brand-intro': BrandIntro,
	'block-polaroid-line': PolaroidLine,
	'block-figure-text': FigureText,
	'block-media': MediaSection,
	'block-teaser': FigureTeaser,
	'block-twin-images': TwinImages,
	'block-features': FeaturesStack,
	'block-collection-gallery': CollectionGallery,
	'block-collection-products': CollectionProducts,
};
