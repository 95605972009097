import css from './TextPageNav.module.scss';
import classnames from 'classnames';
import { BlockTextPage } from '@api/mock/types';
import React, { FC, HTMLAttributes, useEffect, useRef } from 'react';
import { LinkTag } from '@shared';
import { useRouter } from 'next/router';
import { getOffset, withLocale } from '@utils';

interface TextPageNavProps
	extends HTMLAttributes<HTMLElement>,
		Pick<BlockTextPage, 'sectionsNav'> {
			isAuthorized?: boolean
		}

export const TextPageNav: FC<TextPageNavProps> = ({ sectionsNav: links, children, isAuthorized }) => {
	const { asPath } = useRouter();
	const ref = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const scroller = ref.current;
		const activeLink = scroller?.querySelector<HTMLLinkElement>(`.${css.isActive}`);
		if (scroller && activeLink) {
			/*
			 * Решение в лоб
			 */
			scroller.scrollLeft =
				activeLink.offsetLeft - scroller.clientWidth / 2 + activeLink.clientWidth / 2;

			/*
			 * Нативно, но затрагивает вертикальный скролл
			 */
			// activeLink.scrollIntoView({
			// 	inline: 'end',
			// });
		}
	}, [asPath]);

	return (
		<nav className={classnames(css.component)}>
			<div ref={ref} className={classnames(css.nav, {
				[css.navDisabled]: !isAuthorized && asPath === '/account/wishlist'
			})}>
				{links?.map(({ url, name }, i) => {
					const path = asPath.split('?')[0];
					const isActive = url === path || url === withLocale(path);
					return url ? (
						<LinkTag
							key={`text-page-nav-${url}-${i}`}
							href={url}
							className={classnames(css.navLink, isActive && css.isActive)}>
							{name}
						</LinkTag>
					) : null;
				})}
				{children}
			</div>
		</nav>
	);
};

interface TextPageNavButtonProps extends HTMLAttributes<HTMLButtonElement> {
	isActive?: boolean;
}

export const TextPageNavButton: FC<TextPageNavButtonProps> = ({
	isActive,
	className,
	children,
	...rest
}) => {
	return (
		<button
			type="button"
			className={classnames(className, css.navLink, isActive && css.isActive)}
			{...rest}>
			{children}
		</button>
	);
};
