import css from './ToggleButtons.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { Button } from '@shared';

interface ToggleButtonsProps extends HTMLAttributes<HTMLElement> {
	isFade?: boolean;
	onFilterClick?: () => void;
	onSorterClick?: () => void;
}

export const ToggleButtons: FC<ToggleButtonsProps> = ({ isFade, onFilterClick }) => {
	return (
		<div className={classnames(css.component, { [css.isFade]: isFade })}>
			<Button
				type="button"
				className={css.btn}
				onClick={() => {
					onFilterClick && onFilterClick();
				}}
				disabled={true}>
				<div className={css.anchor}>Filters</div>
			</Button>
		</div>
	);
};
