import css from './ProductMain.module.scss';
import classnames from 'classnames';
import type { ProductDetailsProps } from '@components';
import React, { FC } from 'react';
import { Breadcrumbs, Container, Currency, Typography } from '@shared';
import { ProductPurchase } from '@components/ProductDetails/components/ProductPurchase/ProductPurchase';
import { ProductDescription } from '@components/ProductDetails/components/ProductDescription/ProductDescription';
import { useProductDetails } from '@components';
import { usePrice } from '@hooks';

interface ProductMainProps extends ProductDetailsProps {}

export const ProductMain: FC<ProductMainProps> = ({ className, breadcrumbs, name, tag, ...props }) => {
	const { offer } = useProductDetails();
	const price = usePrice(offer?.price);

	return (
		<Container className={classnames(className, css.product)}>
			<Breadcrumbs className={css.breadcrumbs} items={breadcrumbs} />
			<Typography className={css.title} as="h1" type="h3">
				{name}
			</Typography>
			{price && (
				<Typography
					className={css.price}
					as="div"
					type="h5"
					itemType="http://schema.org/Offer"
					itemProp="offers"
					itemScope>
					<span itemProp="price">{price}</span> <Currency />
				</Typography>
			)}
			{!!tag && (
				<Typography
					className={classnames(css.tag, {
						[css.tag_green]: tag?.color === 'green',
						[css.tag_graphite]: tag?.color === 'graphite',
					})}>
					{tag?.name}
				</Typography>
			)}
			<ProductPurchase {...props} />
			<ProductDescription {...props} />
		</Container>
	);
};
