import css from './Checkout.module.scss';
import classnames from 'classnames';
import type { HTMLElementProps } from '@api/hooks/types';
import type { BlockBasketOrderContent } from '@api/mock/types';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Box, Container, Section } from '@shared';
import { CheckoutTopPanel } from '@components/Checkout/components/CheckoutTopPanel/CheckoutTopPanel';
import { CheckoutSummary } from '@components/Checkout/components/CheckoutSummary/CheckoutSummary';
import { CheckoutForm } from '@components/Checkout/components/CheckoutForm/CheckoutForm';
import { CheckoutPromoCode } from '@components/Checkout/components/CheckoutPromoCode/CheckoutPromoCode';
import { FormSumbitHandlerType } from '@shared/Form/FormComponent/FormComponent';
import { submitForm } from '@api/utils/submit';
import { useCartData } from '@components/Cart/hooks';
import { useRouter } from 'next/router';
import { BASKET_ROUTE } from '@utils';

export type CheckoutContextProps = Pick<
	CheckoutProps,
	| 'textCheckoutOrder'
	| 'textPay'
	| 'textCompletion'
	| 'textPersonalData'
	| 'textOrderDelivery'
	| 'textPayMethod'
	| 'textPromoCode'
	| 'textToPay'
	| 'textDescription'
	| 'textOrderPrice'
	| 'textDiscount'
	| 'textDelivery'
	| 'textTotalPrice'
	| 'textSelectDeliveryPoint'
	| 'textMapReturn'
	| 'textMapSelectPoint'
	| 'textMapSelectedPoint'
	| 'textMapPointTitle'
	| 'textMapPrice'
	| 'textMapDeliveryTime'
	| 'textMapPointInfo'
	| 'textMapWorkTime'
	| 'textMapPhone'
	| 'textMapSearchPlaceholder'
> & {
	paymentOnline?: boolean;
	setPaymentOnline?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const CheckoutContext = React.createContext<CheckoutContextProps>({});

export interface CheckoutProps extends HTMLElementProps, BlockBasketOrderContent {}

export const Checkout: FC<CheckoutProps> = React.memo((props) => {
	const { data } = useCartData();
	const { push } = useRouter();

	useEffect(() => {
		if (data?.products?.length === 0) {
			push(BASKET_ROUTE).then();
		}
	}, [data, push]);

	const [paymentOnline, setPaymentOnline] = useState(true);

	const { orderForm } = props;

	const formRef = useRef<HTMLFormElement | null>(null);
	const formSubmitRef = useRef<HTMLButtonElement | null>(null);

	const [isLoading, setIsloading] = useState(false);

	const submitHandler: FormSumbitHandlerType = useCallback(
		(e, ctx) => {
			e.preventDefault();

			if (!orderForm) return;

			const formEl = e.target as HTMLFormElement;
			const formData = new FormData(formEl);

			if (orderForm.providedData) {
				for (const key in orderForm.providedData) {
					if (orderForm.providedData.hasOwnProperty(key)) {
						formData.append(key, orderForm.providedData[key]);
					}
				}
			}

			if (ctx?.setFormState) {
				ctx.setFormState('pending');
			}

			setIsloading(true);

			/*
			 * Проверка каптчи
			 */
			const checkCaptcha =
				ctx?.checkCaptcha ||
				(() => new Promise<string>((resolve) => setTimeout(() => resolve(''), 10)));

			checkCaptcha().then((token) => {
				formData.append('recaptcha', token || '');

				if (!orderForm.action) return;

				submitForm({
					url: orderForm.action,
					method: (orderForm.method || 'post') as CRUDType,
					body: formData,
				}).then((response) => {
					setIsloading(false);

					if (response.location) {
						push(response.location).then();
					}

					/*
					 * ToDo: Обработка ошибок
					 */
					if (response?.status === 'error') {
						console.warn(response);
					}
				});
			});
		},
		[orderForm, push]
	);

	const asideButtonClickHandler = useCallback(() => {
		formSubmitRef?.current?.click();
	}, [formSubmitRef]);
	
	return (
		<CheckoutContext.Provider value={{ ...props, paymentOnline, setPaymentOnline }}>
			<Section className={classnames(css.component)}>
				<CheckoutTopPanel {...props} />
				<Container className={css.layout}>
					<Box className={css.content}>
						<Box className={css.contentInner}>
							<CheckoutForm
								formRef={formRef}
								formSubmitRef={formSubmitRef}
								onSubmit={submitHandler}
								{...props}
							/>
							{/*<CheckoutPromoCode />*/}
						</Box>
					</Box>
					<Box className={css.aside}>
						<CheckoutSummary
							onClickButton={asideButtonClickHandler}
							isSubmiting={isLoading}
							{...props}
						/>
					</Box>
				</Container>
			</Section>
		</CheckoutContext.Provider>
	);
});
