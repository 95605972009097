import type { MainNavigation } from '@model/models';
import React, {
	FC,
	PropsWithChildren,
	useState,
	createContext,
	useContext,
	useEffect,
	useMemo,
} from 'react';
import { concatQuery, scrollToElement } from '@utils';
import { useViewport } from '@context';
import { useFilterSearch } from '@hooks';

export type CatalogViewType = 'sm' | 'md' | 'lg';

interface CatalogContextProps {
	limit: number;
	view: CatalogViewType;
	setView?: React.Dispatch<React.SetStateAction<CatalogViewType>>;
	query?: string;
}

const initialState = {
	limit: 15,
	view: 'md' as const,
	query: '',
};

export const CatalogContext = createContext<CatalogContextProps>(initialState);

export const CatalogContextProvider: FC<
	PropsWithChildren<{
		container?: HTMLElement | null;
		navigation?: MainNavigation;
		view?: CatalogViewType;
	}>
> = ({ container, navigation = {}, view: staticView, children }) => {
	const { isMob } = useViewport();

	const [view, setView] = useState<CatalogViewType>(staticView || initialState.view);

	const query = useFilterSearch();
	const limit = useMemo(() => getLimits(isMob)[view], [isMob, view]);

	useEffect(() => {
		if (container) {
			scrollToElement(container, 72);
		}
	}, [container, view, query]);

	const { sectionCode } = navigation || {};

	const queryString = useMemo(() => {
		return concatQuery(query, sectionCode ? `sectionCode=${sectionCode}` : ``);
	}, [query, sectionCode]);

	return (
		<CatalogContext.Provider
			value={{
				limit,
				query: queryString,
				view,
				setView,
			}}>
			{children}
		</CatalogContext.Provider>
	);
};

export const useCatalog = () => {
	return useContext(CatalogContext);
};

const mob: Record<CatalogViewType, number> = { sm: 12, md: 10, lg: 10 };
const desktop: Record<CatalogViewType, number> = { sm: 20, md: 15, lg: 10 };

const getLimits = (isMob: boolean = false) => (isMob ? mob : desktop);
