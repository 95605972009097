import type { MutableRefObject } from 'react';
import { useEffect } from 'react';

type ElementType = HTMLElement | null;
type RefType = ElementType | MutableRefObject<ElementType>;

const getElement = (ref: MutableRefObject<HTMLElement | null> | HTMLElement | null) =>
	ref instanceof HTMLElement ? ref : ref?.current || null;

export const useOutsideClick = <T = HTMLElement>(
	ref: RefType | RefType[],
	handler: ((e?: MouseEvent | TouchEvent) => void) | undefined,
	exclude?: Array<string>,
	safe?: Array<string>
) => {
	useEffect(() => {
		let mounted = true;
		const isSSR = typeof window === 'undefined';

		if (isSSR || !handler || !mounted) return;

		const refs = Array.isArray(ref) ? [...ref] : [ref];
		const elements = refs.map(getElement);

		const listener = (event: MouseEvent | TouchEvent) => {
			const target = event.target as HTMLElement;
			const hasExclude = exclude && exclude.some((c) => !!target.closest(c));

			if (
				elements.reduce((acc, item) => {
					acc = acc || !item || item.contains(target);
					if (hasExclude) acc = false;
					return acc;
				}, false)
			)
				return;

			if (safe && safe.some((c) => !!target.closest(c))) return;

			if (mounted) {
				handler && handler(event);
			}
		};

		let startX = 0;

		const onTouchStart = (e: TouchEvent) => {
			startX = e?.touches[0]?.clientX || 0;
		};
		const onTouchMove = (e: TouchEvent) => {
			const moveX = e?.touches[0]?.clientX || 0;
			const diffX = Math.abs(startX - moveX);

			if (diffX > 5) {
				listener(e);
			}
		};

		document.addEventListener('mouseup', listener);
		document.addEventListener('touchend', listener);

		document.addEventListener('touchstart', onTouchStart);
		document.addEventListener('touchmove', onTouchMove);

		return () => {
			mounted = false;

			document.removeEventListener('mouseup', listener);
			document.removeEventListener('touchend', listener);

			document.removeEventListener('touchstart', onTouchStart);
			document.removeEventListener('touchmove', onTouchMove);
		};
	}, [ref, handler, exclude, safe]);
};
