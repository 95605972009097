import css from './PopUp.module.scss';
import classnames from 'classnames';
import React, {
	FC,
	PropsWithChildren,
	useEffect,
	useRef,
	useMemo,
	useState,
	useCallback,
	useContext,
	ReactNode,
} from 'react';
import { CloseBtn, Icon, Portal } from '@shared';
import { CloseButton } from './shared';
import { useDelayUnmount, useOutsideClick, useEscape, useLockBodyByDialogs } from '@hooks';
import { useViewport } from '@context';
import FocusTrap from 'focus-trap-react';

type RefType = HTMLDivElement | null;

interface PopUpProps {
	active?: boolean;
	name?: string;
	duration?: number;
	boxWidth?: number | string;
	className?: string;
	classNameBox?: string;
	overlay?: 'solid' | null;
	closeHandler?: (e?: MouseEvent | TouchEvent) => void;
	closeButton?: boolean;
	closeButtonIcon?: ReactNode;
	closeButtonClassName?: string;
	closeButtonPosition?: 'box' | 'overlay';
	closeButtonText?: string;
	label?: string;
}

export const PopUpContext = React.createContext<{
	key: string[];
	toggle?: (key?: string) => void;
	data?: any;
	setData?: React.Dispatch<any>;
}>({
	key: [],
});

export const PopUpProvider: FC<PropsWithChildren> = ({ children }) => {
	const [data, setData] = useState<any>();
	const [currentKeys, setCurrentKeys] = useState<string[]>([]);

	const togglePopUp = useCallback((key?: string) => {
		setCurrentKeys((prev) => {
			if (!key) return [];

			if (key && prev.includes(key)) {
				return prev.filter((stateKey) => stateKey !== key);
			} else {
				return [...prev, key];
			}
		});
	}, []);

	return (
		<PopUpContext.Provider value={{ key: currentKeys, toggle: togglePopUp, data, setData }}>
			{children}
		</PopUpContext.Provider>
	);
};

export const PopUp = React.forwardRef<RefType, PropsWithChildren<PopUpProps>>(
	(
		{
			active,
			name,
			duration = 600,
			boxWidth = 744,
			className,
			classNameBox,
			overlay = 'solid',
			closeHandler,
			closeButton = true,
			closeButtonIcon = <Icon id="close" />,
			closeButtonClassName,
			closeButtonPosition = 'box',
			closeButtonText = 'Скрыть',
			label = 'Модальное окно',
			children,
		},
		ref
	) => {
		const { isMob } = useViewport();

		const { key: contextKeys, toggle: contextToggle } = useContext(PopUpContext);
		const storeState = name ? contextKeys.includes(name) : false;

		const boxRef = useRef<HTMLDivElement | null>(null);
		const isMount = active && !name ? active : name ? storeState : false;

		const shouldRender = useDelayUnmount(isMount, duration);
		const maxWidthCss = useMemo(() => ({ maxWidth: boxWidth }), [boxWidth]);

		/*
		 * Закрытие поп-апа
		 */
		const handleClose = useCallback(() => {
			closeHandler && closeHandler();
			if (name && contextKeys.includes(name) && contextToggle) {
				contextToggle(name);
			}
		}, [closeHandler, name, contextKeys, contextToggle]);

		useEscape(shouldRender, handleClose);
		useOutsideClick<HTMLDivElement | null>(
			boxRef,
			handleClose,
			[],
			['[role="dialog"]', '[role="menu"]']
		);

		/*
		 * Блокировка скролла
		 */
		useLockBodyByDialogs(shouldRender);

		/*
		 * Тип перехода
		 */
		const [transition, setTransition] = useState('waiting');

		useEffect(() => {
			if (isMount) {
				window.setTimeout(() => {
					setTransition('enter');
				}, 100);
			}
			if (!isMount && shouldRender) {
				setTransition('exit');
			}
			if (!isMount && !shouldRender) {
				setTransition('waiting');
			}
		}, [isMount, shouldRender]);

		const popup = useMemo(() => {
			const closeBtn = isMob ? (
				<CloseButton
					className={css.popup__close}
					icon={closeButtonIcon}
					label={closeButtonText}
					onClick={handleClose}
				/>
			) : (
				<CloseBtn className={css.popup__close} onClick={handleClose}>
					{closeButtonText}
				</CloseBtn>
			);

			return (
				<FocusTrap>
					<aside
						role="dialog"
						aria-modal="true"
						aria-label={label}
						className={classnames(
							css.popup,
							css[transition],
							className,
							{
								[css.isShown]: isMount,
								[css.isFullScreen]: boxWidth === 'none',
							},
							overlay && css[overlay]
						)}
						ref={ref}>
						<PopUpOverlay overlay={overlay} handleClose={handleClose} />
						{closeButton && closeButtonPosition === 'overlay' && closeBtn}
						<div className={css.popup__capsule}>
							<div className={css.popup__inner}>
								<div className={css.popup__perspective} style={maxWidthCss}>
									<div
										className={classnames(classNameBox, css.popup__box)}
										style={maxWidthCss}
										ref={boxRef}>
										{closeButton && closeButtonPosition === 'box' && closeBtn}
										{children}
									</div>
								</div>
							</div>
						</div>
					</aside>
				</FocusTrap>
			);
		}, [
			isMob,
			isMount,
			transition,
			maxWidthCss,
			handleClose,
			ref,
			closeButton,
			closeButtonIcon,
			closeButtonText,
			closeButtonPosition,
			label,
			className,
			boxWidth,
			overlay,
			classNameBox,
			children,
		]);

		return shouldRender ? <Portal>{popup}</Portal> : null;
	}
);

export const PopUpOverlay: FC<
	PropsWithChildren<Pick<PopUpProps, 'overlay'> & { handleClose?: () => void }>
> = ({ overlay, handleClose, children }) => {
	if (!overlay) return null;

	const clickHandler = () => {
		handleClose && handleClose();
	};

	return (
		<div className={classnames(css.popup__overlay, css[overlay])} onClick={clickHandler}>
			{children}
		</div>
	);
};

export const usePopUp = () => {
	return useContext(PopUpContext);
};
