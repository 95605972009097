import { PageData } from '@api/hooks/types';

export const collection: PageData = {
	meta: [
		{
			type: 'title',
			content: 'SS Collection 2022 | Agapium',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			type: 'block-intro-cover',
			content: {
				textCollection: 'SS Collection 2022',
				mainPhoto: {
					url: 'https://agapium.backend.rdclr.ru/upload/uf/a06/hw14urx77d6a0o0z6vme3z63yd1abiq1.png',
					alt: '',
				},
			},
		},
		{
			type: 'block-figure-text',
			content: {
				pre: 'Эстетика коллекции',
				title: 'Ты можешь пойти на деловую встречу утром, а вечером отправиться в театр',
				text: 'Сложный крой с имитацией доспехов, с их брутальными формами и геометрией, в то же время воздушные полупрозрачные ткани и романтичные воланы. Отсылки к историческому белью и аксессуарам.',
			},
		},
		{
			type: 'block-twin-images',
			content: {
				images: [
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 1080,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
				],
				textTicker: 'SS Collection 2022',
			},
		},
		{
			type: 'block-collection-gallery',
			content: {
				items: [
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 1080,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
				],
			},
		},
		{
			type: 'block-collection-products',
			content: {
				products: [
					{
						id: 123,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 124,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 125,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 126,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 127,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 128,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 129,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
				],
			},
		},
		{
			type: 'block-polaroid-line',
			content: {
				grayscale: true,
				galleryImages: [
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 1080,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
				],
			},
		},
		{
			type: 'block-media',
			content: {
				textPlay: 'Воспроизвести',
				textPause: 'Остановить',
				src: 'https://previews.customer.envatousercontent.com/h264-video-previews/3e7d95db-2c8a-4a93-bad1-c3f1cfbd8a40/5346243.mp4?_=2',
				poster: '/temp/mediaSectionPoster.jpg',
				// youtube: 'https://www.youtube.com/watch?v=bOFkda4DbJ0'
			},
		},
		{
			type: 'block-figure-text',
			content: {
				pre: 'Эстетика коллекции',
				title: 'Ты можешь пойти на деловую встречу утром, а вечером отправиться в театр',
				text: 'Сложный крой с имитацией доспехов, с их брутальными формами и геометрией, в то же время воздушные полупрозрачные ткани и романтичные воланы. Отсылки к историческому белью и аксессуарам.',
			},
		},
		{
			type: 'block-collection-gallery',
			content: {
				items: [
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 1080,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
					{
						url: '/temp/pic-07.jpg',
						width: 740,
						height: 880,
						alt: '',
					},
				],
			},
		},
		{
			type: 'block-collection-products',
			content: {
				toAllProducts: {
					url: '/catalog/section',
					name: 'Смотреть все модели колекции',
				},
				textNext: 'Смотреть еще',
				textPrev: 'Вернуть модели',
				products: [
					{
						id: 123,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 124,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 125,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 126,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 127,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 128,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
					{
						id: 129,
						name: '\u041f\u043b\u0430\u0442\u044c\u0435 \u043e\u0431\u044b\u0447\u043d\u043e\u0435',
						code: 'plate-obich',
						detailPageUrl: '/ru/catalog/product/plate-obich',
						color: '#000',
						colorText: '\u0427\u0435\u0440\u043d\u044b\u0439',
						images: [
							{
								url: '/temp/pic-07.jpg',
								alt: '',
							},
						],
						offers: [
							{
								id: 6,
								size: 'L',
								article: '25t213424t4opo',
								isAvailable: true,
								price: {
									rub: {
										value: '12500.00',
									},
								},
							},
						],
					},
				],
			},
		},
	],
};
