import css from '../../Checkout.module.scss';
import classnames from 'classnames';
import type { HTMLElementProps } from '@api/hooks/types';
import type { BlockBasketOrderStatusContent } from '@api/mock/types';
import React, { FC, useEffect } from 'react';
import { Box, Section, Button, Container, Icon, Typography } from '@shared';
import { CheckoutTopPanel } from '@components/Checkout/components/CheckoutTopPanel/CheckoutTopPanel';
import { useCartData } from '@components/Cart/hooks';

export interface CheckoutStatusProps extends HTMLElementProps, BlockBasketOrderStatusContent {}

export const CheckoutStatus: FC<CheckoutStatusProps> = React.memo((props) => {
	const { textTitle, textInfo, completeLink } = props;
	const { revalidate } = useCartData();

	useEffect(() => {
		revalidate && revalidate();
	}, [revalidate]);

	return (
		<Section className={classnames(css.component)}>
			<CheckoutTopPanel isComplete={true} {...props} />
			<Container className={css.status}>
				<Typography className={css.statusTitle} as="div" type="h3">
					{textTitle}
					<Icon className={css.statusSmile} id="smile-positive" />
				</Typography>
				<Typography as="p" size="sm" raw={true}>
					{textInfo}
				</Typography>
				<Box className={css.statusTools}>
					{completeLink?.url && (
						<Button to={completeLink.url} design="flat" color="dark">
							{completeLink.name}
						</Button>
					)}
				</Box>
			</Container>
		</Section>
	);
});
