import css from './CartTable.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { Container } from '@src/shared';
import { useBxComponents } from '@context';

interface CartTableProps extends HTMLAttributes<HTMLElement> {}

export const CartTable: FC<CartTableProps> = ({ children }) => {
	const { cart: cartTexts } = useBxComponents();
	const { textColor, textCount, textModel, textPrice, textSize } = cartTexts;

	return (
		<div className={classnames(css.component)}>
			<Container className={classnames(css.thead, css.row)}>
				<div className={css.cell}>{textModel}</div>
				<div className={css.cell}>{textColor}</div>
				<div className={css.cell}>{textSize}</div>
				<div className={css.cell}>{textCount}</div>
				<div className={css.cell}>{textPrice}</div>
			</Container>
			<Container className={css.tbody}>{children}</Container>
		</div>
	);
};
