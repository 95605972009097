import css from './FormResponse.module.scss';
import classnames from 'classnames';
import { HTMLElementProps } from '@api/hooks/types';
import React, { FC, useEffect, useState } from 'react';
import { Box, Typography as Typo, TypographySizeType, TypographyTagType } from '@shared';
import { useDelayUnmount } from '@hooks';

export interface FormResponseProps extends HTMLElementProps {
	active: boolean;
	response: FormResponse | null;
	align?: 'left' | 'center' | 'right';
	justify?: 'top' | 'center' | 'bottom';
	titleType?: TypographyTagType;
	textSize?: TypographySizeType;
}

export const FormResponse: FC<FormResponseProps> = ({
	active,
	response,
	align,
	justify,
	titleType = 'h2',
	textSize = 'md',
	className,
}) => {
	const [data, setData] = useState<FormResponse | null>(null);
	const shouldRender = useDelayUnmount(active, 800);

	useEffect(() => {
		if (response && response?.status === 'success') {
			setData(response);
		}
	}, [response]);

	return (
		<>
			{shouldRender && (
				<Box
					className={classnames(
						className,
						css.response,
						align && css[align],
						justify && css[`justify-${justify}`],
						{
							[css.isShown]: active,
						}
					)}>
					{typeof data?.response === 'string' ? (
						<Typo className={css.text} size="md" color="gray" raw={true}>
							{data.response}
						</Typo>
					) : (
						<>
							{data?.response?.title && (
								<Typo className={css.title} type={titleType} raw={true}>
									{data?.response?.title}
								</Typo>
							)}
							<Typo className={css.text} size={textSize} raw={true} color="gray">
								{data?.response?.text}
							</Typo>
						</>
					)}
				</Box>
			)}
		</>
	);
};
