import { PageData } from '@api/hooks/types';

export const orderSuccess: PageData = {
	meta: [
		{
			type: 'title',
			content: 'Ваш заказ успешно оформлен | Agapium',
		},
		{
			type: 'meta',
			content: 'Описание магазина Agapium',
			name: 'description',
			nameType: 'property',
		},
	],
	items: [
		{
			/*
			 * status т. к. может пригодиться в дальнейшем вообще здесь что-то по этому заказу показывать, не только успех
			 */
			type: 'block-order-status',
			content: {
				/*
				 * Поля по аналогии со страницей оформления
				 * Текстовые фразы и крошки для верхней панели
				 */
				textCheckoutOrder: 'Оформление заказа',
				textPay: 'Оплата',
				textCompletion: 'Заверешение заказа',
				breadcrumbs: [{ utl: '/catalog/sections', name: 'Вернуться в магазин' }],
				/*
				 * Само сообщение
				 * Ссылку потом можно будет на раздел заказов в ЛК увести, но пока что туда никак
				 */
				textTitle: 'Ваш заказ успешно оформлен',
				textInfo: 'Какой-то пояснительный текст или еще что-то такое',
				completeLink: {
					name: 'Перейти в магазин',
					url: '/catalog/sections',
				},
			},
		},
	],
};
