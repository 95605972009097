import css from './TwinImages.module.scss';
import classnames from 'classnames';
import { ImageType } from '@model/imageType';
import React, { FC, HTMLAttributes, useMemo } from 'react';
import { Box, Icon, Section, Typography } from '@shared';
import Image from 'next/image';

interface TwinImagesProps extends HTMLAttributes<HTMLElement> {
	images?: Array<ImageType>;
	textTicker?: string;
}

/*
 * ToDo: блок с двумя картинками и бегущим текстом снизу
 *  1) картинки могут быть разных пропорций (см. на макетах в "Бренде" и в "Коллекции")
 *  2) бегущие тексты сделать по аналогии с такими же блоками в "Политике" или "Клиентском сервисе" (после текста внизу)
 */

export const TwinImages: FC<TwinImagesProps> = React.memo(({ images, textTicker }) => {
	const textLine = useMemo(
		() => (
			<Box className={css.textTicker}>
				<span>
					{textTicker} <Icon id="bullet" /> {textTicker} <Icon id="bullet" />
				</span>
				<span>
					{textTicker} <Icon id="bullet" /> {textTicker} <Icon id="bullet" />
				</span>
			</Box>
		),
		[textTicker]
	);

	return (
		<Section className={classnames(css.component)}>
			<Box className={css.pictures}>
				{images?.map(({ url, width, height, alt = '' }, i) => (
					<picture key={`twin-pic-${url}-${i}`}>
						{url && (
							<Image
								src={url.replace(/\s/gm, '')}
								alt={alt}
								width={width}
								height={height}
								layout="responsive"
								objectFit="cover"
								sizes="50vw"
								quality={100}
								priority={true}
							/>
						)}
					</picture>
				))}
			</Box>
			<Box className={css.labels}>
				<Typography className={css.labelsItem} type="h1">
					<span className={css.labelsItemInner}>{textLine}</span>
				</Typography>
				<Typography className={css.labelsItem} type="h1">
					<span className={css.labelsItemInner}>{textLine}</span>
				</Typography>
			</Box>
		</Section>
	);
});
