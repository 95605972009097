import css from './PageLoadScreen.module.scss';
import classnames from 'classnames';
import React, { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { Container, Odometer, Typography } from '@shared';
import { useBxComponents, usePageLoadState } from '@context';
import { useDelayUnmount, useIsomorphicLayoutEffect } from '@hooks';
import { getRandom } from '@utils';
import { useRouter } from 'next/router';

interface PageLoadScreenProps {}

const tick = {
	initial: 200,
	regular: 800,
};

export const PageLoadScreen: FC<PropsWithChildren<PageLoadScreenProps>> = React.memo(() => {
	const { setLoadingState, setReadyState } = usePageLoadState();
	const { preloader = [] } = useBxComponents();
	const { asPath } = useRouter();

	const title = useMemo(
		() => preloader.find(({ type }) => type === 'title')?.content || null,
		[preloader]
	);
	const sentences = useMemo(() => {
		const array =
			preloader.filter(({ type }) => type !== 'title').map(({ content }) => content) || [];
		return [...array, ...array];
	}, [preloader]);

	const [step, setStep] = useState(0);
	const [tailStep, setTailStep] = useState(0);
	const [start, setStart] = useState(false);
	const [complete, setComplete] = useState(asPath !== '/' ? true : false);

	const shouldRender = useDelayUnmount(!complete, 2000);

	useEffect(() => {
		let timer = 0;

		if (complete) {
			timer = window.setTimeout(() => {
				setReadyState && setReadyState(true);
				setLoadingState && setLoadingState('complete');
			}, 1200);
		}

		return () => {
			window.clearTimeout(timer);
		};
	}, [complete, setReadyState, setLoadingState]);

	useIsomorphicLayoutEffect(() => {
		if (typeof window === 'undefined') return;

		let timer = 0;
		let timerInit = 0;
		let step = 0;
		let tailStep = 0;
		let loaded = false;

		const waitingLoop = () => {
			const interval = (resolve: (value?: unknown) => void, timeout: number) => {
				timer = window.setTimeout(() => {
					const documentReady = document.readyState === 'complete';
					if (documentReady) {
						setTailStep(30);
						setTimeout(resolve, tick.regular);
					} else {
						tailStep = tailStep + getRandom(1, 5);
						setTailStep(tailStep);
						interval(resolve, tick.regular);
					}
				}, timeout);
			};
			return new Promise((resolve) => {
				interval(resolve, 1);
			});
		};

		const animationLoop = () => {
			const interval = (resolve: (value?: unknown) => void, timeout: number) => {
				timer = window.setTimeout(() => {
					if (loaded) {
						resolve();
					} else {
						if (!loaded) step = Math.min(3, step + 1);
						if (step === 3) loaded = true;
						setStep(step);
						interval(resolve, timeout);
					}
				}, timeout);
			};
			return new Promise((resolve) => {
				interval(resolve, tick.regular);
			});
		};

		const initLoadScreen = () => {
			setStart(true);
			animationLoop().then(() => {
				waitingLoop().then(() => {
					setComplete(true);
				});
			});
		};

		initLoadScreen();
		document.documentElement.classList.add('is-started');

		return () => {
			window.clearTimeout(timer);
			window.clearTimeout(timerInit);
		};
	}, []);

	const stepsProgress = Math.min(70, step * 20 + (step > 0 ? 10 : 0));
	const waitingProgress = Math.min(30, tailStep);

	const progress = stepsProgress + waitingProgress;

	return !complete || shouldRender ? (
		<div
			className={classnames(
				css.component,
				{ start: start, complete: complete },
				`next-${Math.min(step, 3)}`
			)}>
			<Container className={css.screen}>
				<div className={css.text}>
					<div className={css.textLine}>
						<div className={css.textStatic}>{title}</div>
						<div className={css.textDynamic}>
							{sentences?.map((item, i) => (
								<div key={`preloader-text-${i}`}>{item}</div>
							))}
						</div>
					</div>
				</div>
				<div className={css.progress}>
					<div
						className={css.bar}
						style={{
							width: progress ? `${progress}%` : 24,
						}}>
						<div className={css.counter}>
							<Typography size="md" weight="regular" className={css.counterInner}>
								<Odometer value={progress || 1} length={3} />%
							</Typography>
						</div>
					</div>
					<div
						className={css.line}
						style={{
							width: progress ? `${progress}%` : undefined,
						}}
					/>
				</div>
			</Container>
		</div>
	) : null;
});
