export type StaticPath = {
	path: string;
	locale?: 'ru' | 'en';
};

export const staticPaths: Array<StaticPath> = [{ path: '/', locale: 'ru' }];

export const tempMockPaths: Array<StaticPath> = [
	{ path: '/contacts', locale: 'ru' },
	{ path: '/client-service/payment-delivery', locale: 'ru' },
	{ path: '/client-service/refund', locale: 'ru' },
	{ path: '/client-service/faq', locale: 'ru' },
	{ path: '/client-service/privacy-police', locale: 'ru' },
	{ path: '/client-service/cookies-police', locale: 'ru' },
];
