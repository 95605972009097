import { getMock, mocks } from '@api/mock';
import { FetchConfig, fetcher } from '@api';
import { FormContextProps } from '@shared/Form/FormComponent/FormComponent';

export const submitForm = async ({
	url,
	method = 'post',
	headers = new Headers(),
	body = undefined,
}: FetchConfig) => {
	// Debug form data
	// if (body instanceof FormData) {
	// 	for (const pair of body.entries()) {
	// 		console.log(pair[0] + '=' + pair[1]);
	// 	}
	// }

	const needsMock = Object.keys(mocks).includes(url);
	const mock = async () => getMock<FormResponse>(url);

	if (needsMock && !!mock) {
		return mock();
	}

	/*
	 * Заголовки запроса
	 */
	headers.set('Content-Type', 'multipart/form-data');
	headers.set('Accept', 'application/json');
	headers.set('type', 'formData');

	return (
		fetcher<FormResponse>({
			url,
			method,
			headers,
			body,
		})
			/*
			 * Пробрасываем (return) ошибки форм в resolved-секцию
			 * для передачи в handleFormResponse
			 * Или throw если response отсутствует
			 */
			.catch((error) => {
				try {
					return error?.response?.data;
				} catch {
					throw error;
				}
			})
	);
};

export const handleFormResponse = async (response?: FormResponse, ctx?: FormContextProps) => {
	switch (response?.status) {
		/*
		 * Все ок, показываем сообщение
		 */
		case 'success':
			ctx?.setFormState && ctx.setFormState('success');
			ctx?.storeResponse && ctx?.storeResponse(response);
			break;

		/*
		 * Бэк шлёт ошибки, помечаем поля
		 */
		case 'error':
			ctx?.resetCaptcha && ctx?.resetCaptcha();
			ctx?.setFormState && ctx.setFormState('reject');
			ctx?.storeResponse && ctx?.storeResponse(response);

			if (response?.errors) {
				Object.entries(response.errors).forEach(([name, message]) => {
					const { setError } = ctx?.store ? ctx.store[name] : { setError: null };

					if (setError) {
						setError({
							type: 'backend',
							message,
						});
					}
				});

				// ctx?.setFormState && ctx.setFormState('waiting');
			}

			break;
	}

	return response;
};
