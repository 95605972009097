import css from './CatalogFilter.module.scss';
import classnames from 'classnames';
import scrollLocker from '@utils/utils.scroll/ScrollLocker';
import React, { FC, HTMLAttributes, useCallback, useState } from 'react';
import { Box, Button, CloseBtn } from '@shared';
import { FilterPanel, IFiltersItem, ToggleButtons, ViewSlider } from '@components/CatalogFilter/components';
import { useScrollSeek } from '@hooks';
import { useViewport } from '@context';

type CatalogFilterPopupState = 'filter' | 'sorter' | null;

interface CatalogFilterProps extends HTMLAttributes<HTMLElement> {
	filterBlock?: IFiltersItem[]
}

export const CatalogFilter: FC<CatalogFilterProps> = ({filterBlock}) => {
	const { isTablet } = useViewport();

	const [main, setMain] = useState<HTMLElement | null>(null);
	const [ref, setRef] = useState<HTMLElement | null>(null);
	const [popupState, setPopupState] = useState<CatalogFilterPopupState>(null);

	const refCb = useCallback((node: HTMLDivElement | null) => {
		if (node) {
			setRef(node);
			setMain(node.closest('main'));
		}
	}, []);

	const { vh } = useViewport();

	useScrollSeek(
		main,
		useCallback(
			({ bounding }) => {
				const touchBottom = bounding.bottom - vh / 2 <= 0;
				ref && ref.classList.toggle(css.isFade, touchBottom);
			},
			[ref, vh]
		),
		{
			checkInView: true,
		}
	);

	const handlePopupStateToggle = (key: CatalogFilterPopupState) => {
		setPopupState((prev) => {
			const state = prev === key ? null : key;
			if (state) {
				scrollLocker.lock();
			} else {
				scrollLocker.unlock().then();
			}
			return state;
		});
	};

	return (
		<Box ref={refCb} className={classnames(css.component)}>
			<Box className={css.topPanel}>
				<Box className={css.topPanelButtons}>
					<Button
						type="button"
						className={classnames(css.topPanelBtn, { [css.isActive]: popupState === 'filter' })}
						onClick={() => handlePopupStateToggle('filter')}
						disabled>
						{popupState === 'filter' ? 'Hide filters' : 'Filters'}
					</Button>
				</Box>
				<ViewSlider isOpen={!!popupState} />
			</Box>
			<Box className={classnames(css.position)}>
				<aside className={classnames(css.popup, { [css.isOpen]: !!popupState })}>
					{!isTablet ? (
						<>
							<CloseBtn
								className={css.closeBtn}
								onClick={() => {
									setPopupState(null);
								}}>
								Скрыть
							</CloseBtn>
							<ViewSlider isOpen={!!popupState} />
							<FilterPanel isOpen={!!popupState} filterItems={filterBlock} />
							<ToggleButtons
								isFade={!!popupState}
								onFilterClick={() => {
									setPopupState('filter');
								}}
							/>
						</>
					) : (
						<FilterPanel isOpen={!!popupState} filterItems={filterBlock} />
					)}
				</aside>
			</Box>
		</Box>
	);
};
