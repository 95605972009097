import React, { FC } from 'react';
import { useViewport } from '@context';
import { ProductGalleryRegular } from './components/ProductGalleryRegular';
import { ProductGalleryMobile } from '@components/ProductDetails/components/ProductGallery/components/ProductGalleryMobile';
import { BlockCatalogProductContent } from '@model/blockCatalogProductContent';

export interface ProductGalleryProps extends BlockCatalogProductContent {
	className?: string;
}

export const ProductGallery: FC<ProductGalleryProps> = (props) => {
	const { isTablet } = useViewport();
	return !isTablet ? <ProductGalleryRegular {...props} /> : <ProductGalleryMobile {...props} />;
};
