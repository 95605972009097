import css from './FormElement.module.scss';
import classnames from 'classnames';
import type { ErrorType } from '@shared/Form/FormComponent/hooks/useInput';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Box } from '@shared';
import { FormError } from '@shared/Form/FormError/FormError';

interface FormElementProps extends HTMLAttributes<HTMLDivElement> {
	error?: ErrorType[];
	isLoading?: boolean;
}

export const FormElement: FC<PropsWithChildren<FormElementProps>> = ({
	error,
	isLoading,
	style,
	className,
	children,
}) => {
	const errors = error?.reduce<string[]>((acc, item) => {
		const msgs = Array.isArray(item.message) ? item.message : [item.message || ''];
		acc = [...acc, ...msgs];
		return acc;
	}, []);

	return (
		<Box
			style={style}
			className={classnames(css.formElement, className, {
				[css.isError]: !!error,
				[css.isLoading]: isLoading,
			})}>
			{children}
			<FormError active={!!error} text={errors} />
		</Box>
	);
};
