import {
	ComponentHeaderContent,
	ComponentFooterContent,
	ComponentBasketContent,
	ComponentPopupCookieContent,
	ComponentPreloader,
} from '@model/models';
import React, { FC, PropsWithChildren, createContext, useContext, useMemo } from 'react';
import { useComponentsData } from '@api';

interface AppDataContextProps {
	header: ComponentHeaderContent;
	footer: ComponentFooterContent;
	cart: ComponentBasketContent;
	cookieConsent: ComponentPopupCookieContent;
	preloader: ComponentPreloader['content'];
}

export const AppDataContext = createContext<AppDataContextProps>({
	header: {},
	footer: {},
	cart: {},
	cookieConsent: {},
	preloader: [],
});

export const AppDataProvider: FC<PropsWithChildren> = ({ children }) => {
	const { data } = useComponentsData('/components');
	const components = useMemo(() => data?.items || [], [data]);

	const header = components.find(({ type }) => type === 'component-header')?.content || {};
	const footer = components.find(({ type }) => type === 'component-footer')?.content || {};
	const cart = components.find(({ type }) => type === 'component-basket')?.content || {};
	const preloader = components.find(({ type }) => type === 'component-preloader')?.content || [];
	const cookieConsent =
		components.find(({ type }) => type === 'component-popup-cookie')?.content || {};

	return (
		<AppDataContext.Provider value={{ header, footer, cart, preloader, cookieConsent }}>
			{children}
		</AppDataContext.Provider>
	);
};

export const useBxComponents = () => {
	return useContext(AppDataContext);
};
