import css from './PopUpHead.module.scss';
import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import { Box, Typography } from '@shared';

interface PopUpHeadProps {
	title?: JSX.Element | string;
}

export const PopUpHead: FC<PropsWithChildren<PopUpHeadProps>> = ({ title, children }) => {
	return (
		<Box className={classnames(css.head)}>
			{title && (
				<Typography className={classnames(css.title)} type="h5">
					{title}
				</Typography>
			)}
			{children}
		</Box>
	);
};
