export const getBounds = (coords: number[][]) => {
	const lngs = coords.map((c) => c[0]);
	const lats = coords.map((c) => c[1]);

	const minLngs = Math.min(...lngs);
	const maxLngs = Math.max(...lngs);

	const minLats = Math.min(...lats);
	const maxLats = Math.max(...lats);

	return {
		southWest: [minLngs, maxLats],
		northEast: [maxLngs, minLats],
	};
};
