import css from './Sandwich.module.scss';
import classnames from 'classnames';
import React, { FC, MouseEventHandler } from 'react';
import { Button } from '@shared';
import { useRouter } from 'next/router';

interface Props {
	active?: boolean;
	onClick?: MouseEventHandler;
}

export const Sandwich: FC<Props> = ({ active, onClick }) => {
	const { locale } = useRouter();
	const label = locale === 'en' ? 'Menu' : 'Меню';

	return (
		<Button
			design="flat"
			className={classnames(css.sandwich, { [css.isActive]: active })}
			onClick={onClick}
			aria-label={label}>
			<span className={css.sandwichIcon}>
				<span />
				<span />
			</span>
		</Button>
	);
};
