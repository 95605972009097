import css from './PolaroidLine.module.scss';
import classnames from 'classnames';
import { BlockPolaroidLineContent } from '@api/mock/types';
import React, { FC, HTMLAttributes, useCallback, useMemo, useState } from 'react';
import Image from 'next/image';
import { Section, Box, PolaroidCard } from '@shared';
import { useViewport } from '@context';
import { useScrollSeek } from '@hooks';
import { limit, normalize } from '@utils';

interface PolaroidLineProps extends HTMLAttributes<HTMLElement>, BlockPolaroidLineContent {}

export const PolaroidLine: FC<PolaroidLineProps> = React.memo(
	({ galleryImages = [], grayscale }) => {
		const [section, setSection] = useState<HTMLElement | null>(null);

		const { vh, isMob } = useViewport();

		useScrollSeek(
			section,
			useCallback(
				({ boundingAnimated, bounding }) => {
					let seek, norm;

					if (isMob) {
						seek = 1 - limit((boundingAnimated.bottom - vh * 0.75) / (bounding.height - vh * 0.25));
						norm = 1 - normalize(seek, 1, 0, true);
					} else {
						seek = 1 - limit((boundingAnimated.bottom - vh) / (bounding.height - vh * 0.75));
						norm = 1 - normalize(seek, 0.85, 0, true);
					}

					if (section) {
						section.style.setProperty('--seek', norm.toString());
					}
				},
				[vh, isMob, section]
			),
			{
				checkInView: true,
				resist: 0.125,
			}
		);

		const firstChunk = useMemo(() => galleryImages.slice(0, 2), [galleryImages]);
		const lastChunk = useMemo(() => galleryImages.slice(2, 4), [galleryImages]);

		return (
			<Section
				ref={setSection}
				className={classnames(css.component, { [css.grayscale]: !!grayscale })}>
				<Box className={css.sticky}>
					<Box className={css.line}>
						<Box className={css.cell}>
							<div className={classnames(css.item, css.itemA)}>
								<div className={css.itemInner}>
									<PolaroidCard mode="fit" size="sm">
										{firstChunk[0]?.url && (
											<Image
												src={firstChunk[0]?.url}
												alt={firstChunk[0]?.alt || ''}
												layout="fill"
												objectFit="cover"
												quality={100}
												priority={true}
												sizes="(max-width: 1023px) 25vw, 25vw"
												itemProp="image"
											/>
										)}
									</PolaroidCard>
								</div>
							</div>
							<div className={classnames(css.item, css.itemB)}>
								<div className={css.itemInner}>
									<PolaroidCard mode="fit" size="sm">
										{firstChunk[1]?.url && (
											<Image
												src={firstChunk[1]?.url}
												alt={firstChunk[1]?.alt || ''}
												layout="fill"
												objectFit="cover"
												quality={100}
												priority={true}
												sizes="(max-width: 1023px) 25vw, 25vw"
												itemProp="image"
											/>
										)}
									</PolaroidCard>
								</div>
							</div>
						</Box>
						<Box className={css.cell}>
							<div className={classnames(css.item, css.itemC)}>
								<div className={css.itemInner}>
									<PolaroidCard mode="fit" size="sm">
										{lastChunk[0]?.url && (
											<Image
												src={lastChunk[0]?.url}
												alt={lastChunk[0]?.alt || ''}
												layout="fill"
												objectFit="cover"
												quality={100}
												priority={true}
												sizes="(max-width: 1023px) 25vw, 25vw"
												itemProp="image"
											/>
										)}
									</PolaroidCard>
								</div>
							</div>
							<div className={classnames(css.item, css.itemD)}>
								<div className={css.itemInner}>
									<PolaroidCard mode="fit" size="sm">
										{lastChunk[1]?.url && (
											<Image
												src={lastChunk[1]?.url}
												alt={lastChunk[1]?.alt || ''}
												layout="fill"
												objectFit="cover"
												quality={100}
												priority={true}
												sizes="(max-width: 1023px) 25vw, 25vw"
												itemProp="image"
											/>
										)}
									</PolaroidCard>
								</div>
							</div>
						</Box>
					</Box>
				</Box>
			</Section>
		);
	}
);
