import css from './CatalogCollection.module.scss';
import classnames from 'classnames';
import type { BlockCatalogCollectionContent } from '@model/models';
import React, { FC, HTMLAttributes, useCallback, useState } from 'react';
import { Button, Container, Heading, Section } from '@shared';
import { useScrollSeek } from '@hooks';
import { useViewport } from '@context';
import { CatalogFeed } from '@src/components';
import { CatalogContextProvider } from '@components/Catalog/context/Catalog.context';

interface CatalogCollectionProps
	extends HTMLAttributes<HTMLElement>,
		BlockCatalogCollectionContent {}

export const CatalogCollection: FC<CatalogCollectionProps> = React.memo((props) => {
	const [section, setSection] = useState<HTMLElement | null>(null);

	const { vh, isTablet } = useViewport();

	useScrollSeek(
		section,
		useCallback(
			({ bounding }) => {
				if (section) {
					section.classList.toggle(css.isShown, bounding.top + (isTablet ? -vh / 2 : vh) <= 0);

					const isLight = -bounding.top > bounding.height / (isTablet ? 4 : 2);

					section.classList.toggle(css.light, isLight);
					section.classList.toggle('is-dark', !isLight);
				}
			},
			[section, vh, isTablet]
		),
		{
			resist: 0,
			checkInView: true,
		}
	);

	return (
		<Section ref={setSection} className={classnames(css.component)}>
			<div className={classnames(css.sticky)}>
				<CatalogCollectionComponent {...props} />
			</div>
		</Section>
	);
});

export const CatalogCollectionComponent: FC<CatalogCollectionProps> = React.memo(
	({ textTitle, watchMore, products }) => {
		return (
			<div className={css.content}>
				<Heading className={css.head} classNameTitle={css.title} title={textTitle}>
					{watchMore && (
						<Button
							to={watchMore.url}
							color="dark"
							design="stroked"
							wide={true}
							className={css.moreLink}>
							{watchMore.name}
						</Button>
					)}
				</Heading>
				<CatalogContextProvider>
					<Container className={css.catalog}>
						<CatalogFeed skipDataFetching={true} staticData={products} />
					</Container>
				</CatalogContextProvider>
			</div>
		);
	}
);
