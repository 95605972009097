import css from '../CartElement.module.scss';
import classnames from 'classnames';
import React, { FC, useCallback, useMemo } from 'react';
import { CartElementOfferType, CartElemmentComponentProps, Select } from '@shared';

interface CartElementSizeProps extends CartElemmentComponentProps {
	setSelected?: (offer?: CartElementOfferType) => void;
}

export const CartElementSize: FC<CartElementSizeProps> = ({
	offer,
	offers,
	setSelected,
	className,
	selectable = true,
}) => {
	const selectedSize: SelectOption | undefined = useMemo(() => {
		switch (selectable) {
			case true: {
				if (!offer?.id || !offer?.size) {
					return undefined;
				}
				return {
					label: offer?.size.toUpperCase(),
					value: offer?.id,
				};
			}
			case false: {
				if (!offer?.id || !offer?.size) {
					if (!offers?.[0].id || !offers?.[0].size) {
						return undefined
					}
					return {
						label: offers?.[0].size.toUpperCase(),
						value: offers?.[0].id
					}
				}
				return {
					label: offer?.size.toUpperCase(),
					value: offer?.id,
				};
			}
		}
		
	}, [offer?.id, offer?.size, offers, selectable]);

	const sizes: SelectOption[] = useMemo(
		() =>
			offers?.filter(item => item.restCount !== 0).map(({ id, size, isSelected }) => ({
				label: size?.toUpperCase() || '',
				value: id || '',
				checked: !!isSelected,
			})) || [],
		[offers]
	);

	const handleSelect = useCallback(
		(selected?: SelectOption) => {
			if (!selected) return;

			const { value } = selected;
			const selectedOffer = offers?.find(({ id }) => id === value);

			setSelected && setSelected(selectedOffer);
		},
		[offers, setSelected]
	);

	return (
		<div className={classnames(className, css.size)}>
			{selectable ? (
				<Select
					name="size"
					options={sizes}
					selected={selectedSize}
					setSelected={handleSelect}
					className={css.select}
					portalize={false}
				/>
			) : (
				selectedSize?.label
			)}
		</div>
	);
};
