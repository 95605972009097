import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const useHomePage = () => {
	const { asPath } = useRouter();
	const [isHomePage, setIsHomePage] = useState(false);

	const route = asPath.split('?')[0];

	useEffect(() => {
		let timer = 0;
		const state = route === '/';

		timer = window.setTimeout(() => {
			setIsHomePage(state);
		}, 0);

		return () => window.clearTimeout(timer);
	}, [route]);

	return isHomePage;
};
