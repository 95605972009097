import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export const Portal: FC<PropsWithChildren> = React.memo(({ children }) => {
	return typeof document !== 'undefined' ? createPortal(children, document.body) : null; // <>{children}</>;
});

export const ClientPortal: FC<PropsWithChildren> = React.memo(({ children }) => {
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setMounted(true);
	}, []);

	return mounted ? createPortal(children, document.body) : null; // <>{children}</>;
});
