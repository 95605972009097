import axios, { AxiosResponse } from 'axios';
import { getJWT, isEmptyObject, setJWT } from '@utils';
import { ParsedUrlQuery } from 'querystring';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';

const API_HOST = process.env.NEXT_PUBLIC_API_HOST || '';
const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || '';

/*
 * Отсечение слэшей
 */
export const withoutTrailingSlash = (url: string) => url.replace(/\/$/, '');
export const withoutLeadingSlash = (url: string) => url.replace(/^\//, '');

export const trimSlash = (url: string) => withoutLeadingSlash(withoutTrailingSlash(url));

/*
 * Отсечение amp'ов
 */
export const withoutTrailingAmp = (q: string) => q.replace(/&$/, '');
export const withoutLeadingAmp = (q: string) => q.replace(/^&/, '');

export const trimAmp = (q: string) => withoutLeadingAmp(withoutTrailingAmp(q));

/*
 * Собирает url
 */
export const concatUrls = (base: string, url: string) => {
	base = withoutTrailingSlash(base);
	url = withoutLeadingSlash(url);

	if (url.match(base)) return url;

	return `${base}/${url}`;
};

/*
 * Очищает url от /api/v1/
 */
export const cleanupUrl = (url: string) => url.replace(/(^.*api\/(v\d)*)/, '');

/*
 * Возвращает полный url до API
 */
export const getUrl = (url: string): string => {
	const isDev = process.env.NODE_ENV !== 'production';
	const isClient = typeof window !== 'undefined';
	const apiUrl = isDev && isClient ? '/api/' : API_HOST + API_BASE_URL;
	return `${withoutTrailingSlash(apiUrl)}/${withoutLeadingSlash(cleanupUrl(url))}`;
};

/*
 * Добавляет переданную локаль в url
 */
export const trimLocale = (url?: string) => {
	return url ? url.replace(/\/(ru|en)$/, '').replace(/^\/(ru|en)\//, '/') : url;
};

export const withLocale = (url: string, locale?: string): string => {
	url = url.replace(/\/(ru|en)$/, '').replace(/^\/(ru|en)\//, '/');
	const i18n = locale ? `/${locale}` : `/ru`;
	return `${i18n}${url}`;
};

export const prepareApiUrl = (url: string, locale?: string, prefix: string = ''): string => {
	if (url.match(/(^\/(ru|en))/)) {
		return url.replace(/(^\/(ru|en))/, `$1${prefix}`);
	}
	return `/${locale}${prefix}${url}`;
};

/*
 * Query string
 */
export const nextQueryToString = (parsedQuery: ParsedUrlQuery): string => {
	const query = { ...parsedQuery };
	if (query.slug) delete query.slug;

	return !isEmptyObject(query)
		? Object.entries(query)
				.map((pair) => `${pair[0]}=${pair[1]}`)
				.join('&')
		: '';
};

export function concatQuery(...args: Array<string | undefined>): string {
	const chunks = [];
	for (const chunk of args) {
		if (typeof chunk === 'string' && !!chunk) {
			chunks.push(chunk);
		}
	}
	return chunks.join('&');
}

/*
 * Готовит заголовки
 */
export const getHeaders = (headers: Headers): Record<string, string> => {
	const access = getJWT();

	if (access) {
		headers.set('Authorization', `Bearer ${access}`);
	}

	headers.set('content-type', `application/json`);

	return Object.fromEntries(headers.entries());
};

/*
 * Обновление токена
 */
export const refreshToken = (): Promise<AxiosResponse> => {
	const refresh = getJWT('refresh');

	return axios({
		method: 'POST',
		url: getUrl('auth/refresh/'),
		data: { refresh: refresh },
	}).then((response) => {
		try {
			setJWT({
				access: response.data.access,
			});
			return response;
		} catch (e) {
			throw response;
		}
	});
};

export const prepareHeaderCookies = (cookies: NextApiRequestCookies) => {
	const headers = new Headers();

		headers.set(
			'cookie',
			Object.entries(cookies)
				.reduce((acc, [k, v]) => {
					acc.push(`${k}=${v}`);
					return acc;
				}, [] as string[])
				.join(';')
		);
	return headers
};

export * from './jwt';
export * from './secure';
