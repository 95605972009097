import css from './MediaSection.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { Section, Typography } from '@shared';
import { MediaSectionContent } from '@src/api/mock/types';
import Image from 'next/image';
import { VideoCursor } from '@shared/VideoCursor/VideoCursor';
import { isTouchDevice, scrollToElement } from '@utils';
import { useViewport } from '@context';

interface MediaSectionProps extends HTMLAttributes<HTMLElement>, MediaSectionContent {}

const isTouch = isTouchDevice();

export const MediaSection: FC<MediaSectionProps> = React.memo(
	({ src, poster, youtube, textPlay, textPause }) => {
		const { isTablet } = useViewport();

		const ref = useRef<HTMLDivElement | null>(null);
		const videoRef = useRef<HTMLVideoElement>(null);
		const youtubeId = useMemo(() => youtube?.split('v=')[1], [youtube]);

		const [videoIsPlaying, setVideoIsPlaying] = useState(false);
		const [posterFullScreen, setPosterFullScreen] = useState(false);
		const [cursorVisible, setCursorVisible] = useState(false);

		useEffect(() => {
			const video = videoRef.current;

			if (video && !!src) {
				const onPlay = () => {
					video.setAttribute('controls', 'controls');
				};
				const onPause = () => {
					setVideoIsPlaying(false);
					setPosterFullScreen(false);
					video.removeAttribute('controls');
				};

				video.addEventListener('play', onPlay);
				video.addEventListener('pause', onPause);

				return () => {
					video.removeEventListener('play', onPlay);
					video.removeEventListener('pause', onPause);
				};
			}
		}, [src]);

		const posterClickHandler = () => {
			if (!!src) {
				if (videoRef.current?.paused) setTimeout(() => videoRef.current?.play(), 300);
			}
			// if (!!youtube) {
			// 	setCursorVisible(false);
			// }
			setVideoIsPlaying(true);
			setPosterFullScreen(true);
		};

		useEffect(() => {
			if (!isTablet && posterFullScreen && ref.current) {
				scrollToElement(ref.current);
			}
		}, [posterFullScreen, isTablet]);

		return (
			<Section ref={ref} className={classnames(css.component)}>
				<div
					className={classnames(css.sticky, { [css.sticky_active]: posterFullScreen })}
					onMouseMove={() => setCursorVisible(true)}
					onMouseLeave={() => setCursorVisible(false)}>
					{/* необходимо раскоментировать для для добавления видео из youtube
					 {poster && youtubeId && (
						<Image
							src={poster}
							alt=""
							layout="fill"
							objectFit="cover"
							onClick={posterClickHandler}
							className={classnames(css.poster, { [css.poster_hidden]: videoIsPlaying })}
						/>
					)} */}
					{src && (
						<div className={css.videoTagWrapper}>
							<div
								onClick={posterClickHandler}
								className={classnames(css.videoTagLayer, {
									[css.videoTagLayer_hidden]: videoIsPlaying,
								})}
							/>
							<video
								ref={videoRef}
								// onClick={posterClickHandler}
								className={css.video}
								preload="metadata">
								<source src={`${src}#t=0.001`} type="video/mp4" />
							</video>
						</div>
					)}
					{/* необходимо раскоментировать для для добавления видео из youtube
					 {!src && youtubeId && videoIsPlaying && (
						<iframe
							src={`https://www.youtube.com/embed/${youtubeId}?autoplay=${Number(videoIsPlaying)}`}
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
							className={css.video}
						/>
					)} */}
					{isTablet ? (
						<Typography className={css.indicator} size="sm" aria-hidden="true">
							{videoIsPlaying ? textPause : textPlay}
						</Typography>
					) : (
						<VideoCursor
							visible={cursorVisible}
							playing={videoIsPlaying}
							textPlay={textPlay}
							textPause={textPause}
						/>
					)}
				</div>
			</Section>
		);
	}
);
