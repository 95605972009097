import css from './CloseButton.module.scss';
import classnames from 'classnames';
import React, { FC, MouseEvent, ReactNode, useCallback } from 'react';

interface Props {
	onClick: (e?: MouseEvent) => void;
	className?: string;
	label?: string;
	icon?: ReactNode;
}

export const CloseButton: FC<Props & Partial<HTMLButtonElement>> = ({
	onClick,
	className,
	label = 'Закрыть модальное окно',
	icon,
}) => {
	const clickHandler = useCallback(
		(e: MouseEvent) => {
			onClick && onClick(e);
		},
		[onClick]
	);

	return (
		<button
			className={classnames(css.button, className)}
			type="button"
			onClick={clickHandler}
			aria-label={label}>
			{icon}
		</button>
	);
};
