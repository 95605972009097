import css from './FilterPanel.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { Scroller, Brackets, Spoiler, Typography } from '@shared';
import { FilterControl } from '@components/CatalogFilter/components/FilterControl/FilterControl';

interface FilterPanelProps extends HTMLAttributes<HTMLElement> {
	isOpen?: boolean;
	filterItems?: IFiltersItem[]
}

export interface IFiltersItem {
	name: 'category' | 'price' | 'sizes' | 'color';
	label: string;
	items?: Array<IItem>
	min?: number;
	max?: number
}

export interface IItem {
	label: string;
	value: string;
	hex?: string;
}


export const FilterPanel: FC<PropsWithChildren<FilterPanelProps>> = ({ isOpen, children, filterItems }) => {
	return (
		<Scroller
			className={classnames(css.component, { [css.isShown]: isOpen })}
			options={{ wheelPropagation: false }}>
			{filterItems?.map(item => <Spoiler
				key={item.name}
				className={css.group}
				classNameHead={css.groupHead}
				classNameBody={css.groupBody}
				defaultActive={true}
				head={
					<Typography className={css.groupHeadInner} size="md">
						{item.label} {item.items?.length && <Brackets>{item.items?.length}</Brackets>}
					</Typography>
				}>
				<FilterControl {...item} />
			</Spoiler>)}

			{children}
		</Scroller>
	);
};
