import css from './Slider.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useEffect, useMemo, useState } from 'react';

export type AxisType = 'x' | 'y';
type CoordType = `client${Uppercase<AxisType>}`;
type ValueType = number | string;

interface SliderProps extends Omit<HTMLAttributes<HTMLElement>, 'onChange'> {
	value: ValueType;
	points: Array<ValueType>;
	onChange: (value: any) => void;
	axis?: AxisType;
	isHidden?: boolean;
}

const getSize = (el: HTMLElement, axis: AxisType = 'x') => {
	return axis === 'x' ? el.offsetWidth : el.offsetHeight;
};

const getOffset = (el: HTMLElement, axis: AxisType = 'x') => {
	return axis === 'x' ? el.offsetLeft : el.offsetTop;
};

const getCoord = (e: MouseEvent | TouchEvent, axis: AxisType = 'x') => {
	const param: CoordType = `client${axis.toUpperCase() as Uppercase<AxisType>}`;
	return (e as TouchEvent).touches ? (e as TouchEvent).touches[0][param] : (e as MouseEvent)[param];
};

export const Slider: FC<SliderProps> = React.memo(
	({ value, points, onChange, axis = 'x', isHidden, className }) => {
		const [slider, setSlider] = useState<HTMLDivElement | null>(null);
		const [sliderBtn, setSliderBtn] = useState<HTMLButtonElement | null>(null);
		const [sliderThumb, setSliderThumb] = useState<HTMLDivElement | null>(null);

		const anchors = useMemo(() => points.map((p, i) => (1 / (points.length - 1)) * i), [points]);
		const current = useMemo(() => points.findIndex((p) => p === value), [points, value]);

		useEffect(() => {}, [value]);

		useEffect(() => {
			if (!slider || !sliderBtn || !sliderThumb) return;

			const state = {
				start: 0,
				move: 0,
				moving: false,
				diff: 0,
				position: 0,
			};

			const rewindToAnchor = () => {
				const goal = state.position / getSize(slider, axis);
				const dest = anchors.reduce(function (prev, curr) {
					return Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev;
				});

				const onTransitionEnd = () => {
					sliderThumb.style.transition = '';
					sliderThumb.removeEventListener('transitionend', onTransitionEnd);

					if (onChange) {
						const index = anchors.findIndex((a) => a === dest);
						onChange(points[index]);
					}
				};

				sliderThumb.addEventListener('transitionend', onTransitionEnd);
				sliderThumb.style.transition = `left .6s cubic-bezier(.215, .61, .355, 1), top .6s cubic-bezier(.215, .61, .355, 1)`;

				setTimeout(() => {
					switch (axis) {
						case 'x':
							sliderThumb.style.left = `${dest * getSize(slider, axis)}px`;
							break;
						case 'y':
							sliderThumb.style.top = `${dest * getSize(slider, axis)}px`;
							break;
					}
				}, 1);
			};

			const computeSlide = () => {
				const pos = Math.max(0, Math.min(state.position + state.diff, getSize(slider, axis) + 1));
				switch (axis) {
					case 'x':
						sliderThumb.style.left = `${pos || .1}px`;
						break;
					case 'y':
						sliderThumb.style.top = `${pos || .1}px`;
						break;
				}
			};

			const onMove = (e: MouseEvent | TouchEvent) => {
				state.move = getCoord(e, axis);

				if (state.moving) {
					state.diff = state.move - state.start;
					computeSlide();
				}
			};

			const onDown = (e: MouseEvent | TouchEvent) => {
				state.moving = true;
				state.start = getCoord(e, axis);
				state.position = getOffset(sliderThumb, axis);

				window.addEventListener('mouseup', onUp);
				window.addEventListener('touchend', onUp);

				window.addEventListener('mousemove', onMove);
				window.addEventListener('touchmove', onMove);
			};

			const onUp = () => {
				if (state.moving) {
					state.moving = false;
					state.position = getOffset(sliderThumb, axis);

					rewindToAnchor();
				}

				window.removeEventListener('mouseup', onUp);
				window.removeEventListener('touchend', onUp);

				window.removeEventListener('mousemove', onMove);
				window.removeEventListener('touchmove', onMove);
			};

			sliderBtn.addEventListener('mousedown', onDown);
			sliderBtn.addEventListener('touchstart', onDown);

			return () => {
				sliderBtn.removeEventListener('mousedown', onDown);
				sliderBtn.removeEventListener('touchstart', onDown);
			};
		}, [points, onChange, axis, slider, sliderBtn, sliderThumb, anchors]);

		return (
			<div
				ref={setSlider}
				className={classnames(className, css.slider, css[`axis${axis.toUpperCase()}`], {
					[css.isFade]: isHidden,
				})}>
				<div
					ref={setSliderThumb}
					className={css.sliderThumb}
					style={{
						[axis === 'x' ? 'left' : 'top']: `${anchors[current] * 100}%`,
					}}>
					<button type="button" ref={setSliderBtn} className={css.sliderBtn}>
						<svg viewBox="0 0 12 12">
							<g fillRule="evenodd">
								<path d="M11.992 0c-1.748 3.942-1.748 8.062.008 12-3.94-1.756-8.057-1.756-12-.008C1.764 8.055 1.764 3.943.008.008 3.942 1.764 8.056 1.764 11.992 0z" />
								<path d="M9.995 2C8.829 4.628 8.829 7.374 10 10c-2.626-1.17-5.371-1.17-8-.005 1.176-2.625 1.176-5.366.005-7.99C4.628 3.176 7.371 3.176 9.995 2z" />
							</g>
						</svg>
					</button>
				</div>
			</div>
		);
	}
);
