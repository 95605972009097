import css from './CheckoutTopPanel.module.scss';
import classnames from 'classnames';
import { CheckoutProps } from '@components';
import { CheckoutStatusProps } from '@components/Checkout/components/CheckoutStatus/CheckoutStatus';
import React, { FC } from 'react';
import { useViewport } from '@context';
import { Breadcrumbs, Button, Container } from '@shared';
import { useRouterPath } from '@hooks';

interface CheckoutTopPanelProps
	extends Pick<CheckoutProps, 'textPay' | 'textCheckoutOrder' | 'textCompletion'> {
	isComplete?: boolean;
}

const ORDER_ROUTE = '/basket/order';
const ORDER_COMPLETE_ROUTE = '/basket/order/complete';

export const CheckoutTopPanel: FC<CheckoutTopPanelProps> = ({
	textPay,
	textCheckoutOrder,
	textCompletion,
	isComplete,
}) => {
	const { isMob } = useViewport();
	const path = useRouterPath();

	return isMob ? (
		<Container className={css.componentMob}>
			<Breadcrumbs items={[{ textTitle: 'Вернуться в корзину', url: '/basket' }]} />
		</Container>
	) : (
		<aside className={classnames(css.component)}>
			<Button
				as="div"
				disabled={!path.includes(ORDER_ROUTE) && !isComplete}
				className={classnames(css.point, {
					[css.isActive]: path.includes(ORDER_ROUTE) || isComplete,
				})}>
				{textCheckoutOrder}
			</Button>
			<Button
				as="div"
				disabled={!path.includes(ORDER_COMPLETE_ROUTE) && !isComplete}
				className={classnames(css.point, {
					[css.isActive]: path.includes(ORDER_COMPLETE_ROUTE) || isComplete,
				})}>
				{textPay}
			</Button>
			<Button
				as="div"
				disabled={!path.includes(ORDER_COMPLETE_ROUTE) && !isComplete}
				className={classnames(css.point, {
					[css.isActive]: path.includes(ORDER_COMPLETE_ROUTE) || isComplete,
				})}>
				{textCompletion}
			</Button>
		</aside>
	);
};
