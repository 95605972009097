import css from './PolaroidGallery.module.scss';
import classnames from 'classnames';
import type { BlockPolaroidGalleryContent } from '@model/models';
import React, { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react';
import Image from 'next/image';
import { PolaroidCard, Section, Typography } from '@shared';
import { useViewport } from '@context';
import { useScrollSeek } from '@hooks';
import { limit, normalize } from '@utils';

interface PolaroidGalleryProps extends HTMLAttributes<HTMLElement>, BlockPolaroidGalleryContent {}

export const PolaroidGallery: FC<PolaroidGalleryProps> = React.memo((props) => {
	const [section, setSection] = useState<HTMLElement | null>(null);

	const { vh, vw } = useViewport();
	useEffect(() => {
		const aspectRatio = 1.6 / (vw / vh);
		if (aspectRatio <= 1) {
			section?.style.setProperty('--fsz', `${aspectRatio}`);
		}
		else section?.style.setProperty('--fsz', '1');
	}, [vh, vw, section]);

	useScrollSeek(
		section,
		useCallback(
			({ boundingAnimated, bounding }) => {
				const seek = 1 - limit((boundingAnimated.bottom - vh) / (bounding.height - vh * 0.75));
				const norm = 1 - normalize(seek, 0.65, 0, true);

				if (section) {
					section.style.setProperty('--seek', norm.toString());
				}
			},
			[vh, section]
		),
		{
			checkInView: true,
			resist: 0.125,
		}
	);

	return (
		<Section ref={setSection} className={classnames(css.component)}>
			<div className={classnames(css.layout, css.sticky)}>
				<PolaroidGalleryText {...props} />
				<PolaroidGalleryPics {...props} />
			</div>
		</Section>
	);
});

export const PolaroidGalleryPics: FC<PolaroidGalleryProps> = React.memo(({ galleryImages }) => {
	return (
		<div className={css.pictures}>
			{galleryImages?.map(({ url, alt }, i) => (
				<div key={`polaroid-gallery-pic-${i}`} className={css.point}>
					<div className={css.pic}>
						<PolaroidCard mode="fit">
							{url && (
								<Image
									src={url}
									alt={alt}
									layout="fill"
									objectFit="cover"
									quality={100}
									priority={true}
									sizes="(max-width: 1023px) 25vw, 25vw"
									itemProp="image"
								/>
							)}
						</PolaroidCard>
					</div>
				</div>
			))}
		</div>
	);
});

export const PolaroidGalleryText: FC<PolaroidGalleryProps> = React.memo(
	({ textPart1, textPart2, textPart3 }) => {
		const textMiddle = textPart2 && textPart2.replace('{', '').replace('}', '');
		return (
			<div className={css.text}>
				<Typography as="div" raw={true} className={css.textLine} type="h1">
					{textPart1}
				</Typography>
				<Typography as="div" raw={true} className={css.textMiddle}>
					&lbrace;{textMiddle}&rbrace;
				</Typography>
				<Typography as="div" raw={true} className={css.textLine} type="h1">
					{textPart3}
				</Typography>
			</div>
		);
	}
);
