import css from '../../Cart.module.scss';
import React, { FC } from 'react';
import { Button, Heading, Icon, Typography } from '@shared';
import { CartProps } from '@components';
import { useBxComponents } from '@context';

export const CartEmpty: FC<CartProps> = () => {
	const { cart: cartTexts } = useBxComponents();
	const { textEmptyHere, toMarket } = cartTexts;

	return (
		<>
			<Heading className={css.heading} inContainer={true}>
				<Typography as="div" type="h2">
					{textEmptyHere}
					<Icon className={css.smile} id="smile-negative" />
				</Typography>
			</Heading>

			{toMarket && (
				<Button to={toMarket.url} design="stroked" wide={true}>
					{toMarket.name}
				</Button>
			)}
		</>
	);
};
