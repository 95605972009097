import css from './CatalogSectionsCard.module.scss';
import classnames from 'classnames';
import type { HTMLElementProps } from '@api/hooks/types';
import type { CatalogProductsSection } from '@model/catalogProductsSection';
import React, { FC, useEffect, useState } from 'react';
import { Typography } from '@shared';
import Image from 'next/image';
import Link from 'next/link';

interface CatalogSectionsCardProps extends HTMLElementProps, CatalogProductsSection {}

export const CatalogSectionsCard: FC<CatalogSectionsCardProps> = React.memo(
	({ textTitle, url, images, className }) => {
		const [cardRef, setCardRef] = useState<HTMLElement | null>(null);
		const [titleRef, setTitleRef] = useState<HTMLElement | null>(null);

		useEffect(() => {
			if (cardRef && titleRef) {
				const { height } = titleRef?.getBoundingClientRect();
				cardRef.style.setProperty('--half-height', (height / 2).toFixed(0) + 'px');
			}
		}, [cardRef, titleRef]);

		const picture = images && images[0];

		return (
			<div ref={setCardRef} className={classnames(className, css.component)}>
				<div className={css.fig}>
					<div className={css.pic}>
						{picture?.url && (
							<Image
								src={picture.url}
								alt={picture.alt}
								layout="fill"
								quality={100}
								priority={true}
								sizes="(max-width: 1023px) 100vw, 50vw"
							/>
						)}
					</div>
				</div>
				<Link href={url || '/'} prefetch={false}>
					<a className={css.title}>
						<div className={css.titleAnchor}>
							<Typography as="h2" type="h2" className={css.titleText}>
								<span ref={setTitleRef}>{textTitle}</span>
							</Typography>
						</div>
					</a>
				</Link>
			</div>
		);
	}
);
