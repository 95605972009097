import css from './Spoiler.module.scss';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import React, {
	FC,
	HTMLAttributes,
	MouseEventHandler,
	PropsWithChildren,
	useCallback,
	useEffect,
	useState,
} from 'react';
import { Box } from '@shared';
import { checkKeyCode } from '@utils';

export interface SpoilerProps extends HTMLAttributes<HTMLDivElement> {
	head: string | JSX.Element;
	size?: 'sm' | 'md';
	isActive?: boolean;
	defaultActive?: boolean;
	toggleActive?: () => void;
	classNameHead?: string;
	classNameBody?: string;
}

export const Spoiler: FC<PropsWithChildren<SpoilerProps>> = React.memo(
	({
		head,
		size = 'sm',
		className,
		classNameHead,
		classNameBody,
		isActive,
		defaultActive = false,
		toggleActive,
		onClick,
		children,
	}) => {
		const [active, setActive] = useState(defaultActive);
		const state = typeof isActive !== 'undefined' ? isActive : active;

		const handleToggle = useCallback(() => {
			toggleActive && toggleActive();
			setActive((prev) => !prev);
		}, [toggleActive]);

		const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
			(e) => {
				onClick && onClick(e);

				if ((e.target as HTMLElement).closest('a')) {
					return;
				}

				handleToggle();
			},
			[onClick, handleToggle]
		);

		const [ref, setRef] = useState<HTMLElement | null>(null);

		useEffect(() => {
			const head = ref?.querySelector(`.${css.head}`);
			if (!head) return;

			const onKeypress = (e: KeyboardEvent) => {
				if (e.target === head && checkKeyCode(e, 32)) {
					e.preventDefault();
					handleToggle();
				}
			};

			document.documentElement.addEventListener('keydown', onKeypress);

			return () => {
				document.documentElement.removeEventListener('keydown', onKeypress);
			};
		}, [ref, handleToggle]);

		return (
			<Box
				ref={setRef}
				className={classnames(className, css.spoiler, css[size], { [css.active]: state })}>
				<Box
					tabIndex={0}
					onClickCapture={handleClick}
					className={classnames(classNameHead, css.head)}>
					{head}
					<span className={css.plus} />
				</Box>
				<Box className={css.body}>
					<AnimateHeight height={state ? 'auto' : 0} duration={600} animateOpacity={true}>
						<div className={classnames(classNameBody, css.inner)}>{children}</div>
					</AnimateHeight>
				</Box>
			</Box>
		);
	}
);
