import useSWR, { useSWRConfig } from 'swr';
import { ReturnedSWRData } from '@api/hooks/types';
import { fetcher } from '@api/utils/fetcher';
import { getDataMock } from '@api/mock';
import { useCallback } from 'react';

interface IUseDataProps {
	url?: string;
	query?: string;
}

export const getData = async (url: string, needMock = false) => {
	if (needMock) {
		return getDataMock(url);
	}

	return fetcher<any>(url);
};

export const useData = <T>(
	{ url, query }: IUseDataProps,
	needMock: boolean = false
): ReturnedSWRData<T> => {
	const { mutate } = useSWRConfig();
	const key = url ? `${url}${query ? `?${query}` : ``}` : null;

	const { data, error } = useSWR(key, needMock ? getDataMock : getData, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
		revalidateOnReconnect: true,
	});

	const revalidate = useCallback(async () => {
		if (key) await mutate(key);
	}, [key, mutate]);

	return {
		data: data,
		revalidate,
		isLoading: !error && !data && !!url,
		isError: error && !!url,
	};
};
