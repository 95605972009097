import css from './Breadcrumbs.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import type { Breadcrumbs as BreadcrumbType } from '@model/models';

export interface Breadcrumb extends BreadcrumbType {
	textTitle?: string;
	url?: string;
}

interface BreadcrumbsProps extends HTMLAttributes<HTMLElement> {
	items?: Array<Breadcrumb>;
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ items, className }) => {
	return items && Array.isArray(items) ? (
		<ul className={classnames(css.breadcrumbs, className)}>
			{items?.map((item, i) => (
				<BreadcrumbsItem key={`breadcrumbs-${i}`} {...item} />
			))}
		</ul>
	) : null;
};

export const BreadcrumbsItem: FC<Breadcrumb> = ({ textTitle, url }) => {
	return (
		<li className={css.breadcrumbsItem}>
			{url ? (
				<a href={url} className={css.breadcrumbsLink}>
					{textTitle}
				</a>
			) : (
				textTitle
			)}
		</li>
	);
};
