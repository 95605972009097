/*
 * Пример https://swr.vercel.app/examples/infinite-loading
 */
import useSWRInfinite from 'swr/infinite';
import { ReturnedSWRDataLazy, PaginatedData } from '@api/hooks/types';
import { getDataMock } from '@api/mock';
import { getData } from '@src/api';

interface UseDataLazyProps {
	url?: string;
	total?: number;
	limit?: number;
	offset?: number;
	query?: string;
	transform?: (res: any) => any;
}

const transformData = (res: any) => res;

export const useDataLazy = <T>(
	{ url, query, total = 0, limit = 10, transform = transformData }: UseDataLazyProps,
	needMock: boolean = false
): ReturnedSWRDataLazy<T> => {
	query = query ? `&${query}` : ``;

	const getKey = (index: number, prevData: PaginatedData<T>) => {
		// Skip data fetching
		if (!url) return null;

		// Check reach end and skip
		if (prevData && !prevData?.navigation?.next) return null;

		// Regular request
		return `${url}?limit=${limit}&pageNumber=${index + 1}${query}`;
	};

	const fetcher = needMock ? getDataMock : getData;

	const { data, error, size, setSize, isValidating } = useSWRInfinite(getKey, fetcher, {
		initialSize: 1,
		revalidateAll: false,
		revalidateFirstPage: false,
	});

	const result = data ? data.reduce((acc, item) => [...acc, ...transform(item)], []) : data;

	const isLoading = !data && !error && !!url;
	const isLoadingMore = isLoading || (size > 0 && !!data && typeof data[size - 1] === 'undefined');
	const isEmpty = data?.[0]?.length === 0;
	const isReachingEnd = isEmpty || (result && result.length >= Number(total));
	const isRefreshing = isValidating && !!data && data.length === size;

	return {
		data: result,
		isError: error && !!url,
		isLoading: !error && !data && !!url,
		isLoadingMore,
		isReachingEnd,
		isRefreshing,
		size,
		setSize,
	};
};
