import css from './VideoCursor.module.scss';
import classnames from 'classnames';
import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import { Move } from '@utils';
import { Typography } from '@src/shared';

interface SliderCursorProps extends HTMLAttributes<HTMLElement> {
	visible?: boolean;
	playing?: boolean;
	textPlay?: string;
	textPause?: string;
}

const fadeDelay = 1600;

export const VideoCursor: FC<SliderCursorProps> = React.memo(
	({ visible, playing, textPlay = 'Воспроизвести', textPause = 'Остановить', className }) => {
		const [point, setPointRef] = useState<HTMLElement | null>(null);
		const [localActive, setLocalActive] = useState(true);

		useEffect(() => {
			if (!point) return;

			const onMouseMove = ({ x, y }: { x: number; y: number }) => {
				point.style.top = `${(y + 0.5) * 100}%`;
				point.style.left = `${(x + 0.5) * 100}%`;
			};

			Move.addListener(onMouseMove);

			return () => {
				Move.removeListener(onMouseMove);
			};
		}, [point]);

		useEffect(() => {
			let timer = 0;

			const onMouseMove = () => {
				window.clearTimeout(timer);
				setLocalActive(true);

				timer = window.setTimeout(() => {
					setLocalActive(false);
				}, fadeDelay);
			};

			window.addEventListener('mousemove', onMouseMove);

			return () => {
				window.removeEventListener('mousemove', onMouseMove);
			};
		}, []);

		const label = playing ? textPause : textPlay;
		const active = playing ? visible && localActive : visible;

		return (
			<div
				ref={setPointRef}
				className={classnames(className, css.point, { [css.isActive]: active })}
				aria-hidden="true">
				<Typography className={css.cursor} size="sm">
					{label}
				</Typography>
			</div>
		);
	}
);
